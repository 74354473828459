import { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import RightPanelModal from '../../modal/RightPanelModal';
import MaterialIcon from '../../commons/MaterialIcon';
import { PROSPECT_RIGHT_PANEL_WIDTH } from '../../../utils/Utils';
import PreCallPlan from './PreCallPlan';
import IdfTooltip from '../../idfComponents/idfTooltip';

const GeneratePreCallPlanModel = ({ open, setOpen, profileInfo }) => {
  return (
    <RightPanelModal
      showModal={open}
      setShowModal={setOpen}
      containerWidth={PROSPECT_RIGHT_PANEL_WIDTH}
      Title={
        <div className="d-flex py-2 align-items-center">
          <MaterialIcon
            icon="text_snippet"
            clazz="font-size-xl text-white bg-yellow icon-circle p-1 mr-2"
          />
          <h4 className="mb-0 d-flex align-items-center gap-1">
            Company Snapshot{' '}
            <IdfTooltip text="Auto Generated by Identifee Digital Assistant (IDA)">
              <MaterialIcon icon="auto_awesome" />
            </IdfTooltip>{' '}
          </h4>
        </div>
      }
    >
      <PreCallPlan profileInfo={profileInfo} />
    </RightPanelModal>
  );
};

const PreCallPlanPanel = ({ profileInfo }) => {
  const [openPanel, setOpenPanel] = useState(false);

  const onLightbulbClick = (e) => {
    setOpenPanel(true);
  };

  return (
    <>
      <OverlayTrigger
        key={'niacs'}
        placement="bottom"
        overlay={
          <Tooltip
            id={`tooltip-niacs}`}
            className={`tooltip-profile font-weight-bold`}
          >
            <p>Generate Pre-Call Plan</p>
          </Tooltip>
        }
      >
        <div className="nav-item mb-2" onClick={onLightbulbClick}>
          <div className={`nav-icon cursor-pointer`}>
            <span className="material-symbols-rounded fs-5 text-white bg-yellow rounded-circle p-1">
              text_snippet
            </span>
          </div>
        </div>
      </OverlayTrigger>
      {openPanel && (
        <GeneratePreCallPlanModel
          open={openPanel}
          setOpen={setOpenPanel}
          profileInfo={profileInfo}
        />
      )}
    </>
  );
};

export default PreCallPlanPanel;
