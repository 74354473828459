import authHeader from './auth-header';
import BaseRequestService from './baseRequest.service';

const API_URL = process.env.REACT_APP_API_URL;

class OwnerServices extends BaseRequestService {
  async getOwners({ type, id, page, limit }) {
    return await this.get(`${API_URL}/api/${type}/${id}/owners`, {
      headers: authHeader(),
      params: {
        page,
        limit,
      },
    });
  }

  async addOwner({ type, id, user_id }) {
    return await this.post(
      `${API_URL}/api/${type}/${id}/owners/${user_id}`,
      {},
      {
        headers: authHeader(),
      }
    );
  }
}

export default new OwnerServices();
