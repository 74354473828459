import { useEffect, useState } from 'react';
import GenericTable from '../../../components/GenericTable';
import { setDateFormat } from '../../../utils/Utils';
import { cubeService } from '../../../services';
import TableSkeleton from '../../../components/commons/TableSkeleton';
import { ShortDescription } from '../../../components/ShortDescription';

const LessonProgressByUserTranscript = ({ userFilters = [], limit }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const filterData = userFilters?.length > 0 && userFilters[0]?.values;
  const filters = userFilters.filter((item) => item?.values);
  useEffect(() => {
    (async () => {
      setLoading(true);
      const results = await cubeService.loadCustomAnalytics({
        type: 'UserTranscript',
        users: filters[0]?.values,
        ...(Object.keys(limit)?.length > 0
          ? limit?.key !== 'all'
            ? { limit: parseInt(limit.key) }
            : {}
          : {}),
      });
      setData(results);
      setLoading(false);
    })();
  }, [filterData, limit]);

  const rows = data?.map((result, index) => {
    return {
      id: index,
      dataRow: [
        {
          key: 'name',
          component: (
            <div className="d-flex font-weight-semi-bold text-wrap text-truncate align-items-center">
              {result?.lesson}
            </div>
          ),
        },

        {
          key: 'assigned',
          component: result?.dueAt ? 'Yes' : 'No',
        },
        {
          key: 'dueAt',
          component: (
            <span className="d-flex align-items-center">
              {result?.dueAt && result?.dueAt !== null ? (
                <ShortDescription content={result?.dueAt} limit="15" />
              ) : (
                ' -- '
              )}
            </span>
          ),
        },
        {
          key: 'completed',
          component: result?.completed > 0 ? 'Yes' : 'No',
        },
        {
          key: 'lastAttempted',
          component: setDateFormat(result?.last_attempted, 'MM/DD/YYYY'),
        },
        {
          key: 'timesTaken',
          component: (
            <span>
              <span>{result?.taken}</span>
            </span>
          ),
        },
      ],
    };
  });
  const loader = () => {
    if (loading) return <TableSkeleton cols={6} rows={10} />;
  };
  return (
    <div className="mt-4">
      {loading ? (
        loader()
      ) : (
        <GenericTable
          checkbox={false}
          data={rows}
          loading={loading}
          exportToCSV={false}
          columns={[
            {
              key: 'Lesson.title',
              component: 'Lesson',
              width: '30%',
            },

            {
              key: 'AssignmentUser.assignmentId',
              component: 'Assigned',
              tooltipTitle: 'Lessons were part of a required assignment ',
            },
            {
              key: 'Assignment.dueAt',
              component: 'Due Date',
              tooltipTitle: 'Deadline to complete',
            },
            {
              key: 'LessonProgress.avgLessonsCompleted',
              component: 'Completed',
            },
            {
              key: 'LessonProgress.maxOfLastAttempt',
              component: 'Last Activity',
            },
            {
              key: 'LessonProgress.count',
              component: 'Times Taken',
            },
          ]}
          usePagination={false}
          noDataInDbValidation={true}
        />
      )}
    </div>
  );
};

export default LessonProgressByUserTranscript;
