export const usersColumns = [
  {
    key: 'user',
    orderBy: 'first_name',
    component: <span style={{ marginLeft: '5px' }}>User</span>,
  },
  {
    key: 'role',
    orderBy: 'role.name',
    component: <span>Profile</span>,
  },
  {
    key: 'group',
    orderBy: 'group.name',
    component: <span>Role</span>,
  },
  {
    key: 'last_login',
    orderBy: 'last_access',
    component: <span>Last Login</span>,
  },
  {
    component: <span></span>,
  },
];
