import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Label } from 'reactstrap';
import AutoComplete from '../AutoComplete';
import userService from '../../services/user.service';
import { useProfileContext } from '../../contexts/profileContext';
import NewKindOfSearchDropdown from '../commons/NewKindOfSearchDropdown';

export const ChecklistOwnerSelect = ({
  label = 'Checklist Owner',
  setOrgChecklist,
  orgChecklist,
  newKindOfSearch,
}) => {
  const { profileInfo } = useProfileContext();
  const [ownerSearch, setOwnerSearch] = useState({
    search: '',
  });
  const [ownerError, setOwnerError] = useState(false);
  const [users, setUsers] = useState([]);
  const [ownerCharactersRequire, setOwnerCharactersRequire] = useState('');
  const getUsers = async (search) => {
    const { data } = await userService.getUsers(
      { status: 'active', search },
      { page: 1, limit: 100 }
    );
    setUsers(data?.users);
  };

  const userStateChange = (e) => {
    const match = e.target.value.match(/([A-Za-z])/g);
    if (match && (newKindOfSearch || match.length >= 2)) {
      setOwnerCharactersRequire('');
      setOwnerSearch({
        ...ownerSearch,
        search: e.target.value,
      });
      getUsers(e.target.value);
    } else {
      setUsers([]);
      return setOwnerCharactersRequire(match?.length);
    }
  };
  useEffect(() => {
    getUsers();
  }, [ownerSearch, orgChecklist]);
  const clearState = () => {
    setOrgChecklist({
      ...orgChecklist,
      user: '',
      user_id: '',
    });
  };
  useEffect(() => {
    setOrgChecklist({
      ...orgChecklist,
      user: profileInfo,
      user_id: profileInfo?.id,
    });
  }, [profileInfo]);

  return (
    <>
      <FormGroup
        row
        className={`align-items-center mt-3 ${
          newKindOfSearch && 'new-kind-of-owner-select'
        }`}
      >
        <Label md={4} className="text-right font-size-sm col-form-label">
          {label}
        </Label>
        <Col md={8} className="pl-0">
          {newKindOfSearch ? (
            <NewKindOfSearchDropdown
              readOnly={true}
              pillButton="text-black bg-gray-5 fs-7 font-weight-medium py-1"
              preData={users}
              placeholder="Select Owner"
              errorClass="owner-btn py-1 pl-2 pr-4"
              optionsClass="owner-options"
              showAvatarButton
              onItemSelect={(item) => {
                setOrgChecklist({
                  ...orgChecklist,
                  user: item,
                  user_id: item?.id,
                });
                setOwnerError(false);
              }}
              currentSelected={orgChecklist?.user}
              onInputSearch={userStateChange}
            />
          ) : (
            <AutoComplete
              id="user_id"
              title={'Select Owner'}
              name="user_id"
              placeholder="Select Owner"
              showAvatar={false}
              customKey="name"
              customTitle={''}
              charactersRequire={ownerCharactersRequire}
              onChange={(e) => userStateChange(e)}
              clearState={(e) => clearState(e)}
              search={ownerSearch?.search}
              data={users}
              onHandleSelect={(item) => {
                setOrgChecklist({
                  ...orgChecklist,
                  user: item,
                  user_id: item?.id,
                });
                setOwnerError(false);
              }}
              selected={orgChecklist?.user?.name}
            />
          )}
          {ownerError && (
            <p className="text-danger mt-2 text-sm text-left">
              Please Select Owner
            </p>
          )}
        </Col>
      </FormGroup>
    </>
  );
};
