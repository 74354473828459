import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import routes from '../../../utils/routes.json';
import Table from '../../GenericTable';
import Skeleton from 'react-loading-skeleton';

const ContactTableModal = ({
  data = [],
  paginationInfo,
  onPageChange,
  handleEdit,
  selectedCourses,
  setSelectedCourses,
  loading,
  onClickRow,
  dataInDB,
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const columns = [
    {
      key: 'name',
      component: 'Name',
    },
    {
      key: 'title',
      component: 'Title',
    },
    {
      key: 'email',
      component: 'Email',
    },
    {
      key: 'phone',
      component: 'Phone',
    },
  ];

  const rows = data.map((item) => {
    const { id, first_name, last_name, email_work, phone_mobile, title } = item;
    const response = {
      ...item,
      dataRow: [
        {
          key: 'name',
          component: (
            <Link
              to={`${routes.contacts}/${id}/profile`}
              className="text-block font-weight-semi-bold pl-0"
            >
              {first_name} {last_name || '--'}
            </Link>
          ),
        },
        {
          key: 'title',
          component: (
            <span className="text-wrap text-truncate">{title || '--'}</span>
          ),
        },
        {
          key: 'email',
          component: <span>{email_work || '--'}</span>,
        },
        {
          key: 'phone',
          component: <span>{phone_mobile || '--'}</span>,
        },
      ],
    };
    return response;
  });

  return (
    <>
      {loading ? (
        <div className="px-3">
          <Skeleton count={3} height={10} className={'mb-2'} />
        </div>
      ) : (
        <div className="overflow-y-auto" style={{ height: '450px' }}>
          <Table
            selectedData={selectedCourses}
            setSelectedData={setSelectedCourses}
            selectAll={selectAll}
            headClass="bg-gray-table-head border-top"
            setSelectAll={setSelectAll}
            columns={columns}
            data={rows}
            paginationInfo={paginationInfo}
            onPageChange={onPageChange}
            onHandleEdit={handleEdit}
            onClick={onClickRow}
            emptyDataIcon="group"
            emptyDataText="This record doesn't have any contacts"
            title="Contacts"
            usePagination={false}
            dataInDB={dataInDB}
          />
        </div>
      )}
    </>
  );
};

export default ContactTableModal;
