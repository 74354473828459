import { CardBody } from 'reactstrap';
import MaterialIcon from '../../../commons/MaterialIcon';
import TenantThemeWrapperWidget from './TenantThemeWrapperWidget';

const ImproveVendorRelationshipsHorizontalWidget = ({
  widgetConfig,
  whenPrinting,
}) => {
  return (
    <TenantThemeWrapperWidget bodyStyles="d-flex flex-column position-relative border-0">
      <div className="text-left">
        <h5>{widgetConfig.header}</h5>
        <p
          className={`text-align-left mb-0 px-2 ${
            whenPrinting ? 'fs-9 sub-text' : 'font-size-sm2'
          }`}
        >
          {widgetConfig.subHeader}
        </p>
      </div>{' '}
      <div className="d-flex p-2">
        {widgetConfig.improvements.map((improvement, index) => {
          return (
            <div key={index} className="d-flex">
              <CardBody className="d-flex flex-column align-items-center">
                <div
                  className={`d-flex align-items-center bg-primary-lighter rounded-circle justify-content-center ${
                    whenPrinting ? 'mt-1' : 'mt-4'
                  }`}
                  style={{
                    width: whenPrinting ? 30 : 50,
                    height: whenPrinting ? 30 : 50,
                  }}
                >
                  <MaterialIcon
                    icon={improvement.icon}
                    size={whenPrinting ? 'fs-5' : 'fs-3'}
                    clazz={'text-primary'}
                  />
                </div>
                <p
                  className={`font-weight-semi-bold ${
                    whenPrinting ? 'fs-9 sub-text' : 'font-size-sm2 pb-2 pt-2'
                  } text-center`}
                >
                  {improvement.text}
                </p>{' '}
              </CardBody>
              {index < widgetConfig.improvements.length - 1 && (
                <div
                  className="bg-primary-lighter"
                  style={{
                    width: 'var(--rpt-widget-border-thickness)',
                    margin: '0 10px',
                  }}
                />
              )}
            </div>
          );
        })}
      </div>
    </TenantThemeWrapperWidget>
  );
};

export default ImproveVendorRelationshipsHorizontalWidget;
