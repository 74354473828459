import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Table from '../GenericTable';
import { isToFixedNoRound, setDateFormat } from '../../utils/Utils';
import routes from '../../utils/routes.json';
import IdfOwnersHeader from '../idfComponents/idfAdditionalOwners/IdfOwnersHeader';
import userService from '../../services/user.service';
import TableSkeleton from '../commons/TableSkeleton';
import Avatar from '../../components/Avatar';
import stageService from '../../services/stage.service';
import Skeleton from 'react-loading-skeleton';
import { ProgressBar } from 'react-bootstrap';
import TextOverflowTooltip from '../commons/TextOverflowTooltip';
import { LOST, WON } from '../../views/Deals/pipelines/Pipeline.constants';
import MaterialIcon from '../commons/MaterialIcon';
import { useModuleContext } from '../../contexts/moduleContext';
import ActivitiesHistory from '../ActivitiesHistory/ActivitiesHistory';
import stringConstants from '../../utils/stringConstants.json';
import Alert from '../Alert/Alert';
import AlertWrapper from '../Alert/AlertWrapper';
import TableRowHover from '../commons/TableRowHover';

const constants = stringConstants.deals.contacts.profile;

function calculateStageProgress(stages, currentStage) {
  const totalStages = stages.length;
  const currentFoundStage = stages.find(
    (stage) => stage.id === currentStage.id
  );
  if (!currentFoundStage) {
    return 0;
  }
  const currentPosition = currentFoundStage.position;
  return Math.round((currentPosition / totalStages) * 100);
}

const StageProgressBar = ({ stages = [], stage = {}, variant = 'primary' }) => {
  // get all the pipeline stages and then dynamic calculate the current stage
  const [perc, setPerc] = useState(0);
  const [loading, setLoading] = useState(false);
  const loadStageProgress = async () => {
    try {
      setLoading(true);
      if (stage?.status === WON || stage.status === LOST) {
        setPerc(100);
      } else {
        setPerc(calculateStageProgress(stages, stage));
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    loadStageProgress();
  }, []);
  return (
    <>
      {loading ? (
        <Skeleton height="10" width={80} />
      ) : (
        <ProgressBar
          now={parseInt(perc)}
          className="w-100 rounded"
          variant={variant}
        />
      )}
    </>
  );
};
const DealTable = ({
  data = [],
  paginationInfo,
  onPageChange,
  handleEdit,
  selectedCourses,
  setSelectedCourses,
  onClickRow,
  service,
  showLoading,
  onAddDeal,
  dataInDB,
  sortingTable,
  sortingOrder,
  selectedPipeline,
  viewType,
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const [me, setMe] = useState(null);
  const [stages, setStages] = useState([]);
  const history = useHistory();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  useEffect(() => {
    getCurrentUser();
  }, []);

  const getCurrentUser = async () => {
    const me = await userService
      .getUserInfo()
      .catch((err) => console.error(err));

    setMe(me);
  };

  const { moduleMap } = useModuleContext();

  const columns = [
    {
      key: 'title',
      component: `${moduleMap.deal.singular} Name`,
      orderBy: 'name',
    },
    {
      key: 'value',
      component: 'value',
      classes: 'pl-3',
      orderBy: 'amount',
    },
    {
      key: 'organization',
      component: `${moduleMap.organization.singular} Name`,
    },
    {
      key: 'stages',
      component: 'stage',
      width: '250px',
    },
    {
      key: 'contactPerson',
      component: 'Contact Person',
    },
    {
      key: 'expectedCloseDate',
      component: 'Closing Date',
      orderBy: 'date_closed',
    },
    {
      key: 'owner',
      component: 'Owners',
    },
  ];

  const loadStages = async () => {
    try {
      const data = await stageService.getStages(selectedPipeline.id);
      setStages(data || []);
    } catch (e) {
      setStages([]);
    }
  };

  const responseActivity = (msg) => {
    switch (msg) {
      case constants.activityAdded:
        return setSuccessMessage(constants.activityAdded);
      case constants.updatedActivity:
        return setSuccessMessage(constants.updatedActivity);
      case constants.activityError:
        return setErrorMessage(constants.activityError);
      case constants.errorUpdatedActivity:
        return setErrorMessage(constants.errorUpdatedActivity);
      default:
        return false;
    }
  };

  const rows = data.map((item) => {
    const {
      id,
      name,
      amount,
      organization,
      contact,
      stage,
      date_closed,
      assigned_user,
      owners,
    } = item;

    const isPrincipalOwner =
      me && item
        ? me?.role?.admin_access || assigned_user?.id === me?.id
        : false;

    const response = {
      ...item,
      bgColor: 'hover-actions',
      dataRow: [
        {
          key: 'title',
          component: (
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <Link
                  to={`${routes.dealsPipeline}/${item.id}`}
                  className="text-black text-truncate fw-bold"
                >
                  {name}
                </Link>
              </div>
            </div>
          ),
        },
        {
          key: 'value',
          component: (
            <span className="pl-2">{isToFixedNoRound(amount, 2)}</span>
          ),
        },
        {
          key: 'organization',
          component: (
            <div className="d-flex gap-1 align-items-center">
              <Avatar
                user={organization}
                classModifiers="bg-primary-soft text-primary"
                sizeIcon="fs-6"
                defaultSize="xs"
                type={organization?.name ? 'organization' : 'contact'}
              />
              {organization?.name}
            </div>
          ),
        },
        {
          key: 'stages',
          component: (
            <div className="d-flex align-items-center">
              <div className="flex-fill w-20 deal-progress-bar">
                <StageProgressBar
                  variant={
                    item?.status === WON
                      ? 'success'
                      : item?.status === LOST
                      ? 'danger'
                      : 'primary'
                  }
                  stages={stages}
                  stage={
                    item?.status === WON || item?.status === LOST ? item : stage
                  }
                />
              </div>
              <span className="flex align-items-center gap-2 flex-fill w-50 pl-2">
                <TextOverflowTooltip
                  text={
                    item?.status === WON
                      ? 'Closed Won'
                      : item?.status === LOST
                      ? 'Closed Lost'
                      : stage?.name
                  }
                />{' '}
                {item?.status === WON && (
                  <MaterialIcon
                    icon="thumb_up"
                    clazz="text-success position-relative"
                    size="fs-6"
                    style={{ top: 3 }}
                  />
                )}
                {item?.status === LOST && (
                  <MaterialIcon
                    icon="thumb_down"
                    clazz="text-red position-relative"
                    size="fs-6"
                    style={{ top: 4 }}
                  />
                )}
              </span>
            </div>
          ),
        },
        {
          key: 'contactPerson',
          component: (
            <>
              {contact?.first_name || contact?.last_name ? (
                <div className="d-flex gap-1 align-items-center">
                  <Avatar
                    defaultSize="xs"
                    isMultiple
                    user={contact}
                    hovering=""
                  />
                  <span>
                    {contact?.first_name} {contact?.last_name || ''}
                  </span>
                </div>
              ) : (
                <span> -- </span>
              )}
            </>
          ),
        },
        {
          key: 'expectedCloseDate',
          component: (
            <span>
              {date_closed ? setDateFormat(date_closed, 'MM/DD/YYYY') : '--'}
            </span>
          ),
        },
        {
          key: 'owner',
          component: (
            <>
              <TableRowHover
                onClick={() => {
                  history.push(`${routes.dealsPipeline}/${item.id}`);
                }}
              />

              <div
                className="position-absolute deal-add-circle z-index-100"
                style={{ left: 220, top: 15 }}
              >
                <ActivitiesHistory
                  icon={'add_circle'}
                  organizationId={organization?.id}
                  response={responseActivity}
                  dealId={id}
                  activities={item?.activities}
                  organization={organization}
                  deal={item}
                  owner={assigned_user}
                />
              </div>
              <div className="position-relative z-index-99">
                <IdfOwnersHeader
                  showToolTip
                  mainOwner={assigned_user}
                  service={service}
                  serviceId={id}
                  listOwners={owners}
                  isClickable={false}
                  onClick={(e) => {
                    e?.preventDefault();
                    e?.stopPropagation();
                  }}
                  defaultSize="xs"
                  maxOwners={0}
                  isprincipalowner={isPrincipalOwner}
                  small
                  addBtnBgGray
                />
              </div>
            </>
          ),
        },
      ],
    };
    return response;
  });

  useEffect(() => {
    if (selectedPipeline?.id && viewType === 'list') {
      loadStages();
    }
  }, [viewType, selectedPipeline]);

  return (
    <div className="table-responsive-md datatable-custom opportunity-list-view-table">
      <AlertWrapper>
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
        />
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
      </AlertWrapper>
      {showLoading ? (
        <TableSkeleton cols={6} rows={10} />
      ) : (
        <div className="deal-table">
          <Table
            stickyColumn="stickyColumn1 fixed-column-width"
            usePagination
            selectedData={selectedCourses}
            setSelectedData={setSelectedCourses}
            selectAll={selectAll}
            setSelectAll={setSelectAll}
            columns={columns}
            data={rows}
            headClass="bg-gray-table-head border-top"
            onPageChange={onPageChange}
            onHandleEdit={handleEdit}
            onClick={onClickRow}
            paginationInfo={paginationInfo}
            toggle={onAddDeal}
            emptyDataText="No opportunities available yet"
            title="Opportunities"
            dataInDB={dataInDB}
            sortingTable={sortingTable}
            sortingOrder={sortingOrder}
            noDataInDbValidation={true}
          />
        </div>
      )}
    </div>
  );
};

export default DealTable;
