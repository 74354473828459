import GenericTable from '../../../components/GenericTable';
import { setDateFormat } from '../../../utils/Utils';

const LessonStatistics = ({ results }) => {
  // We need total users count as well, thats why getting like this
  const rows = results?.map((result, index) => {
    const rank = index + 1;

    return {
      id: index,
      dataRow: [
        {
          key: 'rank',
          component: (
            <div className="rank-container font-weight-semi-bold">
              <span className={`rank-${rank}`}>{rank}</span>
            </div>
          ),
        },
        {
          key: 'lesson',
          component: (
            <div className="d-flex text-wrap font-weight-semi-bold align-items-baseline">
              {result?.lesson || '--'}
            </div>
          ),
        },
        {
          key: 'sumOfDuration',
          component:
            result?.duration !== null ? `${result?.duration} min` : '--',
        },
        {
          key: 'totalAttemps',
          component: result?.taken || '--',
        },
        {
          key: 'engagementRate',
          component: result?.uniqcountofusers || '--',
        },
        {
          key: 'selfAssigned',
          component: result?.taken - result?.taken_assigned || '--',
        },
        {
          key: 'assigned',
          component: result?.taken_assigned || '--',
        },
        {
          key: 'lastAttempted',
          component:
            setDateFormat(result?.last_attempted, 'MM/DD/YYYY') || '--',
        },
      ],
    };
  });
  const updatedRows = results?.map((item) => {
    const taken = item?.taken ?? 0;
    const takenAssigned = item?.taken_assigned ?? 0;

    const self = Math.round(((taken - takenAssigned) * 100) / taken) || 0;
    const assign = Math.round((takenAssigned * 100) / taken) || 0;

    return {
      lesson: item?.lesson,
      totalAttemps: Math.round(item?.taken) || 0,
      engagementRate: item?.uniqcountofusers || 0,
      selfAssigned: self,
      assigned: assign,
      sumOfDuration: item?.duration,
      lastAttempted: item?.last_attempted,
    };
  });
  return (
    <div>
      <div>
        <GenericTable
          checkbox={false}
          exportToCSV={true}
          data={rows}
          tableData={updatedRows}
          fileName="LessonStatisticsReport"
          columns={[
            {
              key: 'rank',
              component: 'Rank',
              tooltipTitle:
                'Sorted by number of times taken, from most to least',
              width: '5%',
            },
            {
              key: 'lesson',
              component: 'Lesson',
              width: '30%',
            },
            {
              key: 'sumOfDuration',
              component: 'Length',
              tooltipTitle: 'Lesson duration in minutes',
            },
            {
              key: 'totalAttemps',
              component: 'Completed',
              tooltipTitle:
                'Total times the lesson has been completed including repeats',
            },
            {
              key: 'engagementRate',
              component: 'Engagement',
              tooltipTitle: 'Unique users who have completed the lesson',
            },
            {
              key: 'selfAssigned',
              component: 'Self Study',
              tooltipTitle:
                'Total times completed that were not part of a required assignment',
            },
            {
              key: 'assigned',
              component: 'Assigned',
              tooltipTitle:
                'Total times completed as part of a required assignment',
            },
            {
              key: 'lastAttempted',
              component: 'Last Activity',
            },
          ]}
          usePagination={false}
          noDataInDbValidation={true}
        />
      </div>
    </div>
  );
};

export default LessonStatistics;
