import React, { useCallback, useContext, useMemo, useState } from 'react';
import DeleteConfirmationModal from '../../../components/modal/DeleteConfirmationModal';
import { AlertMessageContext } from '../../../contexts/AlertMessageContext';
import ReportService from '../../../services/report.service';
import {
  cleanDataWidgets,
  getNAICSWithIndustry,
  overflowing,
} from '../../../utils/Utils';
import {
  ActionTypes,
  ReportTypes,
} from '../../../components/reports/reports.constants';
import {
  getCycleDate,
  getReportName,
  updateJsonObject,
} from '../../../components/reports/reports.helper.functions';
import MaterialIcon from '../../../components/commons/MaterialIcon';
const SyncTMReportModal = ({
  show,
  setShow,
  report,
  organization,
  handleGenerateReport,
  setParentModalShow,
}) => {
  const { setSuccessMessage, setErrorMessage } =
    useContext(AlertMessageContext);

  const [loader, setLoader] = useState(false);
  const updateManualReportFromCoreData = (coreDataObject) => {
    const coreDataMetric = JSON.parse(coreDataObject.metrics_json);
    const estimatedTotalPayables = coreDataMetric.tmr_total_payables_volume;
    const estimatedTotalReceivables =
      coreDataMetric.tmr_total_receivables_volume;
    const paymentMethodsUsed = {
      Checks: coreDataMetric.tmr_checks_out_volume,
      ACH: coreDataMetric.tmr_ach_payables_volume,
      Wires: coreDataMetric.tmr_wires_payables_volume,
    };

    const typesOfReceivables = {
      'ACH/Checks': coreDataMetric.tmr_ach_checks_in_volume,
      Wires: coreDataMetric.tmr_wires_in_volume,
      'Cash Vault': coreDataMetric.tmr_cash_in_volume,
    };

    const paymentRisksValueD = coreDataMetric.tmr_positive_pay;
    const paymentRisksValueE = coreDataMetric.tmr_ach_positive_pay;
    const paymentRisks = {
      balance: {
        total: coreDataMetric.tmr_balances,
        isChecked: paymentRisksValueD > 0 && paymentRisksValueE > 0,
      },
      fraudPreventionProducts: updateJsonObject({
        'Positive Pay': paymentRisksValueD,
        'ACH Positive Pay': paymentRisksValueE,
      }),
    };

    const reportMapped = {
      value1: coreDataObject.customer_client_name || organization.name,
      ...getNAICSWithIndustry(organization),
      value2: getCycleDate(coreDataObject.analysis_date) || new Date(),
      value5: coreDataMetric.tmr_total_bank_fees,
      value6: coreDataMetric.tmr_earnings_credit_rate + '',
      value7: coreDataMetric.tmr_balances,
      value8: coreDataMetric.tmr_balances_needed_to_offset_fees,
      value9: coreDataMetric.tmr_earnings_allowance,
      opportunity: coreDataMetric.tmr_bank_difference + '',
      estimatedTotalPayables: Math.round(estimatedTotalPayables),
      estimatedTotalReceivables: Math.round(estimatedTotalReceivables),
      paymentMethodsUsed: updateJsonObject(paymentMethodsUsed),
      typesOfReceivables: updateJsonObject(typesOfReceivables),
      paymentRisks,
      totalFees: coreDataMetric.tmr_total_fees_paid,
      coreData: {
        report_id: coreDataObject.report_id || coreDataObject.report_ID,
        tenant_id: coreDataObject.tenant_id,
        tenant_name: coreDataObject.tenant_name,
        lead_composite_account_token:
          coreDataObject?.lead_composite_account_token,
      },
    };

    return {
      name: reportMapped.value1,
      date: reportMapped.value2,
      type: ReportTypes.Treasury,
      manualInput: {
        widgets: cleanDataWidgets(report.widgets),
        reportPages: report.reportPages,
        glossary: report.glossary,
        ...reportMapped,
        isAuto: true, // track whether this was generated by core
      },
    };
  };

  const handleConfirmDeleteReport = async () => {
    try {
      setLoader(true);
      // first get new data by calling core data api
      const customerId = organization.customer_id;
      const { data: coreDataReport } = await ReportService.getCoreData(
        customerId
      );
      const newCoreData = updateManualReportFromCoreData(coreDataReport[0]);
      await ReportService.updateReport(report.reportId, newCoreData);
      overflowing();
      setParentModalShow(false);
      setLoader(false);
      handleGenerateReport(report, ActionTypes.UPDATE, {
        ...newCoreData,
        reportId: report.reportId,
        key: report.reportId,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const SyncModalBody = ({ text }) => {
    return (
      <div>
        <div className="d-flex justify-content-center align-items-center">
          <MaterialIcon rounded icon="refresh" clazz="font-size-4em" />
        </div>
        <hr />
        <h4>{text}</h4>
        <ul className="list-disc">
          {[{ ...report, title: getReportName(report) }].map((item) => (
            <li className="font-weight-medium ml-4" key={item?.id}>
              <p className="mb-1">{item?.title}</p>
            </li>
          ))}
        </ul>
      </div>
    );
  };
  return (
    <DeleteConfirmationModal
      showModal={show}
      setShowModal={setShow}
      setSelectedCategories={[{ ...report, title: getReportName(report) }]}
      customEvent={handleConfirmDeleteReport}
      loader={loader}
      customBody={
        <SyncModalBody text="Are you sure you want to resync this Report?" />
      }
      itemsConfirmation={[{ ...report, title: getReportName(report) }]}
      description="Are you sure you want to resync this Report?"
      itemsReport={[]}
      setErrorMessage={setErrorMessage}
      setSuccessMessage={setSuccessMessage}
      positiveBtnText="Yes, Resync"
      positiveBtnIcon="refresh"
    />
  );
};

const useResyncTreasuryReportModal = (
  handleGenerateReport,
  setParentModalShow
) => {
  const [showModal, setShowModal] = useState(false);
  const [report, setReport] = useState('');
  const [organization, setOrganization] = useState('');

  const SyncTMReportModalCallback = useCallback(() => {
    return (
      <SyncTMReportModal
        show={showModal}
        setShow={setShowModal}
        report={report}
        organization={organization}
        handleGenerateReport={handleGenerateReport}
        setParentModalShow={setParentModalShow}
      />
    );
  }, [
    showModal,
    setShowModal,
    report,
    setReport,
    organization,
    setOrganization,
  ]);

  return useMemo(
    () => ({
      setShowModal,
      setReport,
      setOrganization,
      SyncTMReportModal: SyncTMReportModalCallback,
    }),
    [setShowModal, SyncTMReportModalCallback]
  );
};

export default useResyncTreasuryReportModal;
