import axios from 'axios';
import authHeader from './auth-header';
const API_URL = process.env.REACT_APP_API_URL;

class AuditService {
  async getAudits(
    { resourceId, parentResourceId, ...restProps },
    { page = 1, limit = 15 }
  ) {
    const response = await axios.get(`${API_URL}/api/audits`, {
      headers: authHeader(),
      params: {
        resourceId,
        parentResourceId,
        limit,
        page,
        ...restProps,
      },
    });

    return response.data;
  }

  async getAuditsWithAssociations(
    { resourceId, ...restProps },
    { page = 1, limit = 15 }
  ) {
    const response = await axios.get(`${API_URL}/api/auditsWithAssociations`, {
      headers: authHeader(),
      params: {
        resourceId,
        limit,
        page,
        ...restProps,
      },
    });

    return response.data;
  }
}

export default new AuditService();
