import React, { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import moment from 'moment-timezone';

import Alert from '../components/Alert/Alert';
import {
  DEFAULT_LANGUAGE,
  SERVER_ERROR,
  SUCCESS_INFO,
  LOGIN_INFO,
  CHANGE_LOGIN_INFO,
  UPLOAD_ERROR,
  TIMEZONE_DESCRIPTION,
  SAVE,
} from '../utils/constants';
import stringConstants from '../utils/stringConstants.json';
import { useProfileContext } from '../contexts/profileContext';
import Avatar from '../components/Avatar';
import userService from '../services/user.service';
import FormItem from '../components/profile/FormItem';
import Loading from '../components/Loading';
import ModalAvatar from '../components/modal/ModalAvatar';
import filesService from '../services/files.service';
import { base64ToBlob } from '../utils/Utils';
import AlertWrapper from '../components/Alert/AlertWrapper';
import IdfDropdownSearch from '../components/idfComponents/idfDropdown/IdfDropdownSearch';
import IdfFormInput from '../components/idfComponents/idfFormInput/IdfFormInput';
import useEnvSettings from '../hooks/useEnvSettings';

const timezone = `(${moment
  .parseZone(moment().format())
  .format('Z')}) ${moment.tz.guess()}`;

function Profile({ setActiveTab }) {
  const userInputs = stringConstants.users.inputs;
  const [loading, setLoading] = useState(false);
  const [firstLoading, setFirstLoading] = useState(false);
  const { profileInfo, setProfileInfo } = useProfileContext();
  const [userInfo, setUserInfo] = useState({
    firstName: '',
    lastName: '',
    phone: '1 631-860-5659',
    email: '',
    title: '',
    language: DEFAULT_LANGUAGE,
    datetimeFormat: DEFAULT_LANGUAGE,
    timezone: '', // timeZone.value
    currency: 'USD',
  });
  const [loadingAvatar, setLoadingAvatar] = useState(false);
  const [openModalAvatar, setOpenModalAvatar] = useState(false);
  const { disableProfileChanges, disablePasswordChanges } = useEnvSettings();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  useEffect(() => {
    async function getUser() {
      setFirstLoading(true);

      const {
        id,
        first_name: firstName,
        last_name: lastName,
        email,
        avatar,
        title,
        phone,
      } = profileInfo || {};

      setUserInfo({
        id,
        firstName,
        lastName,
        email,
        avatar,
        title,
        phone,
      });

      setFirstLoading(false);
    }

    getUser();
  }, [profileInfo]);

  const onHandleSaveAvatar = async ({ src, name }) => {
    setLoadingAvatar(true);
    const form = new FormData();
    form.append('file', await base64ToBlob(src), name);
    const avatarResult = await userService.uploadAvatar(form).catch((_) => {
      setErrorMessage(UPLOAD_ERROR);
    });
    const result = avatarResult?.data;

    setProfileInfo((prev) => ({
      ...prev,
      avatar: result.data.id,
      avatarSrc: src,
    }));

    setUserInfo((prev) => ({
      ...prev,
      avatar: result.data.id,
      avatarSrc: src,
    }));

    if (result?.data?.id) {
      await updateAvatarUser(result.data.id);
      setLoadingAvatar(false);
    }
  };

  function onHandleChange(e) {
    const target = e.target;
    let inputValue = target.value;
    if (inputValue.trim() === '') {
      inputValue = '';
    }
    setUserInfo({
      ...userInfo,
      [target.name]: inputValue,
    });
  }

  const onHandleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const userInfoBody = { ...userInfo };

    const resp = await userService
      .updateUserInfoById(profileInfo.id, {
        ...userInfoBody,
        first_name: userInfoBody.firstName,
        last_name: userInfoBody.lastName,
      })
      .catch((err) => console.log(err));
    setProfileInfo(userInfoBody);
    if (!resp) {
      setErrorMessage(SERVER_ERROR);
    } else {
      setSuccessMessage(SUCCESS_INFO);
    }

    setLoading(false);
    setProfileInfo((prev) => ({
      ...prev,
      first_name: userInfoBody.firstName,
      last_name: userInfoBody.lastName,
    }));
  };

  const updateAvatarUser = async (avatarId) => {
    const dataSend = {
      avatar: avatarId,
    };

    const resp = await userService
      .updateUserInfoById(profileInfo.id, dataSend)
      .catch((err) => console.log(err));

    if (!resp) {
      setErrorMessage(SERVER_ERROR);
    } else {
      setSuccessMessage(SUCCESS_INFO);
    }
    setOpenModalAvatar(false);
  };

  const removeFile = async () => {
    setLoadingAvatar(true);
    filesService
      .removeFileById(userInfo.avatar)
      .then(() => {
        updateAvatarUser(null);

        setProfileInfo((prev) => ({
          ...prev,
          avatar: null,
          avatarSrc: null,
        }));

        setUserInfo((prev) => ({
          ...prev,
          avatar: null,
          avatarSrc: null,
        }));
        setLoadingAvatar(false);
      })
      .catch(() => {
        setErrorMessage('error');
      });
  };

  return (
    <>
      <AlertWrapper>
        <Alert
          message={errorMessage}
          setMessage={setErrorMessage}
          color="danger"
        />
        <Alert
          message={successMessage}
          setMessage={setSuccessMessage}
          color="success"
        />
      </AlertWrapper>

      <ModalAvatar
        open={openModalAvatar}
        onHandleClose={() => setOpenModalAvatar(false)}
        userInfo={userInfo}
        onRemove={removeFile}
        loading={loadingAvatar}
        onSaveAvatar={onHandleSaveAvatar}
        type="profile"
      />
      <div className="card mb-3 shadow-none border-0 mb-lg-5">
        {firstLoading && <Loading bars={true} />}

        {!firstLoading && (
          <div className="card-body p-0">
            <div className="px-4 pt-2">
              {disableProfileChanges ? (
                <FormItem title="Profile Photo">
                  <div className="input-group input-group-sm-down-break">
                    <label htmlFor="avatar">
                      <div className="edit-avatar-container cursor-default">
                        <Avatar
                          user={userInfo}
                          defaultSize="lg"
                          classModifiers="max-wh"
                        />
                      </div>
                    </label>
                  </div>
                </FormItem>
              ) : (
                <FormItem title="Profile Photo">
                  <div
                    className="input-group input-group-sm-down-break"
                    onClick={() => setOpenModalAvatar(true)}
                  >
                    <label htmlFor="avatar">
                      <div className="edit-avatar-container">
                        <Avatar
                          user={userInfo}
                          defaultSize="lg"
                          classModifiers="max-wh"
                        />
                        <div className="edit-avatar-icon">
                          <i className="material-symbols-rounded fs-6">
                            photo_camera
                          </i>
                        </div>
                      </div>
                    </label>
                  </div>
                </FormItem>
              )}
            </div>
            <form onSubmit={onHandleSubmit}>
              <div className="px-4">
                <FormItem
                  title={userInputs.fullName.title}
                  labelFor="firstNameLabel"
                >
                  <div className="input-group input-group-sm-down-break ">
                    <input
                      type="text"
                      className="form-control mr-2 "
                      name="firstName"
                      id="firstNameLabel"
                      disabled={disableProfileChanges === true}
                      placeholder={`${userInputs.fullName.placeholderName}`}
                      aria-label={`${userInputs.fullName.placeholderName}`}
                      value={userInfo.firstName}
                      data-uw-styling-context="true"
                      onChange={onHandleChange}
                    />
                    <input
                      type="text"
                      className="form-control"
                      name="lastName"
                      id="lastNameLabel"
                      disabled={disableProfileChanges === true}
                      placeholder={`${userInputs.lastName.placeholderName}`}
                      aria-label={`${userInputs.lastName.placeholderName}`}
                      value={userInfo.lastName}
                      data-uw-styling-context="true"
                      onChange={onHandleChange}
                    />
                  </div>
                </FormItem>

                <FormItem title={userInputs.title.title} labelFor="titleLabel">
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    id="titleLabel"
                    disabled={disableProfileChanges === true}
                    placeholder={userInputs.title.placeholder}
                    aria-label={userInputs.title.placeholder}
                    value={userInfo.title}
                    data-uw-styling-context="true"
                    onChange={onHandleChange}
                  />
                </FormItem>

                <FormItem
                  title={userInputs.phoneNumber.title}
                  labelFor="phoneLabel"
                >
                  <IdfFormInput
                    className="mb-0 w-100"
                    placeholder={userInputs.phoneNumber.placeholder}
                    value={userInfo}
                    name={'phone'}
                    maxLength={14}
                    disabled={disableProfileChanges === true}
                    data-uw-styling-context="true"
                    onChange={(e) => onHandleChange(e)}
                    autocomplete="off"
                  />
                </FormItem>

                <FormItem title={userInputs.email.title} labelFor="emailLabel">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="emailLabel"
                    placeholder={userInputs.email.placeholder}
                    aria-label={userInputs.email.placeholder}
                    disabled={true}
                    value={userInfo.email}
                    data-uw-styling-context="true"
                    onChange={onHandleChange}
                  />
                </FormItem>

                <FormItem title="Timezone">
                  <IdfDropdownSearch
                    id="timezone"
                    className="mt-2"
                    name="timezone"
                    showAvatar={false}
                    data={[]}
                    value={timezone}
                    disabled
                  />
                  <span>{TIMEZONE_DESCRIPTION}</span>
                </FormItem>

                {!disablePasswordChanges && (
                  <FormItem>
                    <p className="mb-0">{LOGIN_INFO}</p>
                    <a
                      className="cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab(2);
                      }}
                    >
                      {CHANGE_LOGIN_INFO}
                    </a>
                  </FormItem>
                )}
              </div>
              {!disableProfileChanges && (
                <div className="d-flex border-top pt-3 px-4 mt-3 justify-content-end">
                  <button
                    type="submit"
                    className="btn btn-sm btn-primary"
                    data-uw-styling-context="true"
                  >
                    {loading ? <Spinner className="spinner-grow-xs" /> : SAVE}
                  </button>
                </div>
              )}
            </form>
          </div>
        )}
      </div>
    </>
  );
}

export default Profile;
