import React, { useEffect, useState } from 'react';
import InsightStatItem from '../InsightStatItem';

const ASSIGNMENTS_COMPLETED = 'Assignments Complete';
const TooltipTitlesGet = {
  'Number of Assignments':
    'An assignment may consist of one lesson or a bundled set of lessons. Each assignment is created by an administrator and given a unique name and due date.',
  'Assignments Complete': '% of all assignments complete for organization ',
};
const Headings = {
  'Number of Assignments': 'Number of Assignments',
};
export const LearnDashboardProgress = ({ data }) => {
  const [record, setRecord] = useState([]);
  useEffect(() => {
    const assignmentCompleted =
      (data[0]?.AssignmentCompleted * 100) / data[0]?.totalAssignments;
    const results = [
      {
        name: 'Number of Assignments',
        count: data[0]?.uniqueTotalAssignments,
        tooltipData: TooltipTitlesGet,
        headingsTitle: Headings,
      },
      {
        name: ASSIGNMENTS_COMPLETED,
        tooltipData: TooltipTitlesGet,
        count: assignmentCompleted,
      },
    ];
    setRecord(results);
  }, [data]);

  return (
    <>
      {record?.map((item, index) => (
        <InsightStatItem
          key={item}
          heading={item?.name}
          headingClass="font-size-md"
          countClass={'font-size-4em text-left'}
          subHeadingClass="font-size-xl"
          customClass={`col-md-4 pb-3 pl-0`}
          tooltipData={item.tooltipData}
          headingsTitle={item.headingsTitle}
          stat={item?.count}
          subtext={item?.name === ASSIGNMENTS_COMPLETED ? '%' : ''}
          hideAverage
        />
      ))}
    </>
  );
};
