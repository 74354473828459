import { CardFooter, Row, Col } from 'reactstrap';

import Pagination from './Pagination';
import { useProfileContext } from '../contexts/profileContext';
import { NO_SEARCH_RESULTS } from '../utils/constants';
import React, { useEffect, useRef, useState } from 'react';
import NoDataFound from './commons/NoDataFound';
import ButtonIcon from './commons/ButtonIcon';
import { isPermissionAllowed, setDateFormat } from '../utils/Utils';
import MaterialIcon from './commons/MaterialIcon';
import _ from 'lodash';
import useOutsideClickDropDown from '../hooks/useOutsideClickDropDown';
import { Dropdown } from 'react-bootstrap';
import TooltipComponent from './lesson/Tooltip';

const TableSorterMenu = ({
  show,
  setShow,
  children,
  column,
  flattenSortingOrder,
  handleSort,
  index,
  hasFreezeColumn,
}) => {
  const menuRef = useRef(null);
  const toggleRef = useRef(null);
  useOutsideClickDropDown(menuRef, show, setShow);

  useEffect(() => {
    if (show && index < 1 && hasFreezeColumn) {
      setTimeout(() => {
        const rect = toggleRef.current.getBoundingClientRect();
        menuRef.current.style.position = 'fixed';
        menuRef.current.style.zIndex = 1000;
        menuRef.current.style.transform = `translate3d(${rect.left + 20}px, ${
          rect.top - 70
        }px, 0px)`;
      }, 1);
    }
  }, [show]);
  return (
    <Dropdown show={show} onToggle={setShow}>
      <Dropdown.Toggle
        as="a"
        ref={toggleRef}
        style={{ height: 20 }}
        className="cursor-pointer no-caret text-center text-gray-700 d-inline-flex justify-content-center align-items-center"
      >
        {children}
      </Dropdown.Toggle>
      <Dropdown.Menu ref={menuRef} className="p-1 z-index-250">
        <div
          onClick={() => handleSort('ASC')}
          className={`${
            column.orderBy === flattenSortingOrder[0] &&
            flattenSortingOrder[1] === 'ASC'
              ? 'text-primary'
              : 'text-black'
          } p-2 d-flex bg-hover-gray rounded no-underline btn-link hover-link hoverLink cursor-pointer align-items-center gap-1`}
        >
          <MaterialIcon
            icon="keyboard_arrow_up"
            clazz="font-weight-normal"
            size="fs-4"
          />
          <span className="fs-7 font-weight-medium">ASC</span>
        </div>
        <div
          onClick={() => handleSort('DESC')}
          className={`${
            column.orderBy === flattenSortingOrder[0] &&
            flattenSortingOrder[1] === 'DESC'
              ? 'text-primary'
              : 'text-black'
          } p-2 d-flex bg-hover-gray btn-link no-underline hover-link rounded hoverLink cursor-pointer align-items-center gap-1`}
        >
          <MaterialIcon
            icon="keyboard_arrow_down"
            clazz="font-weight-normal"
            size="fs-4"
          />
          <span className="fs-7 font-weight-medium">DESC</span>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const TableSorter = ({
  column,
  flattenSortingOrder,
  sortingOrder,
  handleSort,
  index,
  hasFreezeColumn,
}) => {
  const [show, setShow] = useState(false);
  return (
    <div className="position-relative">
      <TableSorterMenu
        show={show}
        column={column}
        hasFreezeColumn={hasFreezeColumn}
        setShow={setShow}
        index={index}
        flattenSortingOrder={flattenSortingOrder}
        sortingOrder={sortingOrder}
        handleSort={handleSort}
      >
        {column.orderBy ? (
          <span
            style={{
              top: 3,
            }}
            className="action-items cursor-pointer position-relative d-flex align-items-center justify-content-center bg-hover-gray bg-gray-200 rounded-circle"
          >
            {!flattenSortingOrder.length ? (
              <MaterialIcon icon="keyboard_arrow_down" clazz="fs-6 fw-bold" />
            ) : (
              <>
                {flattenSortingOrder[flattenSortingOrder.length > 2 ? 2 : 1] ===
                  'DESC' && (
                  <MaterialIcon
                    icon="keyboard_arrow_down"
                    clazz={`fs-6 fw-bold ${
                      flattenSortingOrder[
                        flattenSortingOrder.length > 2 ? 2 : 1
                      ] === 'DESC' && column.orderBy === flattenSortingOrder[0]
                        ? 'text-primary'
                        : ''
                    }`}
                  />
                )}
                {flattenSortingOrder[flattenSortingOrder.length > 2 ? 2 : 1] ===
                  'ASC' && (
                  <MaterialIcon
                    icon="keyboard_arrow_up"
                    clazz={`fs-6 fw-bold ${
                      flattenSortingOrder[
                        flattenSortingOrder.length > 2 ? 2 : 1
                      ] === 'ASC' && column.orderBy === flattenSortingOrder[0]
                        ? 'text-primary'
                        : ''
                    }`}
                  />
                )}
              </>
            )}
          </span>
        ) : null}
      </TableSorterMenu>
    </div>
  );
};
export default function Table({
  className,
  actionPadding,
  checkbox,
  columns = [],
  columnsToExport = [],
  exportToCSV,
  headClass = 'bg-gray-table-head',
  data,
  stickyColumn,
  tableSize = 'table-xs',
  selectAll,
  setSelectAll,
  selectedData,
  setSelectedData,
  usePagination = true,
  paginationInfo,
  onPageChange,
  onHandleEdit,
  onClick,
  onClickCol,
  showLoading,
  componentAction,
  clickableCell,
  toggle,
  emptyDataText,
  emptyDataIcon = 'manage_search',
  tableData = [],
  title,
  fileName = 'report',
  dataInDB,
  noDataInDbValidation,
  sortingTable,
  sortingOrder = ['name', 'ASC'],
  setDeleteResults = () => {},
  permission = {},
  customClass,
  expandComponent,
  externalValues = [],
  stats = false,
  checkboxZIndex = 'z-index-100',
  customStyle = {
    position: 'absolute',
    top: '0px',
    right: '0px',
    height: '41px',
  },
}) {
  const { profileInfo } = useProfileContext();
  const isAdmin = profileInfo?.role?.admin_access;
  const flattenSortingOrder = _.flatten(sortingOrder); // for especially lesson/courses table :\ why?
  const ToComponent = expandComponent;
  const onCheck = () => {
    setSelectAll(!selectAll);
    setSelectedData(
      data?.length === selectedData?.length
        ? []
        : data?.map((user) => String(user.id))
    );
  };

  const onHandleSelect = (e, rowId, row) => {
    e.stopPropagation();

    const idsSelected = selectedData.slice();

    const exist = idsSelected.find((id) => id === rowId);

    if (exist) {
      const newIds = idsSelected.filter((id) => id !== rowId);
      setSelectAll(false);
      return setSelectedData(newIds);
    }
    setDeleteResults(row);
    idsSelected.push(rowId);

    setSelectedData(idsSelected);
  };

  const AddYourFirst = ({ title, onClick }) => {
    return (
      <div className="text-center">
        <ButtonIcon
          icon="add"
          label={title}
          classnames="btn-sm my-2"
          onclick={onClick}
        />
      </div>
    );
  };
  const buildCSVContent = (columns, exportData, externalValues = []) => {
    let csvContent = '';

    // Function to escape special characters for CSV
    const escapeForCSV = (value) => {
      if (value == null) return ''; // Handle null or undefined values
      value = value.toString().replace(/"/g, '""'); // Escape double quotes
      if (/[",\n]/.test(value)) {
        // Enclose in double quotes if needed
        value = `"${value}"`;
      }
      return value;
    };

    // Add external values if any
    if (externalValues?.length > 0) {
      externalValues.forEach((obj) => {
        Object.keys(obj).forEach((key) => {
          const extraRowData = [escapeForCSV(key), escapeForCSV(obj[key])];
          csvContent += extraRowData.join(',') + '\n';
        });
      });
    }

    // Add column headers
    const headers = columns?.map((column) => escapeForCSV(column?.component));
    csvContent += headers?.join(',') + '\n';

    // Add rows
    exportData?.forEach((result, index) => {
      // Define the function to format row data
      const formatRowData = (rowResult) => {
        return columns?.map((column) => {
          const key = column?.key;
          let value;
          if (key === 'LessonProgress.countOfCompleted') {
            value =
              rowResult['LessonProgress.countOfCompleted'] > 0 ? 'Yes' : 'No';
          } else if (key === 'rank') {
            value = index + 1;
          } else if (key === 'ChecklistProgress.checklistCompletedDate') {
            value =
              rowResult['ChecklistProgress.progress'] === 'Completed'
                ? setDateFormat(
                    rowResult['ChecklistProgress.checklistCompletedDate'],
                    'MM/DD/YYYY'
                  )
                : '';
          } else if (key === 'ChecklistProgress.initialDueDate') {
            value = setDateFormat(
              rowResult['ChecklistProgress.initialDueDate'],
              'MM/DD/YYYY'
            );
          } else if (key === 'Assignment.dueAt') {
            value = rowResult['Assignment.dueAt']
              ? setDateFormat(rowResult['Assignment.dueAt'], 'MM/DD/YYYY')
              : '--';
          } else if (key === 'LessonProgress.maxOfLastAttempt') {
            value = setDateFormat(
              rowResult['LessonProgress.maxOfLastAttempt'],
              'MM/DD/YYYY'
            );
          } else if (key === 'lastAttempted') {
            value = setDateFormat(rowResult.lastAttempted, 'MM/DD/YYYY');
          } else if (key === 'CourseProgress.maxOfLastAttempt') {
            value = setDateFormat(
              rowResult['CourseProgress.maxOfLastAttempt'],
              'MM/DD/YYYY'
            );
          } else if (
            key === 'AssignmentUser.assignmentId' ||
            key === 'AssignmentTeam.assignmentId'
          ) {
            value = rowResult['AssignmentUser.assignmentId']
              ? 'Yes'
              : rowResult['AssignmentTeam.assignmentId']
              ? 'Yes'
              : 'No';
          } else if (key === 'sumOfDurationCourse') {
            value = rowResult.sumOfDurationCourse / rowResult.count;
          } else {
            value = column?.render
              ? column?.render(rowResult[key])
              : rowResult[key];
          }
          return escapeForCSV(value);
        });
      };

      // Add the primary row
      const rowData = formatRowData(result);
      csvContent += rowData?.join(',') + '\n';

      // Add a second row if a specific condition is met
    });

    return encodeURI('data:text/csv;charset=utf-8,' + csvContent);
  };

  const exportCSV = () => {
    const encodedUri = buildCSVContent(
      columnsToExport.length > 0 ? columnsToExport : columns,
      tableData,
      externalValues
    );
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
  };

  const Title = () => {
    return (
      <div className="font-normal font-size-sm2 text-gray-search">
        {emptyDataText || NO_SEARCH_RESULTS}
      </div>
    );
  };
  const noData = () => {
    if (!dataInDB && !noDataInDbValidation) {
      return (
        <>
          <NoDataFound
            title={<Title />}
            icon={emptyDataIcon}
            iconStyle="text-gray-search font-size-4em"
            description={
              toggle && (
                <>
                  {permission?.collection ? (
                    isPermissionAllowed(permission?.collection, 'create') && (
                      <AddYourFirst onClick={toggle} title={`Add ${title}`} />
                    )
                  ) : (
                    <AddYourFirst onClick={toggle} title={`Add ${title}`} />
                  )}
                </>
              )
            }
            containerStyle="text-gray-900 my-6 py-6"
          />
        </>
      );
    } else if (!data?.length && !showLoading) {
      return (
        <NoDataFound
          icon="manage_search"
          containerStyle="text-gray-search my-6 py-6"
          title={<Title />}
        />
      );
    }
  };
  const [isScrolling, setIsScrolling] = useState(false);
  const tableContainerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (tableContainerRef.current.scrollLeft > 20) {
        setIsScrolling(true);
      } else {
        setIsScrolling(false);
      }
    };

    const container = tableContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);
  return (
    <>
      <div
        ref={tableContainerRef}
        className={`overflow-x-auto ${
          isScrolling ? 'scrolling' : ''
        } table-container ${actionPadding}`}
      >
        {exportToCSV && tableData?.length > 0 && (
          <div className="text-right">
            <ButtonIcon
              icon="ios_share"
              label="Export"
              onclick={exportCSV}
              classnames="m-3"
              style={customStyle}
              color="outline-primary"
            />
          </div>
        )}
        <table
          id={`datatable-${title}`}
          className={`${stickyColumn} ${
            isScrolling ? 'scrolling' : ''
          } table ${tableSize} ${
            stickyColumn ? '' : 'table-hover'
          } table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer ${
            className || ''
          }`}
          role="grid"
        >
          <thead className={headClass}>
            <tr role="row">
              {checkbox && (
                <th className="w-th-45 pr-0">
                  <div
                    className={`custom-control ${checkboxZIndex} custom-checkbox`}
                  >
                    <input
                      id={`datatableCheckAll-${title}`}
                      type="checkbox"
                      className="custom-control-input"
                      onChange={onCheck}
                      checked={selectAll}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={`datatableCheckAll-${title}`}
                    ></label>
                  </div>
                </th>
              )}
              {columns.map((column, i) => (
                <th
                  key={`${column.key}-${i}`}
                  className={`cursor-default ${column.classes || ''} ${
                    i > 0 ? 'px-2' : 'px-4'
                  } ${
                    column.key !== 'owner'
                      ? column.orderBy
                        ? 'sorting-custom'
                        : ''
                      : ''
                  }`}
                  rowSpan="1"
                  colSpan="1"
                  style={{
                    width: column.width,
                    display: !column.onlyAdmin ? '' : isAdmin ? '' : 'none',
                  }}
                >
                  <div
                    style={{ height: 20 }}
                    className="d-flex gap-1 position-relative align-items-center"
                  >
                    <span
                      className={
                        column?.tooltipTitle
                          ? 'd-flex align-items-center gap-1'
                          : ''
                      }
                    >
                      {column.component}{' '}
                      {column?.tooltipTitle && (
                        <TooltipComponent
                          title={column?.tooltipTitle}
                          titleLeftAlign="text-left"
                        >
                          <MaterialIcon icon={'info'} clazz={'font-size-sm'} />
                        </TooltipComponent>
                      )}
                    </span>
                    <TableSorter
                      column={column}
                      index={i}
                      hasFreezeColumn={stickyColumn !== undefined}
                      sortingOrder={sortingOrder}
                      flattenSortingOrder={flattenSortingOrder}
                      handleSort={(ascDesc) => {
                        sortingTable({
                          name: {
                            orderBy: column.orderBy,
                            clicked: ascDesc,
                          },
                        });
                      }}
                    />
                  </div>
                </th>
              ))}
              {onHandleEdit && (
                <th
                  className="sorting_disabled w-0"
                  rowSpan="1"
                  colSpan="1"
                  aria-label=""
                ></th>
              )}
            </tr>
          </thead>

          <tbody>
            {(dataInDB || noDataInDbValidation) &&
              data?.map((row, idx) => {
                const checked = !selectedData
                  ? false
                  : selectedData.find(
                      (selected) => String(selected) === String(row.id)
                    ) || false;

                return (
                  <>
                    <tr
                      key={(row.id || idx || '')?.toString()}
                      role="row"
                      className={`odd ${row?.bgColor} ${
                        stickyColumn ? 'tr-no-hover bg-white' : 'tr-hover'
                      } position-relative ${
                        onClick || clickableCell ? 'cursor-pointer' : ''
                      } ${checked ? 'bg-soft-primary' : ''}`}
                    >
                      {checkbox && (
                        <td className="pr-0">
                          <div
                            className={`custom-control position-relative ${checkboxZIndex} custom-checkbox`}
                            onClick={(e) =>
                              onHandleSelect(e, String(row.id), row)
                            }
                          >
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              checked={checked}
                              readOnly
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={row.id}
                            ></label>
                          </div>
                        </td>
                      )}
                      {row.dataRow.map((tdRow, idx) => (
                        <>
                          <td
                            key={(tdRow.key || idx || '')?.toString()}
                            onClick={() => {
                              if (tdRow.key !== 'action') {
                                if (onClickCol) {
                                  return onClickCol(row, tdRow);
                                } else {
                                  return onClick && onClick(row);
                                }
                              }
                            }}
                            className={`${customClass || ''} ${
                              tdRow.bgColor || ''
                            } ${idx > 0 ? 'px-2' : 'px-4'} ${
                              tdRow.key === 'action'
                                ? 'cursor-default'
                                : 'cursor-pointer'
                            }`}
                            style={{
                              display: !tdRow.onlyAdmin
                                ? ''
                                : isAdmin
                                ? ''
                                : 'none',
                            }}
                          >
                            {tdRow.component}
                          </td>
                        </>
                      ))}
                      {onHandleEdit && (
                        <td>
                          {permission?.collection ? (
                            isPermissionAllowed(
                              permission?.collection,
                              'edit'
                            ) && (
                              <span
                                className="cursor-pointer"
                                onClick={() => onHandleEdit(row)}
                              >
                                {!componentAction && (
                                  <i className="material-symbols-rounded fs-5">
                                    edit
                                  </i>
                                )}

                                {componentAction}
                              </span>
                            )
                          ) : (
                            <span
                              className="cursor-pointer"
                              onClick={() => onHandleEdit(row)}
                            >
                              {!componentAction && (
                                <i className="material-symbols-rounded fs-5">
                                  edit
                                </i>
                              )}

                              {componentAction}
                            </span>
                          )}
                        </td>
                      )}
                    </tr>
                    {/* This is currently being used in resources/prospects [TablePeopleProspect.jsx, TableCompanyProspect.jsx] section only,
                  if need it in more places i'll add a generic way  */}
                    {row.isExpanded && (
                      <tr className="p-0 tr-no-hover">
                        <React.Fragment>
                          <style>
                            {`
                            #r-${row.id} {
                              padding: unset !important;
                            }
                          `}
                          </style>
                        </React.Fragment>
                        <td
                          id={`r-${row.id}`}
                          className="text-left"
                          colSpan={row.dataRow.length + 1}
                        >
                          <div className="bg-gray-5 p-2 w-100">
                            <ToComponent prospect={row} inline={true} />
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                );
              })}
          </tbody>
        </table>

        {noData()}
      </div>

      {usePagination &&
        dataInDB &&
        (stats || paginationInfo.totalPages > 1) && (
          <CardFooter>
            <Row className="align-items-center">
              <Col xs={12} md={7} lg={8} xl={8} className="mb-2 mb-sm-0">
                {stats && stats()}
              </Col>
              {paginationInfo.totalPages > 1 && (
                <Col xs={12} md={5} lg={4} xl={4}>
                  <Pagination
                    paginationInfo={paginationInfo}
                    onPageChange={onPageChange}
                  />
                </Col>
              )}
            </Row>
          </CardFooter>
        )}
    </>
  );
}
