import React, { useCallback, useMemo, useState } from 'react';
import { RIGHT_PANEL_WIDTH } from '../../utils/Utils';
import { AddChecklistOrg } from './AddChecklistOrg';
import RightPanelModal from '../modal/RightPanelModal';

const ChecklistCreateModal = ({
  show,
  setShow,
  switchTab,
  setErrorMessage,
  setSuccessMessage,
}) => {
  return (
    <>
      <RightPanelModal
        showOverlay={true}
        containerBgColor={'pb-0'}
        containerWidth={RIGHT_PANEL_WIDTH}
        containerPosition={'position-fixed z-index-250'}
        headerBgColor="bg-gray-5"
        showModal={show}
        setShowModal={setShow}
        Title={
          <div className="d-flex py-2 align-items-center text-capitalize">
            <h3 className="mb-0">Add Checklist</h3>
          </div>
        }
      >
        <AddChecklistOrg
          activeTab={'checklist'}
          setSuccessMessage={setSuccessMessage}
          getChecklistsData={() => {}}
          setErrorMessage={setErrorMessage}
          setChecklistShow={setShow}
          isChecklistShow={show}
          switchTab={switchTab}
        />
      </RightPanelModal>
    </>
  );
};

const useCreateChecklist = (
  switchTab,
  { setErrorMessage, setSuccessMessage }
) => {
  const [showModal, setShowModal] = useState(false);

  const ChecklistCreateModalCallback = useCallback(() => {
    return (
      <ChecklistCreateModal
        show={showModal}
        setShow={setShowModal}
        switchTab={switchTab}
        setErrorMessage={setErrorMessage}
        setSuccessMessage={setSuccessMessage}
      />
    );
  }, [showModal, setShowModal, setErrorMessage, setSuccessMessage]);

  return useMemo(
    () => ({
      setShowModal,
      ChecklistCreateModal: showModal
        ? ChecklistCreateModalCallback
        : () => <div />,
    }),
    [setShowModal, ChecklistCreateModalCallback]
  );
};

export default useCreateChecklist;
