import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Spinner } from 'reactstrap';

const ConfirmationModal = ({
  show,
  title = 'Confirm Delete?',
  description = 'Are you sure you want to delete this?',
  handleClose,
  handleConfirm,
  isLoading,
}) => {
  return (
    <Modal
      show={show}
      className="team-confirmation-modal"
      animation={false}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h3">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-3">
        <p className="mb-0" dangerouslySetInnerHTML={{ __html: description }} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="white" size="sm" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="danger" size="sm" onClick={handleConfirm}>
          {isLoading ? (
            <Spinner className="spinner-grow-xs" />
          ) : (
            <span>Remove</span>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
