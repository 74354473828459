import React, { useState, useEffect } from 'react';

import SimpleModal from '../../modal/SimpleModal';
import contactService from '../../../services/contact.service';
import AutoComplete from '../../AutoComplete';
import { SEARCH_FOR_CONTACT } from '../../../utils/constants';
import { onInputSearch } from '../../../views/Deals/contacts/utils';
import dealService from '../../../services/deal.service';

const AddDealContact = ({
  organizationId,
  moduleMap,
  showAddContactModal,
  setShowAddContactModal,
  organization,
  setSuccessMessage,
  getContacts = () => {},
  setErrorMessage,
  deal,
  children,
}) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [allContacts, setAllContact] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dealFormData, setDealFormData] = useState({ ...deal });
  const [charactersContact, setCharactersContact] = useState('');
  const [searchContact, setSearchContact] = useState({
    search: '',
  });

  const handleSubmit = async () => {
    setIsLoading(true);
    delete dealFormData?.id;
    try {
      await dealService.updateDeal(deal?.id, dealFormData);
      setSuccessMessage('Contact person added successfully.');
      getContacts();
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  };

  const handleCloseAddContactModal = () => {
    setShowAddContactModal(false);
  };

  const createNewUser = async (name) => {
    const user = {
      first_name: name,
      last_name: '',
      organization_id: deal?.contact_organization_id,
    };
    const { data } = await contactService.createContact(user);
    setDealFormData({
      ...dealFormData,
      contact_person_id: data?.id,
    });
  };

  const getOrganizationContacts = async (id) => {
    const organizationContacts = await contactService
      .getContactsByOrganizationId(
        {
          organizationId: id,
          ...searchContact,
        },
        {
          page: 1,
          limit: 10,
        }
      )
      .catch((err) => {
        setErrorMessage(err?.message);
        console.log(err);
      });
    const { contacts } = organizationContacts || {};
    const updatedContacts = contacts?.map((c) => ({
      ...c,
      name: `${c.first_name} ${c.last_name}`,
    }));
    setAllContact(updatedContacts);
    const getAssignedContact = updatedContacts?.find((item) => {
      return deal?.contact_person_id === item?.id;
    });
    if (deal?.contact_person_id) {
      setSelectedItem(getAssignedContact);
    }
  };

  const handleContactChange = (e) => {
    const match = e.target.value.match(/([A-Za-z])/g);
    if (match && match.length >= 2) {
      setCharactersContact('');
      onInputSearch(e, searchContact, setSearchContact);
    } else {
      setCharactersContact(match?.length);
    }
    if (e.target.value === '') clearState('contact_person_id');
  };

  const clearState = (name) => {
    const updatedFormData = { ...dealFormData };
    delete updatedFormData[name];
    setDealFormData(updatedFormData);
  };

  const handleContactSelect = async (item) => {
    setDealFormData({
      ...dealFormData,
      contact_person_id: item?.id,
    });
    setSelectedItem(item?.name);
  };

  useEffect(() => {
    if (organizationId) {
      getOrganizationContacts(organizationId);
    }
  }, [organizationId]);

  return (
    <>
      <SimpleModal
        onHandleCloseModal={handleCloseAddContactModal}
        open={showAddContactModal}
        modalTitle={`Add ${moduleMap}`}
        buttonLabel={`Add ${moduleMap}`}
        moduleMap={moduleMap}
        isLoading={isLoading}
        buttonsDisabled={!selectedItem}
        handleSubmit={() => {
          handleSubmit();
        }}
      >
        <AutoComplete
          id="contact_person_id"
          placeholder={SEARCH_FOR_CONTACT.replace(/contact/, moduleMap)}
          name="contact_person_id"
          type={moduleMap?.toLowerCase()}
          charactersRequire={charactersContact}
          showAvatar={true}
          customKey="name"
          label={'Add Person Contact'}
          clearState={(name) => clearState(name)}
          onChange={(e) => handleContactChange(e)}
          data={allContacts}
          onHandleSelect={(item) => handleContactSelect(item)}
          search={searchContact.search}
          selected={selectedItem?.name || ''}
          createItem={(data) => {
            createNewUser(data);
          }}
        />
      </SimpleModal>
      {children}
    </>
  );
};

export default AddDealContact;
