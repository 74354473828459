import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AlertWrapper from '../Alert/AlertWrapper';
import Alert from '../Alert/Alert';
import RightPanelModal from '../modal/RightPanelModal';
import { RIGHT_PANEL_WIDTH } from '../../utils/Utils';
import TooltipComponent from '../lesson/Tooltip';
import MaterialIcon from '../commons/MaterialIcon';
import { Card, Col, FormCheck } from 'react-bootstrap';
import { CardBody, CardFooter, FormGroup, Label, Spinner } from 'reactstrap';
import AnimatedTabs from '../commons/AnimatedTabs';
import Skeleton from 'react-loading-skeleton';
import userService from '../../services/user.service';
import Table from '../GenericTable';
import { CANCEL_LABEL, paginationDefault } from '../../utils/constants';
import ConfirmationModal from './confirmationModal';
import TeamService from '../../services/teams.service';
import { DeactivateTeamModal } from './DeactivateTeamModal';
import stringConstants from '../../utils/stringConstants.json';
import { ActionTypes } from '../reports/reports.constants';
import SearchUsersAutocomplete from '../commons/SearchUsersAutocomplete';
import { usePagesContext } from '../../contexts/pagesContext';
import { CardButton } from '../layouts/CardLayout';
const constants = stringConstants.settings.teams;

const ViewTeamMembersModal = ({ show, setShow, team, refreshTeams }) => {
  const TABS = {
    Overview: 1,
    Timeline: 2,
  };
  const tabsData = [
    {
      tabId: TABS.Overview,
      title: 'Overview',
    },
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [isDeactivate, setIsDeactivate] = useState('');
  const [isActivate, setIsActivate] = useState(false);
  const [isDeactivateTeamName, setIsDeactivateTeamName] = useState('');
  const [teamMembers, setTeamMembers] = useState([]);
  const [teamManager, setTeamManager] = useState({});
  const [newTeamManager, setNewTeamManager] = useState({});
  const [newTeamMember, setNewTeamMember] = useState({ clear: 0 });
  const [loadingManager, setLoadingManager] = useState(false);
  const [updatingManager, setUpdatingManager] = useState(false);
  const [deleteMemberLoading, setDeleteMemberLoading] = useState(false);
  const [addingMemberLoading, setAddingMemberLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);
  const [paginationUsers, setPaginationUsers] = useState({});
  const [editTeamManager, setEditTeamManager] = useState(false);
  const [paginationUsersChange, setPaginationUsersChange] =
    useState(paginationDefault);
  const [loadingMembers, setLoadingMembers] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState(null);
  const [activeTab, setActiveTab] = useState(tabsData[0].tabId);
  const { pageContext, setPageContext } = usePagesContext();

  const loadTeamManager = async () => {
    setLoadingManager(true);
    try {
      const data = await TeamService.getTeamMembersById(team.id, {
        page: 1,
        limit: 10,
        isManager: true,
      });
      const manager = data?.data?.length ? data.data[0] : {};
      setTeamManager(manager);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingManager(false);
    }
  };
  const loadTeamMembers = async () => {
    setLoadingMembers(true);
    try {
      const data = await TeamService.getTeamMembersById(team.id, {
        ...paginationUsersChange,
        isManager: false,
      });
      setTeamMembers(data.data);
      setPaginationUsers(data.pagination);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingMembers(false);
    }
  };
  const renderTeamMembers = (userData = []) => {
    const membersColumns = [
      {
        key: 'Name',
        orderBy: '',
        component: <span>Name</span>,
      },
      {
        key: 'Email',
        orderBy: '',
        component: <span>Email</span>,
      },
      {
        key: 'Delete',
        orderBy: '',
        component: <span></span>,
      },
    ];
    const membersData = userData?.map((user) => ({
      ...user,
      dataRow: [
        {
          key: 'Name',
          component: (
            <div className="pl-0 d-flex align-items-center gap-1">
              {user?.user?.first_name + ' ' + user?.user?.last_name}
              {user?.isManager && (
                <span className="badge badge-success">Manager</span>
              )}
            </div>
          ),
        },
        {
          key: 'Email',
          component: <div>{user?.user?.email}</div>,
        },
        {
          key: 'delete',
          component: (
            <>
              {user?.isManager ? (
                ''
              ) : (
                <a
                  className="icon-hover-bg"
                  onClick={() => openModalDelete(user)}
                >
                  <MaterialIcon icon="delete" />
                </a>
              )}
            </>
          ),
        },
      ],
    }));
    return (
      <div>
        <Table
          columns={membersColumns}
          data={membersData}
          paginationInfo={paginationUsers}
          usePagination
          onPageChange={(newPage) => changePaginationPageUsers(newPage)}
          emptyDataText="No team members."
          title="Teams"
          dataInDB={teamMembers}
        />
      </div>
    );
  };

  const handleEditTeamManager = (e) => {
    e.preventDefault();
    setEditTeamManager(true);
  };
  const handleSetNewTeamManager = async (e) => {
    e.preventDefault();
    if (newTeamManager?.id || teamManager.userId) {
      if (newTeamManager?.id) {
        if (newTeamManager?.id !== teamManager.userId) {
          try {
            setUpdatingManager(true);
            // removing old user as team manager
            if (teamManager.userId) {
              await userService.inviteTeamUsers(teamManager.userId, [
                {
                  teamId: team.id,
                  isManager: false,
                },
              ]);
              // also delete that manager as team member too
              await TeamService.deleteTeamMember(teamManager.teamMemberId);
            }
            // make the selected user a team manager
            const { data: newUser } = await userService.inviteTeamUsers(
              newTeamManager?.id,
              [
                {
                  teamId: team.id,
                  isManager: true,
                },
              ]
            );
            const newManager = {
              user: newTeamManager,
              ...newUser[0],
            };
            setTeamManager(newManager);
            setSuccessMessage('Manager updated.');
            setPageContext({
              ...pageContext,
              UpdatedTeamMember: {
                team,
                manager: newManager,
              },
            });
            setEditTeamManager(false);
          } catch (err) {
            console.log(err);
            setErrorMessage(err.response.data.error);
          } finally {
            setUpdatingManager(false);
          }
        } else {
          setEditTeamManager(false);
        }
      } else {
        setEditTeamManager(false);
      }
    } else {
      setErrorMessage('Please select new team manager.');
    }
  };
  const handleCancelEditTeamManager = (e) => {
    e.preventDefault();
    setEditTeamManager(false);
  };
  const handleManagerName = () => {
    const managerExists = Object.keys(teamManager).length ? teamManager : null;
    return (
      <p className="my-2">
        <div className="d-flex gap-1 align-items-center">
          {!editTeamManager ? (
            <span>
              {managerExists ? (
                <>
                  {managerExists?.user?.first_name !== null
                    ? `${managerExists?.user?.first_name} ${managerExists?.user?.last_name}`
                    : managerExists?.user.email}
                </>
              ) : (
                '--'
              )}
            </span>
          ) : (
            <div className="flex-fill">
              <SearchUsersAutocomplete
                name="search_managers"
                onSelect={setNewTeamManager}
                onLoad={editTeamManager}
                selected={managerExists?.user?.name}
              />
            </div>
          )}
          {!editTeamManager ? (
            <TooltipComponent title="Edit">
              <a
                className="icon-hover-bg cursor-pointer"
                onClick={handleEditTeamManager}
              >
                <MaterialIcon icon="edit" size="fs-6" />{' '}
              </a>
            </TooltipComponent>
          ) : (
            <div className="d-flex align-items-center">
              {!updatingManager ? (
                <TooltipComponent title="Save">
                  <a
                    className="icon-hover-bg cursor-pointer"
                    onClick={handleSetNewTeamManager}
                  >
                    <MaterialIcon
                      icon="check_circle"
                      clazz="text-green text-success"
                      filled
                    />{' '}
                  </a>
                </TooltipComponent>
              ) : (
                <Spinner className="spinner-grow-xs" />
              )}
              {editTeamManager && (
                <TooltipComponent title="Cancel">
                  <a
                    className="icon-hover-bg cursor-pointer"
                    onClick={handleCancelEditTeamManager}
                  >
                    <MaterialIcon icon="cancel" clazz="text-gray-400" filled />{' '}
                  </a>
                </TooltipComponent>
              )}
            </div>
          )}
        </div>
      </p>
    );
  };

  const openModalDelete = (member) => {
    setMemberToDelete(member);
    setShowModalDelete(true);
  };

  const closeModalDelete = () => {
    setShowModalDelete(false);
    setMemberToDelete(null);
  };

  const confirmDelete = async () => {
    await deleteMember(memberToDelete);
    setShowModalDelete(false);
  };

  const setMemberCount = (arr, type) => {
    if (paginationUsers.count < 10) {
      setPaginationUsers({
        ...paginationUsers,
        count: arr.length,
      });
    } else {
      setPaginationUsers({
        ...paginationUsers,
        count:
          type === ActionTypes.ADD
            ? paginationUsers.count++
            : paginationUsers.count--,
      });
    }
  };
  const addMemberToTeam = async () => {
    try {
      const user = newTeamMember;
      if (teamMembers.find((usr) => usr.user.id === user.id)) {
        setErrorMessage('Member is already added.');
      } else {
        setAddingMemberLoading(true);
        const { data: newUser } = await userService.inviteTeamUsers(user.id, [
          {
            teamId: team.id,
            isManager: false,
          },
        ]);
        const updatedTeamMembers = [
          ...teamMembers,
          {
            ...newUser[0],
            user: {
              ...user,
              teamId: team.id,
              userId: user.id,
              isManager: false,
            },
          },
        ];
        setTeamMembers(updatedTeamMembers);
        setSuccessMessage('Member added.');
        setMemberCount(updatedTeamMembers, ActionTypes.ADD);
        setNewTeamMember({ clear: 1 });
      }
    } catch (err) {
      console.log(err);
      setErrorMessage(err.response.data.error);
    } finally {
      setAddingMemberLoading(false);
    }
  };
  const deleteMember = async (member) => {
    try {
      setDeleteMemberLoading(true);
      const usersToKeep = [...teamMembers].filter(
        (user) => user.teamMemberId !== member.teamMemberId
      );
      await TeamService.deleteTeamMember(member.teamMemberId);
      setTeamMembers(usersToKeep);
      setMemberCount(usersToKeep, ActionTypes.REMOVE);
      setSuccessMessage('Member removed.');
    } catch (err) {
      console.log(err);
      setErrorMessage(err.response.data.error);
    } finally {
      setDeleteMemberLoading(false);
    }
  };

  useEffect(() => {
    if (team?.id) {
      loadTeamMembers(team.id);
    }
  }, [show, paginationUsersChange?.page]);

  useEffect(() => {
    if (team?.id) {
      loadTeamManager();
    }
  }, [team, show]);

  const changePaginationPageUsers = (newPage) => {
    setPaginationUsersChange((prev) => ({ ...prev, page: newPage }));
  };

  const handleShowDeavtivateModal = (item) => {
    setIsDeactivateTeamName(item);
    setIsActivate(false);
    setIsDeactivate(true);
  };
  const handleShowActivateModal = (item) => {
    setIsDeactivateTeamName(item);
    setIsActivate(true);
  };
  const handleActiveDeactiveTeam = async () => {
    setIsLoading(true);
    try {
      await TeamService.updateTeam(isDeactivateTeamName.id || team.id, {
        isActive: isActivate,
      });
      setIsLoading(false);
      closeModal();
      if (isActivate) {
        setSuccessMessage(constants.edit.activated);
      } else {
        setSuccessMessage(constants.edit.TeamUpdateSuccess);
      }
      setShow(false);
      refreshTeams();
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const closeModal = async () => {
    setIsDeactivate(false);
    setIsActivate(false);
  };
  return (
    <>
      <AlertWrapper>
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
        />
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
      </AlertWrapper>
      <ConfirmationModal
        show={showModalDelete}
        title="Confirm Removing Member"
        isLoading={deleteMemberLoading}
        description={`This action will remove <b>${memberToDelete?.user?.first_name} ${memberToDelete?.user?.last_name}</b> from <b>${team?.name}</b>. `}
        handleClose={closeModalDelete}
        handleConfirm={confirmDelete}
      />
      <RightPanelModal
        showModal={show}
        setShowModal={setShow}
        showOverlay={true}
        containerBgColor={'bg-gray-5'}
        containerWidth={RIGHT_PANEL_WIDTH}
        containerPosition={'position-fixed'}
        Title={
          <div className="d-flex py-2 align-items-center justify-content-between">
            <div className="d-flex gap-2 align-items-center">
              <h3 className="mb-0">{team.name}</h3>
              <TooltipComponent title={team.description}>
                <MaterialIcon icon="info" />
              </TooltipComponent>
            </div>
            <div className="d-flex fs-7 ml-4 align-items-center">
              <FormCheck
                type="switch"
                id={`team-${team.id}`}
                label=""
                checked={team.isActive}
                onChange={() => {
                  if (team.isActive) {
                    handleShowDeavtivateModal(team);
                  } else {
                    handleShowActivateModal(team);
                  }
                }}
              />
              <span>{team.isActive ? 'Active' : 'Inactive'}</span>
            </div>
          </div>
        }
      >
        <CardBody className="h-100 overflow-y-auto">
          <AnimatedTabs
            activeTab={activeTab}
            toggle={(tab) => setActiveTab(tab.tabId)}
            tabsData={tabsData}
            tabActiveClass={'active mb-0'}
          />
          <Card className="rounded-0">
            <CardBody className="p-0">
              {loadingMembers ? (
                <div className="p-3">
                  <Skeleton count={5} height={10} className={'mb-2'} />
                </div>
              ) : (
                <>
                  {activeTab === TABS.Overview ? (
                    <>
                      <div className="p-3">
                        <FormGroup row className="align-items-center mb-0">
                          <Label md={4} className="font-size-sm">
                            Team Manager
                          </Label>
                          <Col md={8} className="pl-0">
                            {loadingManager ? (
                              <Skeleton height={10} width={200} />
                            ) : (
                              handleManagerName(team)
                            )}
                          </Col>
                        </FormGroup>
                        <FormGroup row className="align-items-center mb-0">
                          <Label md={4} className="font-size-sm">
                            Add New Member
                          </Label>
                          <Col md={8} className="pl-0">
                            <div className="d-flex align-items-center">
                              <div className="flex-fill">
                                <SearchUsersAutocomplete
                                  name="search_users"
                                  onSelect={setNewTeamMember}
                                  onLoad={show}
                                  clearField={newTeamMember}
                                />
                              </div>
                              {newTeamMember?.id ? (
                                <>
                                  <div className="d-flex align-items-center">
                                    {!addingMemberLoading ? (
                                      <TooltipComponent title="Save">
                                        <a
                                          className="icon-hover-bg cursor-pointer"
                                          onClick={addMemberToTeam}
                                        >
                                          <MaterialIcon
                                            icon="check_circle"
                                            clazz="text-green text-success"
                                            filled
                                          />{' '}
                                        </a>
                                      </TooltipComponent>
                                    ) : (
                                      <Spinner className="spinner-grow-xs" />
                                    )}
                                    <TooltipComponent title="Cancel">
                                      <a
                                        className="icon-hover-bg cursor-pointer"
                                        onClick={() =>
                                          setNewTeamMember({ clear: 1 })
                                        }
                                      >
                                        <MaterialIcon
                                          icon="cancel"
                                          clazz="text-gray-400"
                                          filled
                                        />{' '}
                                      </a>
                                    </TooltipComponent>
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </Col>
                        </FormGroup>
                      </div>
                      {renderTeamMembers(teamMembers)}
                    </>
                  ) : null}
                </>
              )}
            </CardBody>
          </Card>
        </CardBody>
        <CardFooter className="px-3 bg-white">
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="text-black-50 font-weight-medium mb-0">
              {`${team?.totalUsers || 0} Total Users`}
            </h5>
            <CardButton
              title={CANCEL_LABEL}
              variant={'light'}
              onClick={() => setShow(false)}
              className="btn-sm mr-2"
            />
          </div>
        </CardFooter>
      </RightPanelModal>
      <DeactivateTeamModal
        handleShowModal={isDeactivate}
        handleHideModal={closeModal}
        handleUpdateTeam={handleActiveDeactiveTeam}
        isTeamName={isDeactivateTeamName}
        isActivate={isActivate}
        isLoading={isLoading}
      />
    </>
  );
};
const useViewTeamMembersModal = ({ refreshTeams }) => {
  const [showModal, setShowModal] = useState(false);
  const [team, setTeam] = useState('');
  const ViewTeamMembersModalCallback = useCallback(() => {
    return (
      <ViewTeamMembersModal
        show={showModal}
        setShow={setShowModal}
        team={team}
        refreshTeams={refreshTeams}
      />
    );
  }, [showModal, setShowModal, team, setTeam]);

  return useMemo(
    () => ({
      setShowModal,
      setTeam,
      ViewTeamMembersModal: ViewTeamMembersModalCallback,
    }),
    [setShowModal, ViewTeamMembersModalCallback]
  );
};

export default useViewTeamMembersModal;
