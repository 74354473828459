import React, { useContext, useEffect, useState } from 'react';
import { useProfileContext } from '../../contexts/profileContext';
import notificationService from '../../services/notification.service';
import LookupLoader from '../loaders/LookupPeople';
import Skeleton from 'react-loading-skeleton';
import ButtonIcon from '../commons/ButtonIcon';
import { Card, CardBody, CardFooter, Spinner } from 'reactstrap';
import { overflowing } from '../../utils/Utils';
import { useHistory } from 'react-router';
import routes from '../../utils/routes.json';
import MaterialIcon from '../commons/MaterialIcon';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';
import LoadMoreButton from '../lesson/LoadMoreButton';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import Collapse from '@mui/material/Collapse';
import { Form } from 'react-bootstrap';
import IdfTooltip from '../idfComponents/idfTooltip';
import { AlertMessageContext } from '../../contexts/AlertMessageContext';
import AnimatedTabs from '../commons/AnimatedTabs';
import { useModuleContext } from '../../contexts/moduleContext';
import AuditActivityTimeline from '../ActivityTimeline/AuditActivityTimeline';

const NotificationsType = {
  AlertOnly: 'AlertOnly',
  AlertAndEmail: 'AlertAndEmail',
};
const AuditResourceTypes = {
  activity: { name: 'activity', icon: 'phone' },
  activityRequest: { name: 'activity request', icon: 'mail' },
  activityOwner: { name: 'activity owner', icon: 'person' },
  assignment: { name: 'assignment', icon: 'Assignment' },
  checklist: { name: 'checklist', icon: 'Checklist' },
  checklistItem: { name: 'checklist item', icon: 'Checklist' },
  contact: { name: 'contact', icon: 'person' },
  contactFollower: { name: 'contact follower', icon: 'person' },
  contactOwner: { name: 'Owner', icon: 'person' },
  course: { name: 'course', icon: 'book_2' },
  deal: { name: 'opportunity', icon: 'monetization_on' },
  dealFollower: { name: 'opportunity follower', icon: 'person' },
  dealOwner: { name: 'owner', icon: 'person' },
  field: { name: 'field', icon: 'text_fields' },
  file: { name: 'file', icon: 'attachment' },
  userNotificationSetting: {
    name: 'Notification',
    icon: 'notifications',
  },
  pipelineStage: {
    name: 'Opportunity & Stages',
    icon: 'view_carousel',
  },
  note: { name: 'note', icon: 'insert_comment' },
  organization: { name: 'organization', icon: 'corporate_fare' },
  organizationChecklist: {
    name: 'checklist',
    icon: 'Checklist',
  },
  organizationChecklistContact: {
    name: 'Organization Checklist Contact',
    icon: 'person',
  },
  organizationFollower: { name: 'Organization Follower', icon: 'person' },
  organizationOwner: { name: 'owner', icon: 'person' },
  organizationReport: { name: 'Organization Report', icon: 'Report' },
  product: { name: 'product', icon: 'Inventory' },
  report: { name: 'report', icon: 'Report' },
  team: { name: 'team', icon: 'groups' },
  teamMember: { name: 'teamMember', icon: 'person' },
  user: { name: 'user', icon: 'person' },
  comment: { name: 'comment', icon: 'insert_comment' },
  category: { name: 'category', icon: 'Category' },
  organizationChecklistProgress: {
    name: 'Organization Checklist Progress',
    icon: 'person_raised_hand',
  },
  organizationChecklistItemProgress: {
    name: 'Organization Checklist Item Progress',
    icon: 'gesture_select',
  },
  organizationChecklistOwner: {
    name: 'owner',
    icon: 'person',
  },
  organizationChecklistFile: {
    name: 'file',
    icon: 'upload_file',
  },
  dealProduct: {
    name: 'opportunity product',
    icon: 'shopping_cart',
  },
  stage: {
    name: 'stage',
    icon: 'stacked_bar_chart',
  },
};

const Links = {
  deal: routes.dealsPipeline, // deal detail route
  user: routes.contacts,
  owner: routes.contacts,
  users: routes.users,
};

const TABS = {
  ActivityLog: 1,
  Notifications: 2,
};

const tabsData = [
  {
    tabId: TABS.ActivityLog,
    title: 'Timeline',
  },
  {
    tabId: TABS.Notifications,
    title: 'Notifications',
  },
];

const ResourceLink = ({ audit, closeSidePanel }) => {
  const path = Links[audit.resourceType];
  const trailingPath = path !== Links.deal ? '/profile' : '';
  return (
    <>
      {path ? (
        <Link
          to={`${path}/${audit.resourceId}${trailingPath}`}
          onClick={closeSidePanel}
        >
          <a>{audit.resourceDisplayValue}</a>
        </Link>
      ) : (
        <p className="mb-0 d-inline-block font-weight-medium">
          {audit.resourceDisplayValue}
        </p>
      )}
    </>
  );
};

const ActorLink = ({ audit, closeSidePanel }) => {
  const path = Links.users;
  return (
    <Link to={`${path}/${audit.actorId}`} onClick={closeSidePanel}>
      <a>{audit.actorDisplayValue}</a>
    </Link>
  );
};
const NotificationSettings = ({ closeSidePanel, item }) => {
  const { from, to } = item?.changeLog?.update?.settings;

  const changes = Object.keys(from).reduce((acc, key) => {
    if (from[key] !== to[key]) {
      acc.push({
        field: key,
        from: from[key],
        to: to[key],
      });
    }
    return acc;
  }, []);

  return (
    <div>
      <div className="text-wrap">
        <ActorLink audit={item} closeSidePanel={closeSidePanel} />{' '}
        {changes?.length > 0 ? (
          changes?.map((change, index) => (
            <>
              <b>
                {String(
                  change.to === false
                    ? 'disabled'
                    : change.to === true
                    ? 'enabled'
                    : ''
                )}
              </b>{' '}
              {change.field}
              {index === changes?.length - 2
                ? ' and '
                : index === changes?.length - 1
                ? ' in '
                : ', '}
            </>
          ))
        ) : (
          <>{item.action}d </>
        )}
        {AuditResourceTypes[item.resourceType]?.name}
      </div>
      <div className="text-muted text-break">
        {item.resourceDisplayValue !== null
          ? item.resourceDisplayValue
          : item.resourceDisplayValue}
      </div>
    </div>
  );
};

const ChangeLog = ({ audit, closeSidePanel }) => {
  const { changeLog } = audit;
  const { associations } = changeLog;
  const { moduleMap } = useModuleContext();
  const path = Links.users;

  if (!associations || associations.length === 0) {
    return (
      <>
        {audit?.resourceType === 'userNotificationSetting' ? (
          <NotificationSettings closeSidePanel={closeSidePanel} item={audit} />
        ) : (
          <>
            <ActorLink audit={audit} closeSidePanel={closeSidePanel} /> updated
            a {audit.resourceType}{' '}
            <ResourceLink audit={audit} closeSidePanel={closeSidePanel} />
          </>
        )}
      </>
    );
  }

  const firstPart = associations.filter((asc) => asc.type !== 'parent');
  const secondPart = associations.filter((asc) => asc.type === 'parent');

  const associationMsg = firstPart.reduce((acc, curr, index) => {
    const name = curr?.resources[0]?.displayValue;

    const anchorTag = (
      <>
        {moduleMap.organization.singular}{' '}
        <Link to={`${path}/${curr?.resources[0]?.id}`} onClick={closeSidePanel}>
          {name}
        </Link>
      </>
    );
    if (index !== 0) {
      acc.push(', ');
    }
    acc.push(anchorTag);
    return acc;
  }, []);

  const associationResources =
    secondPart?.length > 0 &&
    secondPart[0]?.resources?.reduce((acc, curr, index) => {
      const name = curr.displayValue;
      const linkId = curr.id;
      let anchorTag;
      if (curr.type === 'organization') {
        anchorTag = (
          <>
            {moduleMap.organization.singular}{' '}
            <Link
              to={`${routes.companies}/${linkId}/organization/profile`}
              onClick={closeSidePanel}
            >
              {name}
            </Link>
          </>
        );
      } else if (curr.type === 'deal') {
        anchorTag = (
          <>
            {moduleMap.deal.singular}{' '}
            <Link to={`${Links.deal}/${linkId}`} onClick={closeSidePanel}>
              {name}
            </Link>
          </>
        );
      }
      acc.push(anchorTag);
      if (index < secondPart[0]?.resources?.length - 1) {
        acc.push(', ');
      }
      return acc;
    }, []);

  return (
    <>
      {audit.resourceType === 'pipelineStage' ? (
        <>
          <ActorLink audit={audit} closeSidePanel={closeSidePanel} />{' '}
          {audit.action}d a stage on{' '}
          {audit?.changeLog?.associations[0]?.resources[0]?.displayValue}
          {' in '}
          {AuditResourceTypes[audit.resourceType]?.name}
        </>
      ) : (
        <>
          <ActorLink audit={audit} closeSidePanel={closeSidePanel} />{' '}
          {audit.action}d a {AuditResourceTypes[audit.resourceType]?.name} and{' '}
          {associationMsg} on {associationResources}
        </>
      )}
    </>
  );
};

const NotificationCard = ({ notification, closeSidePanel }) => {
  const { audit } = notification;
  return (
    <div className="d-flex mb-1 border-bottom px-3 py-2">
      <div className="mr-2">
        <MaterialIcon
          icon={AuditResourceTypes[audit.resourceType]?.icon}
          clazz="font-size-2xl text-muted"
        />
      </div>
      <div className="flex-grow-1">
        <p className="mb-0 font-weight-medium">
          {audit.changeLog ? (
            <ChangeLog audit={audit} closeSidePanel={closeSidePanel} />
          ) : (
            <>
              <ActorLink audit={audit} closeSidePanel={closeSidePanel} />{' '}
              {audit.action}d a {audit.resourceType}{' '}
              <ResourceLink audit={audit} closeSidePanel={closeSidePanel} />
            </>
          )}
        </p>
        <div className="text-muted fs-8">
          {moment(audit.updatedAt).format('MMMM DD, YYYY hh:mm A')}
        </div>
      </div>
    </div>
  );
};

export const NotificationsList = ({
  showSelectComponentModal,
  setShowSelectComponentModal = () => {},
  detail = false,
  notificationsLimit = 7,
}) => {
  const { profileInfo } = useProfileContext();
  const limit = notificationsLimit;
  const [notifications, setNotifications] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, limit });
  const [loader, setLoader] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(tabsData[0].tabId);
  const [loading, setLoading] = useState(false);
  const { setSuccessMessage, setErrorMessage } =
    useContext(AlertMessageContext);
  const [settings, setSettings] = useState({
    associations: false,
    dealsUpdates: false,
    separateActivities: false,
    mentionsAndComments: false,
  });

  const [notificationType, setNotificationType] = useState(
    NotificationsType.AlertAndEmail
  );

  const updateObjectValues = (obj, onOff) => {
    const keys = Object.keys(obj);
    for (const key of keys) {
      obj[key] = onOff;
    }
    return obj;
  };

  const handleNotificationType = async (e) => {
    const { value } = e.target;
    // set settings object values to true/false
    const newSettings = updateObjectValues(
      settings,
      value === NotificationsType.AlertAndEmail
    );
    setNotificationType(value);
    setSettings(newSettings);
    try {
      setLoading(true);
      await notificationService.addSettings(newSettings);
      setSuccessMessage('Notifications setting saved.');
    } catch (error) {
      setErrorMessage(
        'Error saving notifications settings. Please check console for details.'
      );
    } finally {
      setLoading(false);
    }
  };
  const getNotifications = async (isLoadMore) => {
    !isLoadMore && setLoader(true);
    try {
      // for side panel showing max 7, in details page will make it paginated/load more like.
      const response = await notificationService.getNotifications(
        pagination.page,
        pagination.limit
      );
      const newNotifications = isLoadMore
        ? [...notifications, ...response?.data]
        : response?.data;
      setNotifications(newNotifications);
      setPagination(response?.pagination);
    } catch (e) {
      console.error(e);
    } finally {
      setLoader(false);
      setLoadMore(false);
    }
  };

  const NoNotificationPlaceholder = () => {
    return (
      <div className="p-4 mt-4 text-center d-flex flex-column align-items-center justify-content-center flex-wrap">
        <h2>
          Good day, {profileInfo?.first_name} {profileInfo?.last_name}!
        </h2>
        <div className="my-2">
          <img
            src="/img/notifications.png"
            className="w-100 rounded mb-2"
            alt=""
          />
        </div>
        <h4>No notifications at this point</h4>
      </div>
    );
  };

  const closeSidePanel = () => {
    overflowing();
    setShowSelectComponentModal();
  };

  const redirectToNotifications = () => {
    closeSidePanel();
    history.push(routes.notificationsAll);
  };

  const onPaginationChange = () => {
    setLoadMore(true);
    setPagination((prevState) => ({ ...prevState, page: prevState.page + 1 }));
  };

  useEffect(() => {
    if (showSelectComponentModal && activeTab === 2) {
      // call to get user notifications
      getNotifications();
    }
  }, [showSelectComponentModal, activeTab]);

  useEffect(() => {
    if (detail) {
      setLoadMore(true);
      getNotifications(true);
    }
  }, [pagination?.page]);

  return (
    <Card
      className={`border-0 ${
        activeTab === TABS.Notifications ? 'h-100' : ''
      } p-0`}
    >
      <CardBody
        className={`${
          activeTab === TABS.Notifications ? 'overflow-y-auto' : ''
        } p-0 pt-2`}
      >
        <div className="border-bottom pt-2">
          <AnimatedTabs
            activeTab={activeTab}
            toggle={(tab) => setActiveTab(tab.tabId)}
            tabsData={tabsData}
          />
        </div>
        {activeTab === TABS.Notifications ? (
          <>
            {loader ? (
              <LookupLoader
                count={7}
                circle={<Skeleton height={40} width={40} circle />}
                lineCount={2}
                containerStyle="mb-1 border-bottom px-3 py-2"
              />
            ) : (
              <div className="pt-2">
                <div className="d-flex align-items-center py-2 px-3 justify-content-between">
                  <div className="mb-0">
                    <div className="d-inline-flex align-items-center">
                      <label
                        className={`mr-1 font-size-sm font-weight-medium mb-0`}
                      >
                        Notification type
                      </label>
                      <IdfTooltip
                        placement="bottom"
                        text="Choose how you want to receive notifications."
                      >
                        <MaterialIcon
                          icon="info"
                          rounded
                          symbols
                          clazz={`mt-2`}
                        />
                      </IdfTooltip>
                      {loading && <Spinner className="ml-1 spinner-grow-xs" />}
                    </div>
                  </div>
                  <div>
                    <Form.Control
                      as="select"
                      className="comfort"
                      value={notificationType}
                      onChange={handleNotificationType}
                    >
                      <option key={1} value={NotificationsType.AlertOnly}>
                        Show Alert
                      </option>
                      <option key={2} value={NotificationsType.AlertAndEmail}>
                        Alert and Email notification
                      </option>
                    </Form.Control>
                  </div>
                </div>
                <hr className="my-1" />
                {notifications.length > 0 ? (
                  <TransitionGroup appear={true}>
                    {notifications.map((notification) => (
                      <Collapse key={notification.id}>
                        {
                          <NotificationCard
                            key={notification.id}
                            notification={notification}
                            closeSidePanel={closeSidePanel}
                          />
                        }
                      </Collapse>
                    ))}
                  </TransitionGroup>
                ) : (
                  <NoNotificationPlaceholder />
                )}
              </div>
            )}
          </>
        ) : (
          <>
            <AuditActivityTimeline
              resourceId=""
              parentResourceId=""
              parentAudit={true}
              closeSidePanel={closeSidePanel}
              customClass="pt-3"
            />
          </>
        )}
      </CardBody>
      {!loader && activeTab === TABS.Notifications && (
        <>
          {pagination?.count > limit && !detail ? (
            <CardFooter>
              <ButtonIcon
                label="View All"
                color="primary"
                classnames="btn-sm btn-block w-100"
                onclick={redirectToNotifications}
              />
            </CardFooter>
          ) : (
            <LoadMoreButton
              loading={loadMore}
              pagination={pagination}
              list={notifications}
              onClick={onPaginationChange}
              btnStyle="w-100"
              btnContainerStyle="p-3"
            />
          )}
        </>
      )}
    </Card>
  );
};
