import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Deals from '../../../components/peopleProfile/deals/Deals';
import Header from '../../../components/organizationProfile/Header';
import AddContent from '../../../components/peopleProfile/AddContent';
import organizationService from '../../../services/organization.service';
import Contacts from '../../../components/organizationProfile/contacts/Contacts';
import RelatedOrg from '../../../components/organizationProfile/relatedOrgs/RelatedOrg';
import Overview from '../../../components/organizationProfile/overview/Organization';
import RightBar from '../../../components/organizationProfile/overview/RightBar';
import AlertWrapper from '../../../components/Alert/AlertWrapper';
import Alert from '../../../components/Alert/Alert';
import stringConstants from '../../../utils/stringConstants.json';
import userService from '../../../services/user.service';
import fieldService from '../../../services/field.service';
import { useModuleContext } from '../../../contexts/moduleContext';
import {
  DetailTabs,
  getAccountsChildPermissions,
  isModuleAllowed,
  isPermissionAllowed,
} from '../../../utils/Utils';
import { useTenantContext } from '../../../contexts/TenantContext';
import naicsService from '../../../services/naics.service';
import AddOrganization from '../../../components/organizations/AddOrganization';
import Breadcrumb from '../../../utils/Breadcrumb';
import { LoadingDetailSkeleton } from '../../../components/LoadingDetailComponent';
import feedService from '../../../services/feed.service';
import activityService from '../../../services/activity.service';
import { PermissionsConstants } from '../../../utils/permissions.constants';
import checklistService from '../../../services/checklist.service';

const TABS = DetailTabs;

const Profile = ({ children }) => {
  const constants = stringConstants.deals.contacts.profile;
  const history = useHistory();
  const { organizationId, activityId } = useParams();
  const [showContactsModal, setShowContactsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [profileInfo, setProfileInfo] = useState({});
  const [refreshRecentFiles, setRefreshRecentFiles] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [refreshOwners, setRefresOwners] = useState(false);
  const [activityIdOpen, setActivityIdOpen] = useState(activityId);
  const [organization, setOrganization] = useState({});
  const [refreshTabCounts, setRefreshTabCounts] = useState(0);

  const [getUser, setGetUser] = useState({});
  const [me, setMe] = useState(null);
  const [openCloneModal, setOpenCloneModal] = useState(false);
  const [, setOpenList] = useState(false);
  const { moduleMap } = useModuleContext();
  const { tenant } = useTenantContext();
  const [tabCounts, setTabCounts] = useState({
    [TABS.Data]: 0,
    [TABS.Timeline]: 0,
    [TABS.Notes]: 0,
    [TABS.Checklist]: 0,
    [TABS.Activities]: 0,
    [TABS.Files]: 0,
  });
  const isPrincipalOwner =
    me && profileInfo
      ? me?.role?.admin_access ||
        me?.role?.owner_access ||
        profileInfo?.assigned_user_id === me?.id
      : false;

  const loadCounts = async () => {
    const updatedType = getAccountsChildPermissions(
      tenant.modules,
      'companies'
    );
    const queryParams = {
      page: 1,
      limit: 1,
      self: true,
    };

    const orgTabs = [
      {
        tabId: TABS.Data,
        module: 'engagement',
        count: 0,
        countApiCall: () => Promise.resolve({ pagination: { count: 0 } }),
      },
      {
        tabId: TABS.Timeline,
        module: `${updatedType}_timeline`,
        count: 0,
        countApiCall: () => Promise.resolve({ pagination: { count: 0 } }),
      },
      {
        tabId: TABS.Notes,
        module: `${updatedType}_notes`,
        count: 0,
        countApiCall: () =>
          feedService.getNote(
            { contactId: null, organizationId, dealId: null },
            queryParams
          ),
      },
      {
        tabId: TABS.Checklist,
        module: PermissionsConstants.Checklist.checklist,
        count: 0,
        countApiCall: () =>
          checklistService.getOrgCheckLists({ ...queryParams, organizationId }),
      },
      {
        tabId: TABS.Activities,
        module: `activities_company_activities`,
        count: 0,
        countApiCall: () =>
          activityService.getActivity(
            { contactId: null, organizationId, dealId: null },
            queryParams
          ),
      },
      {
        tabId: TABS.Files,
        module: `${updatedType}_files`,
        count: 0,
        countApiCall: () =>
          feedService.getFiles(
            { contactId: null, organizationId, dealId: null },
            queryParams
          ),
      },
    ];
    const enabledTabs = orgTabs?.filter((item) => {
      return isModuleAllowed(tenant?.modules, item?.module);
    });

    const countCalls = enabledTabs.map((cl) => cl.countApiCall());
    const responses = await Promise.all(countCalls);
    const Counts = {};
    enabledTabs.forEach((tb, index) => {
      Counts[tb.tabId] =
        responses[index]?.pagination?.count ||
        responses[index]?.data?.pagination?.count;
    });
    setTabCounts(Counts);
  };

  useEffect(() => {
    getCurrentUser();
    loadCounts();
  }, [profileInfo]);

  useEffect(() => {
    refreshTabCounts > 0 && loadCounts();
  }, [refreshTabCounts]);

  useEffect(() => {
    if (refreshOwners) {
      setRefresOwners(false);
    }
  }, [refreshOwners]);

  useEffect(() => {
    if (organizationId) {
      getProfileInfo();
      getOrganizationById();
    }
  }, [organizationId]);

  const getCurrentUser = async () => {
    const me = await userService
      .getUserInfo()
      .catch((err) => console.error(err));

    setMe(me);
  };

  const goToHome = () => {
    history.push('/');
  };
  const permissionCheck = {
    collection: 'organizations',
    action: 'edit',
  };
  const getOrganizationById = async () => {
    setIsLoading(true);
    const organizationObj = await organizationService.getOrganizationById(
      organizationId
    );
    setOrganization(organizationObj);
  };

  const getProfileInfo = async (message) => {
    try {
      if (message) {
        setActivityIdOpen(''); // After activity update to not open activity modal
        switch (message) {
          case constants.contactUpdated:
            setSuccessMessage(constants.contactUpdated);

            break;
          case constants.errorContactUpdated:
            setErrorMessage(constants.errorContactUpdated);
            break;
          default:
            setSuccessMessage(message);
        }
      }
      const { data } = await fieldService.getFields('organization', {
        usedField: true,
      });
      const {
        data: { data: customFields },
      } = await organizationService.getCustomField(organizationId, {
        page: 1,
        limit: 50,
      });
      let customValues = {};
      Promise.all([
        organizationService.getOrganizationById(organizationId),
        organizationService.getFieldByOrganization(organizationId, {}),
      ])
        .then(([result, response]) => {
          if (!result) {
            goToHome();
          }
          const fields = response?.data?.sort((a, b) => {
            return a.field.order - b.field.order;
          });
          data.forEach((field) => {
            if (field.isCustom) {
              customFields.forEach((item) => {
                if (
                  field.key === item.field.key &&
                  field.field_type !== 'DATE'
                ) {
                  customValues = {
                    ...customValues,
                    [field.key?.toLowerCase().replace(/\s+/g, '')]:
                      field.field_type === 'CURRENCY'
                        ? item.value.substring(1)
                        : item.value,
                  };
                } else if (
                  field.key === item.field.key &&
                  field.field_type === 'DATE'
                ) {
                  customValues = {
                    ...customValues,
                    [field.key?.toLowerCase().replace(/\s+/g, '')]: new Date(
                      item.value
                    ),
                  };
                }
              });
            }
          });
          setProfileInfo({ ...result, ...customValues, fields });
        })
        .catch(() => {
          goToHome();
        });
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };
  const getUserById = async () => {
    const data = await userService.getUserById(profileInfo?.assigned_user_id);
    setGetUser(data);
  };
  const getNaicsInfo = async () => {
    if (organization?.naics_code) {
      const respo = await naicsService.getNaicsByCode(organization?.naics_code);
      setOrganization({ ...organization, industry: respo.title });
    }
  };

  useEffect(() => {
    getUserById();
  }, [profileInfo]);

  useEffect(() => {
    getNaicsInfo();
  }, [profileInfo?.naics_code]);

  useEffect(() => {
    if (refreshRecentFiles) {
      getProfileInfo();
      setRefreshRecentFiles(false);
    }
  }, [refreshRecentFiles]);

  const updateLabel = (label) => {
    if (label.id === profileInfo?.label?.id) {
      setProfileInfo({ ...profileInfo, label });
    }
  };
  return (
    <>
      {isLoading ? (
        <div className="splitted-content-fluid position-relative container-fluid overflow-hidden">
          <LoadingDetailSkeleton />
        </div>
      ) : (
        <>
          {children}
          <div className="splitted-content-fluid position-relative container-fluid content-with-insights overflow-hidden">
            <div className="pl-0 breadcrumb-mobile">
              {moduleMap.organization && <Breadcrumb moduleMap={moduleMap} />}
            </div>
            <AlertWrapper>
              <Alert
                color="success"
                message={successMessage}
                setMessage={setSuccessMessage}
              />
              <Alert
                message={errorMessage}
                setMessage={setErrorMessage}
                color="danger"
              />
            </AlertWrapper>
            {moduleMap.contact && (
              <Header
                me={me}
                service={organizationService}
                withFollowers={organizationId}
                mainOwner={getUser}
                organization={organization}
                data={profileInfo}
                setOpenCloneModal={setOpenCloneModal}
                contact={moduleMap.contact.singular}
                moduleMap={moduleMap.organization.singular}
                refreshOwners={refreshOwners}
                setRefresOwners={setRefresOwners}
                isPrincipalOwner={isPrincipalOwner}
                getProfileInfo={getProfileInfo}
                organizationId={organizationId}
                showContactsModal={showContactsModal}
                setShowContactsModal={setShowContactsModal}
              />
            )}
            <hr className="mt-0" />
            <div className="row">
              <div className="col-lg-4">
                <Overview
                  data={profileInfo}
                  me={me}
                  labelType="organization"
                  setProfileInfo={setProfileInfo}
                  getProfileInfo={getProfileInfo}
                  isPrincipalOwner={isPrincipalOwner}
                  updateLabel={updateLabel}
                  permissionCheck={permissionCheck}
                />
                {isModuleAllowed(tenant?.modules, 'contacts') &&
                  moduleMap.contact && (
                    <Contacts
                      companyName={moduleMap.organization.singular}
                      moduleMap={moduleMap.contact.singular}
                      organizationId={organizationId}
                      showContactsModal={showContactsModal}
                      setShowContactsModal={setShowContactsModal}
                      profileInfo={profileInfo}
                      organization={organization}
                      getProfileInfo={getProfileInfo}
                      isPrincipalOwner={isPrincipalOwner}
                      mainOwner={profileInfo?.assigned_user}
                      mainModuleMap={moduleMap}
                    />
                  )}
                {moduleMap.deal &&
                  isModuleAllowed(tenant.modules, 'pipelines') && (
                    <Deals
                      moduleMap={moduleMap.deal}
                      organizationId={organizationId}
                      organization={organization}
                    />
                  )}

                {isModuleAllowed(
                  tenant?.modules,
                  getAccountsChildPermissions(
                    tenant?.modules,
                    'companies_related_companies'
                  )
                ) &&
                  isPermissionAllowed(permissionCheck?.collection, 'view') &&
                  moduleMap.organization && (
                    <RelatedOrg
                      moduleMap={moduleMap.organization.singular}
                      organizationId={organizationId}
                      getProfileInfo={getProfileInfo}
                      isPrincipalOwner={isPrincipalOwner}
                      mainOwner={profileInfo?.assigned_user}
                    />
                  )}
              </div>

              <div className="col-lg-8 pl-0 profile-col-right">
                <div>
                  {moduleMap.task && (
                    <AddContent
                      moduleMap={moduleMap}
                      dataType="organization"
                      type="companies"
                      activityType="company"
                      organizationId={organizationId}
                      getProfileInfo={getProfileInfo}
                      setProfileInfo={profileInfo}
                      dataSection
                      organization={organization}
                      contactIs={'organization'}
                      setRefreshTabCounts={setRefreshTabCounts}
                      fromOrganization={true}
                      refreshRecentFiles={refreshRecentFiles}
                      setRefreshRecentFiles={setRefreshRecentFiles}
                      isPrincipalOwner={isPrincipalOwner}
                      me={me}
                      openActivityId={activityIdOpen}
                      tabCounts={tabCounts}
                    />
                  )}
                </div>
              </div>
            </div>
            {isModuleAllowed(
              tenant?.modules,
              getAccountsChildPermissions(tenant?.modules, 'companies_sidebar')
            ) && (
              <RightBar
                profileInfo={profileInfo}
                isLoading={isLoading}
                isPeople={false}
                moduleName={getAccountsChildPermissions(
                  tenant?.modules,
                  'companies_sidebar_generate_pre-call_plan'
                )}
              />
            )}
            {openCloneModal && (
              <AddOrganization
                moduleMap={moduleMap.organization.singular}
                openOrganization={openCloneModal}
                setOpenOrganization={setOpenCloneModal}
                setOpenList={setOpenList}
                organizationData={profileInfo}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                successMessage={successMessage}
                setSuccessMessage={setSuccessMessage}
                me={me}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Profile;
