import ReportDragDrop from '../../reports/ReportDragDrop';
import React, { useEffect, useState } from 'react';
import useGenerateAccountStructureReportModal from '../../reports/account-structure/modals/GenerateAccountStructureReport';
import { ActionTypes, ReportTypes } from '../../reports/reports.constants';
import {
  getCycleDate,
  getReportName,
  PdfFileNames,
} from '../../reports/reports.helper.functions';
import Accounts from '../../reports/account-structure/pages/Accounts';
import { ListGroup, ListGroupItem } from 'reactstrap';
import AccountsPayables from '../../reports/account-structure/pages/AccountsPayables';
import AccountsReceivables from '../../reports/account-structure/pages/AccountsReceivables';
import SignersAndBankContacts from '../../reports/account-structure/pages/SignersAndBankContacts';
import WorkingCapitalDPO from '../../reports/account-structure/pages/WorkingCapitalDPO';
import WorkingCapitalDSO from '../../reports/account-structure/pages/WorkingCapitalDSO';
import BalanceTrends from '../../reports/account-structure/pages/BalanceTrends';
import {
  AccountSchematicReportSections,
  AccountSchematicReportSectionsWidgets,
  AccountStructureTabMap,
} from '../../reports/account-structure/account.structure.constants';
import { usePagesContext } from '../../../contexts/pagesContext';
import { getNaicsWithTitle } from '../../../utils/Utils';
import { useProfileContext } from '../../../contexts/profileContext';
import Alert from '../../Alert/Alert';
import AlertWrapper from '../../Alert/AlertWrapper';
import NoDataFound from '../../commons/NoDataFound';
import {
  NO_REPORTS_AVAILABLE,
  NO_REPORTS_AVAILABLE_ICON,
} from '../../../utils/constants';
import ReportAction from '../../reports/ReportAction';
import useFetchEngagementReports from '../../../hooks/reports/useFetchEngagementReports';
import naicsService from '../../../services/naics.service';
import OrganizationService from '../../../services/organization.service';
import ReportService from '../../../services/report.service';
import ReportBlocksSkeleton from '../../loaders/ReportBlocksSkeleton';
import ReportDropdownItem from '../../reports/ReportDropdownItem';
import moment from 'moment';
import ReportPDFWrapper from '../../reportbuilder/ReportPDFWrapper';
import ReportCover from '../../reports/ReportCover';
import ReportPagesAccountStructure from '../../reportbuilder/ReportPagesAccountStructure';
import FundsFlow from '../../reports/account-structure/pages/FundsFlow';
import _ from 'lodash';
import useLocalAccountStructureReport from '../../reports/account-structure/useLocalAccountStructureReport';
import useHash from '../../../hooks/useHash';
import DisclaimerWidget from '../../reportbuilder/widgets/horizontal/DisclaimerWidget';
const getReportPrettyDate = (rpt) => {
  return moment(rpt.reportDate).format('MMMM YYYY');
};
const AccountStructureReport = ({
  organization = {},
  readOnly,
  selectedTenant,
  currentTab,
  getProfileInfo,
}) => {
  const { hash, updateHash } = useHash();
  const downloadOptions = [
    {
      id: 1,
      icon: 'picture_as_pdf',
      key: 'downloadAsPdf',
      name: 'PDF Download',
    },
  ];
  const [report] = useState({});
  const { profileInfo } = useProfileContext();
  const [selectedRpt, setSelectedRpt] = useState({});
  const [loadingReport, setLoadingReport] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { pageContext, setPageContext } = usePagesContext();
  const [startDownload, setStartDownload] = useState(false);
  const [, setIsEdited] = useState(false);
  const [, setLoaderInsights] = useState(false);
  const [, setInsightsData] = useState({});
  const {
    reports,
    setReports,
    loading: loadingPastReports,
    fetchReports,
  } = useFetchEngagementReports(organization, ReportTypes.AccountStructure);
  const { getReport: getLocalStorageReport, clearReport } =
    useLocalAccountStructureReport();
  const localReportExisted = Object.keys(getLocalStorageReport()).length > 0;
  const {
    GenerateAccountStructureReportModal,
    setModalOrganization,
    setSelectedReport,
    setShowModal,
  } = useGenerateAccountStructureReportModal((newReport, type) => {
    setRptGenerated(true);
    clearReport();
    setLoadingReport(false);
    if (type === ActionTypes.REMOVE) {
      const newReports = [...reports].filter(
        (rpt) => rpt.key !== newReport.key
      );
      setReports(newReports);
      if (newReports.length) {
        const firstReport = newReports[0];
        setSelectedRpt(firstReport);
        setPageContext({
          ...pageContext,
          AccountStructureReport: {
            id: firstReport.reportId,
            ...firstReport,
          },
          AccountStructureReportModal: {
            id: firstReport.reportId,
            ...firstReport,
          },
        });
      } else {
        setSelectedRpt({});
        setDefaultReportContext();
        setRptGenerated(false);
      }
    } else if (type === ActionTypes.ADD) {
      setSuccessMessage('Report Created');
      const reportInfo = newReport.manualInput.ReportInfo;
      const newReports = [
        {
          ...newReport.manualInput,
          key: newReport.reportId,
          reportId: newReport.reportId,
          customElement: (
            <ReportDropdownItem
              item={{
                reportDate: reportInfo.reportDate,
                key: newReport.reportId,
                reportId: newReport.reportId,
              }}
            />
          ),
          name: getReportName(reportInfo),
          isManual: true,
          createdById: newReport.createdById,
          createdAt: newReport.createdAt,
          updatedAt: newReport.updatedAt,
          prettyDate: getReportPrettyDate(reportInfo),
        },
        ...reports,
      ];
      setReports(newReports);
      const firstReport = newReports[0];
      setSelectedRpt(firstReport);
      setPageContext({
        ...pageContext,
        AccountStructureReport: {
          id: newReport.reportId,
          ...newReport.manualInput,
        },
        AccountStructureReportModal: {
          id: newReport.reportId,
          ...newReport.manualInput,
        },
      });
    } else {
      setSuccessMessage('Report Saved');
      const reportInfo = newReport.manualInput.ReportInfo;
      if ('key' in selectedRpt) {
        const newReports = [
          ...reports.map((rpt) =>
            rpt.key === selectedRpt.key
              ? {
                  ...newReport.manualInput,
                  key: newReport.reportId,
                  reportId: newReport.reportId,
                  customElement: (
                    <ReportDropdownItem
                      item={{
                        reportDate: reportInfo.reportDate,
                        key: newReport.reportId,
                        reportId: newReport.reportId,
                      }}
                    />
                  ),
                  name: getReportName(reportInfo),
                  createdById: profileInfo.id,
                  updatedAt: new Date().toISOString(),
                  prettyDate: getReportPrettyDate(reportInfo),
                }
              : { ...rpt }
          ),
        ];
        setReports(newReports);
        setSelectedRpt(newReports.find((r) => r.key === selectedRpt.key));
        const updatedContext = {
          ...pageContext,
          AccountStructureReport: {
            id: newReport.reportId,
            ...newReport.manualInput,
          },
          AccountStructureReportModal: {
            id: newReport.reportId,
            ...newReport.manualInput,
          },
        };
        setPageContext(updatedContext);
      } else {
        const reportInfo = newReport.manualInput.ReportInfo;
        const newReports = [
          {
            ...newReport.manualInput,
            key: newReport.reportId,
            reportId: newReport.reportId,
            customElement: (
              <ReportDropdownItem
                item={{
                  reportDate: reportInfo.reportDate,
                  key: newReport.reportId,
                  reportId: newReport.reportId,
                }}
              />
            ),
            name: getReportName(reportInfo),
            isManual: true,
            createdById: newReport.createdById,
            createdAt: newReport.createdAt,
            updatedAt: newReport.updatedAt,
            prettyDate: getReportPrettyDate(reportInfo),
          },
          ...reports,
        ];
        setReports(newReports);
        const firstReport = newReports[0];
        setSelectedRpt(firstReport);
        setPageContext({
          ...pageContext,
          AccountStructureReport: {
            id: newReport.reportId,
            ...newReport.manualInput,
          },
          AccountStructureReportModal: {
            id: newReport.reportId,
            ...newReport.manualInput,
          },
        });
      }
    }
  });

  const [rptGenerated, setRptGenerated] = useState(false);
  const handleDraftClick = () => {
    const localReport = getLocalStorageReport();
    const firstReport = localReport.AccountStructureReportModal;
    const newData = {
      id: firstReport.id,
      mode: firstReport.mode,
      [AccountStructureTabMap.ReportInfo.key]:
        firstReport[AccountStructureTabMap.ReportInfo.key],
      [AccountStructureTabMap.ReportDetails.key]:
        firstReport[AccountStructureTabMap.ReportDetails.key],
      [AccountStructureTabMap.WorkingCapital.key]:
        firstReport[AccountStructureTabMap.WorkingCapital.key],
      [AccountStructureTabMap.Widgets.key]:
        firstReport[AccountStructureTabMap.Widgets.key],
      [AccountStructureTabMap.FundsFlow.key]:
        firstReport[AccountStructureTabMap.FundsFlow.key],
    };
    setPageContext({
      ...pageContext,
      AccountStructureReportModal: _.cloneDeep(newData),
    });
    setIsEdited(true);
    setShowModal(true);
  };
  const handleGenerateManualReport = () => {
    setShowModal(true);
    setDefaultReportContext();
    setSelectedReport(report);
  };

  const getInsights = async (newNaics, callback) => {
    setLoaderInsights(true);
    try {
      let data = {};
      const naicsCode =
        newNaics || report?.valueNaicsSic || organization.naics_code;
      if (naicsCode) {
        let insightsDataRpmgSp = {};
        try {
          // get rpmg/sp summary by naics if company has it
          const naicsFirstTwo = naicsCode.slice(0, 2);
          data = await Promise.all([
            naicsService.getNaicsRpmgSummary(naicsFirstTwo),
            naicsService.getNaicsSpSummary(naicsFirstTwo),
          ]);
          insightsDataRpmgSp = {
            rpmg: data[0],
            sp: data[1],
          };
        } catch (err) {
          insightsDataRpmgSp = {};
        }
        setInsightsData(insightsDataRpmgSp);
        callback(insightsDataRpmgSp);
      } else {
        data = await OrganizationService.getInsightsByOrganization(
          organization.id
        );
        setPageContext({
          ...pageContext,
          AccountStructureReport: {
            ...pageContext.AccountStructureReport,
            insightsData: data,
          },
          AccountStructureReportModal: {
            ...pageContext.AccountStructureReportModal,
            insightsData: data,
          },
        });
        setInsightsData(data);
        callback(data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingReport(false);
      setLoaderInsights(false);
    }
  };

  const setDefaultReportContext = () => {
    const newReport = {
      [AccountStructureTabMap.ReportInfo.key]: {
        companyName: organization.name,
        reportDate: getCycleDate(new Date()),
        valueN: getNaicsWithTitle({}, organization),
        valueNaicsSic: getNaicsWithTitle({}, organization, true),
      },
      [AccountStructureTabMap.ReportDetails.key]: {
        accounts: [],
        authorizedSigners: [],
        authorizedUsers: [],
        balances: [],
        bankContacts: [],
      },
      [AccountStructureTabMap.WorkingCapital.key]: {
        isActive: false,
        data: {},
      },
      [AccountStructureTabMap.Widgets.key]: {
        ...AccountSchematicReportSectionsWidgets,
      },
    };
    setPageContext({
      ...pageContext,
      AccountStructureReport: newReport,
      AccountStructureReportModal: _.cloneDeep(newReport),
    });
  };
  useEffect(() => {
    if (reports.length) {
      const firstReport = hash?.includes('/new') ? {} : reports[0] || {};
      // if we have the reportId then get it
      if (firstReport?.key || hash?.includes('/id')) {
        // we got the id from url now we want to find that in our reports array and get full object
        const urlHashId = hash?.split('/')?.at(-1); // get the last index which has report id
        const reportFound = reports.find((rp) => rp.reportId === urlHashId);
        if (reportFound) {
          setSelectedRpt(reportFound);
        } else {
          setSelectedRpt(firstReport);
        }
        getInsights(null, (insightsData) => {
          const data = {
            id: firstReport.key,
            updatedAt: firstReport.updatedAt,
            insightsData,
            [AccountStructureTabMap.ReportInfo.key]:
              firstReport[AccountStructureTabMap.ReportInfo.key],
            [AccountStructureTabMap.ReportDetails.key]:
              firstReport[AccountStructureTabMap.ReportDetails.key],
            [AccountStructureTabMap.WorkingCapital.key]:
              firstReport[AccountStructureTabMap.WorkingCapital.key],
            [AccountStructureTabMap.Widgets.key]:
              firstReport[AccountStructureTabMap.Widgets.key],
            [AccountStructureTabMap.FundsFlow.key]:
              firstReport[AccountStructureTabMap.FundsFlow.key],
          };
          setPageContext({
            ...pageContext,
            AccountStructureReport: data,
            AccountStructureReportModal: _.cloneDeep(data),
          });
          setRptGenerated(true);
        });
      } else {
        setSelectedRpt({});
        setDefaultReportContext();
        setRptGenerated(false);
      }
    } else {
      setSelectedRpt({});
      setDefaultReportContext();
      setRptGenerated(false);
    }
  }, [reports]);
  useEffect(() => {
    if (organization?.id) {
      setModalOrganization(organization);
      fetchReports();
    }
  }, [organization]);

  const handleEditReport = () => {
    setIsEdited(true);
    setShowModal(true);
  };

  const handleCreateButtonReport = () => {
    setSelectedRpt({});
    setDefaultReportContext();
    setIsEdited(false);
    setRptGenerated(false);
  };

  const getReportById = async (selectedReport) => {
    setLoadingReport(true);
    try {
      const data = await ReportService.getReport(selectedReport.key);
      const firstReport = data.manualInput;
      getInsights(null, (insightsData) => {
        const newData = {
          id: data.reportId,
          updatedAt: data.updatedAt,
          insightsData,
          [AccountStructureTabMap.ReportInfo.key]:
            firstReport[AccountStructureTabMap.ReportInfo.key],
          [AccountStructureTabMap.ReportDetails.key]:
            firstReport[AccountStructureTabMap.ReportDetails.key],
          [AccountStructureTabMap.WorkingCapital.key]:
            firstReport[AccountStructureTabMap.WorkingCapital.key],
          [AccountStructureTabMap.Widgets.key]:
            firstReport[AccountStructureTabMap.Widgets.key],
          [AccountStructureTabMap.FundsFlow.key]:
            firstReport[AccountStructureTabMap.FundsFlow.key],
        };
        setPageContext({
          ...pageContext,
          AccountStructureReport: newData,
          AccountStructureReportModal: _.cloneDeep(newData),
        });
        setRptGenerated(true);
        // when report is full loaded trigger the modal based on has url if it has
        if (hash?.includes('/edit')) {
          updateHash('');
          setShowModal(true);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (selectedRpt?.key || hash?.includes('/id')) {
      getReportById(selectedRpt);
    }
  }, [selectedRpt?.key]);

  useEffect(() => {
    // look hash url if it contains new open a add modal.
    if (hash?.includes('/new')) {
      setDefaultReportContext();
      setSelectedReport({});
    }
  }, [hash]);
  const ReportPageWrapper = ({ children }) => {
    return (
      <ListGroupItem
        className={`account-structure-display-item border-0 px-3 position-relative py-0 ${
          readOnly ? 'bg-transparent' : ''
        }`}
      >
        {children}
      </ListGroupItem>
    );
  };
  return (
    <>
      <AlertWrapper className="alert-position">
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
          time={8000}
        />
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
          time={8000}
        />
      </AlertWrapper>
      <GenerateAccountStructureReportModal />
      {reports.length === 0 &&
        readOnly &&
        !loadingReport &&
        !loadingPastReports && (
          <NoDataFound
            icon={NO_REPORTS_AVAILABLE_ICON}
            iconRounded={true}
            containerStyle="my-6 py-6"
            title={NO_REPORTS_AVAILABLE}
          />
        )}
      <ReportAction
        report={report}
        readOnly={readOnly}
        reportType={ReportTypes.AccountStructure}
        pastReports={reports}
        selectedRpt={selectedRpt}
        profileInfo={profileInfo}
        rptGenerated={rptGenerated}
        loadingReport={loadingReport}
        startDownload={startDownload}
        setSelectedRpt={(newReport) => {
          updateHash('');
          setSelectedRpt(newReport);
        }}
        downloadOptions={downloadOptions}
        setStartDownload={setStartDownload}
        handleEditReport={handleEditReport}
        handleManualReport={handleCreateButtonReport}
        loadingPastReports={loadingPastReports}
        linkConfig={{}}
        draftMode={localReportExisted}
        handleDraftClick={handleDraftClick}
      />
      {loadingReport || loadingPastReports ? (
        <ReportBlocksSkeleton />
      ) : (
        <>
          {rptGenerated && !loadingReport && selectedRpt?.key ? (
            <div className="p-0 w-100 overflow-x-auto">
              <ListGroup className="list-group-no-gutters d-flex flex-column w-100 gap-3 mt-2 mb-3 list-group-flush">
                <ReportPageWrapper>
                  <FundsFlow />
                </ReportPageWrapper>
                <ReportPageWrapper>
                  <Accounts />
                </ReportPageWrapper>
                <ReportPageWrapper>
                  <BalanceTrends hideHeading={false} />
                </ReportPageWrapper>
                {selectedRpt[AccountStructureTabMap.WorkingCapital.key]
                  .isActive && (
                  <ReportPageWrapper>
                    <WorkingCapitalDPO />
                  </ReportPageWrapper>
                )}
                <ReportPageWrapper>
                  <AccountsPayables />
                </ReportPageWrapper>
                {selectedRpt[AccountStructureTabMap.WorkingCapital.key]
                  .isActive ? (
                  <ReportPageWrapper>
                    <WorkingCapitalDSO />
                  </ReportPageWrapper>
                ) : null}
                {selectedRpt[AccountStructureTabMap.Widgets.key][
                  AccountSchematicReportSections.AccountsReceivable
                ]?.isActive ||
                selectedRpt[AccountStructureTabMap.Widgets.key][
                  AccountSchematicReportSections.AccountsReceivableAutomating
                ]?.isActive ? (
                  <ReportPageWrapper>
                    <AccountsReceivables />
                  </ReportPageWrapper>
                ) : null}
                <ReportPageWrapper>
                  <SignersAndBankContacts />
                </ReportPageWrapper>
                <ReportPageWrapper>
                  <DisclaimerWidget styles="border-0 px-0 pt-0 mt-0" />
                </ReportPageWrapper>
              </ListGroup>
            </div>
          ) : (
            <>
              {!readOnly ? (
                <ReportDragDrop
                  file={null}
                  setFile={() => {}}
                  loader={false}
                  onRemoveFile={() => {}}
                  onLoadFile={() => {}}
                  handleGenerate={handleGenerateManualReport}
                  uploadIcon="edit_document"
                  fileUpload={`Enter data to generate ${
                    PdfFileNames[[ReportTypes.AccountStructure]]
                  } report.`}
                />
              ) : null}
            </>
          )}

          {startDownload && (
            <ReportPDFWrapper>
              <ReportCover
                name={selectedRpt?.ReportInfo?.companyName}
                date={selectedRpt?.ReportInfo?.reportDate}
                report={selectedRpt}
                type={ReportTypes.AccountStructure}
                organization={organization}
              />
              <ReportPagesAccountStructure
                report={selectedRpt}
                reportType={ReportTypes.AccountStructure}
              />
            </ReportPDFWrapper>
          )}
        </>
      )}
    </>
  );
};

export default AccountStructureReport;
