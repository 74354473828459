import AddNewReportButton from './AddNewReportButton';
import Skeleton from 'react-loading-skeleton';
import TooltipComponent from '../lesson/Tooltip';
import ReportOwner from './ReportOwner';
import MaterialIcon from '../commons/MaterialIcon';
import ButtonFilterDropdown from '../commons/ButtonFilterDropdown';
import ButtonIcon from '../commons/ButtonIcon';
import DownloadReportDropdown from './DownloadReportDropdown';
import React from 'react';
import { ReportTypes } from './reports.constants';

const ReportAction = ({
  readOnly,
  pastReports,
  handleManualReport,
  rptGenerated,
  handleEditReport,
  loadingReport,
  loadingPastReports,
  selectedRpt,
  setSelectedRpt,
  profileInfo,
  report,
  startDownload,
  setStartDownload,
  downloadOptions,
  csvData,
  exportToCSV,
  reportType,
  linkConfig,
  draftMode,
  handleDraftClick,
  reSync,
  handleResyncClick,
}) => {
  const isCoreData =
    Object.keys(report?.coreData || {}).length || report?.manualInput?.isAuto;
  return (
    <>
      {!readOnly && (
        <div
          className={`d-flex position-absolute align-items-center gap-1 report-controls ${
            readOnly ? 'end-0' : ''
          }`}
          style={{ top: readOnly ? -60 : -35 }}
        >
          <AddNewReportButton
            reports={pastReports}
            readOnly={readOnly}
            handleManualReport={handleManualReport}
            addView={!rptGenerated && !loadingReport && !readOnly}
          />
        </div>
      )}
      <div
        className={`d-flex align-items-center gap-1 w-100 report-controls ${
          pastReports.length > 0 && !readOnly ? 'pt-3' : ''
        } ${readOnly ? 'pb-0' : ''}`}
        style={{ top: readOnly ? -60 : -42 }}
      >
        {loadingPastReports ? (
          <div className="d-flex align-items-center px-3 pt-3 justify-content-between w-100">
            <div>
              <Skeleton height={12} width={120} />
            </div>
            <div className="d-flex align-items-center ml-auto gap-1 mx-2">
              <Skeleton height={12} width={50} />
              <Skeleton height={12} width={80} />
              <Skeleton height={12} width={50} />
            </div>
          </div>
        ) : (
          <div className="d-flex align-items-center pl-3 pr-2 pt-0 justify-content-between w-100">
            <div>
              <h1
                className={`mb-0 d-flex align-items-center gap-1  ${
                  readOnly ? 'font-size-lg' : 'font-size-em'
                }`}
              >
                <span>{selectedRpt?.prettyDate}</span>
                {selectedRpt?.prettyDate && !readOnly && (
                  <TooltipComponent
                    placement="right"
                    title={
                      <ReportOwner report={selectedRpt} me={profileInfo} />
                    }
                  >
                    <MaterialIcon icon="info" />
                  </TooltipComponent>
                )}
              </h1>
            </div>
            <div className="d-flex align-items-center ml-auto gap-1 mx-2">
              {draftMode && !readOnly && (
                <ButtonIcon
                  icon="edit_square"
                  color="outline-primary"
                  label="1 Draft Report"
                  onclick={handleDraftClick}
                  classnames="btn-sm bg-white"
                />
              )}
              {rptGenerated && !readOnly && reSync && isCoreData && (
                <ButtonIcon
                  icon="refresh"
                  color="outline-primary"
                  label="Re-sync"
                  onclick={() => handleResyncClick(report)}
                  classnames="btn-sm"
                />
              )}
              {rptGenerated && !readOnly && (
                <ButtonIcon
                  icon="edit"
                  color="white"
                  label="Edit"
                  onclick={() => handleEditReport('0')}
                  classnames="btn-sm"
                />
              )}
              {pastReports.length > 0 && (
                <ButtonFilterDropdown
                  buttonText="Select Date"
                  icon="description"
                  menuClass="rpt-history-dd-width max-h-300"
                  btnToggleStyle="btn-h-sm"
                  options={pastReports}
                  customKeys={['key', 'prettyDate']}
                  filterOptionSelected={selectedRpt}
                  handleFilterSelect={(e, item) => {
                    setSelectedRpt(item);
                  }}
                />
              )}

              {readOnly ? (
                <>
                  {(rptGenerated || pastReports.length > 0) && (
                    <>
                      {rptGenerated && (
                        <DownloadReportDropdown
                          report={report}
                          selectedRpt={selectedRpt}
                          startDownload={startDownload}
                          setStartDownload={setStartDownload}
                          downloadOptions={downloadOptions}
                          csvData={csvData}
                          reportType={reportType}
                          exportToCSV={exportToCSV}
                          linkConfig={linkConfig}
                        />
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {reportType === ReportTypes.Merchant ? (
                    <>
                      {rptGenerated && (
                        <DownloadReportDropdown
                          report={report}
                          startDownload={startDownload}
                          setStartDownload={setStartDownload}
                          downloadOptions={downloadOptions}
                          csvData={csvData}
                          reportType={reportType}
                          exportToCSV={exportToCSV}
                          linkConfig={linkConfig}
                        />
                      )}
                    </>
                  ) : null}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ReportAction;
