import { useEffect, useState } from 'react';
import { cubeService } from '../../../services';
import Loading from '../../../components/Loading';
import InsightStatItem from '../InsightStatItem';
import NoDataFound from '../../../components/commons/NoDataFound';
const AssignmentStats = {
  AssignmentCompleted: 'Assignments Completed',
  AssignmentInProgress: 'Assignments In Progress',
  AssignmentNotStarted: 'Assignments Not Started',
  AssignmentOverDue: 'Assignments Overdue',
  uniqueTotalAssignments: 'Number of Assignments',
  peopleAssigned: 'Users Assigned',
  totalLessonCount: 'Lessons',
};
export const AssignmentSnapChatWidget = ({
  assignmentStatsNames = {},
  queryFilter = [],
  param = 'teams',
  allSelectedData = [],
  type = 'AssignmentStats',
  headingClass,
  assignmentStatsTooltip = {},
  countClass,
  subHeadingClass,
  customClass = 'col-md-3 pb-3 pr-2',
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const getData = async () => {
    setLoading(true);
    const results = await cubeService.loadCustomAnalytics({
      type,
      ...(queryFilter?.length > 0 ? { [param]: queryFilter } : {}),
    });

    setData(results);
    setLoading(false);
  };

  useEffect(() => {
    if (queryFilter?.length > 0) {
      getData();
    }
  }, [queryFilter]);

  useEffect(() => {
    getData();
  }, []);
  const totalCompleted = Math.round(
    (data[0]?.AssignmentCompleted * 100) / data[0]?.totalAssignments
  );
  const totalDue = Math.round(
    (data[0]?.AssignmentOverDue * 100) / data[0]?.totalAssignments
  );
  const totalNotStarted = Math.round(
    (data[0]?.AssignmentNotStarted * 100) / data[0]?.totalAssignments
  );
  const totalInProgress = Math.round(
    (data[0]?.AssignmentInProgress * 100) / data[0]?.totalAssignments
  );
  return (
    <>
      <div className="w-100 px-3">
        <div className="mx-0 row pt-3 px-0">
          {loading ? (
            <Loading />
          ) : data?.length > 0 ? (
            Object.entries(data[0]).map(([key, value]) => {
              return (
                <>
                  {((type === 'AssignmentDetailStats' &&
                    key !== 'totalAssignments') ||
                    (type === 'AssignmentStats' &&
                      key !== 'totalAssignments' &&
                      type !== 'AssignmentDetailStats' &&
                      key !== 'uniqueTotalAssignments')) && (
                    <InsightStatItem
                      heading={
                        Object.keys(assignmentStatsNames)?.length > 0
                          ? assignmentStatsNames[key]
                          : AssignmentStats[key]
                      }
                      customClass={customClass}
                      tooltipTitle={assignmentStatsTooltip[key]}
                      tooltipData={assignmentStatsTooltip}
                      headingClass={headingClass}
                      countClass={countClass}
                      subHeadingClass={subHeadingClass}
                      stat={
                        type === 'AssignmentDetailStats' &&
                        key === 'AssignmentCompleted'
                          ? totalCompleted
                          : key === 'AssignmentOverDue'
                          ? totalDue
                          : key === 'AssignmentInProgress'
                          ? totalInProgress
                          : key === 'AssignmentNotStarted'
                          ? totalNotStarted
                          : value || 0
                      }
                      subtext={
                        key === 'AssignmentCompleted' ||
                        key === 'AssignmentOverDue' ||
                        key === 'AssignmentInProgress' ||
                        key === 'AssignmentNotStarted'
                          ? '%'
                          : ''
                      }
                      hideAverage
                    />
                  )}
                </>
              );
            })
          ) : (
            <NoDataFound title="Data no found" />
          )}
        </div>
      </div>
    </>
  );
};
