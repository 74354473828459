import { Card, CardBody, ListGroupItem } from 'reactstrap';
import MaterialIcon from '../../../commons/MaterialIcon';
import React from 'react';
import { useTenantContext } from '../../../../contexts/TenantContext';
import { isModuleAllowed } from '../../../../utils/Utils';
import { PermissionsConstants } from '../../../../utils/permissions.constants';

const DISCLAIMER_TEXT = `The information provided in this report is intended for
                    informational purposes only and does not constitute
                    financial advice. The accuracy and completeness of the data
                    used in this report cannot be guaranteed. Any projections or
                    forecasts contained herein are based on assumptions and may
                    not reflect actual results. First Fidelity Bank does not
                    guarantee any specific savings or returns from the
                    recommendations or strategies presented in this report. It
                    is recommended that you consult with a qualified financial
                    advisor before making any investment decisions based on the
                    information contained in this report. Speak with your
                    Treasury Service Consultant for more information.`;
const DisclaimerWidget = ({ digital = true, styles = 'px-3' }) => {
  const { tenant } = useTenantContext();
  return (
    <>
      {isModuleAllowed(
        tenant.modules,
        PermissionsConstants.Reports.Disclaimer
      ) ? (
        <>
          {digital ? (
            <ListGroupItem className={`mb-0 pb-0 ${styles}`}>
              <Card className="report-widget bg-soft-yellow border border-yellow mb-0">
                <CardBody>
                  <div className="d-flex gap-1">
                    <MaterialIcon
                      icon="info"
                      clazz="text-yellow"
                      rounded
                      filled
                    />{' '}
                    <p className="mb-0 text-left text-black font-size-sm2">
                      <b>
                        <p className="mb-0">Disclaimer</p>
                      </b>
                      {DISCLAIMER_TEXT}
                    </p>
                  </div>
                </CardBody>
              </Card>
            </ListGroupItem>
          ) : (
            <ListGroupItem className="px-5 border-0 mb-0 pb-0">
              <Card className="report-widget mb-0">
                <CardBody className="p-3">
                  <p className="mb-0 text-left text-black font-size-sm2">
                    {DISCLAIMER_TEXT}
                  </p>
                </CardBody>
              </Card>
            </ListGroupItem>
          )}
        </>
      ) : null}
    </>
  );
};

export default DisclaimerWidget;
