import React, { useEffect, useRef } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import MaterialIcon from '../../../commons/MaterialIcon';
import { CardBody } from 'reactstrap';
import { hslToHex } from '../../../../utils/Utils';
import * as d3 from 'd3';
import { PaymentTrendsData } from '../../constants/widgetsConstants';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const LineChartD3 = ({ data, labels, hexColor, tenantColor }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const margin = { top: 20, right: 20, bottom: 30, left: 50 };
    const width = 600 - margin.left - margin.right;
    const height = 250 - margin.top - margin.bottom;

    // Clear the previous chart
    d3.select(chartRef.current).selectAll('*').remove();

    const svg = d3
      .select(chartRef.current)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // Set the x and y scales
    const xScale = d3
      .scalePoint()
      .domain(labels)
      .range([10, width - 10]);
    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data)])
      .range([height, 0]);

    // Create the line generator
    const line = d3
      .line()
      .x((d, i) => xScale(labels[i]))
      .y((d) => yScale(d))
      .curve(d3.curveMonotoneX); // tension for smooth curves

    // Add the x-axis
    svg
      .append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(xScale));

    // Add the y-axis
    svg.append('g').call(d3.axisLeft(yScale));

    // Add the line path
    svg
      .append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', hexColor)
      .attr('stroke-width', 2)
      .attr('d', line);

    // Add circles for the data points
    const points = svg.selectAll('.dot').data(data).enter().append('g');

    points
      .append('circle')
      .attr('cx', (d, i) => xScale(labels[i]))
      .attr('cy', (d) => yScale(d))
      .attr('r', 20) // Set radius to match the original Chart.js configuration
      .attr('fill', hexColor)
      .attr('stroke', 'none')
      .on('mouseover', function (event, d) {
        d3.select(this)
          .transition()
          .duration(100)
          .attr('r', 25)
          .attr('fill', tenantColor);
      })
      .on('mouseout', function (event, d) {
        d3.select(this)
          .transition()
          .duration(100)
          .attr('r', 20)
          .attr('fill', hexColor);
      });

    // Add labels inside the circles
    points
      .append('text')
      .attr('x', (d, i) => xScale(labels[i]))
      .attr('y', (d) => yScale(d))
      .attr('dy', '0.35em') // Center the text vertically
      .attr('text-anchor', 'middle') // Center the text horizontally
      .attr('fill', tenantColor)
      .attr('font-size', '10px')
      .attr('font-weight', 'bold')
      .text((d) => `${d}%`); // Format label as percentage
  }, [data, labels, hexColor, tenantColor]);

  return <div ref={chartRef}></div>;
};

const BarChartD3 = ({ data, labels, hexColor, tenantColor }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const margin = { top: 20, right: 20, bottom: 60, left: 60 }; // Increased bottom margin for labels
    const width = 400 - margin.left - margin.right;
    const height = 200 - margin.top - margin.bottom;

    // Clear the previous chart
    d3.select(chartRef.current).selectAll('*').remove();

    const svg = d3
      .select(chartRef.current)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // Set the x and y scales
    const xScale = d3.scaleBand().domain(labels).range([0, width]).padding(0.2); // Padding between bars

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data)])
      .range([height, 0]);

    // Add the x-axis
    svg
      .append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(xScale))
      .selectAll('text') // Select all x-axis labels
      .style('text-anchor', 'center') // Align text to the end
      .attr('font-size', '7px')
      .attr('dy', '1em'); // Vertical adjustment

    // Add the y-axis
    svg.append('g').call(d3.axisLeft(yScale));

    // Create bars
    svg
      .selectAll('.bar')
      .data(data)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', (d, i) => xScale(labels[i]))
      .attr('y', (d) => yScale(d))
      .attr('width', xScale.bandwidth())
      .attr('height', (d) => height - yScale(d) - 5)
      .attr('fill', hexColor);

    // Add labels inside bars
    svg
      .selectAll('.label')
      .data(data)
      .enter()
      .append('text')
      .attr('x', (d, i) => xScale(labels[i]) + xScale.bandwidth() / 2)
      .attr('y', (d) => yScale(d) - 10) // Position above the bar
      .attr('text-anchor', 'middle')
      .attr('fill', tenantColor)
      .attr('font-size', '12px')
      .attr('font-weight', 'bold')
      .text((d) => `${d}%`); // Format as percentage
  }, [data, labels, hexColor, tenantColor]);

  return <div ref={chartRef}></div>;
};

const PaymentTrendsWidget = ({ widgetConfig, whenPrinting }) => {
  const root = getComputedStyle(document.documentElement);
  const hslHue = root.getPropertyValue('--primaryColorHsl').trim();
  const tenantColor = root.getPropertyValue('--primaryColor');
  const hexColor = hslToHex(Number(hslHue), 100, 94);

  const lineChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.raw}%`;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        max: 100,
        ticks: {
          callback: function (value) {
            return value + '%';
          },
        },
        grid: {
          display: false,
        },
        display: false,
      },
    },
  };

  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.raw}%`;
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 8,
          },
          maxRotation: 0,
          minRotation: 0,
          align: 'center',
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        max: 80,
        ticks: {
          callback: function (value) {
            return value;
          },
        },
      },
    },
  };

  return (
    <div
      className={`d-flex flex-column ${
        whenPrinting ? 'p-2' : 'py-2 px-4'
      } widget-border-2`}
      style={{
        borderRadius: 'var(--rpt-widget-border-radius)',
        background: '#ffffff',
      }}
    >
      <div
        className={`d-flex justify-content-between ${
          whenPrinting ? 'pt-1' : 'pt-3'
        }`}
      >
        <span
          className={`${
            whenPrinting ? 'fs-9' : 'font-size-md'
          } font-weight-medium`}
        >
          {widgetConfig.title}
        </span>
        <div
          className="d-flex flex-column justify-content-end"
          style={{ width: 250 }}
        >
          <p className="fs-10 text-muted source-block mb-0 text-right">
            {widgetConfig.subtitle}
          </p>
          <p
            className={`${
              whenPrinting ? 'fs-9' : 'font-size-md'
            } font-weight-medium text-right`}
          >
            {widgetConfig.lineChartData.description}
          </p>
        </div>
      </div>

      <div
        className="chart-container"
        style={{ height: whenPrinting ? 270 : 'auto' }}
      >
        {whenPrinting ? (
          <LineChartD3
            data={widgetConfig.lineChartData.data}
            tenantColor={tenantColor}
            hexColor={hexColor}
            labels={widgetConfig.lineChartData.labels}
          />
        ) : (
          <Line
            data={{
              labels: widgetConfig.lineChartData.labels,
              datasets: [
                {
                  data: widgetConfig.lineChartData.data,
                  borderColor: hexColor,
                  tension: 0.1,
                  pointStyle: 'circle',
                  radius: 20,
                  pointBackgroundColor: hexColor,
                  pointBorderWidth: 0,
                  datalabels: {
                    display: true,
                    color: tenantColor,
                    font: {
                      weight: 'bold',
                      size: 12,
                    },
                    formatter: function (value) {
                      return `${value}%`;
                    },
                  },
                },
              ],
            }}
            options={lineChartOptions}
          />
        )}
      </div>

      <div className="d-flex flex-column justify-content-start">
        <span
          className={`${
            whenPrinting ? 'fs-9 py-1' : 'font-size-md py-3'
          } font-weight-medium text-left`}
        >
          {widgetConfig.barChartData.title}
        </span>

        <div className="d-flex" style={{ height: whenPrinting ? 180 : 'auto' }}>
          <div className="w-65">
            {whenPrinting ? (
              <BarChartD3
                data={widgetConfig.barChartData.data}
                tenantColor={tenantColor}
                hexColor={hexColor}
                labels={PaymentTrendsData.barChartData.labelsPdf}
              />
            ) : (
              <Bar
                data={{
                  labels: widgetConfig.barChartData.labels,
                  datasets: [
                    {
                      data: widgetConfig.barChartData.data,
                      backgroundColor: hexColor,
                      datalabels: {
                        display: true,
                        color: tenantColor,
                        font: {
                          weight: 'bold',
                          size: 12,
                        },
                        formatter: function (value) {
                          return `${value}%`;
                        },
                      },
                    },
                  ],
                }}
                options={barChartOptions}
              />
            )}
          </div>
          <CardBody
            className="d-flex w-35 p-2 flex-column align-items-center justify-content-center bg-green-5 border border-green-5 ml-3"
            style={{
              borderRadius: 'var(--rpt-widget-border-radius)',
              maxHeight: 220,
            }}
          >
            <div
              className="d-flex align-items-center bg-white rounded-circle justify-content-center mt-0"
              style={{ width: '50px', height: '50px' }}
            >
              <MaterialIcon
                icon={widgetConfig.infoBox.icon}
                size="fs-3"
                clazz={'text-green'}
              />
            </div>
            <p
              className={`font-weight-medium ${
                whenPrinting ? 'fs-9 sub-text' : 'font-size-sm2'
              } px-3 text-center`}
            >
              {widgetConfig.infoBox.text}
            </p>
          </CardBody>
        </div>
      </div>
    </div>
  );
};

export default PaymentTrendsWidget;
