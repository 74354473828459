import { useEffect, useState } from 'react';
import { cubeService } from '../../../services';
import NoDataFound from '../../../components/commons/NoDataFound';
import Loading from '../../../components/Loading';
import InsightStatItem from '../InsightStatItem';
import { numbersWithComma } from '../../../utils/Utils';
const AssignmentStats = {
  totalAssignments: 'Number of Assignments',
  assignmentCompleted: 'Assignments - Completed',
  assignmentInProgress: 'Assignments - In Progress',
  assignmentNotStarted: 'Assignments - Not Started',
  assignmentOverDue: 'Assignments - Overdue',
};
const TooltipTitlesGet = {
  'Number of Assignments': 'Total Number of Assignments',
};
export const UserTranscriptProgress = ({ insightName, userFilters = [] }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const filterData = userFilters?.length > 0 && userFilters[0]?.values;
  const filters = userFilters.filter((item) => item?.values);
  useEffect(() => {
    (async () => {
      setLoading(true);
      const results = await cubeService.loadCustomAnalytics({
        type: 'AssignmentUserStats',
        users: filters[0]?.values,
      });
      setData(results);
      setLoading(false);
    })();
  }, [filterData]);

  return (
    <div className="w-100 px-3">
      <div className="row pt-3 border-top px-2">
        {loading ? (
          <Loading />
        ) : data?.length > 0 ? (
          Object.entries(data[0]).map(([key, value]) => {
            const statValue = numbersWithComma(value);
            return (
              <InsightStatItem
                key={key}
                heading={AssignmentStats[key]}
                tooltipData={TooltipTitlesGet}
                stat={statValue || 'N/A'}
                hideAverage
                customClass="col-md-3 px-2"
              />
            );
          })
        ) : (
          <NoDataFound title="Data no found" />
        )}
      </div>
    </div>
  );
};
