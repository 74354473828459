import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import userService from '../../services/user.service';
import { Spinner } from 'reactstrap';
import OwnerAvatar from '../ActivitiesTable/OwnerAvatar';

const OwnerName = ({ loader, owner, isAutoGenerated, onlyIcon }) => {
  const name = owner?.name || `${owner?.first_name} ${owner?.last_name || ''}`;
  return (
    <>
      {name ? (
        <p className="mb-0">
          <b>By: </b>
          {loader ? (
            <Spinner className="spinner-grow-xs" />
          ) : isAutoGenerated ? (
            <>Auto Generated (Core Data)</>
          ) : (
            name?.trim()
          )}{' '}
        </p>
      ) : null}
    </>
  );
};
const ReportOwner = ({ report, me, onlyIcon }) => {
  const [loader, setLoader] = useState(false);
  const [owner, setOwner] = useState({});
  const isAutoGenerated = report?.isAuto;

  const getUserById = async () => {
    try {
      setLoader(true);
      const data = await userService.getUserById(report?.createdById);
      const name = data?.name || `${data?.first_name} ${data?.last_name || ''}`;
      setOwner({ ...data, name });
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    if (me?.id !== report?.createdById) {
      getUserById();
    } else {
      setOwner(me);
    }
  }, []);
  return (
    <>
      {onlyIcon ? (
        <OwnerAvatar item={owner} isMultiple={true} />
      ) : (
        <div className="fs-9 d-flex flex-column align-items-start">
          <p className="mb-0">
            <b>Saved: </b>{' '}
            {moment(report.updatedAt).format('MM/DD/YYYY h:mm A')}
          </p>
          <OwnerName
            owner={owner}
            loader={loader}
            isAutoGenerated={isAutoGenerated}
            onlyIcon={false}
          />
        </div>
      )}
    </>
  );
};

export default ReportOwner;
