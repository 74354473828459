import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Alert from '../../Alert/Alert';
import AlertWrapper from '../../Alert/AlertWrapper';
import RightSlidingDrawer from '../../modal/RightSlidingDrawer';
import ButtonIcon from '../../commons/ButtonIcon';
import {
  emailRegex,
  isDefined,
  overflowing,
  removeBodyScroll,
  setDateFormat,
} from '../../../utils/Utils';
import {
  badgeColorStatus,
  NAME_UNKNOWN_USER,
  PROFILE_LABEL,
  ROLE_LABEL,
  SEND_EMAIL_SUCCESS,
  SERVER_ERROR,
  STATUS_ACTIVE,
  STATUS_DELETED,
  STATUS_INVITED,
  STATUS_SUSPENDED,
  USER_ACTIVE,
  USER_SUSPENDED,
  USER_UPDATE_SUCCESS,
} from '../../../utils/constants';
import { Col, FormGroup, Label } from 'reactstrap';
import MaterialIcon from '../../commons/MaterialIcon';
import Avatar from '../../Avatar';
import MoreActions from '../../MoreActions';
import InputValidation from '../../commons/InputValidation';
import { useForm } from 'react-hook-form';
import ControllerValidation from '../../commons/ControllerValidation';
import { DropdownTreeView } from '../../prospecting/v2/common/DropdownTreeView';
import AutoComplete from '../../AutoComplete';
import teamsService from '../../../services/teams.service';
import NewKindOfSearchDropdown from '../../commons/NewKindOfSearchDropdown';
import roleService from '../../../services/role.service';
import userService from '../../../services/user.service';
import stringConstants from '../../../utils/stringConstants.json';
import authService from '../../../services/auth.service';
import groupsService from '../../../services/groups.service';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';

const cache = {
  profiles: null,
};

const InviteFormGroup = ({
  label,
  component,
  alignLabel = 'align-items-center',
}) => {
  return (
    <FormGroup className={`mb-0 ${alignLabel} mt-0`} row>
      <Label md={3} className="text-right py-0 font-size-sm">
        {label}
      </Label>
      <Col md={9} className="pl-0 py-0">
        {component}
      </Col>
    </FormGroup>
  );
};
const DrawerHeader = ({ user, setUser, setShow, modalConfig }) => {
  return (
    <ProfileHeader
      user={user}
      setUser={setUser}
      setShow={setShow}
      modalConfig={modalConfig}
    />
  );
};

const Badge = ({ user }) => {
  const badgeStatus = user?.status
    ? badgeColorStatus.filter((b) => b.status === user?.status.toLowerCase())
    : null;

  let classnames = '';

  if (badgeStatus?.length > 0) {
    classnames = badgeStatus[0].color;
  }

  if (user) {
    return (
      <span
        className={`badge badge-lg label text-capitalize fs-7 p-2 ml-1 rounded-pill fw-bold ${classnames}`}
      >
        {user?.status === 'invite_cancelled'
          ? 'CANCELLED INVITE'.toLowerCase()
          : user?.status?.toLowerCase()}
      </span>
    );
  }
  return <></>;
};

const STATUS_OPTIONS = {
  [STATUS_SUSPENDED]: {
    label: 'Activate',
    styles: 'text-green',
    className: 'bg-hover-success',
    icon: 'check_circle',
  },
  [STATUS_ACTIVE]: {
    label: 'Deactivate',
    styles: 'text-danger',
    icon: 'person_cancel',
    className: 'bg-hover-danger',
  },
};

const ProfileHeader = ({ user, setUser, setShow, modalConfig }) => {
  const history = useHistory();
  const getActionItemsByUserStatus = (user) => {
    const actions = [];
    if (user?.status === STATUS_ACTIVE) {
      actions.push({
        id: 'add',
        name: 'Ghost Login',
        icon: 'tv_signin',
      });
    }
    const statusStyles = STATUS_OPTIONS[user.status] || {
      id: 'reInvite',
      label: 'Resend Invite',
      styles: 'text-black',
      icon: 'refresh',
    };
    actions.push({
      id: 'edit',
      name: statusStyles.label,
      textStyles: '',
      className: statusStyles.className,
      icon: statusStyles.icon,
    });
    return actions;
  };
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loader, setLoader] = useState(false);
  const [loaderReInvite, setLoadReInvite] = useState(false);
  const constants = stringConstants.settings.security;
  const handleChangeStatus = async (e) => {
    e.preventDefault();
    const currentUser = user;
    const status =
      currentUser?.status === STATUS_ACTIVE
        ? STATUS_SUSPENDED
        : currentUser?.status === STATUS_SUSPENDED
        ? STATUS_ACTIVE
        : currentUser?.status === STATUS_INVITED
        ? 'invite_cancelled'
        : STATUS_INVITED;
    const MESSAGE_ALERT =
      currentUser?.status === STATUS_ACTIVE
        ? USER_SUSPENDED
        : currentUser?.status === STATUS_SUSPENDED
        ? USER_ACTIVE
        : currentUser?.status === STATUS_INVITED
        ? 'Invite Cancelled'
        : 'Invite Sent';
    try {
      setLoadReInvite(true);
      await userService.updateUserInfoById(currentUser.id, { status });
      setUser({ ...user, status });
      setSuccessMessage(MESSAGE_ALERT);
      modalConfig.refreshUsers();
      setShow(false);
    } catch (err) {
      setErrorMessage(SERVER_ERROR);
    } finally {
      setLoadReInvite(false);
    }
  };
  const handleGhostLogin = async (e) => {
    e.preventDefault();
    try {
      setLoadReInvite(true);
      await authService.impersonate(user.id, true);
      history.push('/');
      window.location.reload(false);
    } catch (err) {
      console.log(err);
      setErrorMessage(SERVER_ERROR);
    } finally {
      setLoadReInvite(false);
    }
  };
  const handleReInvite = async () => {
    setLoadReInvite(true);

    try {
      await userService.resendInvite(user.id);
      setSuccessMessage(SEND_EMAIL_SUCCESS);
    } catch (err) {
      setErrorMessage(SERVER_ERROR);
    } finally {
      setLoadReInvite(false);
    }
  };
  const handleResetPassword = async () => {
    try {
      setLoader(true);
      await userService.updatePasswordByUserId(user.id, { generate: true });
      setSuccessMessage('Password reset has been sent.');
    } catch (error) {
      setErrorMessage(constants.errorMessage);
    } finally {
      setLoader(false);
    }
  };

  return (
    <div className="w-100">
      <AlertWrapper>
        <Alert
          message={successMessage}
          setMessage={setSuccessMessage}
          color="success"
        />
        <Alert
          message={errorMessage}
          setMessage={setErrorMessage}
          color="danger"
        />
      </AlertWrapper>
      <div className="d-flex align-items-center w-100 justify-content-between gap-2">
        <div className="d-flex gap-1 align-items-center">
          {user && (
            <Avatar
              user={user}
              classModifiers="avatar-lg bg-primary-soft text-primary"
              sizeIcon="font-size-xl"
            />
          )}
          <div>
            <h3
              className="mb-0 d-flex align-items-center text-capitalize"
              data-uw-styling-context="true"
            >
              {user &&
              isDefined(user.first_name) &&
              user.first_name !== null &&
              user?.last_name !== null
                ? `${user?.first_name} ${user?.last_name}`
                : NAME_UNKNOWN_USER}

              <Badge user={user} />
            </h3>
            <p className="mb-0 d-flex align-items-center gap-1 fs-7">
              <MaterialIcon icon="email" clazz="fs-6 text-black-50" />
              <a href={`mailto:${user?.email}`}>{user?.email}</a>
            </p>
          </div>
        </div>
        <div>
          <div className="d-flex gap-2 align-items-center">
            {user.status !== STATUS_DELETED && (
              <ButtonIcon
                color="outline-primary"
                classnames="btn-sm bg-white pl-2 pr-3"
                icon="lock_reset"
                label="Reset"
                loading={loader}
                onclick={handleResetPassword}
                hidden={user.status === STATUS_INVITED}
              />
            )}
            <MoreActions
              icon="more_vert"
              loader={loaderReInvite}
              items={getActionItemsByUserStatus(user)}
              onHandleEdit={handleChangeStatus}
              onHandleReinvite={handleReInvite}
              onHandleAdd={handleGhostLogin}
            />
            <a
              className="icon-hover-bg cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                setShow(false);
              }}
            >
              <MaterialIcon icon="close" size="fs-5" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const UserProfileModal = ({ show, setShow, user, setUser, modalConfig }) => {
  const [inviteFormData, setInviteFormData] = useState({ ...user });
  const [selectedRole, setRoleSelection] = useState({ ...user.role });
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [savingTeam, setSavingTeam] = useState(false);
  const {
    register,
    setValue,
    getFieldState,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });
  const [isShowTreeView, setIsShowTreeView] = useState('');
  const { setErrorMessage, setSuccessMessage } = modalConfig;
  const [loaderGroups, setLoaderGroups] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [saving, setSaving] = useState(false);
  function phoneFormatting(phone) {
    if (phone.length < 13) {
      const cleaned = ('' + phone).replace(/\D/g, '');

      return `${cleaned.substring(0, 3)}${
        cleaned.length > 3 ? '-' : ''
      }${cleaned.substring(3, 6)}${
        cleaned.length > 6 ? '-' : ''
      }${cleaned.substring(6, 11)}`;
    }
    return phone.slice(0, 12);
  }
  const clearState = (name) => {
    if (name === 'roleId') {
      setInviteFormData({ ...inviteFormData, [name]: '' });
      setValue(name, '');
    } else {
      setSelectedTeam([]);
    }
  };
  const onHandleChangeSelect = (e) => {
    const { value } = e.target;
    setRoleSelection(value);
    setInviteFormData({ ...inviteFormData, roleId: value.id });
    setValue('roleId', value.id);
  };
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phone') {
      const newValue = phoneFormatting(value);
      setInviteFormData({
        ...inviteFormData,
        [name]: newValue,
      });
      setValue(name, newValue);
    } else {
      setInviteFormData({ ...inviteFormData, [name]: value });
      setValue(name, value);
    }
  };
  const handleTeamSelect = async (team) => {
    const newTeams = [...selectedTeam, team];
    setSelectedTeam(newTeams);
  };
  const getTeamMembers = async () => {
    try {
      const { data } = await userService.getTeamMemberByUserId(user?.id);
      const teamObjects = data.map(({ team }) => team);
      setSelectedTeam(teamObjects);
    } catch (error) {
      setErrorMessage('');
    }
  };
  const getTeams = async () => {
    setSavingTeam(true);
    const { data } = await teamsService.getTeams({ page: 1, limit: 50 });
    // add All option in dropdown
    setTeams(data);
    setSavingTeam(false);
  };
  useEffect(() => {
    if (user && Object.keys(user).length) {
      getTeams();
      getTeamMembers();
      setRoleSelection(user?.role);
      setIsShowTreeView(user.group);
      // react-hook form setvalues
      setValue('first_name', user.first_name);
      setValue('last_name', user.last_name);
      setValue('title', user.title);
      setValue('email', user.email);
      setValue('phone', user.phone);
      setValue('roleId', user?.role?.id);
      setValue('groupId', user?.group?.id);
      if (user.phone) {
        setInviteFormData({
          ...inviteFormData,
          phone: phoneFormatting(user.phone),
        });
      }
    }
  }, [user]);
  const [isAllTreeData, setIsAllTreeData] = useState([]);
  const getListGroups = async () => {
    try {
      setLoaderGroups(true);
      const result = await groupsService.getRoles();
      setIsAllTreeData(result);
    } catch (error) {
      setErrorMessage('Error loading profiles.');
    } finally {
      setLoaderGroups(false);
    }
  };
  useEffect(() => {
    getListGroups();
  }, []);
  useEffect(() => {
    if (show) {
      removeBodyScroll();
    } else {
      overflowing();
    }
  }, [show]);

  const updateUser = async () => {
    const userUpdate = {
      first_name: inviteFormData.first_name,
      last_name: inviteFormData.last_name,
      roleId: selectedRole.id,
      groupId: isShowTreeView?.id,
      phone: inviteFormData.phone,
      title: inviteFormData.title,
      email: inviteFormData.email,
    };
    try {
      setSaving(true);
      const teams = selectedTeam?.map((item) => ({
        isManager: false,
        teamId: item?.id,
      }));
      const userId = user.id;
      await userService.updateUserInfoById(userId, userUpdate);
      if (userId && teams.length) {
        await userService.inviteTeamUsers(userId, teams);
      }
      setSaving(false);
      setSuccessMessage(USER_UPDATE_SUCCESS);
      setShow(false);
      modalConfig.refreshUsers();
    } catch (e) {
      setSaving(false);
      console.log('err-user-update', e);
    }
  };

  return (
    <>
      <RightSlidingDrawer
        open={show}
        containerWidth={670}
        modalBodyClass="p-0"
        withCard={true}
        toggleDrawer={() => {
          setShow(false);
        }}
        header={
          <DrawerHeader
            user={user}
            setUser={setUser}
            setShow={setShow}
            modalConfig={modalConfig}
          />
        }
        body={
          <>
            <div className="pt-3 pb-2 position-relative px-3_1 flex-column d-flex gap-3">
              {user.last_access && (
                <div className="d-flex position-absolute my-1 mr-4 fs-8 text-gray align-items-center gap-1 right-0">
                  <MaterialIcon icon="history" filled rounded clazz="fs-6" />{' '}
                  <span className="font-weight-semi-bold">Last Login: </span>
                  <span>
                    {user.last_access ? setDateFormat(user.last_access) : 'N/A'}
                  </span>
                </div>
              )}
              <h4 className="pt-1 pb-0 px-2 mb-0">User Information</h4>
              <InviteFormGroup
                label="First Name"
                component={
                  <InputValidation
                    name="first_name"
                    type="input"
                    placeholder=""
                    value={inviteFormData.first_name || ''}
                    errorDisplay="mb-0"
                    classNames="mr-2"
                    validationConfig={{
                      required: 'First Name cannot be empty.',
                      inline: false,
                      borderLeft: true,
                      onChange: handleOnChange,
                    }}
                    errors={errors}
                    register={register}
                  />
                }
              />
              <InviteFormGroup
                label="Last Name"
                component={
                  <InputValidation
                    name="last_name"
                    type="input"
                    placeholder=""
                    value={inviteFormData.last_name || ''}
                    errorDisplay="mb-0"
                    classNames="mr-2"
                    validationConfig={{
                      required: 'Last Name cannot be empty.',
                      inline: false,
                      borderLeft: true,
                      onChange: handleOnChange,
                    }}
                    errors={errors}
                    register={register}
                  />
                }
              />
              <InviteFormGroup
                label="Title"
                component={
                  <InputValidation
                    name="title"
                    type="input"
                    placeholder=""
                    value={inviteFormData.title || ''}
                    classNames="mr-2"
                    errorDisplay="mb-0"
                    validationConfig={{
                      onChange: handleOnChange,
                    }}
                    errors={errors}
                    register={register}
                  />
                }
              />
              <InviteFormGroup
                label="Email"
                component={
                  <InputValidation
                    name="email"
                    type="input"
                    placeholder=""
                    value={inviteFormData.email || ''}
                    classNames="mr-2"
                    errorDisplay="mb-0"
                    disabled={true}
                    validationConfig={{
                      required: 'Email cannot be empty.',
                      inline: false,
                      borderLeft: true,
                      onChange: handleOnChange,
                      pattern: {
                        value: emailRegex,
                        message: 'Please enter a valid email.',
                      },
                    }}
                    errors={errors}
                    register={register}
                  />
                }
              />
              <InviteFormGroup
                label="Phone"
                component={
                  <InputValidation
                    name="phone"
                    type="input"
                    placeholder=""
                    value={inviteFormData.phone || ''}
                    classNames="mr-2"
                    errorDisplay="mb-0"
                    validationConfig={{
                      onChange: handleOnChange,
                    }}
                    errors={errors}
                    register={register}
                  />
                }
              />
              <InviteFormGroup
                label={PROFILE_LABEL}
                component={
                  <ControllerValidation
                    name="roleId"
                    errors={errors}
                    form={inviteFormData}
                    errorDisplay="mb-0"
                    control={control}
                    validationConfig={{
                      required: 'Profile is required.',
                    }}
                    renderer={({ field }) => (
                      <NewKindOfSearchDropdown
                        {...field}
                        cache={cache}
                        cacheKey="profiles"
                        dataService={roleService}
                        dataServiceApi="GetRoles"
                        name="roleId"
                        validationConfig={{
                          required: 'Profile is required.',
                        }}
                        fieldState={getFieldState('roleId')}
                        currentSelected={selectedRole}
                        onItemSelect={(newRole) => {
                          onHandleChangeSelect({
                            target: { value: newRole, name: 'roleId' },
                          });
                        }}
                      />
                    )}
                  />
                }
              />

              <InviteFormGroup
                label={ROLE_LABEL}
                alignLabel={
                  isExpanded ? 'align-items-start' : 'align-items-center'
                }
                component={
                  <>
                    {loaderGroups ? (
                      <Skeleton height={10} className="d-block w-100" />
                    ) : (
                      <ControllerValidation
                        name="groupId"
                        errors={errors}
                        form={inviteFormData}
                        errorDisplay="mb-0"
                        control={control}
                        validationConfig={{
                          required: 'Role is required.',
                        }}
                        renderer={({ field }) => (
                          <>
                            <DropdownTreeView
                              {...field}
                              data={isAllTreeData}
                              isShow={isExpanded}
                              setIsShow={setIsExpanded}
                              getRoleData={user?.groupId}
                              setIsDropdownId={(selected) => {
                                setValue('groupId', selected.id);
                                setInviteFormData({
                                  ...inviteFormData,
                                  groupId: selected.id,
                                });
                                setIsShowTreeView(selected);
                              }}
                              isDropdownId={isShowTreeView}
                              validationConfig={{
                                required: 'Role is required.',
                              }}
                              fieldState={getFieldState('groupId')}
                            />
                          </>
                        )}
                      />
                    )}
                  </>
                }
              />
            </div>
            <div className="px-3_1 pt-2">
              <InviteFormGroup
                label={'Teams'}
                component={
                  <AutoComplete
                    id={`team_id`}
                    placeholder="Select Team"
                    name={`team_id`}
                    data={teams}
                    loading={savingTeam}
                    onChange={(items, itemToRemove) => {
                      const allOption = items.find((t) => t.id === -1);
                      if (allOption) {
                        setSelectedTeam(teams.filter((t) => t.id !== -1));
                      } else {
                        setSelectedTeam(items.filter((t) => t.id !== -1));
                      }
                    }}
                    clearState={(e) => clearState(e)}
                    customKey="name"
                    isMultiple={true}
                    selected={selectedTeam}
                    onHandleSelect={(item) => handleTeamSelect(item)}
                  />
                }
              />
            </div>
          </>
        }
        footer={
          <div className="d-flex justify-content-end align-items-center">
            <div className="d-flex align-items-center gap-2">
              <ButtonIcon
                color="white"
                onclick={() => setShow(false)}
                classnames="font-weight-normal btn-sm"
                label="Cancel"
              />
              <ButtonIcon
                color="primary"
                classnames="btn-sm"
                onclick={handleSubmit(updateUser)}
                loading={saving}
                label="Save"
              />
            </div>
          </div>
        }
      />
    </>
  );
};

const useUserProfileModal = (modalConfig) => {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState({});
  const UserProfileModalCallback = useCallback(() => {
    return (
      <UserProfileModal
        show={showModal}
        setShow={setShowModal}
        user={data}
        setUser={setData}
        modalConfig={modalConfig}
      />
    );
  }, [showModal, setShowModal, data, setData]);

  return useMemo(
    () => ({
      setShowModal,
      setData,
      UserProfileModal: UserProfileModalCallback,
    }),
    [setShowModal, UserProfileModalCallback]
  );
};

export default useUserProfileModal;
