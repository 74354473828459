import React from 'react';
import { CardBody } from 'reactstrap';
import CutoutWidget from './CutoutWidget';

const VenderAnalysisPhasesWidget = ({ widgetConfig, whenPrinting }) => {
  const formatNumber = (num) => {
    return num.toLocaleString('en-US');
  };

  const ColorMapping = {
    venderCountKey0: 'green',
    venderCountKey1: 'orange',
    venderCountKey2: 'red',
  };
  return (
    <div>
      {widgetConfig?.items?.map((phase, index) => {
        return (
          <div
            key={index}
            className={`d-flex flex-column ${
              index > 0 ? 'mt-3' : ''
            } px-2 report-widget ${whenPrinting ? 'p-2' : 'p-4'}`}
            style={{
              borderRadius: 'var(--rpt-widget-border-radius)',
              background: '#ffffff',
            }}
          >
            <h5 className="text-align-left mb-0">{phase.title}</h5>
            <p className="text-align-left px-2 font-size-sm2">
              {phase.description}
            </p>

            <CutoutWidget
              widgetConfig={widgetConfig}
              whenPrinting={whenPrinting}
              printingClasses="pt-1 mt-2"
              cutoutConfig={{
                icon: 'finance',
                text: `${
                  widgetConfig?.data[phase.venderCountKey] || 0
                } vendors`,
                bg: `report-widget-inner-section-cutout ${
                  ColorMapping[phase.venderCountKey]
                } bg-white mx-2`,
                iconConfig: {
                  icon: 'speed',
                },
              }}
            >
              <CardBody className={`py-2 text-center`}>
                <p
                  className={`font-weight-light mb-0 font-size-sm2`}
                  dangerouslySetInnerHTML={{ __html: 'Total spend' }}
                />
                <h1 className={`mb-0 font-weight-bold font-size-md`}>
                  ${formatNumber(widgetConfig?.data[phase.totalSpentKey] || 0)}
                </h1>
              </CardBody>
              <div
                className="center-line"
                style={{
                  width: 'var(--rpt-widget-border-thickness)',
                  margin: '0 10px',
                  height: '38px',
                }}
              />
              <CardBody className={`py-2 text-center`}>
                <p
                  className={`font-weight-light mb-1 fs-8`}
                  dangerouslySetInnerHTML={{ __html: 'Expected conversion' }}
                />
                <h1 className={`mb-0 font-weight-semi-bold font-size-sm2`}>
                  $
                  {formatNumber(
                    widgetConfig?.data[phase.expectedConversationKey] || 0
                  )}
                </h1>
              </CardBody>
            </CutoutWidget>
          </div>
        );
      })}
    </div>
  );
};

export default VenderAnalysisPhasesWidget;
