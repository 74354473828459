/* eslint-disable no-debugger */
import React, { useEffect, useState, useRef } from 'react';

import ButtonIcon from '../../../components/commons/ButtonIcon';
import Board from '../../../components/deals/Board';
import {
  DEALS_LABEL_BUTTON,
  OWNER,
  SEARCH,
  paginationDefault,
  TABLE_RECORDS_LIMIT,
} from '../../../utils/constants';
import dealService from '../../../services/deal.service';
import pipelineService from '../../../services/pipeline.services';
import AlertWrapper from '../../../components/Alert/AlertWrapper';
import Alert from '../../../components/Alert/Alert';
import DealList from './DealList';
import userService from '../../../services/user.service';
import { DataFilters } from '../../../components/DataFilters';
import AddDeal from '../../../components/peopleProfile/deals/AddDeal';
import stageService from '../../../services/stage.service';
import Skeleton from 'react-loading-skeleton';
import { usePipelineBoardContext } from '../../../contexts/PipelineBoardContext';
import moment from 'moment';
import TooltipComponent from '../../../components/lesson/Tooltip';
import { isPermissionAllowed } from '../../../utils/Utils';
import FilterTabsButtonDropdown from '../../../components/commons/FilterTabsButtonDropdown';
import { usePagesContext } from '../../../contexts/pagesContext';
import { useModuleContext } from '../../../contexts/moduleContext';
import _ from 'lodash';
import SortButtonDropdown from '../../../components/commons/SortButtonDropdown';
import useDefaultFilter from '../../../hooks/useDefaultFilter';
import { ReportSkeletonLoader } from '../../Insights/ReportSkeletonLoader';
import { LOST, OPENED, WON } from './Pipeline.constants';
import MaterialIcon from '../../../components/commons/MaterialIcon';
import routes from '../../../utils/routes.json';
import { Link } from 'react-router-dom';
import IdfTooltip from '../../../components/idfComponents/idfTooltip';
import TextOverflowTooltip from '../../../components/commons/TextOverflowTooltip';
import PageTitle from '../../../components/commons/PageTitle';
import { LexoRank } from 'lexorank';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { TransitionGroup } from 'react-transition-group';
import Collapse from '@mui/material/Collapse';

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

let DEALS_FILTER_OPTIONS_LIST = [
  { id: 12, key: 'MyDeals', name: 'My Opportunities' },
  { id: 13, key: 'AllDeals', name: 'All Deals' },
  { id: 14, key: OPENED, name: 'Open Deals' },
  { id: 15, key: 'closed', name: 'Closed Deals' },
  { id: 16, key: WON, name: 'Won Deals' },
  { id: 17, key: LOST, name: 'Lost Deals' },
  { id: 18, key: 'OneMonth', name: 'Deals created in this month' },
  { id: 19, key: 'ThreeMonths', name: 'More than 3 months old deals' },
];

const sortDefaultFilter = {
  id: 4,
  key: 'name',
  name: 'Name',
};

const BoardLoader = ({ count }) => {
  const [loaderCount] = useState(Array(count).fill(0));
  const ColumnLoader = () => {
    return (
      <div className="px-1 text-center pipeline-board-edit">
        <Skeleton
          count={6}
          height={80}
          className="my-2 d-block w-100 deal-col"
        />
      </div>
    );
  };
  return (
    <div className="d-flex justify-content-between flex-row w-100 parent-column">
      {loaderCount.map((_, index) => (
        <ColumnLoader key={index} />
      ))}
    </div>
  );
};

const Nav = ({ active = false, onClick, togglePipelineEdit }) => {
  return (
    <div className="mx-3">
      <ul
        className="nav nav-segment border bg-white p-0"
        id="leadsTab"
        role="tablist"
      >
        <li className="nav-item">
          <TooltipComponent title="Column view">
            <a
              className={`btn-sm btn rounded-0 hover-icon d-flex align-items-center justify-content-center bg-hover-gray ${
                active ? 'bg-primary-soft fw-bold text-primary' : ''
              }`}
              id="pipeline-tab"
              data-toggle="tab"
              role="tab"
              aria-controls="pipeline"
              aria-selected="true"
              onClick={() => onClick(true)}
            >
              <i
                className="material-symbols-rounded fs-5"
                data-uw-styling-context="true"
              >
                view_week
              </i>
            </a>
          </TooltipComponent>
        </li>
        <li className="nav-item">
          <TooltipComponent title="List view">
            <a
              className={`btn-sm btn rounded-0 hover-icon d-flex align-items-center justify-content-center bg-hover-gray ${
                !active ? 'bg-primary-soft fw-bold text-primary' : ''
              }`}
              id="list-tab"
              data-toggle="tab"
              role="tab"
              aria-controls="list"
              aria-selected="false"
              onClick={() => onClick(false)}
            >
              <i className="material-symbols-rounded fs-5">menu</i>
            </a>
          </TooltipComponent>
        </li>
      </ul>
    </div>
  );
};

const PipelineItem = ({
  pipeline,
  index,
  selectedPipeline,
  handleSelectedPipeline,
}) => {
  return (
    <Draggable key={pipeline.id} draggableId={pipeline.id} index={index}>
      {(provided, snapshot) => (
        <li
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`setting-item ${
            snapshot.isDragging
              ? 'shadow-lg bg-white rounded-lg'
              : 'bg-hover-gray'
          } `}
        >
          <a
            className={`cursor-pointer w-100 d-flex gap-2 px-1 align-items-center menu-hover-gray mb-0 fs-7 primary ${
              selectedPipeline?.key === pipeline.key
                ? 'active font-weight-medium bg-primary-soft'
                : 'text-black font-weight-normal'
            }`}
            onClick={() => handleSelectedPipeline(pipeline)}
          >
            <MaterialIcon
              icon="drag_indicator"
              clazz="text-gray-600 cursor-grab"
            />
            <MaterialIcon
              icon={pipeline.icon}
              filled={selectedPipeline?.key === pipeline.key}
            />
            <TextOverflowTooltip text={pipeline.name} maxLength={30} />
          </a>
        </li>
      )}
    </Draggable>
  );
};
const Deals = () => {
  const { pageContext, setPageContext } = usePagesContext();
  const isMounted = useRef(false);
  const [active, setActive] = useState(
    pageContext?.PipelinePage?.selectedView ?? true
  );
  const [openDeal, setOpenDeal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [allDeals, setAllDeals] = useState([]);
  const [filtersItems, setFiltersItems] = useState([]);
  const { moduleMap } = useModuleContext();

  const DEALS_SORT_OPTIONS_LIST = [
    { id: 4, name: 'Recent Activity', key: 'date_modified', suffix: 'asc' },
    { id: 3, name: 'Closing Date', key: 'date_closed', suffix: 'asc' },
    {
      id: 2,
      name: `${moduleMap.deal.singular} Value`,
      key: 'amount',
      suffix: 'asc',
    },
    {
      id: 1,
      name: `${moduleMap.deal.singular} Name`,
      key: 'name',
      suffix: 'asc',
    },
  ];
  const [sortFilterOption, setSortFilterOption] = useState(
    DEALS_SORT_OPTIONS_LIST
  );
  const [defaultFilter] = useDefaultFilter('deal');

  const [filterSelected, setFilterSelected] = useState(
    pageContext?.PipelinePage?.selectedFilter ?? defaultFilter
  );
  useEffect(() => {
    setFilterSelected(
      pageContext?.PipelinePage?.selectedFilter ?? defaultFilter
    );
  }, [defaultFilter]);

  const [searchTerm, setSearchTerm] = useState({});
  const [pagination, setPagination] = useState({
    page: paginationDefault.page,
  });
  const [paginationData, setPaginationData] = useState({
    page: paginationDefault.page,
  });
  // const defaultFilters = DEALS_FILTER_OPTIONS_LIST[0];
  const { selectedDealFilterKey } = pageContext?.PipelinePage || {};

  // Find the matching filter option based on `selectedDealFilterKey`
  const defaultFilters = selectedDealFilterKey
    ? DEALS_FILTER_OPTIONS_LIST?.find(
        (option) => option.key === selectedDealFilterKey
      )
    : DEALS_FILTER_OPTIONS_LIST[0];

  const [addDealBtnLoading, setAddDealBtnLoading] = useState(false);
  const [infoDeals, setInfoDeals] = useState({});
  const [flagDeal, setFlagDeal] = useState([]);
  const [order, setOrder] = useState([]);
  const [filterSortSelected, setFilterSortSelected] = useState({});
  const [initialDeals, setInitialDeals] = useState({});
  const [listDeals, setListDeals] = useState(initialDeals);
  const [pipelineEdit, setPipelineEdit] = useState(false);
  const { stages, setStages } = usePipelineBoardContext();
  const [refreshBoardHeader, setRefreshBoardHeader] = useState(1);
  const [selectedStage, setSelectedStage] = useState({});
  const [openFilter, setOpenFilter] = useState(false);
  const [sort, setSort] = useState([]);
  const [openSortFilter, setOpenSortFilter] = useState(false);
  const [dealFilterTab, setDealFilterTab] = useState('filters');
  const [dealFilterOptionSelected, setDealFilterOptionSelected] =
    useState(defaultFilters);
  const [filterOptionSelected, setFilterOptionSelected] =
    useState(sortDefaultFilter);
  const [me, setMe] = useState(null);
  const [, setTitle] = useState({
    id: 14,
    key: OPENED,
    name: 'Open Deals',
  });
  const [pipelines, setPipelines] = useState([]);
  const [selectedPipeline, setSelectedPipeline] = useState({});
  const [loadingPipelines, setLoadingPipelines] = useState(false);
  const [selectedOwner, setSelectedOwner] = useState('');
  const getStageByName = (name) => {
    return stages.find((stage) => stage?.name === name);
  };

  useEffect(() => {
    (async () => {
      setListDeals({});
      const pipelineId = selectedPipeline?.id;
      if (pipelineId) {
        setShowLoading(true);

        const responses = await Promise.allSettled([
          stageService.getStages(pipelineId),
          pipelineService.getPipelineStageStatuses(),
        ]);
        const stages = responses[0]?.value;
        const getStages = {};
        stages.forEach((stage) => {
          getStages[stage?.name] = {
            loading: true,
            id: stage?.id,
            pipelineId,
            pipelineStageStatusId: stage?.pipelineStageStatusId,
            stagePosition: stage?.position,
            rank: stage?.rank || LexoRank.max().toString(),
            name: stage?.name,
            title: stage?.name,
          };
        });
        setStages(stages);
        setInitialDeals(getStages);
        setShowLoading(false);
      }
      onGetUsers();
    })();
  }, [refreshBoardHeader, selectedPipeline]);

  useEffect(() => {
    (async () => {
      setLoadingPipelines(true);
      const { data } = await pipelineService.getPipelines();
      const updatedPipelines = data?.map((p) => ({ ...p, key: p.id }));
      const defaultPipeline =
        updatedPipelines?.find((d) => d.isDefault) ||
        (updatedPipelines?.length && updatedPipelines[0]);
      setPipelines(updatedPipelines);
      const contextPipeline =
        pageContext?.PipelinePage?.selectedPipeline ?? defaultPipeline;
      setSelectedPipeline(contextPipeline || {});
      setLoadingPipelines(false);
    })();
  }, []);

  useEffect(() => {
    if (moduleMap.deal) {
      DEALS_FILTER_OPTIONS_LIST = DEALS_FILTER_OPTIONS_LIST.map((option) => ({
        ...option,
        name: option.name.replace(/Deals/i, _.startCase(moduleMap.deal.plural)),
      }));
    }
  }, [moduleMap.deal]);

  useEffect(() => {
    (async () => {
      const me = await userService
        .getUserInfo()
        .catch((err) => console.log(err));
      setMe(me);
    })();
  }, []);

  useEffect(() => {
    if (active) {
      Object.values(initialDeals).forEach((item) => {
        getDeals(
          {
            ...item,
            name: item?.name,
            id: item?.id,
            stagePosition: item?.position || item?.stagePosition,
          },
          paginationDefault.page
        );
      });
    } else onGetDeals(true);
  }, [active, paginationData, flagDeal, initialDeals]);

  useEffect(() => {
    const summary = [];
    Object.keys(listDeals).forEach((key) => {
      if (listDeals[key]?.header?.total_amount) {
        summary.push(listDeals[key]?.header);
      }
      setInfoDeals({});
    });
  }, [listDeals]);

  async function onGetUsers() {
    const response = await userService
      .getUsers(
        {
          search: '',
          users: [],
          filters: '',
        },
        {}
      )
      .catch((err) => err);

    const { data } = response || {};

    const newFilterOptions = filtersItems.slice();
    newFilterOptions.push({
      id: 1,
      label: OWNER,
      name: 'assigned_user_id',
      options: data?.users,
      type: 'search',
    });

    setFiltersItems(newFilterOptions);
  }

  const filterID = (id, FList) => {
    return FList
      ? FList.includes(id)
        ? FList.filter((n) => n !== id)
        : [id, ...FList]
      : id;
  };

  const onHandleFilterContact = (item, avatars = true) => {
    const prevFils = filterSelected.filter
      ? filterSelected.filter.assigned_user_id
      : null;
    setOpenFilter(false);

    if (item) setListDeals(initialDeals);
    setFilterSelected({
      ...filterSelected,
      filter: {
        assigned_user_id: avatars ? filterID(item.id, prevFils) : item.id,
      },
    });

    setPageContext({
      ...pageContext,
      PipelinePage: {
        ...pageContext.PipelinePage,
        selectedDealFilterKey: 'MyDeals',
        selectedFilter: {
          ...filterSelected,
          filter: {
            assigned_user_id: avatars ? filterID(item.id, prevFils) : item.id,
          },
        },
      },
    });

    setPaginationData({ page: paginationDefault.page });
    setSelectedOwner(item.name);
  };

  const onHandleFilterDeal = (item) => {
    onHandleFilterContact(item, false);
  };

  useEffect(() => {
    (async () => {
      if (filterSelected.filter) {
        if (
          filterSelected.filter.assigned_user_id &&
          filterSelected.filter.assigned_user_id.length !== 0
        ) {
          const Len = filterSelected.filter.assigned_user_id.length;
          if (Len > 1) {
            setTitle({ key: 0, name: selectedOwner });
          }
        } else if (
          filterSelected.filter.status ||
          filterSelected.filter.recent_activity ||
          filterSelected.filter.start_date
        ) {
          // dont liking it :| not breaking faizan implementation
          const filterStatus = filterSelected.filter.recent_activity
            ? 'RecentlyViewed'
            : filterSelected.filter.discriminator
            ? filterSelected.filter.discriminator
            : filterSelected.filter.status;

          const Title = DEALS_FILTER_OPTIONS_LIST.filter(
            (status) => status.key === filterStatus
          )[0];
          setTitle(Title);
        } else {
          setTitle({ id: 4, key: OPENED, name: 'Open Deals' });
        }
      }
    })();
  }, [filterSelected]);

  const cleanFilter = () => {
    // this is trash
    const { filter } = filterSelected || { filter: {} };
    const { discriminator, ...rest } = filter || {};
    return rest;
  };
  const limit = pageContext?.PipelinePage?.selectedView
    ? 100
    : TABLE_RECORDS_LIMIT;
  const onGetDeals = async (orderArray) => {
    setShowLoading(true);
    try {
      const { data, pagination } = await pipelineService.getPipelineDeals(
        selectedPipeline.id,
        paginationData.page,
        limit,
        cleanFilter(),
        orderArray?.length > 0 ? orderArray : sort
      );
      if (pagination) setPagination(pagination);
      setAllDeals(data);
      setInfoDeals({});
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingPipelines(false);
      setShowLoading(false);
    }
  };

  const setNotification = async (notificationCode, description) => {
    const notificationsStatus = {
      success: setSuccessMessage,
      error: setErrorMessage,
    };

    notificationsStatus[notificationCode](description);
  };

  const getDeals = async (status, page, sortArray, dropped) => {
    const filters = cleanFilter();
    if (
      Object.keys(filters).length ||
      dealFilterOptionSelected?.key === 'AllDeals'
    ) {
      const foundStage = getStageByName(status?.name);
      const sortArrayParam = sortArray?.length > 0 ? [sortArray] : null;

      try {
        const { data, pagination } = await pipelineService.getPipelineDeals(
          selectedPipeline?.id,
          page,
          1000,
          {
            ...filters,
            tenant_deal_stage_id: status.id,
          },
          sortArrayParam?.length > 0 ? sortArrayParam : sort
        );

        // Concatenate the new data with the existing data
        setListDeals((prev) => {
          const items = prev[status.name]?.items || [];
          return {
            ...prev,
            [status.name]: {
              stageId: status.id,
              loading: false,
              stagePosition: foundStage?.position || status.stagePosition,
              items: [...items, ...data], // Concatenate new data with existing data
              pagination,
              header: {
                ...status,
                tenant_deal_stage_id: status.id,
                total_amount: calculateTotalAmount([...items, ...data]), // Recalculate total amount
              },
            },
          };
        });

        setInfoDeals({});
      } catch (e) {
        console.log(e);
      } finally {
        setShowLoading(false);
        setLoadingPipelines(false);
      }
    }
  };

  // Function to calculate the total amount
  const calculateTotalAmount = (deals) => {
    return deals.reduce((acc, dt) => {
      return {
        amount: (acc?.amount || 0) + dt.amount,
      };
    }, 0).amount;
  };

  const dataFilter = (search) => {
    setListDeals(initialDeals);
    setFilterSelected(search);
    setPaginationData({ page: paginationDefault.page });
    setPageContext({
      ...pageContext,
      PipelinePage: {
        ...pageContext.PipelinePage,
        // selectedFilter: search,
      },
    });
  };

  useEffect(() => {
    if (isMounted.current) {
      const delayTime = setTimeout(() => {
        dataFilter(searchTerm);
      }, [1000]);
      return () => clearTimeout(delayTime);
    } else isMounted.current = true;
  }, [searchTerm]);

  const editPipeline = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setPipelineEdit(!pipelineEdit);
  };

  const changeView = (value) => {
    if (active !== value) {
      setActive(value);
      setPaginationData({ page: paginationDefault.page });
      setListDeals(initialDeals);

      setShowLoading(false);
      setPageContext({
        ...pageContext,
        PipelinePage: {
          ...pageContext.PipelinePage,
          selectedView: !active, // true means column, false means table
        },
      });
    }
  };

  const refreshDeals = (type, page, load = false) => {
    if (load) getDeals(type, page);
    else {
      type.forEach((status) => {
        setListDeals((prev) => ({
          ...prev,
          [status.name]: {
            stageId: status.id,
            stagePosition: status.stagePosition,
            loading: true,
            items: [],
            pagination: page,
            header: [],
          },
        }));
        getDeals(status, paginationDefault.page);
      });
    }
  };

  const onAddDeal = async (stage) => {
    setAddDealBtnLoading(true);
    setOpenDeal(true);
    setAddDealBtnLoading(false);
  };

  const handleRefreshBoardHeader = () => {
    setShowLoading(true);
    setRefreshBoardHeader((prevState) => prevState + 1);
    setPipelineEdit(!pipelineEdit);
    setListDeals({});
  };

  const refreshBoard = () => {
    setFlagDeal(!flagDeal);
    setListDeals(initialDeals);
  };

  const handleAddDeal = async (stage) => {
    setSelectedStage(stage);
    setAddDealBtnLoading(true);
    setOpenDeal((prev) => !prev);
    setAddDealBtnLoading(false);
  };

  const handleFilterSelect = (e, status) => {
    e.preventDefault();
    setOpenFilter(!openFilter);
    setListDeals(initialDeals);
    setDealFilterOptionSelected(status);
    const { key } = status;
    let filter = {};

    if (key === 'MyDeals') {
      filter = { assigned_user_id: me.id };
    } else if (key === 'OneMonth') {
      const now = moment().toISOString();
      const startOfMonth = moment().startOf('month').toISOString();
      filter = {
        start_date: startOfMonth,
        end_date: now,
        discriminator: 'OneMonth',
      };
    } else if (key === 'ThreeMonths') {
      const startOfTime = moment(new Date(1970, 0, 1)).toISOString();
      const threeMonthsOld = moment().subtract(3, 'months').toISOString();
      filter = {
        start_date: startOfTime,
        end_date: threeMonthsOld,
        discriminator: 'ThreeMonths',
      };
    } else if (key === 'RecentlyViewed') {
      const oneHourBefore = moment().utc().subtract(1, 'hours').toISOString();
      const now = moment().utc().toISOString();
      filter = {
        recent_activity: true,
        start_date: oneHourBefore,
        end_date: now,
      };
    } else if (key === 'AllDeals') {
      filter = {};
    } else {
      filter = { status: status.key };
    }

    setPaginationData({ page: paginationDefault.page });
    setFilterSelected({
      ...filterSelected,
      filter,
    });

    setPageContext({
      ...pageContext,
      PipelinePage: {
        ...pageContext.PipelinePage,
        selectedDealFilterKey: key,
        selectedFilter: { ...filterSelected, filter },
      },
    });
  };

  const sortTable = ({ name }) => {
    // Find the matching object in DEALS_SORT_OPTIONS_LIST
    const matchingOption = DEALS_SORT_OPTIONS_LIST.find(
      (option) => option.key === name.orderBy
    );

    if (matchingOption) {
      setSortFilterOption((prevSortFilterOption) => {
        const updatedOptions = prevSortFilterOption.map((item) => {
          if (item.key === name.orderBy) {
            const updatedItem = {
              ...item,
              suffix: name.clicked === 'ASC' ? 'desc' : 'asc',
            };
            return updatedItem;
          }
          return item;
        });
        return updatedOptions;
      });

      handleSelectedFilter(matchingOption);
    }
  };

  const handleSelectedFilter = (status) => {
    setListDeals([]);
    if (status) {
      setSortFilterOption((prevSortFilterOption) => {
        const updatedOptions = prevSortFilterOption.map((item) => {
          if (item.id === status?.id) {
            const updatedItem = {
              ...item,
              suffix: item.suffix === 'asc' ? 'desc' : 'asc',
            };
            setShowLoading(true);

            const orderArray = [updatedItem.key, updatedItem.suffix];
            stages?.map((item) => {
              return getDeals(item, 1, orderArray);
            });
            setOrder([item.key, item.suffix === 'asc' ? 'DESC' : 'ASC']);
            setSort([orderArray]);
            onGetDeals([orderArray]);
            setFilterOptionSelected(updatedItem); // Update filterOptionSelected with the updated item
            return updatedItem;
          }
          return item;
        });

        return updatedOptions;
      });
    } else {
      setSortFilterOption(DEALS_SORT_OPTIONS_LIST);
      setFilterOptionSelected(sortDefaultFilter);
      setOrder([]);
      setSort([]);
      setShowLoading(true);
      stages?.map((item) => {
        return getDeals(item, 1, []);
      });
      onGetDeals([]);
    }
  };

  const handleSelectedPipeline = (pipe) => {
    setSelectedPipeline(pipe);
    setPageContext({
      ...pageContext,
      PipelinePage: {
        selectedPipeline: pipe || {},
        selectedView: active, // true means column, false means table
      },
    });
  };

  const defaultPipeline = pipelines?.find((item) => item.isDefault);
  const otherPipelines = pipelines?.filter((item) => !item.isDefault);
  const [, setSortedPipelines] = useState(
    defaultPipeline ? [defaultPipeline, ...otherPipelines] : otherPipelines
  );

  const updatePipelineRank = async (pipeline) => {
    try {
      await pipelineService.updatePipeline(pipeline?.id, {
        rank: pipeline.rank,
      });
      setSuccessMessage('Opportunity rearranged successfully.');
    } catch (err) {
      console.log(err);
      setErrorMessage(
        'Error in updating pipeline position. Please check console for details.'
      );
    }
  };

  const updatePipelineOrder = (fromIndex, toIndex) => {
    const updatedItems = [...pipelines];
    const [movedItem] = updatedItems.splice(fromIndex, 1);
    updatedItems.splice(toIndex, 0, movedItem);

    const previousRank =
      toIndex === 0
        ? LexoRank.min()
        : LexoRank.parse(updatedItems[toIndex - 1].rank);
    const nextRank =
      toIndex === updatedItems.length - 1
        ? LexoRank.max()
        : LexoRank.parse(updatedItems[toIndex + 1].rank);
    const newRank = previousRank.between(nextRank).toString();

    movedItem.rank = newRank;
    updatedItems.sort((a, b) => a.rank.localeCompare(b.rank));
    setSortedPipelines(updatedItems);
    updatePipelineRank(movedItem);
  };
  const onHandleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    updatePipelineOrder(result.source.index, result.destination.index);
  };

  return (
    <>
      <PageTitle page={selectedPipeline?.name} pageModule="Opportunities" />
      <div className="settings-layout position-relative">
        <div className="setting-sidebar overflow-x-hidden">
          <div className="d-flex justify-content-between align-items-center mt-0 pt-3 pb-3 px-2">
            <h3 className="mb-0">Opportunities</h3>
            {isPermissionAllowed('pipelines', 'view') && (
              <IdfTooltip text="Create New Stage">
                <Link
                  to={routes.pipelinesAndStages}
                  className="d-flex icon-hover-bg align-items-center"
                >
                  <MaterialIcon
                    icon="add_circle"
                    filled
                    clazz="action-items text-primary cursor-pointer"
                  />
                </Link>
              </IdfTooltip>
            )}
          </div>
          {loadingPipelines ? (
            <ReportSkeletonLoader rows={7} height={30} />
          ) : (
            <DragDropContext onDragEnd={onHandleDragEnd}>
              <Droppable droppableId="pipeline-main-list">
                {(provided, snapshot) => (
                  <ul
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ listStyleType: 'none' }}
                  >
                    <TransitionGroup appear={true}>
                      {pipelines
                        ?.sort((a, b) => a.rank.localeCompare(b.rank))
                        ?.map((item, index) => (
                          <Collapse key={item.id}>
                            <PipelineItem
                              pipeline={item}
                              selectedPipeline={selectedPipeline}
                              handleSelectedPipeline={handleSelectedPipeline}
                              index={index}
                              key={index}
                            />
                          </Collapse>
                        ))}
                      {provided.placeholder}
                    </TransitionGroup>
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </div>
        <div className="settings-content">
          <div className="d-flex mb-0 pipline-options-btn bg-white py-2_1 px-3">
            <FilterTabsButtonDropdown
              options={DEALS_FILTER_OPTIONS_LIST}
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
              filterOptionSelected={dealFilterOptionSelected}
              filterSelected={filterSelected}
              filterTabs={dealFilterTab}
              handleFilterSelect={handleFilterSelect}
              onHandleFilterOrg={onHandleFilterDeal}
              setFilterOptionSelected={setDealFilterOptionSelected}
              setFilterSelected={setFilterSelected}
              setFilterTabs={setDealFilterTab}
              defaultSelection={defaultFilters}
              extraClasses={'dropdown-left'}
            />
            <div className="ml-auto mr-3 btn-filter">
              <DataFilters
                filterSelected={filterSelected}
                setFilterSelected={setSearchTerm}
                searchPlaceholder={SEARCH}
                infoDeals={infoDeals}
                paginationPage={paginationData}
                setPaginationPage={setPaginationData}
                showSearch={false}
                variant
              >
                <div className="filter-right d-flex">
                  {active && (
                    <SortButtonDropdown
                      options={sortFilterOption}
                      openFilter={openSortFilter}
                      btnToggleStyle="ml-3 btn-sm p-2 rounded-circle"
                      setOpenFilter={setOpenSortFilter}
                      filterOptionSelected={filterOptionSelected}
                      filterSelected={filterSortSelected}
                      handleFilterSelect={handleSelectedFilter}
                      setFilterOptionSelected={setFilterOptionSelected}
                      setFilterSelected={setFilterSortSelected}
                      defaultSelection={defaultFilters}
                      showRemoveSort
                      showSortIcon
                    />
                  )}
                  <Nav
                    active={active}
                    onClick={changeView}
                    togglePipelineEdit={editPipeline}
                  />
                </div>
              </DataFilters>
            </div>
            {isPermissionAllowed('deals', 'create') && (
              <>
                {moduleMap.deal && (
                  <ButtonIcon
                    icon="add"
                    classnames="btn-sm border-0"
                    loading={addDealBtnLoading}
                    label={DEALS_LABEL_BUTTON.replace(
                      /Pipeline/g,
                      moduleMap.deal.singular
                    )}
                    onClick={() => handleAddDeal(undefined)}
                  />
                )}
              </>
            )}
            {openDeal && (
              <AddDeal
                className="btn-transparent border-0"
                setOpenDeal={setOpenDeal}
                openDeal={openDeal}
                initialDeals={initialDeals}
                pipeline={selectedPipeline}
                onGetDeals={refreshBoard}
                setErrorMessage={setErrorMessage}
                setSuccessMessage={setSuccessMessage}
                selectedStage={selectedStage}
              />
            )}
          </div>
          <div className={`tab-content ${active ? 'pt-2' : ''}`}>
            <div
              className={`tab-pane fade col-md-12 pl-0 py-0 ${
                active && 'active show'
              }`}
            >
              {showLoading ? (
                <BoardLoader count={5} />
              ) : (
                <Board
                  onGetDeals={(type, id, stagePosition, page, dropped) => {
                    setListDeals((prev) => ({
                      ...prev,
                      [type]: {
                        stageId: id,
                        stagePosition,
                        loading: true,
                        items: [],
                        pagination: page,
                        header: [],
                      },
                    }));
                    getDeals({ name: type, id, stagePosition }, page, dropped);
                  }}
                  setNotification={setNotification}
                  listDeals={listDeals}
                  onClick={refreshDeals}
                  selectedPipeline={selectedPipeline}
                  editPipeline={pipelineEdit}
                  refreshBoard={refreshBoard}
                  refreshBoardHeader={handleRefreshBoardHeader}
                  onAddDeal={handleAddDeal}
                  viewType={active ? 'column' : 'list'}
                />
              )}
            </div>
            <div
              className={`tab-pane fade col-12 p-0 ${!active && 'active show'}`}
            >
              <DealList
                allDeals={allDeals}
                pagination={pagination}
                viewType={active ? 'column' : 'list'}
                service={dealService}
                selectedPipeline={selectedPipeline}
                showLoading={showLoading}
                onPaginationChange={(page) =>
                  setPaginationData({ ...paginationData, page })
                }
                onAddDeal={onAddDeal}
                sortingTable={sortTable}
                sortingOrder={order}
              />
            </div>
          </div>
        </div>
      </div>

      <AlertWrapper>
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
        />
      </AlertWrapper>
    </>
  );
};

export default Deals;
