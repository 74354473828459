import React, { useContext, useEffect, useState } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { useHistory } from 'react-router';
import stringConstants from '../../utils/stringConstants.json';
import { TabsContext } from '../../contexts/tabsContext';
import RightPanelModal from '../../components/modal/RightPanelModal';
import fieldService from '../../services/field.service';
import {
  RIGHT_PANEL_WIDTH,
  isPermissionAllowed,
  overflowing,
} from '../../utils/Utils';
import Loading from '../../components/Loading';
import AddActivity from '../../components/peopleProfile/contentFeed/AddActivity';
import { groupBy } from 'lodash';
import {
  ActivitiesFiltersList,
  paginationDefault,
  TABLE_RECORDS_LIMIT,
} from '../../utils/constants';
import LayoutHead from '../../components/commons/LayoutHead';
import ButtonFilterDropdown from '../../components/commons/ButtonFilterDropdown';
import ButtonIcon from '../../components/commons/ButtonIcon';
import activityService from '../../services/activity.service';
import { useProfileContext } from '../../contexts/profileContext';
import AlertWrapper from '../../components/Alert/AlertWrapper';
import Alert from '../../components/Alert/Alert';
import AllActivitiesTable from '../../components/ActivitiesTable/AllActivitiesTable';
import ActivityStats from '../../components/ActivitiesTable/ActivityStats';
import UpdateContact from '../../components/organizationProfile/contacts/updateContact';
import Breadcrumb from '../../utils/Breadcrumb';
import ChecklistCalendar from '../ChecklistViews/ChecklistCalendar';
import { Form } from 'react-bootstrap';
import MaterialIcon from '../../components/commons/MaterialIcon';
import Pagination from '../../components/Pagination';
import dayjs from 'dayjs';

const Stats = ({
  tabType,
  isFilterCheck,
  moduleMap,
  updateFilter,
  selectedCount,
  activeTabs,
  statusFilter,
}) => {
  return (
    <ActivityStats
      tab={tabType}
      filter={isFilterCheck?.filter}
      task={moduleMap.task.plural}
      call={moduleMap.call.plural}
      event={moduleMap.event.plural}
      updateFilter={updateFilter}
      selectedCount={selectedCount}
      activeTabs={activeTabs}
      statusFilter={statusFilter}
    />
  );
};

const TASK = 'task';
const EVENT = 'event';
const CALL = 'call';
const AllActivities = (data) => {
  const { profileInfo } = useProfileContext();
  const [isShow, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isFieldsData, setIsFieldsData] = useState([]);
  const [btnType, setIsBtnType] = useState();
  const [tabType, setIsTabType] = useState('all');
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState([TASK, EVENT, CALL]);
  const history = useHistory();
  const [order, setOrder] = useState([]);
  const [filterSelected] = useState({});
  const [contacts, setContacts] = useState();
  const [deals, setDeals] = useState();
  const [allData, setAllData] = useState([]);
  const [organizations, setOrganizations] = useState();
  const { setActivatedTab } = useContext(TabsContext);
  const [showLoading, setShowLoading] = useState(false);
  const [pagination, setPagination] = useState(paginationDefault);

  const [dataInDB, setDataInDB] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [filterTabs, setFilterTabs] = useState('filters');
  const [deleteResults, setDeleteResults] = useState();
  const [showDeleteOrgModal, setShowDeleteOrgModal] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [getActivityId, setGetActivityId] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedDate, setSelectedDate] = useState(new Date());

  const constants = stringConstants.tasks;
  const moduleMap = data.data;

  const defaultAllFilter = {
    key: 'MyOpenActivities',
    name: 'My Scheduled Activities',
    filter: {},
  };

  const defaultStatusFilter = {
    key: 'AllStatus',
    name: 'All',
    filter: {},
  };
  const limit = TABLE_RECORDS_LIMIT;
  const [isFilterCheck, setIsFilterCheck] = useState({});
  const [statusFilter, setStatusFilter] = useState(defaultStatusFilter);
  const [ownershipFilter, setOwnershipFilter] = useState(defaultAllFilter);

  const [cloneData, setCloneData] = useState(false);
  const [updateOwners, setUpdateOwners] = useState(false);
  const [activityData, setActivityData] = useState({});
  const [selectedCount, setSelectedCount] = useState('none');

  const getData = async () => {
    setShowLoading(true);
    let params = {
      ...ownershipFilter?.filter,
      ...statusFilter?.filter,
      order,
    };
    // what a mess :\
    if (ownershipFilter?.key?.toLowerCase().includes('my')) {
      params.self = true;
    }
    if (Object.keys(isFilterCheck?.filter || {}).length) {
      params = { ...params, ...isFilterCheck.filter };
    }
    try {
      const data = await activityService.getActivity(params, {
        type: activeTab,
        ...pagination,
        limit,
      });
      setPagination(data?.pagination);
      setDataInDB(Boolean(data?.pagination?.totalPages));
      setAllData(data?.data);
      setShowLoading(false);
    } catch (err) {
      setErrorMessage(constants.create?.groupCreatedFailed);
    }
  };
  const groupBySection = (fieldsList) => {
    setIsFieldsData(groupBy(fieldsList, 'section'));
  };
  const getFields = async (item) => {
    setLoading(true);
    const { data } = await fieldService.getFields(item, {
      preferred: true,
    });
    groupBySection(data);
    setLoading(false);
  };
  const handleClearSelection = () => {
    setSelectAll(false);
    setSelectedData([]);
  };

  const handleEditActivity = async (singleItem) => {
    setGetActivityId(singleItem);
    setLoading(true);
    try {
      const singleData = await activityService.getSingleActivity(
        singleItem?.id
      );

      setContacts(singleData?.data?.contact);
      setDeals(singleData?.data?.deal);
      setOrganizations(singleData?.data?.organization);
      setIsBtnType(singleData?.data?.type);
      setShowModal(true);

      const { data } = await fieldService.getFields(singleItem.type, {
        usedField: true,
      });
      const {
        data: { data: customFields },
      } = await activityService.getCustomField(singleItem?.id, {
        page: 1,
        limit: 50,
      });
      let customValues = {};
      data.forEach((field) => {
        if (field.isCustom) {
          customFields.forEach((item) => {
            if (field.key === item.field.key && field.field_type !== 'DATE') {
              customValues = {
                ...customValues,
                [field.key?.toLowerCase().replace(/\s+/g, '')]:
                  field.field_type === 'CURRENCY'
                    ? item.value.substring(1)
                    : item.value,
              };
            } else if (
              field.key === item.field.key &&
              field.field_type === 'DATE'
            ) {
              customValues = {
                ...customValues,
                [field.key?.toLowerCase().replace(/\s+/g, '')]: new Date(
                  item.value
                ),
              };
            }
          });
        }
      });
      customValues = { ...singleData?.data, ...customValues };

      setActivityData(customValues);
      groupBySection(data);
    } catch {
      setErrorMessage('Server Error');
    } finally {
      setLoading(false);
    }
  };

  const updateFilter = (filterType, tabName) => {
    switch (filterType) {
      case 'all':
        if (selectedCount === 'all') {
          setSelectedCount('none');
        } else {
          setSelectedCount('all');
          handleFilterSelect({
            filter: {},
          });
        }
        break;
      case 'completed':
        if (selectedCount === 'completed') {
          setSelectedCount('none');
          handleFilterSelect({
            filter: {},
          });
        } else {
          setSelectedCount('completed');
          handleFilterSelect({
            filter: { done: true },
          });
        }
        break;
      case 'pending':
        if (selectedCount === 'pending') {
          setSelectedCount('none');
          handleFilterSelect({
            filter: {},
          });
        } else {
          setSelectedCount('pending');
          handleFilterSelect({
            filter: { done: false, startDate: new Date() },
          });
        }
        break;
      case 'overdue':
        if (selectedCount === 'overdue') {
          setSelectedCount('none');
          handleFilterSelect({
            filter: {},
          });
        } else {
          setSelectedCount('overdue');
          handleFilterSelect({
            filter: { done: false, endDate: new Date() },
          });
        }
        break;
      case 'changeTab':
        if (tabName === 'Tasks') {
          setActiveTab(['task']);
          setOwnershipFilter(ownershipOptions[1]);
        } else if (tabName === 'Events') {
          setActiveTab(['event']);
          setOwnershipFilter(ownershipOptions[1]);
        } else {
          setActiveTab(['call']);
          setOwnershipFilter(ownershipOptions[1]);
        }
        break;
    }
  };

  const tabsData = [
    {
      title: moduleMap.task.plural,
      key: 'task',
      component: null,
      tabId: 2,
    },
    {
      title: moduleMap.event.plural,
      key: 'event',
      component: null,
      tabId: 4,
    },
    {
      title: moduleMap.call.plural,
      key: 'call',
      component: null,
      tabId: 3,
    },
  ];
  const toggle = (tabKey) => {
    setActiveTab((prevState) =>
      prevState.includes(tabKey)
        ? prevState.filter((f) => f !== tabKey)
        : [...prevState, tabKey]
    );
  };
  const closeModal = () => {
    setShowModal(false);
    setGetActivityId();
    setIsBtnType('');
    setActivityData({});
    setContacts();
    setDeals();
    setOrganizations();
  };
  const loader = () => {
    if (loading) return <Loading />;
  };
  const handleDelete = (data) => {
    setShowDeleteOrgModal(true);
    setDeleteResults(data);
  };
  const handleClick = (type) => {
    getFields(type);
    setShowModal(true);
    setIsBtnType(type);
  };

  const handleFilterSelect = (item) => {
    const { key } = item || {};

    // Setting the filter check and resetting pagination
    setIsFilterCheck(item);
    setPagination((prevState) => ({ ...prevState, page: 1 }));

    // Mapping conditions to filters for concise control flow
    const ownershipConfig = {
      MyOverdueActivities: ownershipOptions[0],
      MyClosedActivities: ownershipOptions[0],
    };

    const statusConfig = {
      MyClosedActivities: statusOptions[1],
      ClosedActivities: statusOptions[1],
      OpenActivities: statusOptions[0],
      MyOverdueActivities: statusOptions[0],
    };

    // Set ownership filter based on key, fallback to default
    setOwnershipFilter(ownershipConfig[key] || ownershipOptions[1]);

    // Set status filter based on key, fallback to default
    setStatusFilter(statusConfig[key] || defaultStatusFilter);
  };

  const handleOwnershipSelect = (item) => {
    const newFilter = {
      ...item,
    };
    const dates =
      isFilterCheck && Object.hasOwn(isFilterCheck?.filter || {}, 'startDate')
        ? {
            startDate: isFilterCheck?.filter.startDate,
            endDate: isFilterCheck?.filter.endDate,
          }
        : {};
    setOwnershipFilter({
      ...newFilter,
      filter: { ...newFilter.filter, ...dates },
    });
    setPagination((prevState) => ({
      ...prevState,
      page: 1,
    }));
  };
  const handleStatusSelect = (item) => {
    setStatusFilter(item);
  };

  useEffect(() => {
    const path = location.pathname;
    let newUrl = path;
    if (activeTab === 2) {
      newUrl += '#task';
    } else if (activeTab === 3) {
      newUrl += '#call';
    } else if (activeTab === 4) {
      newUrl += '#event';
    } else if (activeTab === 5) {
      newUrl += '#checklist';
    }
    history.push(newUrl);
  }, [activeTab]);

  const handleSwtichOwner = () => {
    setUpdateOwners(true);
  };
  const handleOWnerChanged = async () => {
    try {
      const data = selectedData?.map((item) => {
        const obj = {
          activityId: item,
        };
        return obj;
      });
      await activityService.updateOwnerActivity(selectedItem, data);
      getData(tabType);
      setUpdateOwners(false);
      setSelectAll(false);
      setSelectedData([]);
      overflowing();
    } catch (e) {
      console.log(e);
    }
  };

  const handleDateChange = (newValue) => {
    const startDate = dayjs(newValue).startOf('day').toISOString();
    const endDate = dayjs(newValue).endOf('day').toISOString();
    setIsFilterCheck({
      ...isFilterCheck,
      filter: {
        ...isFilterCheck.filter,
        startDate,
        endDate,
      },
    });
    setPagination((prevState) => ({
      ...prevState,
      page: 1,
    }));
  };

  const handleClearDate = () => {
    const { startDate, endDate, ...restFilters } = isFilterCheck.filter || {};
    setIsFilterCheck({
      ...isFilterCheck,
      filter: restFilters,
    });
    setSelectedDate(new Date());
  };

  const hasDateFilter = () => {
    const { startDate, endDate } = isFilterCheck.filter || {};

    if (startDate && endDate) {
      return (
        dayjs(startDate).isSame(endDate, 'day') &&
        !dayjs(startDate).isSame(endDate)
      );
    }

    return false;
  };

  const onPaginationChange = (newPage) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  const isFilterMatch = (optionFilter) => {
    // Check if both filters are empty (this ensures only one empty filter can be selected)
    if (
      Object.keys(optionFilter).length === 0 &&
      Object.keys(isFilterCheck.filter || {}).length === 0
    ) {
      return true;
    }

    // Ensure the filters match exactly (length and key-value pairs)
    return (
      Object.keys(optionFilter).length ===
        Object.keys(isFilterCheck.filter || {}).length &&
      Object.keys(optionFilter).every(
        (key) => isFilterCheck.filter?.[key] === optionFilter[key]
      )
    );
  };

  const getBaseOptions = () => {
    const selectedTab = tabsData.find((item) => item.tabId === activeTab);

    if (!selectedTab) return null;

    const baseLabel =
      selectedTab.title === 'All' ? 'Activities' : selectedTab.title;

    return {
      label: baseLabel,
      key: selectedTab.key,
    };
  };

  const baseOptions = getBaseOptions();

  const ownershipOptions = [
    {
      label: `My Activities`,
      filter: { done: false },
      key: 'MyOpenActivities',
      name: 'My Scheduled Activities',
      selected: isFilterMatch({ done: false }),
    },
    {
      label: `All Activities`,
      filter: {},
      key: 'AllActivities',
      name: 'All Activities',
      selected: isFilterMatch({}),
    },
  ];

  const statusOptions = [
    {
      label: `Open Activities`,
      key: `status_open_${baseOptions?.key}`,
      filter: { done: false },
      selected: isFilterMatch({ done: false }),
    },
    {
      label: `Closed Activities`,
      key: `status_closed_${baseOptions?.key}`,
      filter: { done: true },
      selected: isFilterMatch({ done: true }),
    },
    {
      label: `All`,
      key: `AllStatus`,
      filter: {},
      selected: isFilterMatch({}),
    },
  ];
  const [datesWithDots, setDatesWithDots] = useState([]);

  const getCalendarData = async (startDate, endDate) => {
    try {
      const response = await activityService.getActivitiesByAggregateStatus({
        startDateRange: {
          start: startDate,
          end: endDate,
        },
        unit: 'day',
      });
      setDatesWithDots(response.data);
    } catch (err) {
      console.error('Error fetching dots data:', err);
    }
  };

  useEffect(() => {
    if (activeTab.length) {
      getData();
    }
  }, [activeTab, isFilterCheck, statusFilter, ownershipFilter]);
  return (
    <>
      <AlertWrapper>
        <Alert
          message={errorMessage}
          setMessage={setErrorMessage}
          color="danger"
        />
        <Alert
          message={successMessage}
          setMessage={setSuccessMessage}
          color="success"
        />
      </AlertWrapper>

      <div className="checklist-layout">
        <div className="checklist-sidebar overflow-x-hidden">
          <ChecklistCalendar
            className="border-bottom calendar-checklist"
            value={selectedDate}
            onChange={handleDateChange}
            datesWithDots={datesWithDots}
            dateKey="aggregateStartDate"
            dotKey="type"
            getCalendarData={getCalendarData}
          />
          {hasDateFilter() && (
            <div
              className="d-flex align-items-center justify-content-end cursor-pointer pt-2 pr-3 fs-7"
              onClick={handleClearDate}
            >
              <MaterialIcon icon="close" size="fs-5" clazz="d-flex" />
              <span className="date-clear-btn">Clear date</span>
            </div>
          )}

          <div className="d-flex flex-column gap-2 m-3 mb-5">
            <h3 className="fs-7 mb-0">Activity Types</h3>
            {tabsData?.map((item) => (
              <Form.Check
                key={item.tabId}
                id={item.tabId}
                inline
                label={item.title}
                name={item.key}
                type="checkbox"
                disabled={
                  activeTab?.length === 1 && activeTab?.includes(item?.key)
                }
                className={`large-check fs-7 filter-${item.key}`}
                checked={activeTab?.includes(item?.key)}
                onChange={() => toggle(item.key)}
              />
            ))}
          </div>

          <div className="d-flex flex-column gap-2 m-3 mt-4 mb-5">
            <h3 className="fs-7 mb-0">Ownership</h3>
            {ownershipOptions?.map((item) => (
              <Form.Check
                key={item.key}
                id={item.key}
                inline
                label={item.label}
                name={item.key}
                className={`large-radio fs-7`}
                type="radio"
                checked={item?.key === ownershipFilter?.key}
                onChange={() => handleOwnershipSelect(item)}
              />
            ))}
          </div>

          <div className="d-flex flex-column gap-2 m-3 mt-4">
            <h3 className="fs-7 mb-0">Status</h3>
            {statusOptions?.map((item) => (
              <Form.Check
                key={item.key}
                id={item.key}
                inline
                label={item.label}
                name={item.key}
                className={`large-radio fs-7`}
                type="radio"
                checked={item?.key === statusFilter?.key}
                onChange={() => handleStatusSelect(item)}
              />
            ))}
          </div>
        </div>

        <div className="checklist-content overflow-hidden bg-white">
          <div className="justify-content-between button-pannel d-flex py-3 mx-3 align-items-center">
            <ButtonFilterDropdown
              options={ActivitiesFiltersList}
              openFilter={openFilter}
              btnToggleStyle="py-2 btn-sm"
              setOpenFilter={setOpenFilter}
              dropmenuWidth="min-width-220"
              filterOptionSelected={
                ownershipOptions.map((o) => o.key).includes(isFilterCheck?.key)
                  ? {}
                  : isFilterCheck
              }
              filterSelected={filterSelected}
              filterTabs={filterTabs}
              handleFilterSelect={(e, item) => handleFilterSelect(item)}
              setFilterOptionSelected={setIsFilterCheck}
              setFilterSelected={setIsFilterCheck}
              setFilterTabs={setFilterTabs}
              defaultSelection={defaultAllFilter}
            />

            <LayoutHead
              selectedData={selectedData}
              onDelete={handleDelete}
              alignTop="mb-0"
              permission={{
                collection: 'activities',
                action: 'delete',
              }}
              onClear={handleClearSelection}
              extraClasses={'all-activities'}
            >
              {selectedData?.length > 0 && (
                <ButtonIcon
                  label="Change Owner"
                  onClick={() => handleSwtichOwner()}
                  classnames="btn-sm"
                  color="primary"
                />
              )}

              {selectedData?.length === 0 &&
                isPermissionAllowed('activities', 'create') && (
                  <div className="d-flex gap-1 align-items-center">
                    <ButtonIcon
                      label={moduleMap.task.singular}
                      icon="add"
                      onClick={() => handleClick('task')}
                      color="primary"
                      classnames="btn-sm px-3"
                    />
                    <ButtonIcon
                      label={moduleMap.event.singular}
                      icon="add"
                      onClick={() => handleClick('event')}
                      color="primary"
                      classnames="btn-sm px-3"
                    />
                    <ButtonIcon
                      label={moduleMap.call.singular}
                      icon="add"
                      onClick={() => handleClick('call')}
                      classnames="btn-sm px-3"
                      color="primary"
                    />
                  </div>
                )}
            </LayoutHead>

            <div className="breadcrumb-mobile w-100 mb-0">
              {moduleMap.organization && <Breadcrumb moduleMap={moduleMap} />}
            </div>
          </div>

          {updateOwners && (
            <UpdateContact
              moduleMap={moduleMap}
              setShowAddContactModal={setUpdateOwners}
              showAddContactModal={updateOwners}
              selectedItem={selectedItem}
              handleSubmit={handleOWnerChanged}
              setSelectedItem={setSelectedItem}
              onHandleShowAlertSuccess={setSuccessMessage}
              onHandleShowAlertFailed={setErrorMessage}
            >
              <button className="btn btn-icon btn-sm rounded-circle">
                <i className="material-symbols-rounded fs-5">add</i>
              </button>
            </UpdateContact>
          )}

          <TabContent className="w-100 overflow-hidden px-0">
            <TabPane className="position-relative d-flex flex-column w-100 p-0">
              <div className="flex-1">
                <AllActivitiesTable
                  getData={getData}
                  selectedData={selectedData}
                  setSelectedData={setSelectedData}
                  setShowLoading={setShowLoading}
                  setDataInDB={setDataInDB}
                  tabType={tabType}
                  cloneData={cloneData}
                  setCloneData={setCloneData}
                  allData={allData}
                  setActivatedTab={setActivatedTab}
                  handleEditActivity={handleEditActivity}
                  isFilterCheck={isFilterCheck}
                  setErrorMessage={setErrorMessage}
                  setSuccessMessage={setSuccessMessage}
                  deleteResults={deleteResults}
                  showDeleteOrgModal={showDeleteOrgModal}
                  setDeleteResults={setDeleteResults}
                  setShowDeleteOrgModal={setShowDeleteOrgModal}
                  selectAll={selectAll}
                  setSelectAll={setSelectAll}
                  order={order}
                  handleClearSelection={handleClearSelection}
                  setOrder={setOrder}
                  pagination={pagination}
                  showLoading={showLoading}
                  dataInDB={dataInDB}
                  setPagination={setPagination}
                />
              </div>
              <div className="border-top">
                <div className="d-flex py-1">
                  <ul className="px-3 mb-0 flex-fill">
                    <Stats
                      selectedCount={selectedCount}
                      updateFilter={updateFilter}
                      isFilterCheck={isFilterCheck}
                      statusFilter={statusFilter}
                      moduleMap={moduleMap}
                      activeTabs={activeTab}
                      tabType={tabType}
                    />
                  </ul>
                  <div className="py-1 ml-auto">
                    {pagination.totalPages > 1 && (
                      <Pagination
                        paginationInfo={pagination}
                        onPageChange={onPaginationChange}
                      />
                    )}
                  </div>
                </div>
              </div>
            </TabPane>
          </TabContent>
        </div>
        {isShow && (
          <RightPanelModal
            showModal={isShow}
            setShowModal={() => closeModal()}
            showOverlay={true}
            containerBgColor={'pb-0'}
            containerWidth={RIGHT_PANEL_WIDTH}
            containerPosition={'position-fixed'}
            headerBgColor="bg-gray-5"
            Title={
              <div className="d-flex py-2 align-items-center text-capitalize">
                {cloneData || Object.keys(activityData).length === 0 ? (
                  <h3 className="mb-0">
                    Add{' '}
                    {btnType === 'task'
                      ? moduleMap.task.singular
                      : btnType === 'call'
                      ? moduleMap.call.singular
                      : moduleMap.event.singular}
                  </h3>
                ) : (
                  <h3 className="mb-0">
                    Edit{' '}
                    {btnType === 'task'
                      ? moduleMap.task.singular
                      : btnType === 'call'
                      ? moduleMap.call.singular
                      : moduleMap.event.singular}
                  </h3>
                )}
              </div>
            }
          >
            {loading ? (
              loader()
            ) : (
              <AddActivity
                call={moduleMap.call.singular}
                event={moduleMap.event.singular}
                task={moduleMap.task.singular}
                btnType={btnType}
                activityData={activityData}
                feedInfo={activityData}
                getProfileInfo={profileInfo}
                setActiveTab={setActiveTab}
                setCloneData={setCloneData}
                cloneData={cloneData}
                activeTab={activeTab}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                successMessage={successMessage}
                setSuccessMessage={setSuccessMessage}
                getActivityId={getActivityId}
                setIsTabType={setIsTabType}
                isModal={isShow}
                setActivatedTab={setActivatedTab}
                getData={getData}
                setGetActivityId={setGetActivityId}
                feedId={cloneData ? '' : getActivityId?.id}
                dataType={
                  deals
                    ? 'deal'
                    : contacts
                    ? 'contact'
                    : organizations
                    ? 'organization'
                    : ''
                }
                deal={deals}
                contactInfo={contacts}
                organization={organizations}
                organizationId={organizations?.id}
                allFields={isFieldsData}
                closeModal={() => {
                  setShowModal(false);
                  overflowing();
                  closeModal();
                }}
              />
            )}
          </RightPanelModal>
        )}
      </div>
    </>
  );
};

export default AllActivities;
