import { InsightsTable } from './components';
import { AssignmentDetails } from './reportRenders/AssignmentDetails';
import { AssignmentProgress } from './reportRenders/AssignmentProgress';
import { AssignmentTeamSnapChat } from './reportRenders/AssignmentTeamSnapchat';

import DealAgingReport from './reportRenders/DealAgingReport';
import DealConversionRateReport from './reportRenders/DealConversionRateReport';
import DealPipelineReport from './reportRenders/DealPipelineReport';
import DealsProgressReport from './reportRenders/DealsProgressReport';
import LessonProgressByUser from './reportRenders/LessonProgressByUser';
import SalesActivityReport from './reportRenders/SalesActivityReport';
import SalesForecastReport from './reportRenders/SalesForecastReport';
import SalesFunnelReport from './reportRenders/SalesFunnelReport';
import SalesPerformanceReport from './reportRenders/SalesPerformanceReport';
import SalesTeamReport from './reportRenders/SalesTeamReport';

const insightNameToRender = {
  'Deal Pipeline Report': { render: DealPipelineReport, filters: [] },
  'Deals Progress Report': { render: DealsProgressReport, filters: [] },
  'Deal Aging Report': { render: DealAgingReport, filters: [] },
  'Sales Forecast Report': { render: SalesForecastReport, filters: [] },
  'Sales Performance Report': { render: SalesPerformanceReport, filters: [] },
  'Deal Conversion Rate Report': {
    render: DealConversionRateReport,
    filters: [],
  },
  'Sales Activity Report': { render: SalesActivityReport, filters: [] },
  'Sales Funnel Report': { render: SalesFunnelReport, filters: [] },
  'Sales Team Report': { render: SalesTeamReport, filters: [] },
  'Product Performance Report': { render: SalesPerformanceReport, filters: [] },
  'Activity Details': {
    render: LessonProgressByUser,
    filters: [],
    exportToCSV: true,
  },
};

export const validInsights = [...Object.keys(insightNameToRender)];

export const SwitchAllReports = (props) => {
  if (props.insightName === 'Assignment Statistics') {
    return <AssignmentProgress {...props} />;
  }

  if (props.insightName === 'Assignment Details') {
    return <AssignmentDetails {...props} />;
  }

  if (props.insightName === 'Assignments Team Snapshot') {
    return <AssignmentTeamSnapChat {...props} />;
  }

  if (!insightNameToRender[props.insightName]) {
    return;
  }
  const insight = insightNameToRender[props.insightName];

  return (
    <InsightsTable
      {...props}
      render={insight.render}
      filters={insight.filters}
      exportToCSV={insight.exportToCSV}
    />
  );
};
