import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Dropdown } from 'react-bootstrap';
import { findIndex } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import {
  // ERROR_CONTACT_UPDATE_CUSTOMER,
  // ERROR_ORGANIZATION_UPDATE_CUSTOMER,
  // ERROR_UPDATE_STATUS,
  items,
  itemAllowDelete,
  itemDisableDelete,
  CLOSED_WON,
  CLOSED_LOST,
  // CONTACT_NOT_FOUND,
  isWonDeal,
  isLostDeal,
} from './Pipeline.constants';
import dealService from '../../../services/deal.service';
// import contactService from '../../../services/contact.service';
import organizationService from '../../../services/organization.service';
import AlertWrapper from '../../../components/Alert/AlertWrapper';
import Alert from '../../../components/Alert/Alert';
import {
  DEAL_DELETE_CONFIRMATION,
  DEAL_REMOVED,
  CANT_REMOVE_DEAL,
  SOMETHING_IS_WRONG,
} from '../../../utils/constants';
import ModalConfirm from '../../../components/modal/ModalConfirmDefault';
import {
  capitalizeEachWord,
  isPermissionAllowed,
  isToFixedNoRound,
  setDateFormat,
} from '../../../utils/Utils';
import routes from '../../../utils/routes.json';
import IdfOwnersHeader from '../../../components/idfComponents/idfAdditionalOwners/IdfOwnersHeader';
import stageService from '../../../services/stage.service';
import { useProfileContext } from '../../../contexts/profileContext';
import IdfAllAdditionalOwnersList from '../../../components/idfComponents/idfAdditionalOwners/IdfAllAdditionalOwnersList';
import PageTitle from '../../../components/commons/PageTitle';
import TooltipComponent from '../../../components/lesson/Tooltip';
import MaterialIcon from '../../../components/commons/MaterialIcon';
import Skeleton from 'react-loading-skeleton';
import ContactCompanyDeleteMenu from '../../../components/commons/ContactCompanyDeleteMenu';
import DeleteModal from '../../../components/modal/DeleteModal';
import stringConstants from '../../../utils/stringConstants.json';
import Avatar from '../../../components/Avatar';
import ReactDatepicker from '../../../components/inputs/ReactDatpicker';
import useOutsideClickDropDown from '../../../hooks/useOutsideClickDropDown';
import moment from 'moment-timezone';
import { usePagesContext } from '../../../contexts/pagesContext';
import TextOverflowTooltip from '../../../components/commons/TextOverflowTooltip';
import AddDealContact from '../../../components/organizationProfile/contacts/AddDealContact';

const organizationConstants = stringConstants.deals.organizations;

const StagesDropdown = ({
  isOpen,
  setIsOpen,
  stages,
  deal,
  children,
  className,
  onChangeStage,
}) => {
  const highlightItem = (st) => {
    if (deal?.status) {
      return deal.status === st.id
        ? 'bg-primary-soft font-weight-semi-bold'
        : '';
    }
    return st.id === deal.tenant_deal_stage_id
      ? 'bg-primary-soft font-weight-semi-bold'
      : 'bg-white';
  };
  return (
    <Dropdown
      className="stage-dd"
      drop="down"
      show={isOpen}
      onToggle={setIsOpen}
    >
      <Dropdown.Toggle as="a" className="no-caret text-white">
        {children}
      </Dropdown.Toggle>
      <Dropdown.Menu
        className={`py-1 ${className} max-h-300 overflow-y-auto`}
        style={{ width: 220, zIndex: 10 }}
      >
        {stages?.map((stage, index) => (
          <div
            key={stage.id}
            onClick={() => {
              setIsOpen(false);
              onChangeStage(stage);
            }}
            className={`${highlightItem(stage)} ${
              index < stages.length - 1 ? 'border-bottom-light' : ''
            } ${
              stage.name === CLOSED_WON || stage.name === CLOSED_LOST
                ? 'py-1 px-2_1'
                : 'p-2_1'
            } font-size-sm2 d-flex align-items-center justify-content-between text-truncate font-weight-normal bg-hover-gray cursor-pointer`}
          >
            <TextOverflowTooltip text={stage.name} />
            {stage.name === CLOSED_WON && (
              <a className="bg-soft-success p-1 rounded-circle">
                <MaterialIcon
                  icon="thumb_up"
                  clazz="text-success"
                  size="fs-6"
                />
              </a>
            )}
            {stage.name === CLOSED_LOST && (
              <a className="bg-red-soft p-1 rounded-circle">
                <MaterialIcon icon="thumb_down" clazz="text-red" size="fs-6" />
              </a>
            )}
          </div>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
const PipelineHeader = (
  {
    moduleMap,
    classnames,
    deal,
    getDeal,
    refreshOwners,
    setRefresOwners,
    isPrincipalOwner,
    setOpenCloneModal,
    setOpenEditModal,
    refreshPipelineStage,
    withFollowers,
    mainOwner,
    service,
    openCompanyModal,
    setEditInfoModal,
  },
  props
) => {
  const history = useHistory();
  const { organizationId, contactId, id: dealId } = useParams();
  const serviceId = organizationId || contactId || dealId;
  const [owners, setOwners] = useState([]);
  const [count, setCount] = useState(0);
  const [openAllOwners, setOpenAllOwners] = useState(false);
  const [followersInfo, setFollowersInfo] = useState([]);
  const [userId, setUserId] = useState('');
  const { profileInfo } = useProfileContext();
  const [isFollowing, setIsFollowing] = useState(false);
  const [isOwner, setIsOwner] = useState(true);
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [removeDeal, setRemoveDeal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [, setItemsOptions] = useState(items);
  const [stages, setStages] = useState([]);
  const [updatingStage, setUpdatingStage] = useState('');
  const [spinner, setSpinner] = useState(false);
  const [openOwnerModal, setOpenOwnerModal] = useState(false);
  const [deleteResults, setDeleteResults] = useState([]);
  const [showDeleteReport, setShowDeleteReport] = useState(false);
  const [modified, setModified] = useState(false);
  const [ownerInfo, setOwnerInfo] = useState({});
  const [selectedOwner, setSelectedOwner] = useState([]);
  const { pageContext, setPageContext } = usePagesContext();
  const [addContact, setAddContact] = useState(false);

  const [allOwners, setAllOwners] = useState([]);

  const getUserId = () => {
    const id = profileInfo?.id;
    if (id) {
      setUserId(id);
    }
  };

  useEffect(() => {
    if (serviceId) onGetOwners();
  }, [serviceId, refreshOwners, isFollowing]);

  const ownersService = async (pagination) => {
    return await service
      .getOwners(serviceId, pagination)
      .catch((err) => console.log(err));
  };

  // this function takes the find function and put the item to first index in array if found
  const putItemsFirst = ({ findFunction, array }) => [
    ...array.filter(findFunction),
    ...array.filter((item) => !findFunction(item)),
  ];

  useEffect(() => {
    // placing primary owner to start of follower section
    setOwners([
      ...putItemsFirst({
        array: [...owners],
        findFunction: (rc) => {
          return rc.user?.id === props.mainOwner?.id;
        },
      }),
    ]);
    getCurrentUser();
  }, [mainOwner]);

  useEffect(() => {
    if (
      mainOwner &&
      (owners.length === 0 || owners[0].user_id !== mainOwner?.id)
    ) {
      setOwners([
        {
          organization_id: withFollowers,
          user: { ...mainOwner, type: 'primaryOwner' },
          user_id: mainOwner?.id,
        },
        ...owners,
      ]);
    }
  }, [owners]);

  const checkFollower = async () => {
    const result = await dealService.checkFollowing(deal?.id, userId);
    const IsOwner =
      owners.filter((owner) => {
        return owner.user_id === userId && owner.user.type !== 'follower';
      }).length > 0;
    setIsOwner(IsOwner);
    setIsFollowing(result);
  };

  useEffect(() => {
    getFollowers();
  }, []);

  useEffect(() => {
    if (userId && deal) {
      checkFollower();
    }
  }, [userId, followersInfo, deal]);

  useEffect(() => {
    getUserId();
  }, [profileInfo]);

  const onGetOwners = async (pagination = { page: 1, limit: 10 }) => {
    try {
      const requests = [];
      requests.push(ownersService(pagination));
      if (withFollowers) {
        // withFollowers should contain id of organization so that we get its followers only
        requests.push(organizationService.getFollowers(withFollowers, {}));
      }
      const resp = await Promise.all(requests); // ownersService(pagination);
      const owners = resp[0];

      const {
        data,
        pagination: { count },
      } = owners || {};

      const contacts = withFollowers && (resp[1].data || []);
      // to show icon, we need to add a property to distinguish between owners and followers
      const ownersList = data?.map((ow) => ({
        ...ow,
        user: { ...ow.user, type: 'owner' },
      }));
      const contactsList =
        (contacts &&
          contacts.map((fo) => ({
            ...fo,
            user: { ...fo.user, type: 'follower' },
          }))) ||
        [];
      setOwners([...ownersList, ...contactsList]);
      setCount(count);
    } catch (e) {
      console.log(e);
    }
  };

  const getFollowers = async () => {
    try {
      const result = await dealService.getFollowers(serviceId, {});
      setFollowersInfo(result?.data);
    } catch (e) {
      console.log(e);
    }
  };

  const stopFollowing = async () => {
    setSpinner(true);
    const response = await dealService.stopFollowing(deal?.id, userId);
    if (response?.response?.data?.error) {
      return setErrorMessage('There is something wrong in Stop Following!');
    }
    await getFollowers();
    setSpinner(false);
  };

  const startFollowing = async () => {
    setSpinner(true);
    const response = await dealService.startFollowing(deal?.id, userId);
    if (response?.response?.data?.error) {
      return setErrorMessage('There is something wrong in Following!');
    }
    await getFollowers();
    setSpinner(false);
  };

  const getCurrentUser = async () => {
    try {
      if (
        profileInfo?.role?.admin_access ||
        mainOwner?.id === profileInfo?.id
      ) {
        setItemsOptions([itemAllowDelete]);
      } else {
        setItemsOptions([itemDisableDelete]);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onChangeStage = async (stage) => {
    // if (stage.name === CLOSED_WON || stage.name === CLOSED_LOST) {
    //   onChangeStatus(stage.id);
    // } else {
    setUpdatingStage(stage.id);
    try {
      await dealService.updateDeal(deal.id, {
        tenant_deal_stage_id: stage.id,
        status: null,
        sales_stage: null,
      });
      getDeal('', true);
      setSuccessMessage(`${moduleMap.deal.singular} updated successfully.`);
      setPageContext({
        ...pageContext,
        RefreshTimeline: pageContext.RefreshTimeline
          ? pageContext.RefreshTimeline + 1
          : 1,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setUpdatingStage('');
    }
  };

  const onChangeClosingDate = async (newDate) => {
    try {
      await dealService
        .updateDeal(deal.id, {
          date_closed: newDate
            ? moment(newDate).tz('utc').startOf('day').toISOString()
            : undefined,
        })
        .catch(() => setErrorMessage(SOMETHING_IS_WRONG));
      // for now call get deal again.
      getDeal('', true);
      setSuccessMessage(`${moduleMap.deal.singular} updated successfully.`);
    } catch (e) {}
  };
  // const onChangeStatus = async (status) => {
  //   setUpdatingStage(status);
  //   const customer = {
  //     is_customer: status === WON,
  //   };

  //   if (!deal?.contact_person_id && !deal?.contact_organization_id)
  //     return setErrorMessage(
  //       CONTACT_NOT_FOUND.replace(/Contact/g, moduleMap.contact.singular)
  //     );

  //   const data = {
  //     status,
  //   };

  //   data.sales_stage = status;

  //   try {
  //     const dealUpdated = await dealService.updateDeal(deal.id, data);

  //     if (dealUpdated.status === 401) {
  //       return setErrorMessage(ERROR_UPDATE_STATUS);
  //     }

  //     if (dealUpdated?.data[0] === 0) {
  //       return setErrorMessage(ERROR_UPDATE_STATUS);
  //     }

  //     if (dealUpdated?.contact_person_id) {
  //       await contactService
  //         .updateContact(deal?.contact_person_id, customer)
  //         .catch(() => setErrorMessage(ERROR_CONTACT_UPDATE_CUSTOMER));
  //     }

  //     if (deal?.contact_organization_id) {
  //       await organizationService
  //         .updateOrganization(deal?.contact_organization_id, customer)
  //         .catch(() => setErrorMessage(ERROR_ORGANIZATION_UPDATE_CUSTOMER));
  //     }

  //     getDeal('', true);
  //     setPageContext({
  //       ...pageContext,
  //       RefreshTimeline: pageContext.RefreshTimeline
  //         ? pageContext.RefreshTimeline + 1
  //         : 1,
  //     });
  //     setSuccessMessage(`${moduleMap.deal.singular} updated successfully.`);
  //   } catch (err) {
  //     if (err.response.status !== 403) {
  //       setErrorMessage('Unable to update deal.');
  //     }
  //   } finally {
  //     setUpdatingStage('');
  //   }
  // };

  const setNotification = async (notificationCode, description) => {
    const notificationsStatus = {
      success: setSuccessMessage,
      error: setErrorMessage,
    };

    notificationsStatus[notificationCode](description);
  };

  const onHandleRemove = async () => {
    setModalLoading(true);
    const resp = await dealService.deleteDeal(deal.id).catch((err) => {
      if (err?.response?.status === 400) {
        setNotification(
          'error',
          err.response.data?.errors?.[0]?.message || CANT_REMOVE_DEAL
        );
      } else {
        setNotification('error', CANT_REMOVE_DEAL);
      }

      setModalLoading(false);
      return toggleModal();
    });

    if (resp?.data) {
      setNotification('success', DEAL_REMOVED);
      setModalLoading(false);

      setTimeout(() => {
        history.push(routes.dealsPipeline);
      }, 3000);
    }

    toggleModal();
  };

  const toggleModal = () => {
    setRemoveDeal((prev) => !prev);
  };

  const getStages = async () => {
    try {
      // setLoader(true);
      const pipelineId = deal?.tenant_deal_stage?.pipelineId;
      if (pipelineId) {
        const stages = await stageService.getStages(pipelineId);
        stages.sort((a, b) => a.rank.localeCompare(b.rank));
        setStages(stages);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  useEffect(() => {
    getStages();
  }, [deal]);

  const textColor = (index) => {
    const stageIndex = findIndex(stages, {
      id: deal.tenant_deal_stage_id || 'cold',
    });

    if (index === stageIndex) {
      if (isLostDeal(deal)) return 'text-red';
      if (isWonDeal(deal)) return 'text-success';

      return 'text-primary';
    }

    return 'text-primary';
  };

  const checkedStages = (index, stages, currentDeal) => {
    const stageIndex = findIndex(stages, {
      id: currentDeal.tenant_deal_stage_id || 'cold',
    });
    return index < stageIndex;
  };
  const deleteOwner = async () => {
    try {
      await dealService.removeOwner(deal?.id, ownerInfo.id);
      setDeleteResults([{ id: ownerInfo.id, msg: '', result: 'success' }]);
      setShowDeleteReport(true);
      setRefresOwners(true);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const setDeleteOwner = (profile) => {
    setOwnerInfo(profile.user);
    setAllOwners([profile.user]);
    setSelectedOwner([profile.user.id]);
    setOpenOwnerModal(true);
  };

  const checkedStagesAddingLast = (index, stages) => {
    return index === stages.length - 1;
  };
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const [dateClosed, setDateClosed] = useState(
    deal?.date_closed ? new Date(deal.date_closed) : new Date()
  );
  const [showPicker, setShowPicker] = useState(false);
  const toggleDatePicker = () => {
    setShowPicker(!showPicker);
  };

  const dtRef = useRef();
  useOutsideClickDropDown(dtRef, showPicker, setShowPicker);

  const StatusColors = {
    [CLOSED_WON]: 'var(--wonDarkColor)',
    [CLOSED_WON]: 'var(--wonDarkColor)',
    [`${CLOSED_WON}_c`]: 'bg-success',
    [`${CLOSED_WON}_t`]: 'text-success',
    [`${CLOSED_WON}_i`]: 'thumb_up',
    [CLOSED_LOST]: 'var(--rpt-color-red)',
    [CLOSED_LOST]: 'var(--rpt-color-red)',
    [`${CLOSED_LOST}_c`]: 'bg-red',
    [`${CLOSED_LOST}_t`]: 'text-red',
    [`${CLOSED_LOST}_i`]: 'thumb_down',
  };
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (pageContext.DealDateClose) {
      setDateClosed(pageContext.DealDateClose);
    }
  }, [pageContext]);

  return (
    <>
      {openOwnerModal && (
        <DeleteModal
          type="owners"
          showModal={openOwnerModal}
          setShowModal={setOpenOwnerModal}
          selectedData={selectedOwner}
          setSelectedData={setSelectedOwner}
          event={deleteOwner}
          data={allOwners}
          results={deleteResults}
          setResults={setDeleteResults}
          showReport={showDeleteReport}
          setShowReport={setShowDeleteReport}
          modified={modified}
          setModified={setModified}
          constants={organizationConstants.ownerDelete}
          resetSeletedData={false}
        />
      )}
      <PageTitle page={deal.name} pageModule="Deals" />

      <div className="px-3 pt-3">
        <div className="d-flex gap-1 align-items-center">
          <button
            className="btn btn-sm d-flex align-items-center icon-hover-bg"
            onClick={() => history.push(routes.dealsPipeline)}
          >
            <span className="material-symbols-rounded fs-3">
              keyboard_backspace
            </span>
          </button>
          <div className="d-flex align-items-center position-relative pl-1 rounded pr-5 py-2 pipleline-header-name">
            <h3 className="page-header-title mb-0">{deal.name}</h3>
            <span className="text-muted-light">•</span>
            <h3 className="page-header-title mb-0 pt-1">
              {isToFixedNoRound(deal?.amount)}
            </h3>
            <div className="position-absolute right-0 edit-div">
              <button
                className="btn btn-icon btn-sm hover-bg-none"
                onClick={() => setEditInfoModal(true)}
              >
                <MaterialIcon icon="edit" size="fs-5" />
              </button>
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <IdfOwnersHeader
              className="ml-0"
              mainOwner={deal?.assigned_user}
              service={dealService}
              serviceId={deal?.id}
              refreshOwners={refreshOwners}
              onClick={setDeleteOwner}
              setRefresOwners={setRefresOwners}
              isprincipalowner={isPrincipalOwner}
              addBtnBgGray
              headerType={{ collection: 'deals', action: 'edit' }}
            />
            <IdfAllAdditionalOwnersList
              openAllOwners={openAllOwners}
              setOpenAllOwners={setOpenAllOwners}
              serviceId={serviceId}
              count={count}
              setRefresOwners={setRefresOwners}
              onGetOwners={onGetOwners}
              owners={owners}
              isPrincipalOwner={isPrincipalOwner}
              {...props}
            />
            {addContact && (
              <AddDealContact
                getContacts={getDeal}
                showAddContactModal={addContact}
                setShowAddContactModal={setAddContact}
                organization={deal?.organization}
                organizationId={deal?.organization?.id}
                deal={deal}
                setSuccessMessage={setSuccessMessage}
                setErrorMessage={setErrorMessage}
                moduleMap={moduleMap?.contact.singular}
              />
            )}
            {isOwner ? (
              <>
                <div className="d-flex ml-2 align-items-center">
                  <TooltipComponent title="you are the owner, you can't follow this company.">
                    <button disabled className="btn follow-tag">
                      Follow
                    </button>
                  </TooltipComponent>
                </div>
              </>
            ) : isFollowing ? (
              <>
                <div className="d-flex ml-2 align-items-center">
                  <button className="btn follow-tag" onClick={stopFollowing}>
                    <span className="following-tag">Following</span>
                    <span className="unfollow-tag">Unfollow</span>
                  </button>
                </div>
                {spinner === true && (
                  <Spinner color="black" size="sm" className="m-1" />
                )}
              </>
            ) : (
              <>
                {isPermissionAllowed('contacts', 'create') && (
                  <>
                    <TooltipComponent title="Follow this company to get notified of any updates happening on this record">
                      <button
                        className="follow-tag btn ml-2 d-inline-flex align-items-center"
                        onClick={startFollowing}
                      >
                        Follow
                      </button>
                    </TooltipComponent>
                    {spinner === true && (
                      <Spinner color="black" size="sm" className="m-1" />
                    )}
                  </>
                )}
              </>
            )}
          </div>

          <div className="ml-auto d-flex align-items-center gap-2">
            {profileInfo?.role?.owner_access === true &&
              isPermissionAllowed('deals', 'edit') && (
                <a
                  className="icon-hover-bg-soft-primary cursor-pointer border p-2"
                  onClick={() => setOpenEditModal(true)}
                >
                  <MaterialIcon icon="edit" />
                </a>
              )}
            <ContactCompanyDeleteMenu
              isPrincipalOwner={mainOwner?.id === profileInfo?.id}
              permission={{ collection: 'deals', action: 'delete' }}
              setOpenModal={toggleModal}
              setOpenCloneModal={setOpenCloneModal}
            />
          </div>
        </div>
        <Row className="d-flex pl-4_2 justify-content-between align-items-center mb-1">
          <Col xs lg="auto">
            <Row className="d-flex align-items-center">
              <Col
                xs="auto"
                className="d-flex gap-1 align-items-center font-size-description-field"
              >
                <Avatar
                  user={deal?.organization}
                  classModifiers="bg-primary-soft text-primary"
                  sizeIcon="fs-5"
                  type={deal?.organization?.name ? 'organization' : 'contact'}
                />
                <a onClick={openCompanyModal} className="cursor-pointer">
                  {deal?.organization?.name}
                </a>
              </Col>
              {deal?.date_closed && <span className="text-muted-light">•</span>}
              <Col xs="auto" className="d-flex gap-1 align-items-center">
                <div ref={dtRef} className="position-absolute abs-dt">
                  <ReactDatepicker
                    id={'dealCloseDate'}
                    name={'dealCloseDate'}
                    className="d-none"
                    value={dateClosed}
                    open={showPicker}
                    onChange={(newDate) => {
                      setShowPicker(false);
                      setDateClosed(newDate);
                      onChangeClosingDate(newDate);
                    }}
                  />
                </div>
                Closing Date:
                {deal?.date_closed ? (
                  <>
                    <span
                      className={`${
                        dateClosed >= currentDate ? '' : 'text-red'
                      } font-size-description-field fw-bold cursor-pointer`}
                      onClick={toggleDatePicker}
                    >
                      {setDateFormat(dateClosed, 'MMMM DD, YYYY')}
                    </span>
                  </>
                ) : (
                  '--'
                )}
                {profileInfo?.role?.owner_access === true &&
                  isPermissionAllowed('deals', 'edit') && (
                    <MaterialIcon
                      icon="arrow_drop_down"
                      clazz="text-icon cursor-pointer"
                      onClick={toggleDatePicker}
                    />
                  )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-5 pl-4_2">
          <Col>
            <div className="row align-items-center no-gutters mt-2">
              <div className="col-auto w-100">
                {loader ? (
                  <Skeleton height={8} className="w-100" />
                ) : (
                  <>
                    {stages?.length > 0 ? (
                      <div className="d-flex align-items-center position-relative w-100">
                        <div className="d-flex flex-fill align-items-center">
                          <MaterialIcon
                            icon={`fiber_manual_record`}
                            filled
                            clazz={`text-icon-light fs-9 cursor-default pe-none ${
                              isWonDeal(deal) || isLostDeal(deal)
                                ? StatusColors[
                                    `${deal?.tenant_deal_stage?.name}_t`
                                  ]
                                : 'text-primary'
                            }`}
                          />
                        </div>
                        {stages?.map((stage, index) => (
                          <div
                            key={stage.id}
                            style={{
                              width: `${100 / stages.length}%`,
                            }}
                            className={`cursor-pointer flex-fill position-relative ${
                              (profileInfo?.role?.owner_access === true ||
                                profileInfo?.id === deal?.created_by) &&
                              isPermissionAllowed('deals', 'edit')
                                ? ''
                                : 'cursor-not-allowed pointer-events-none'
                            }`}
                            onClick={() => onChangeStage(stage)}
                          >
                            <span className={`${textColor(index)}`}>
                              {classnames(index, stages, deal) ===
                              'bg-primary' ? (
                                <div className="d-flex z-index-10 position-relative align-items-center">
                                  <div
                                    style={{
                                      height: 2,
                                      background: checkedStages(
                                        index,
                                        stages,
                                        deal
                                      )
                                        ? isWonDeal(deal) || isLostDeal(deal)
                                          ? StatusColors[
                                              deal?.tenant_deal_stage?.name
                                            ]
                                          : 'var(--primaryColor)'
                                        : isWonDeal(deal) || isLostDeal(deal)
                                        ? StatusColors[
                                            deal?.tenant_deal_stage?.name
                                          ]
                                        : 'var(--primaryColor)',
                                      width: '100%',
                                    }}
                                  >
                                    &nbsp;
                                  </div>
                                  <span
                                    className={`text-icon-light position-relative ${
                                      checkedStages(index, stages, deal)
                                        ? isWonDeal(deal) || isLostDeal(deal)
                                          ? StatusColors[
                                              `${deal?.tenant_deal_stage?.name}_t`
                                            ]
                                          : 'text-primary'
                                        : ''
                                    } d-flex align-items-center`}
                                  >
                                    <div
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setIsOpen(!isOpen);
                                      }}
                                      style={{
                                        left: '100%',
                                        transform: 'translate(-60%, -50%)',
                                      }}
                                      className={`position-absolute badge ${
                                        isWonDeal(deal) || isLostDeal(deal)
                                          ? StatusColors[
                                              `${deal?.tenant_deal_stage?.name}_c`
                                            ]
                                          : 'bg-primary'
                                      } text-white py-1 rounded-pill px-2 badge-default abs-center-xy`}
                                    >
                                      <div className="d-flex justify-content-center align-items-center gap-1">
                                        <MaterialIcon
                                          icon={` ${
                                            isWonDeal(deal) || isLostDeal(deal)
                                              ? StatusColors[
                                                  `${deal?.tenant_deal_stage?.name}_i`
                                                ]
                                              : 'check_circle'
                                          }`}
                                          clazz="text-white fs-5"
                                        />
                                        <span className="text-white text-capitalize fs-7">
                                          {stage?.name?.toLowerCase()}
                                        </span>
                                        {(profileInfo?.role?.owner_access ===
                                          true ||
                                          profileInfo?.id ===
                                            deal?.created_by) &&
                                          isPermissionAllowed(
                                            'deals',
                                            'edit'
                                          ) && (
                                            <StagesDropdown
                                              isOpen={isOpen}
                                              stages={stages}
                                              deal={deal}
                                              className={deal.status}
                                              onChangeStage={onChangeStage}
                                              setIsOpen={setIsOpen}
                                            >
                                              <a
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  setIsOpen(!isOpen);
                                                }}
                                              >
                                                <MaterialIcon
                                                  icon="arrow_drop_down"
                                                  clazz="text-icon text-white cursor-pointer"
                                                />
                                              </a>
                                            </StagesDropdown>
                                          )}
                                      </div>
                                    </div>
                                    {stage.name === CLOSED_WON && (
                                      <div
                                        className="position-absolute"
                                        style={{
                                          left: 2,
                                          bottom: -28,
                                          zIndex: -1,
                                        }}
                                      >
                                        {' '}
                                        <MaterialIcon
                                          icon="thumb_up"
                                          clazz="text-success"
                                          size="fs-6"
                                        />
                                      </div>
                                    )}

                                    {stage.name === CLOSED_LOST && (
                                      <div
                                        className="position-absolute"
                                        style={{
                                          left: 2,
                                          bottom: -28,
                                          zIndex: -1,
                                        }}
                                      >
                                        {' '}
                                        <MaterialIcon
                                          icon="thumb_down"
                                          clazz="text-red"
                                          size="fs-6"
                                        />
                                      </div>
                                    )}
                                    <MaterialIcon
                                      icon={`${
                                        checkedStages(index, stages, deal)
                                          ? 'check_circle'
                                          : 'fiber_manual_record'
                                      }`}
                                      clazz="fs-4"
                                    />
                                    {updatingStage === stage.id && (
                                      <div
                                        className="position-absolute"
                                        style={{ left: 2, bottom: 26 }}
                                      >
                                        {' '}
                                        <Spinner color="black" size="sm" />
                                      </div>
                                    )}
                                  </span>
                                  <div
                                    style={{
                                      height: 2,
                                      background:
                                        checkedStages(index, stages, deal) ||
                                        checkedStagesAddingLast(
                                          findIndex(stages, {
                                            id:
                                              deal?.tenant_deal_stage_id ||
                                              'cold',
                                          }),
                                          stages,
                                          deal
                                        )
                                          ? isWonDeal(deal) || isLostDeal(deal)
                                            ? StatusColors[
                                                deal?.tenant_deal_stage?.name
                                              ]
                                            : 'var(--primaryColor)'
                                          : 'var(--light-grey)',
                                      width: '100%',
                                    }}
                                  >
                                    &nbsp;
                                  </div>
                                </div>
                              ) : (
                                <div className="d-flex position-relative align-items-center">
                                  <div
                                    style={{
                                      height: 2,
                                      background: checkedStages(
                                        index,
                                        stages,
                                        deal
                                      )
                                        ? isWonDeal(deal) || isLostDeal(deal)
                                          ? StatusColors[
                                              deal?.tenant_deal_stage?.name
                                            ]
                                          : 'var(--primaryColor)'
                                        : 'var(--light-grey)',
                                      width: '100%',
                                    }}
                                  >
                                    &nbsp;
                                  </div>
                                  <TooltipComponent
                                    title={capitalizeEachWord(stage?.name)}
                                  >
                                    <span
                                      className={`text-icon-light position-relative ${
                                        checkedStages(index, stages, deal)
                                          ? isWonDeal(deal) || isLostDeal(deal)
                                            ? isLostDeal(deal) &&
                                              stage.name === CLOSED_WON
                                              ? 'border p-1 rounded-circle'
                                              : StatusColors[
                                                  `${deal?.tenant_deal_stage?.name}_t`
                                                ]
                                            : 'text-primary'
                                          : 'border p-1 rounded-circle'
                                      } ${
                                        stage.name === CLOSED_WON
                                          ? 'text-success bg-hover-white-primary border-success-hover'
                                          : ''
                                      } ${
                                        stage.name === CLOSED_LOST
                                          ? 'text-red bg-hover-white-primary border-danger-hover'
                                          : ''
                                      } d-flex align-items-center`}
                                    >
                                      <MaterialIcon
                                        icon={`${
                                          checkedStages(index, stages, deal)
                                            ? isLostDeal(deal) &&
                                              stage.name === CLOSED_WON
                                              ? 'fiber_manual_record'
                                              : 'check_circle'
                                            : 'fiber_manual_record'
                                        }`}
                                        filled={
                                          checkedStages(index, stages, deal)
                                            ? isLostDeal(deal) &&
                                              stage.name === CLOSED_WON
                                            : deal?.status !== undefined
                                        }
                                        size={
                                          checkedStages(index, stages, deal)
                                            ? isLostDeal(deal) &&
                                              stage.name === CLOSED_WON
                                              ? 'fs-7'
                                              : 'filled fs-4'
                                            : 'fs-7'
                                        }
                                      />
                                      {updatingStage === stage.id && (
                                        <div
                                          className="position-absolute"
                                          style={{ left: 2, bottom: 26 }}
                                        >
                                          {' '}
                                          <Spinner color="black" size="sm" />
                                        </div>
                                      )}
                                    </span>
                                  </TooltipComponent>
                                  {stage.name === CLOSED_WON && (
                                    <div
                                      className="position-absolute abs-center pe-none"
                                      style={{ bottom: -28 }}
                                    >
                                      {' '}
                                      <MaterialIcon
                                        icon="thumb_up"
                                        clazz="text-success"
                                        size="fs-6"
                                      />
                                    </div>
                                  )}

                                  {stage.name === CLOSED_LOST && (
                                    <div
                                      className="position-absolute abs-center pe-none"
                                      style={{ bottom: -28 }}
                                    >
                                      {' '}
                                      <MaterialIcon
                                        icon="thumb_down"
                                        clazz="text-red"
                                        size="fs-6"
                                      />
                                    </div>
                                  )}
                                  <div
                                    style={{
                                      height: 2,
                                      background:
                                        checkedStages(index, stages, deal) ||
                                        checkedStagesAddingLast(
                                          findIndex(stages, {
                                            id:
                                              deal?.tenant_deal_stage_id ||
                                              'cold',
                                          }),
                                          stages,
                                          deal
                                        )
                                          ? isWonDeal(deal) || isLostDeal(deal)
                                            ? StatusColors[
                                                deal?.tenant_deal_stage?.name
                                              ]
                                            : 'var(--primaryColor)'
                                          : 'var(--light-grey)',
                                      width: '100%',
                                    }}
                                  >
                                    &nbsp;
                                  </div>
                                </div>
                              )}
                            </span>
                          </div>
                        ))}
                        <div className="d-flex flex-fill align-items-center">
                          <MaterialIcon
                            icon={`fiber_manual_record`}
                            filled
                            clazz={`fs-9 cursor-default pe-none ${
                              checkedStagesAddingLast(
                                findIndex(stages, {
                                  id: deal?.tenant_deal_stage_id || 'cold',
                                }),
                                stages,
                                deal
                              )
                                ? isWonDeal(deal) || isLostDeal(deal)
                                  ? StatusColors[
                                      `${deal?.tenant_deal_stage?.name}_t`
                                    ]
                                  : 'text-primary'
                                : 'text-icon-light'
                            }`}
                          />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <hr className="d-my-0" />
      <AlertWrapper>
        <Alert
          message={errorMessage}
          setMessage={setErrorMessage}
          color="danger"
        />
        <Alert
          message={successMessage}
          setMessage={setSuccessMessage}
          color="success"
        />
      </AlertWrapper>

      <ModalConfirm
        open={removeDeal}
        onHandleConfirm={onHandleRemove}
        onHandleClose={toggleModal}
        textBody={DEAL_DELETE_CONFIRMATION}
        iconButtonConfirm=""
        colorButtonConfirm={'outline-danger'}
        icon="report_problem"
        loading={modalLoading}
        labelButtonConfirm="Yes, Delete"
      />
    </>
  );
};

export default PipelineHeader;
