import {
  addMonths,
  endOfDay,
  endOfMonth,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfYear,
  subYears,
} from 'date-fns';

const currentDate = new Date();

export function applyReportDateRange(setRange, dateRange, item = {}) {
  const { key } = item;

  if (key === 'All Time') {
    setRange({
      startDate: item?.startDate || dateRange.startDate,
    });
    return;
  }

  setRange({
    ...item,
    startDate: item?.startDate || dateRange.startDate,
    endDate: item?.endDate || dateRange.endDate,
  });
}

export const defineds = {
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),

  startOfThisYear: startOfYear(new Date()),
  endOfThisYear: endOfYear(new Date()),

  startOfLastYear: startOfYear(subYears(currentDate, 1)),
  endOfLastYear: endOfYear(subYears(currentDate, 1)),

  startOfLast30Days: endOfDay(addMonths(new Date(), -1)),
  endOfLast30Days: startOfDay(new Date()),
};

export const staticRanges = [
  {
    key: 'All Time',
    label: 'All Time',
    startDate: startOfYear(new Date(1970, 0, 1)),
  },
  {
    key: 'Last 30 Days',
    label: 'Last 30 Days',
    startDate: defineds.startOfLast30Days,
    endDate: defineds.endOfLast30Days,
  },
  {
    key: 'This Year',
    label: 'This Year',
    startDate: defineds.startOfThisYear,
    endDate: defineds.endOfThisYear,
  },
  {
    key: 'Last Year',
    label: 'Last Year',
    startDate: defineds.startOfLastYear,
    endDate: defineds.endOfLastYear,
  },
  {
    key: 'Custom',
    label: 'Custom',
    customRange: true,
  },
];
