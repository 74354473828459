import React, { useState, useEffect } from 'react';
import {
  Spinner,
  Modal,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
} from 'reactstrap';

import stringConstants from '../../utils/stringConstants.json';
import { DropdownTreeView } from '../prospecting/v2/common/DropdownTreeView';
import { Button } from 'react-bootstrap';
import Alert from '../Alert/Alert';
import AlertWrapper from '../Alert/AlertWrapper';

const constants = stringConstants.deleteConfirmationModal;

const ConfirmationModal = ({
  showModal,
  setShowModal,
  setSelectedCategories = [],
  event,
  itemsConfirmation,
  itemsReport,
  clearSelection = () => {},
  setIsDropdownId,
  isDropdownId,
  deleteTreeViewShow = '',
  data,
  customBody,
  description = constants.confirmationMessage,
  positiveBtnText = constants.deleteButton,
  positiveBtnIcon = 'delete',
  showModalFooter = true,
  className,
  customFooter,
  FooterClassName,
  customRoleModal,
  customEvent,
  loader,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [failedReport, setFailedReport] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const onHandleCloseModal = () => {
    setShowModal(false);
    try {
      setSelectedCategories([]);
    } catch (e) {
      console.log(e);
    }
    clearSelection();
  };
  const handleSubmit = async () => {
    if (customEvent) {
      customEvent();
    } else {
      try {
        setIsLoading(true);
        await event();
        setIsLoading(false);
        setShowModal(false);
        setSelectedCategories([]);
        clearSelection();
      } catch (err) {
        try {
          const parsedError = JSON.parse(err?.message || '{}');
          if (parsedError?.message) {
            setErrorMessage(parsedError?.message);
          }
        } catch (e) {
          setErrorMessage(
            'Something went wrong. Please check console for details.'
          );
        }
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const renderConfirmationIcon = (icon, classModifier) => (
    <div className="container">
      <div className="row">
        <div className="col text-center">
          <i
            className={`material-symbols-rounded confirmation-icon ${classModifier}`}
          >
            {icon}
          </i>
        </div>
      </div>
    </div>
  );

  const validateReport = () => {
    setFailedReport(itemsReport.filter((item) => !item.isDeleted));
  };

  useEffect(() => {
    if (itemsReport.length > 0) {
      validateReport();
    }
  }, [itemsReport]);

  return (
    <Modal
      isOpen={showModal}
      fade={false}
      zIndex={1202}
      className={`delete-role-modal ${className}`}
    >
      <AlertWrapper>
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
      </AlertWrapper>
      <ModalBody>
        <>
          {customBody || (
            <>
              {itemsConfirmation.length > 0 && itemsReport.length === 0 && (
                <>
                  {renderConfirmationIcon('report_problem')}
                  <h4>{description}</h4>
                  <hr />
                  <p className="mb-2">{constants.aboutToDelete}</p>
                  <ul className="list-disc">
                    {itemsConfirmation.map((item) => (
                      <li className="font-weight-medium ml-4" key={item?.id}>
                        <p className="mb-1">{item?.title || item?.name}</p>
                      </li>
                    ))}
                  </ul>
                </>
              )}

              {itemsReport.length > 0 && (
                <>
                  {renderConfirmationIcon('check_circle', 'text-success')}
                  <h4>
                    {failedReport.length > 0
                      ? constants.confirmationResultFailed
                      : constants.confirmationResultSuccess}
                  </h4>
                  <p>{constants.details}</p>
                  <ul>
                    {itemsReport.map((item) => (
                      <li key={item.id}>
                        {item.title || item?.name}
                        <i
                          className={`material-symbols-rounded fs-5 ${
                            item.isDeleted ? 'text-success' : 'text-error'
                          }`}
                        >
                          {item.isDeleted ? 'done' : 'close'}
                        </i>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              {deleteTreeViewShow && (
                <DropdownTreeView
                  data={data}
                  setIsDropdownId={setIsDropdownId}
                  isDropdownId={isDropdownId}
                />
              )}
            </>
          )}
          {customBody && deleteTreeViewShow && (
            <div className="mt-4">
              <FormGroup className={`d-flex w-100 gap-2 align-items-start`}>
                <Label className="mb-0 mt-2">Transfer to</Label>
                <div className="flex-fill">
                  <DropdownTreeView
                    data={data}
                    setIsDropdownId={setIsDropdownId}
                    isDropdownId={isDropdownId}
                  />
                </div>
              </FormGroup>
            </div>
          )}
        </>
      </ModalBody>
      {showModalFooter && (
        <ModalFooter className={`flex-nowrap ${FooterClassName}`}>
          {customFooter || (
            <>
              {itemsConfirmation.length > 0 && itemsReport.length === 0 && (
                <>
                  {customRoleModal ? (
                    <>
                      <Button
                        variant="white"
                        size="sm"
                        onClick={onHandleCloseModal}
                      >
                        {constants.cancelButton}
                      </Button>
                      <Button variant="danger" size="sm" onClick={handleSubmit}>
                        {isLoading || loader ? (
                          <Spinner className="spinner-grow-xs" />
                        ) : (
                          <span> {positiveBtnText}</span>
                        )}
                      </Button>
                    </>
                  ) : (
                    <>
                      <button
                        type="button"
                        className="btn btn-sm w-50 btn-outline-danger"
                        onClick={handleSubmit}
                      >
                        {isLoading || loader ? (
                          <Spinner className="spinner-grow-xs" />
                        ) : (
                          <span className="d-flex align-items-center justify-content-center">
                            <i className="material-symbols-rounded fs-5">
                              {positiveBtnIcon}
                            </i>
                            {positiveBtnText}
                          </span>
                        )}
                      </button>
                      <button
                        className="btn btn-sm w-50 btn-primary"
                        data-dismiss="modal"
                        onClick={onHandleCloseModal}
                      >
                        {constants.cancelButton}
                      </button>
                    </>
                  )}
                </>
              )}

              {itemsReport.length > 0 && (
                <button
                  className="btn btn-sm btn-block btn-primary"
                  data-dismiss="modal"
                  onClick={onHandleCloseModal}
                >
                  {constants.okButton}
                </button>
              )}
            </>
          )}
        </ModalFooter>
      )}
    </Modal>
  );
};

export default ConfirmationModal;
