import ReportDownloadWrapper from '../reportbuilder/blocks/ReportDownloadWrapper';
import React from 'react';
import useReportHeaderFooter from '../../hooks/useReportHeaderFooter';
import { PDF_PAGE_HEIGHT, ReportTypes } from './reports.constants';

const ReportBlockWrapper = ({
  children,
  showLogo,
  current,
  ignoreHeadings,
  pageHeight = PDF_PAGE_HEIGHT,
  report,
  selectedTenant,
  reportType,
}) => {
  const { reportFooterImage } = useReportHeaderFooter(selectedTenant);
  const isClientPortal = /clientportal/gi.test(document.URL);
  const styles = isClientPortal
    ? { height: showLogo ? pageHeight : 'auto' }
    : {
        height: showLogo ? pageHeight : 'auto',
        background: showLogo ? '#FFFFFF' : '#FFFFFF',
      };
  return (
    <div className="px-0 report-pdf-page position-relative" style={styles}>
      {children}
      {showLogo && (
        <>
          {reportType !== ReportTypes.WorkingCapital && <br />}
          <ReportDownloadWrapper
            whenPrinting={showLogo}
            containerPrintClass="px-5 d-flex align-items-center position-absolute w-100 bottom-15 justify-content-between"
          >
            <div className="w-100 d-flex align-items-center position-relative -top-10 justify-content-between">
              <div className="fs-11 d-flex flex-column justify-content-start text-rpt-heading">
                <div className="position-relative text-left" style={{ top: 4 }}>
                  Prepared for:
                </div>
                <div className="font-weight-medium">
                  {report?.value1 || report?.companyName || report?.name}
                </div>
              </div>
              <div className="d-flex fs-11 text-rpt-heading align-items-center gap-2">
                {current}
              </div>
              <div className="position-absolute abs-center">
                <img
                  crossOrigin="anonymous"
                  alt="Tenant Logo"
                  src={reportFooterImage}
                  width={65}
                />
              </div>
            </div>
          </ReportDownloadWrapper>
        </>
      )}
    </div>
  );
};
export default ReportBlockWrapper;
