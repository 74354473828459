import React, { useState, useEffect, useContext } from 'react';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Label,
} from 'reactstrap';
import { Col } from 'react-bootstrap';

import dataReportConstants from '../../utils/constants/dataReport.json';
import { CardButton } from '../layouts/CardLayout';
import authService from '../../services/auth.service';
import contactService from '../../services/contact.service';
import { useTenantContext } from '../../contexts/TenantContext';
import { AlertMessageContext } from '../../contexts/AlertMessageContext';

const constants = dataReportConstants.strings;

const SendOrDownloadModal = ({
  contact,
  showModal,
  setShowModal,
  getProfileInfo,
  setToast,
  setColorToast,
  organizationId,
}) => {
  const [isLoadingEmail, setIsLoadingEmail] = useState(false);
  const { tenant } = useTenantContext();
  const { clientPortalUrl, id } = tenant;
  const { setSuccessMessage } = useContext(AlertMessageContext);

  const sendReport = async (selectedItem) => {
    const invitationResults = selectedItem?.map(async (singleItem) => {
      await authService.guestToken(
        singleItem.email,
        singleItem.organization_id,
        clientPortalUrl
      );
    });

    await Promise.all(invitationResults);
  };

  const handleCloseModal = () => {
    setIsLoadingEmail(false);
    setShowModal(false);
  };

  const handleAlreadySentReportNotif = () => {
    handleCloseModal();

    setColorToast(constants.colors.danger);
    return setToast('Email already sent before');
  };

  const handleSendReport = async () => {
    setIsLoadingEmail(true);
    const contacts = selectedItem?.map((c) => ({
      ...c,
      email: c.email_work || c.email_home || c.email_fax || c.email_other,
    }));
    await sendReport(contacts).catch(() => handleAlreadySentReportNotif());

    handleCloseModal();

    setToast('Email sent successfully');
    setColorToast(constants.colors.success);
  };

  const [selectedItem] = useState([]);
  const [, setData] = useState([]);

  const onCopyLink = (e) => {
    const params = new URLSearchParams();
    params.set('tenant_id', id);
    if (selectedItem.length > 0) {
      params.set('email', selectedItem[0].email);
      params.set('organizationId', selectedItem[0].organization_id);
    }
    navigator.clipboard.writeText(
      `${clientPortalUrl}/clientportal/login?${params.toString()}`
    );
    setSuccessMessage('Link copied!');
  };
  const searchContacts = async () => {
    try {
      const { data } = await contactService.getContacts(
        { organization_id: organizationId },
        { limit: 10, page: 1 }
      );
      setData(
        data.contacts?.map((u) => ({
          ...u,
          name: `${u.first_name} ${u.last_name}`,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    searchContacts();
  }, []);

  return (
    <Modal
      isOpen={showModal}
      fade={false}
      size="lg"
      className="w-600"
      id="clientShare"
    >
      <ModalHeader tag="h2" className="p-3" toggle={() => setShowModal(false)}>
        Share Client Portal
      </ModalHeader>
      <ModalBody className="border-top mb-0 p-3">
        <FormGroup>
          <Label className="mb-0">{`Select ${contact}`}</Label>
        </FormGroup>
        <Label>Add Custom Message [Optional]</Label>
        <textarea
          rows={4}
          className="form-control"
          placeholder="Add your custom message here"
        ></textarea>
      </ModalBody>
      <ModalFooter className="p-3 gap-2">
        <Col
          className={`d-flex align-items-center justify-content-between p-0`}
        >
          <div>
            <CardButton
              type="button"
              className={'btn-sm btn-primary'}
              title="Copy Login Link"
              icon="copy_all"
              onClick={onCopyLink}
            />
          </div>
          <div className="d-flex align-items-center gap-1 ml-auto">
            <CardButton
              className={'font-weight-500 btn-sm btn-white'}
              title={`Cancel`}
              onClick={handleCloseModal}
            />
            {selectedItem?.length > 0 && (
              <CardButton
                className={'font-weight-500 btn-sm btn-primary'}
                title={`Send`}
                icon={`email`}
                isLoading={isLoadingEmail}
                onClick={handleSendReport}
              />
            )}
          </div>
        </Col>
      </ModalFooter>
    </Modal>
  );
};

export default SendOrDownloadModal;
