import React, { useState, useEffect, useLayoutEffect } from 'react';
import {
  Spinner,
  Form,
  FormGroup,
  Label,
  CardBody,
  CardFooter,
} from 'reactstrap';
import RightPanelModal from '../modal/RightPanelModal';

import roleService from '../../services/role.service';
import {
  initialCreateRoleState,
  CANCEL_LABEL,
  ROLE_CREATED,
  ERROR_ROLE_EXIST,
  ADD_NEW_PROFILE_LABEL,
} from '../../utils/constants';
import stringConstants from '../../utils/stringConstants.json';
import Alert from '../Alert/Alert';
import AlertWrapper from '../Alert/AlertWrapper';
import { useForm } from 'react-hook-form';
import InputValidation from '../commons/InputValidation';
import { RIGHT_PANEL_WIDTH } from '../../utils/Utils';
import ControllerValidation from '../commons/ControllerValidation';
import DropdownSelect from '../DropdownSelect';

const constants = stringConstants.settings.roles.add;

const CreateRoleModal = ({
  showModal,
  setShowModal,
  selectedItem = {},
  setSelectedItem,
  handleEditRole,
  toggleLoadRoles,
  ...restProps
}) => {
  const usersProfileForm = {
    name: '',
    clone: '',
    description: '',
    admin_access: false,
    owner_access: false,
  };
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getFieldState,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: usersProfileForm,
  });
  const [, setModified] = useState(false);
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [createRoleData, setCreateRoleData] = useState(usersProfileForm);

  // Handler of Modal close
  const onHandleCloseModal = () => {
    reset(
      setCreateRoleData({
        name: '',
        clone: '',
        description: '',
      })
    );
    setModified(false);
    setErrorMessage(null);
    setSuccessMessage(null);
    setCreateRoleData(initialCreateRoleState);
    setShowModal(false);
    setSelectedItem('');
  };
  // Create role service
  const createNewRole = async (newRole, cloneId) => {
    setSuccessMessage(null);

    try {
      const roleToClone = await roleService.getRoleById(cloneId);
      newRole.owner_access = roleToClone.owner_access;

      const response = await roleService.createRole(newRole);

      if (!newRole.owner_access) {
        const permissions = await roleService.getPermissionsByRole(cloneId);
        await roleService.updatePermissions(response.id, permissions);
      }

      setSuccessMessage(ROLE_CREATED);
      setTimeout(() => {
        handleEditRole(response);
        toggleLoadRoles();
      }, 1000);
    } catch (error) {
      setErrorMessage(ERROR_ROLE_EXIST);
    }

    setShowModal(false);

    reset(
      setCreateRoleData({
        name: '',
        clone: '',
        description: '',
      })
    );
  };

  // Capture form input changes
  const onInputChange = (e) => {
    const { name, value } = e.target || {};
    setCreateRoleData({
      ...createRoleData,
      [name]: value,
    });
    setModified(true);
  };

  // Handler of submit
  const handleCreate = async () => {
    delete createRoleData.clone;
    const clonedRole = selectedItem || roles[0];
    setIsLoading(true);
    const newRole = {
      ...createRoleData,
      admin_access: clonedRole.admin_access,
      owner_access: clonedRole.owner_access,
    };
    setCreateRoleData(newRole);
    await createNewRole(newRole, clonedRole.id);
    setSelectedItem('');
    setIsLoading(false);
    setIsRedirecting(true);
  };

  const handleSelectedItemChange = (item) => {
    if (item.owner_access === true) {
      createRoleData.description =
        'This profile can have all the permissions. Users with custom profile will be able to view and manage all the data within the organization account by default with chosen permissions';
    } else {
      createRoleData.description = '';
    }
  };

  const getRoles = async (count) => {
    const result = await roleService.GetRoles();
    const roles = result?.data?.filter((item) => item?.name !== '');
    setRoles(roles);
  };
  useEffect(() => {
    getRoles();
  }, [showModal]);
  const fieldInFields = (item) => {
    setSelectedItem(item);
    setValue('clone', item);
  };
  useLayoutEffect(() => {
    if (Object.keys(selectedItem)?.length > 0) {
      if (selectedItem?.owner_access === true) {
        setCreateRoleData({
          ...createRoleData,
          description:
            'This profile can have all the permissions. Users with custom profile will be able to view and manage all the data within the organization account by default with chosen permissions',
        });
      }
      setValue('clone', selectedItem);
    }
  }, [Object.keys(selectedItem)?.length > 0]);
  useEffect(() => {
    if (!showModal) {
      setIsRedirecting(false);
    }
  }, [showModal]);

  return (
    <RightPanelModal
      showModal={showModal}
      setShowModal={onHandleCloseModal}
      showOverlay={true}
      containerBgColor={'pb-0'}
      containerWidth={RIGHT_PANEL_WIDTH}
      containerPosition={'position-fixed'}
      headerBgColor="bg-gray-5"
      Title={
        <div className="d-flex py-2 align-items-center text-capitalize">
          <h3 className="mb-0">{ADD_NEW_PROFILE_LABEL}</h3>
        </div>
      }
    >
      <CardBody>
        <AlertWrapper>
          <Alert
            color="danger"
            message={errorMessage}
            setMessage={setErrorMessage}
          />
          <Alert
            color="success"
            message={successMessage}
            setMessage={setSuccessMessage}
          />
        </AlertWrapper>
        <Form>
          <FormGroup>
            <Label htmlFor="name">{constants.name}</Label>
            <InputValidation
              type="input"
              name="name"
              id="name"
              required
              value={createRoleData.name}
              register={register}
              validationConfig={{
                required: `Profile Name is required.`,
                onChange: onInputChange,
              }}
              errors={errors}
              placeholder={constants.name}
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="clone">{constants.clone}</Label>
            <ControllerValidation
              name={'clone'}
              errors={errors}
              form={createRoleData}
              errorDisplay="mb-0"
              control={control}
              validationConfig={{
                required: `Clone From is required.`,
              }}
              renderer={({ field }) => (
                <DropdownSelect
                  data={roles}
                  showIcon={false}
                  {...field}
                  onHandleSelect={(item) => {
                    fieldInFields(item);
                    handleSelectedItemChange(item);
                  }}
                  select={selectedItem ? selectedItem.name : ''}
                  placeholder="Select Profile"
                  customClasses={'w-100 overflow-y-auto max-h-300'}
                  validationConfig={{
                    required: true,
                    inline: false,
                    onChange: fieldInFields,
                  }}
                  fieldState={getFieldState('clone')}
                  {...restProps}
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="description">{constants.description}</Label>
            <textarea
              name="description"
              rows={4}
              id="description"
              className="form-control"
              value={createRoleData.description}
              placeholder={constants.descriptionPlaceholder}
              onChange={onInputChange}
            ></textarea>
          </FormGroup>
        </Form>
      </CardBody>
      <CardFooter className="px-3 text-right">
        <button
          className="btn btn-sm btn-white"
          data-dismiss="modal"
          data-uw-styling-context="true"
          onClick={onHandleCloseModal}
        >
          {CANCEL_LABEL}
        </button>
        {isRedirecting ? (
          <>
            <Spinner className={`text-primary spinner-grow-xs`} />
            <span className={`text-primary`}>{constants.redirecting}</span>
          </>
        ) : (
          <button
            type="button"
            className="btn btn-sm min-w-100 btn-primary ml-lg-2"
            data-uw-styling-context="true"
            onClick={handleSubmit(handleCreate)}
          >
            {isLoading ? (
              <Spinner className="spinner-grow-xs" />
            ) : (
              <span>Save</span>
            )}
          </button>
        )}
      </CardFooter>
    </RightPanelModal>
  );
};

export default CreateRoleModal;
