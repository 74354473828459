import MaterialIcon from '../components/commons/MaterialIcon';
import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import useIsCommonLogin from './useIsCommonLogin';

const LocalStorageKey = 'login-alert-display';

const LoginAlert = ({
  shouldShowAlert,
  clicked,
  handleDismissAlert,
  message,
}) => {
  if (!shouldShowAlert() || clicked) return null;
  return (
    <div
      className="login-alert-container position-relative"
      style={{ zIndex: 101 }}
    >
      <div className="d-flex gap-2 rounded p-3 align-items-center justify-content-center login-alert">
        <div className="d-flex gap-2 align-items-center">
          {message ? (
            <>
              <MaterialIcon
                icon={'notifications'}
                clazz={'text-secondary font-size-2em'}
              />
              <p className="mb-0 text-left text-black flex-fill text-sm-left text-lg-center text-md-center font-weight-medium font-size-sm">
                We&apos;ve made some updates!{' '}
                <Link
                  to="/"
                  style={{ textDecoration: 'underline' }}
                  className="font-weight-semi-bold cursor-pointer text-primary text-decoration-underline"
                >
                  Check out what&apos;s new.
                </Link>{' '}
              </p>
            </>
          ) : (
            <p className="mb-0 text-left text-black flex-fill text-sm-left text-lg-center text-md-center font-weight-medium font-size-sm">
              We&apos;ve updated the sign-in page, please{' '}
              <Link
                to="/request-password"
                style={{ textDecoration: 'underline' }}
                className="font-weight-semi-bold cursor-pointer text-primary text-decoration-underline"
              >
                reset your password
              </Link>{' '}
              to login.
            </p>
          )}
        </div>
        <a
          className="icon-hover-bg position-absolute"
          style={{ right: 5 }}
          href=""
          onClick={handleDismissAlert}
        >
          <MaterialIcon icon="close" clazz="fs-4" rounded />{' '}
        </a>
      </div>
    </div>
  );
};
const useLoginAlert = (message) => {
  const [dismissedAt, setDismissedAt] = useState(
    localStorage.getItem(LocalStorageKey)
  );
  const [clicked, setClicked] = useState(false);
  const { isCommonLogin } = useIsCommonLogin();

  const shouldShowAlert = () => {
    if (!dismissedAt || dismissedAt === 'null') return true; // If not dismissed yet, always show alert
    const dismissedMoment = moment(dismissedAt);
    const currentMoment = moment();
    const weekDiff = currentMoment.diff(dismissedMoment, 'weeks');
    return weekDiff <= 2; // Show alert if within two weeks span and not yet dismissed by user
  };

  const setDismissAlert = () => {
    setClicked(true);
    const newDate = moment().subtract(3, 'weeks').toISOString();
    setDismissedAt(newDate);
    localStorage.setItem(LocalStorageKey, newDate);
  };
  const handleDismissAlert = (e) => {
    e.preventDefault();
    setDismissAlert();
  };

  const LoginAlertCallback = useCallback(() => {
    return (
      <LoginAlert
        shouldShowAlert={shouldShowAlert}
        handleDismissAlert={handleDismissAlert}
        clicked={clicked}
        message={message}
      />
    );
  }, [clicked, setClicked, dismissedAt, message]);

  return useMemo(
    () => ({
      LoginAlert: isCommonLogin ? () => {} : LoginAlertCallback,
      setDismissAlert,
    }),
    [setDismissAlert, clicked, dismissedAt, LoginAlertCallback]
  );
};

export default useLoginAlert;
