import React from 'react';
import MaterialIcon from '../../../commons/MaterialIcon';

const YourBenefitsWidget = ({ widgetConfig, whenPrinting }) => {
  return (
    <div
      className={`d-flex card flex-column report-widget`}
      style={{
        borderRadius: 'var(--rpt-widget-border-radius)',
        background: '#ffffff',
      }}
    >
      <div className="card-body pb-2">
        <div className="d-flex align-items-start">
          <h5 className="mb-0">{widgetConfig.header}</h5>
        </div>
        {widgetConfig.benefits.map((row, rowIndex) => (
          <div className="d-flex px-2 py-2 gap-2" key={rowIndex}>
            {row.map((item, itemIndex) => (
              <YourBenefitsWidgetItem
                key={itemIndex}
                icon={item.icon}
                benefit={item.benefit}
                whenPrinting={whenPrinting}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

const YourBenefitsWidgetItem = ({ icon, benefit, whenPrinting }) => {
  return (
    <div
      className="flex-1 border-2 border-light-color"
      style={{
        borderRadius: 'var(--rpt-widget-border-radius)',
        background: '#ffffff',
      }}
    >
      <div className="d-flex flex-column align-items-center">
        <div
          className="d-flex align-items-center bg-primary-soft rounded-circle justify-content-center mt-7"
          style={{
            width: whenPrinting ? 30 : 50,
            height: whenPrinting ? 30 : 50,
          }}
        >
          <MaterialIcon icon={icon} size="fs-3" clazz={'text-primary'} filled />
        </div>
        <p
          className={`${
            whenPrinting ? '' : 'px-9 pb-5 pt-2'
          } font-weight-semi-bold font-size-sm2 text-center`}
        >
          {benefit}
        </p>
      </div>
    </div>
  );
};

export default YourBenefitsWidget;
