import _ from 'lodash';
import moment from 'moment';
import {
  PayableBlockTypes,
  PayableKeys,
  ReceivableKeys,
  ReportTypes,
} from './reports.constants';
import { parseCurrency } from '../../utils/Utils';

export const sumItemValues = (data) => {
  let total = 0;

  for (const key in data) {
    if (Object.hasOwn(data, key)) {
      let itemValue = data[key].itemValue;
      try {
        itemValue = parseFloat(data[key].itemValue.replace(/,/g, '')); // Remove commas and parse as float
      } catch (e) {
        itemValue = data[key].itemValue;
      }
      if (!isNaN(itemValue)) {
        total += itemValue;
      }
    }
  }

  return total;
};

export const workingCapitalMapping = (reportValues, insightsData) => {
  const yearly = 365;
  const mapping = {};

  try {
    const accountsPayable = parseCurrency(
      reportValues.accountsPayable || '$0.00'
    );
    const accountsReceivable = parseCurrency(
      reportValues.accountsReceivable || '$0.00'
    );
    const costOfGoodsSold = parseCurrency(
      reportValues.costOfGoodsSold || '$0.00'
    );
    const netSales = parseCurrency(reportValues.netSales || '$0.00');

    // Accounts Payable/Cost of Goods Sold*365
    const yourDPO = Math.round((accountsPayable / costOfGoodsSold) * yearly);
    // Accounts Receivable/Net Sales *365
    const yourDSO = Math.round((accountsReceivable / netSales) * yearly);

    // ((Your DPO+1)/365*COGS) - (Your DPO/365*COGS)
    // value 8 and 16 in pdf
    const oneDayDPODiff = Math.round(
      ((yourDPO + 1) / yearly) * costOfGoodsSold -
        (yourDPO / yearly) * costOfGoodsSold
    );

    // (Your DSO/365*Net Sales) - ((Your DSO-1)/365*Net Sales)
    // value 9 and 26 in pdf
    const oneDayDSODiff = Math.round(
      (yourDSO / yearly) * netSales - ((yourDSO - 1) / yearly) * netSales
    );

    const avgDPO = insightsData?.sp?.[PayableBlockTypes.DPO.key] || 0;
    const avgDSO = insightsData?.sp?.[PayableBlockTypes.DSO.key] || 0;

    const bestInClassDPO =
      insightsData?.sp?.aggregations?.find(
        (agr) => agr.aggregation_type === 'AVERAGE_TOP_10_PERCENT'
      )?.[PayableBlockTypes.DPO.key] || 0;

    const bestInClassDSO =
      insightsData?.sp?.aggregations?.find(
        (agr) => agr.aggregation_type === 'AVERAGE_BOTTOM_10_PERCENT'
      )?.[PayableBlockTypes.DSO.key] || 0;

    // new v2 changes
    // DPO Improvement to industry Average:
    // If (Industry Average DPO)>(YourDPO) Then =((Industry Average DPO)-(YourDPO)*DPOOneDay), Otherwise = 0
    // value 2 in pdf
    mapping.dpoImprovmentToIndustryAvg =
      avgDPO > yourDPO ? (avgDPO - yourDPO) * Math.round(oneDayDPODiff) : 0;

    // DPO Improvement to industry Average:
    // If (Industry Average DSO<YourDSO) Then = ((Your DSO-Industry Average DSO)*DSOOneDay), Otherwise = 0
    // value 3 in pdf
    mapping.dsoImprovmentToIndustryAvg =
      avgDSO < yourDSO ? (yourDSO - avgDSO) * Math.round(oneDayDSODiff) : 0;

    // Total Improvement by moving to Industry Average = DPO Improvement to Industry Average + DSO Improvement to Industry Average
    // value 1 and 6 in pdf
    mapping.totalImprovementToIndustryAvg =
      mapping.dpoImprovmentToIndustryAvg + mapping.dsoImprovmentToIndustryAvg;

    // Cash unlocked by improving your DPO to the industry Average:
    // Dark Blue Bar = If (Industry Average DPO)>(YourDPO) Then =((Industry Average DPO)-(YourDPO)*DPOOneDay Otherwise = 0
    // value 4 in pdf graph progress bar
    mapping.cashUnlockedDPOAvg = mapping.dpoImprovmentToIndustryAvg;

    // Cash unlocked by improving your DSO to the industry Average:
    // Dark Blue Bar = If (Industry Average DSO<YourDSO) Then = ((Your DSO-Industry Average DSO)*DSOOneDay), Otherwise = 0
    // value 5 in pdf graph progress bar
    mapping.cashUnlockedDSOAvg = mapping.dsoImprovmentToIndustryAvg;

    // if its true show a diff message mentioned in widget
    mapping.improved =
      mapping.dpoImprovmentToIndustryAvg === 0 &&
      mapping.dsoImprovmentToIndustryAvg === 0;

    // value 10 in pdf
    mapping.totalOneDayDiff = Math.round(oneDayDPODiff + oneDayDSODiff);

    // value 11, 12, 13 in pdf
    mapping.yourDPO = yourDPO;
    // value 14 in pdf
    mapping.avgDPO = avgDPO;
    // value 15 in pdf
    mapping.bestInClassDPO = bestInClassDPO;
    mapping.bestInClassDPO_ = Math.abs(bestInClassDPO - yourDPO);

    // DPO Best In Class Difference:
    // If (Industry Best In Class DPO)>(YourDPO) Then =((Industry Best In Class DPO)-(YourDPO)*DPOOneDay Otherwise = 0
    // value 17 in pdf
    mapping.totalBestInClassDPODiff =
      bestInClassDPO > yourDPO ? (bestInClassDPO - yourDPO) * oneDayDPODiff : 0;

    // value 21, 22, 23 in pdf
    mapping.yourDSO = yourDSO;
    // value 24 in pdf
    mapping.avgDSO = avgDSO;
    // value 25 in pdf
    mapping.bestInClassDSO = bestInClassDSO;
    mapping.bestInClassDSO_ = Math.abs(bestInClassDSO - yourDSO);

    // DSO Best In Class Difference:
    // If (Industry Best In Class DSO)<(YourDSO) Then =((YourDSO)-(Industry Best In Class DSO)*DSOOneDay Otherwise = 0
    // value 27 in pdf
    mapping.totalBestInClassDSODiff =
      bestInClassDSO < yourDSO ? (yourDSO - bestInClassDSO) * oneDayDSODiff : 0;

    mapping.oneDayDPODiff = oneDayDPODiff;
    mapping.oneDayDSODiff = oneDayDSODiff;
  } catch (e) {
    console.error('sss-working-capital-mapping', e);
  }
  return mapping;
};

export const getEstimatedTotalPayablesValue = (payablesData) => {
  let sum = 0;

  for (const key in payablesData) {
    const obj = payablesData[key];
    sum += obj['Total Volume'];
  }

  return sum;
};

const findIsEditableByKey = (searchKey, keys) => {
  const foundItem = keys.find((item) => item[0] === searchKey);

  if (foundItem) {
    return foundItem[2]; // it will return whether its true/false
  }

  return false;
};

// this converts {ACH: 0} -> {ACH: {key: ACH, value: 0}}
export const updateJsonObject = (originalObject = {}) => {
  const keyPairs = Object.hasOwn(originalObject, 'Cash Vault')
    ? ReceivableKeys
    : PayableKeys;
  const finalObject = Object.entries(originalObject).reduce(
    (acc, [key, value]) => {
      try {
        if (_.isObject(value)) {
          acc[key] = {
            key: value.key,
            value: value.value,
            isEdited: findIsEditableByKey(key, keyPairs),
            itemValue: value.itemValue || value.value,
            isChecked:
              value.isChecked === undefined && parseFloat(value.value) > 0
                ? true
                : value.isChecked,
          };
        } else {
          acc[key] = {
            key,
            value,
            isEdited: findIsEditableByKey(key, keyPairs),
            isChecked: parseFloat(value) > 0,
            itemValue: value,
          };
        }
      } catch (e) {
        acc[key] = {
          key,
          value,
          isEdited: findIsEditableByKey(key, keyPairs),
          isChecked: parseFloat(value) > 0,
          itemValue: value,
        };
      }
      return acc;
    },
    {}
  );

  // sorting based on payable/receivable keys order
  const keyValueArray = Object.entries(finalObject);
  keyValueArray.sort((a, b) => {
    const keyA = keyPairs.find((pair) => pair[0] === a[0]);
    const keyB = keyPairs.find((pair) => pair[0] === b[0]);
    if (keyA && keyB) {
      return keyA[1] - keyB[1];
    }
    return -1;
  });

  return Object.hasOwn(originalObject, 'Cash Vault') ||
    Object.hasOwn(originalObject, 'Wires')
    ? Object.fromEntries(keyValueArray)
    : finalObject;
};

// this extracts date from pdf
export const getCycleDate = (dateText) => {
  const momentDate = moment(dateText).format('MMMM, YYYY');
  const dateParts = momentDate.split(', ');
  const monthName = dateParts[0];
  const year = dateParts[1];

  const monthNumber = new Date(Date.parse(monthName + ' 1, 2000')).getMonth();

  return new Date(year, monthNumber);
};

export const getReportName = (rpt) => {
  // name and date
  return `${rpt?.value1 || rpt?.companyName || rpt?.name} ${moment(
    rpt?.value2 || rpt?.reportDate
  ).format('MMMM YYYY')}`;
};

export const PdfFileNames = {
  [ReportTypes.Treasury]: 'Treasury Management',
  [ReportTypes.WorkingCapital]: 'Working Capital',
  [ReportTypes.Merchant]: 'Merchant Services',
  [ReportTypes.AccountStructure]: 'Account Structure',
  [ReportTypes.Fraud]: 'Fraud Insights',
  [ReportTypes.KnowledgeAssessment]: 'Knowledge Assessment',
  [ReportTypes.CommercialCard]: 'Commercial Card',
};
export const getReportPDFName = (reportType, rpt) => {
  // name and date
  return `${moment(rpt?.value2 || rpt?.reportDate).format('MMMM YYYY')} ${
    PdfFileNames[reportType]
  } ${rpt?.value1 || rpt?.companyName || rpt?.name}`;
};

export const calculateOpportunity = (checks, selectedTenant) => {
  return Math.round(checks * 0.3 * 2.705 * 12);
};

export const updateWidgetBySection = (
  widgets,
  section,
  widgetType,
  newReport,
  updatedConfig
) => {
  const sectionWidgets = widgets[section];
  let updatedWidgets = [];
  // what if we update multiple widgets in a section ?
  if (_.isArray(widgetType)) {
    updatedWidgets = sectionWidgets.map((widget) => {
      const foundType = widgetType.find((wgType) => widget.type === wgType);
      return foundType
        ? { ...widget, widgetConfig: { ...updatedConfig, data: newReport } }
        : {
            ...widget,
            widgetConfig: {
              ...widget.widgetConfig,
              data: newReport,
            },
          };
    });
  } else {
    // set update report object into the editing widget
    updatedWidgets = sectionWidgets.map((widget) =>
      widget.type === widgetType
        ? { ...widget, widgetConfig: { ...updatedConfig, data: newReport } }
        : {
            ...widget,
            widgetConfig: { ...widget.widgetConfig, data: newReport },
          }
    );
  }

  return {
    ...widgets,
    [section]: updatedWidgets,
  };
};

export const calculateMonthDataUsage = (newReport) => {
  // lowUtilization, peakUtilization, avgMonthlyUtilization, calculatedTotalExpense
  const total = 12;
  const values = newReport.loanOverview.monthData.map((mp) =>
    parseFloat(mp.value)
  );
  const sum = values.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
  const nonZeroValues = values.every((v) => v === 0)
    ? values
    : values.filter((value) => value !== 0);
  const lowUtilization =
    Math.min(...nonZeroValues) === Infinity ? 0 : Math.min(...nonZeroValues);
  const peakUtilization =
    Math.max(...values) === -Infinity ? 0 : Math.max(...values);
  const avgMonthlyUtilization = Math.round(sum / total);
  const calculatedTotalExpense =
    sum *
    0.1 *
    (parseFloat(newReport.loanOverview.avgAnnualRate || '0.0') / 100);

  return {
    lowUtilization,
    peakUtilization,
    avgMonthlyUtilization,
    sum,
    calculatedTotalExpense,
  };
};
