import React, { useEffect, useState } from 'react';
import {
  defaultGlossary,
  MAX_WEIGHT,
  NO_REPORTS_AVAILABLE,
  NO_REPORTS_AVAILABLE_ICON,
} from '../../../utils/constants';
import {
  cleanDataWidgets,
  getDifferenceWidgetVisibility,
  getNAICSWithIndustry,
  getNaicsWithTitle,
  MultipleSources,
  overflowing,
} from '../../../utils/Utils';
import moment from 'moment/moment';
import OrganizationService from '../../../services/organization.service';
import naicsService from '../../../services/naics.service';
import Alert from '../../Alert/Alert';
import AlertWrapper from '../../Alert/AlertWrapper';
import ReportService from '../../../services/report.service';
import fakeCSVData from './excel-bank-fake-csv.json';
import NoDataFound from '../../commons/NoDataFound';
import {
  DemoTenantsKeys,
  EngagementReportConfig,
  getVideoUrlWithTenant,
  ReportTypes,
  TreasuryReportSections,
} from '../../reports/reports.constants';
import {
  calculateOpportunity,
  getCycleDate,
  getReportName,
  updateJsonObject,
} from '../../reports/reports.helper.functions';
import GenerateTreasuryReportModal from '../../reports/GenerateTreasuryReportModal';
import ReportBlocksSkeleton from '../../loaders/ReportBlocksSkeleton';
import ReportCover from '../../reports/ReportCover';
import ReportDragDrop from '../../reports/ReportDragDrop';
import ReportDropdownItem from '../../reports/ReportDropdownItem';
import useIsTenant from '../../../hooks/useIsTenant';
import { useProfileContext } from '../../../contexts/profileContext';
import _ from 'lodash';
import {
  getCompanyNameAndStatementDate,
  processComericaBankStatement,
  processSVBBankStatement,
} from '../../reports/mozilla.pdf.parser';
import {
  DSOCashFlowData,
  ElectronicPaymentsSavingsData,
  ElectronicPaymentsToGrowthData,
  FraudMitigationData,
  FraudPreventData,
  HoursSpentWeeklyData,
  ImproveVendorRelationshipData,
  PFIBData,
  WidgetTypes,
} from '../../reportbuilder/constants/widgetsConstants';
import { ListGroup } from 'reactstrap';
import WidgetWithActionButtons from '../../reportbuilder/widgets/WidgetWithActionButtons';
import useWidgetsLibrary from '../../reportbuilder/widgets/useWidgetsLibrary';
import ReportPages from '../../reportbuilder/ReportPages';
import ReportPDFWrapper from '../../reportbuilder/ReportPDFWrapper';
import { useTenantContext } from '../../../contexts/TenantContext';
import ReportAction from '../../reports/ReportAction';
import useHash from '../../../hooks/useHash';
import useResyncTreasuryReportModal from '../../../hooks/reports/modals/useResyncTreasuryReportModal';
import DisclaimerWidget from '../../reportbuilder/widgets/horizontal/DisclaimerWidget';

const pdfJS = require('pdfjs-dist');
pdfJS.GlobalWorkerOptions.workerSrc =
  'https://cdn.jsdelivr.net/npm/pdfjs-dist@3.11.174/build/pdf.worker.min.js';

const XLSX = require('sheetjs-style');

const PageConfig = EngagementReportConfig[ReportTypes.Treasury].PageConfig;

const TreasuryReportSectionKeys =
  EngagementReportConfig[ReportTypes.Treasury].SectionKeys;

const TreasuryManagementReport = ({
  organization,
  getProfileInfo,
  currentTab,
  selectedTenant,
  readOnly, // this mean its open from client portal, we only allow download and switch between report nothing else
}) => {
  const MapReportDropdownItem = (rpt) => {
    const rptObject = rpt.input || rpt.manualInput;
    return {
      key: rpt.reportId,
      reportId: rpt.reportId,
      customElement: <ReportDropdownItem item={rptObject} />,
      name: getReportName(rptObject),
      isManual: !!rpt.manualInput,
      createdById: rpt.createdById,
      createdAt: rpt.createdAt,
      updatedAt: rpt.updatedAt,
      prettyDate: getReportPrettyDate(rptObject),
      isAuto: rptObject?.isAuto,
    };
  };
  const { hash, updateHash } = useHash();
  const getDefaultReportPages = () => {
    return _.cloneDeep(PageConfig);
  };
  const getReportPrettyDate = (rpt) => {
    try {
      const justDate = rpt?.value2 || rpt?.date || rpt.updatedAt;
      return moment(justDate.split('T')[0]).format('MMMM YYYY');
    } catch (e) {
      return moment
        .utc(rpt?.value2 || rpt?.date || rpt.updatedAt)
        .format('MMMM YYYY');
    }
  };
  const [pdf, setPdf] = useState(null);
  const [loader, setLoader] = useState(false);
  const [report, setReport] = useState({});
  const [reportCreated, setReportCreated] = useState({});
  const [feeAllocationData, setFeeAllocationData] = useState({});
  const [pastReports, setPastReports] = useState([]);
  const [loadingPastReports, setLoadingPastReports] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [selectedRpt, setSelectedRpt] = useState({});
  const [loaderInsights, setLoaderInsights] = useState(false);
  const [insightsData, setInsightsData] = useState({});
  const [accordionBlock, setAccordionBlock] = useState(
    TreasuryReportSections.Overview
  );
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [cancelTokenSources] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [isEdited, setIsEdited] = useState(false);
  const { isExcelBank, isCenturyBank, isSVB } = useIsTenant();
  const { tenant } = useTenantContext();
  const {
    SyncTMReportModal,
    setShowModal,
    setOrganization,
    setReport: setResyncReport,
  } = useResyncTreasuryReportModal(
    (oldReport, action, newReport) => {
      setReport(newReport.manualInput);
      setSelectedRpt(MapReportDropdownItem(newReport));
      setReportPages(newReport.manualInput.reportPages);
      setWidgets(newReport.manualInput.widgets);
      setShowModal(false);
      setSuccessMessage('Report synced.');
    },
    () => {}
  );
  const excelBankMode = isExcelBank;
  const {
    setShowModal: setShowWidgetsLibrary,
    WidgetsLibraryModal,
    setSelectedWidget,
  } = useWidgetsLibrary();
  const downloadOptions = [
    {
      id: 1,
      icon: 'picture_as_pdf',
      key: 'downloadAsPdf',
      name: 'PDF Download',
    },
  ];
  const [rptGenerated, setRptGenerated] = useState(false);
  const [openGenerateReport, setOpenGenerateReport] = useState(false);
  const [startDownload, setStartDownload] = useState(false);
  const { profileInfo } = useProfileContext();
  const widgetsSections = {
    Overview: [],
    Payables: [],
    Receivables: [],
    Fraud: [],
    FeeSummary: [],
    Glossary: [],
  };
  const [widgets, setWidgets] = useState(widgetsSections);
  const [editWidgets, setEditWidgets] = useState(widgetsSections);
  const [reportPages, setReportPages] = useState(getDefaultReportPages());

  const hasCoreDataReportCreated = (coreDataReport, oldReports) => {
    // since we are currently looking by month/year so...
    const dateFormat = 'MMMM YYYY';
    const analysisDate = moment(coreDataReport.analysis_date).format(
      dateFormat
    );
    const foundReport = oldReports.find((rpt) => {
      const rptDate = moment(rpt.date).format(dateFormat);
      return analysisDate === rptDate;
    });
    return foundReport !== undefined;
  };

  const getReports = async (dontSelect) => {
    setLoadingPastReports(true);
    const customerId = organization?.customer_id;
    try {
      const requests = [
        OrganizationService.getReports(organization.id, {
          limit: 100,
          page: 1,
          type: ReportTypes.Treasury,
        }),
      ];

      // if organization has customer id we will call core api to get its data
      if (customerId) {
        // current month without time
        requests.push(ReportService.getCoreData(customerId));
      }

      const responses = await Promise.all(requests);
      const { data: oldReports } = responses[0].data;
      let reports = oldReports
        .filter((rpt) => !!rpt.input || !!rpt.manualInput)
        .map(MapReportDropdownItem)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      // means we got the core data call
      if (responses.length > 1) {
        const { data: coreDataReports } = responses[1];
        // if core data exists
        if (coreDataReports.length) {
          // first filter out those that hasn't been created
          const mappedReports = coreDataReports
            .filter((coreData) => {
              return !hasCoreDataReportCreated(coreData, oldReports);
            })
            .map((coreData) => {
              return createManualReportFromCoreData(coreData);
            });
          // if we got the reports then
          if (mappedReports.length) {
            // ready all create requests
            const newReportRequests = mappedReports.map((newReport) => {
              return OrganizationService.createManualReport(
                organization.id,
                newReport
              );
            });
            const newReportResponses = await Promise.all(newReportRequests);
            const newReports = newReportResponses.map(MapReportDropdownItem);
            // add these reports at first/latest to show in FE
            reports = [...newReports, ...reports];
            const mappedReport = mappedReports[0];
            setSelectedRpt(mappedReport);
            setReport(mappedReport.manualInput);
            setReportPages(mappedReport.manualInput.reportPages);
            setWidgets(mappedReport.manualInput.widgets);
            setRptGenerated(true);
          }
        }
      }

      setPastReports(reports);

      if (!dontSelect) {
        const firstReport = reports.length
          ? hash?.includes('/new')
            ? {}
            : reports[0]
          : {};
        // if we have the reportId then get it
        if (firstReport?.key || hash?.includes('/id')) {
          // we got the id from url now we want to find that in our reports array and get full object
          const urlHashId = hash?.split('/')?.at(-1); // get the last index which has report id
          const reportFound = reports.find((rp) => rp.reportId === urlHashId);
          if (reportFound) {
            setSelectedRpt(reportFound);
          } else {
            setSelectedRpt(firstReport);
          }
        } else {
          setSelectedRpt({});
          setRptGenerated(false);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingPastReports(false);
    }
  };

  const getInsights = async (newNaics) => {
    setLoaderInsights(true);
    try {
      let data = {};
      const naicsCode = newNaics || organization.naics_code;
      if (naicsCode) {
        try {
          // get rpmg/sp summary by naics if company has it
          const naicsFirstTwo = naicsCode.slice(0, 2);
          data = await Promise.all([
            naicsService.getNaicsRpmgSummary(naicsFirstTwo),
            naicsService.getNaicsSpSummary(naicsFirstTwo),
          ]);
          const insightsDataRpmgSp = {
            rpmg: data[0],
            sp: data[1],
          };
          setInsightsData(insightsDataRpmgSp);
        } catch (err) {
          setInsightsData({});
        }
      } else {
        data = await OrganizationService.getInsightsByOrganization(
          organization.id
        );
        setInsightsData(data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoaderInsights(false);
    }
  };

  const defaultGlossaryWidget = {
    [TreasuryReportSections.Glossary]: [
      {
        id: 1,
        widgetConfig: { glossary: defaultGlossary, data: { id: 'gloss' } },
        type: WidgetTypes.GLOSS,
      },
    ],
  };
  const getDefaultWidgets = (reportObject) => {
    return {
      ...widgets,
      [TreasuryReportSections.Overview]: [
        {
          id: 1,
          widgetConfig: {
            data: reportObject,
          },
          type: WidgetTypes.ESTPR,
        },
        {
          id: 2,
          widgetConfig: {
            data: reportObject,
          },
          type: WidgetTypes.PEO,
        },
        {
          id: 3,
          widgetConfig: ElectronicPaymentsSavingsData,
          type: WidgetTypes.EPS,
        },
      ],
      [TreasuryReportSections.Payables]: [
        {
          id: 1,
          widgetConfig: ElectronicPaymentsToGrowthData,
          type: WidgetTypes.EPG,
        },
        {
          id: 2,
          widgetConfig: {
            data: reportObject,
            insightsData,
            source: MultipleSources,
          },
          type: WidgetTypes.DPO,
        },
        {
          id: 3,
          widgetConfig: ImproveVendorRelationshipData,
          type: WidgetTypes.IVR,
        },
      ],
      [TreasuryReportSections.Receivables]: [
        {
          id: 1,
          widgetConfig: HoursSpentWeeklyData,
          type: WidgetTypes.HSW,
        },
        {
          id: 2,
          widgetConfig: {
            data: reportObject,
            insightsData,
            source: MultipleSources,
          },
          type: WidgetTypes.DSO,
        },
      ],
      [TreasuryReportSections.Fraud]: [
        {
          id: 1,
          widgetConfig: {
            data: reportObject,
          },
          type: WidgetTypes.BAR,
        },
        {
          id: 2,
          widgetConfig: FraudMitigationData,
          type: WidgetTypes.QRCode,
        },
        {
          id: 3,
          widgetConfig: FraudPreventData,
          type: WidgetTypes.BEC,
        },
      ],
      [TreasuryReportSections.FeeSummary]: [
        {
          id: 1,
          widgetConfig: {
            data: reportObject,
          },
          type: WidgetTypes.BAEC,
        },
      ],
      ...defaultGlossaryWidget,
    };
  };

  const getUpdatedWidgetConfig = (wg, reportData, section) => {
    const newDescriptions = {
      [WidgetTypes.BEC]: FraudPreventData.description,
      [WidgetTypes.EPS]: ElectronicPaymentsSavingsData.description,
    };
    const newHeadings = {
      [WidgetTypes.BEC]: FraudPreventData.heading,
      [WidgetTypes.EPS]: ElectronicPaymentsSavingsData.heading,
    };
    const newItems = {
      [WidgetTypes.PFIB]: PFIBData.items,
    };
    const newConfigs = {
      [WidgetTypes.EPG]: ElectronicPaymentsToGrowthData,
      [WidgetTypes.IVR]: ImproveVendorRelationshipData,
      [WidgetTypes.BEC]: FraudPreventData,
      [WidgetTypes.HSW]: HoursSpentWeeklyData,
      [WidgetTypes.IconSet]: DSOCashFlowData,
    };

    let newReport = reportData;
    if (wg.type === WidgetTypes.PEO) {
      newReport = {
        ...reportData,
        enableDifferenceWidget: getDifferenceWidgetVisibility(wg.widgetConfig),
      };
    }
    const defaultWidget = {
      ...wg,
      widgetConfig: {
        ...wg.widgetConfig,
        data: newReport,
        insightsData,
        heading: newHeadings[wg.type] || wg.widgetConfig.heading,
        description: newDescriptions[wg.type] || wg.widgetConfig.description,
        items: newItems[wg.type] || wg.widgetConfig.items,
      },
    };
    switch (section) {
      case TreasuryReportSections.Fraud:
        if (wg.type === WidgetTypes.QRCode) {
          return { ...wg, widgetConfig: FraudMitigationData };
        }
        return defaultWidget;
      case TreasuryReportSections.Payables:
      case TreasuryReportSections.Receivables:
        return newConfigs[wg.type]
          ? { ...wg, widgetConfig: newConfigs[wg.type] }
          : defaultWidget;
      default:
        return defaultWidget;
    }
  };
  const getReportById = async (selectedReport) => {
    setLoadingReport(true);
    try {
      const reportResponse = await ReportService.getReport(selectedReport.key);
      const reportObject = reportResponse.manualInput;
      const checks = _.cloneDeep(reportObject.paymentMethodsUsed);
      checks.Checks = checks.Checks || checks.Check;
      checks.Checks.key = 'Checks';
      delete reportObject.paymentMethodsUsed.Check;
      reportObject.paymentMethodsUsed.Checks = checks.Checks;
      reportObject.paymentMethodsUsed = updateJsonObject(
        reportObject.paymentMethodsUsed
      );

      const achChecks = _.cloneDeep(reportObject.typesOfReceivables);
      achChecks['ACH/Checks'] =
        achChecks['ACH/Checks'] || achChecks['ACH/Check'];
      if (Object.hasOwn(achChecks, 'ACH')) {
        delete reportObject.typesOfReceivables['ACH/Checks'];
      } else {
        achChecks['ACH/Checks'].key = 'ACH/Checks';
        delete reportObject.typesOfReceivables['ACH/Check'];
        reportObject.typesOfReceivables['ACH/Checks'] = achChecks['ACH/Checks'];
      }
      reportObject.typesOfReceivables = updateJsonObject(
        reportObject.typesOfReceivables
      );

      // remove for comerica as per Emily
      delete reportObject.paymentMethodsUsed['Automated Payable Solution'];
      delete reportObject.typesOfReceivables['Automated Receivables Solution'];
      reportObject.paymentRisks.fraudPreventionProducts = updateJsonObject(
        reportObject.paymentRisks.fraudPreventionProducts
      );
      setReport({
        ...reportObject,
        value2: getCycleDate(reportObject.value2),
        glossary: reportObject?.glossary || defaultGlossary,
        valueN: getNaicsWithTitle(reportObject, organization),
        valueNaicsSic: getNaicsWithTitle(reportObject, organization, true),
        reportId: reportResponse.reportId,
      });
      if (Object.hasOwn(reportObject, 'feeAllocation')) {
        setFeeAllocationData(reportObject.feeAllocation);
      }

      const defaultWidgets = getDefaultWidgets(reportObject);
      const defaultReportPages = getDefaultReportPages();
      const opportunity = calculateOpportunity(
        reportObject?.paymentMethodsUsed?.Checks?.itemValue,
        selectedTenant
      );

      // if we have .widgets saved in report then load those otherwise load default ones to cater old reports too
      if (Object.hasOwn(reportObject, 'widgets')) {
        setFeeAllocationData(reportObject.feeAllocation);
        for (const key in reportObject.widgets) {
          reportObject.widgets[key] = reportObject.widgets[key].map((wg) => {
            return getUpdatedWidgetConfig(
              wg,
              {
                ...reportObject,
                opportunity,
              },
              key
            );
          });
        }
        const updatedReportWidgets = {
          ...reportObject.widgets,
          ...defaultGlossaryWidget,
        };
        setWidgets(updatedReportWidgets);
        setEditWidgets(updatedReportWidgets);
      } else {
        setWidgets(defaultWidgets);
        // these are for modal, will see how it can be removed and manage in one state
        setEditWidgets(defaultWidgets);
      }

      // if we have .reportPages saved in report then load those otherwise load default ones to cater old reports too
      if (Object.hasOwn(reportObject, 'reportPages')) {
        setReportPages(reportObject.reportPages);
      } else {
        setReportPages(defaultReportPages);
      }
      setRptGenerated(true);
      if (hash?.includes('/edit')) {
        handleEditReport();
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingReport(false);
    }
  };

  useEffect(() => {
    if (organization?.naics_code) {
      setReport({
        ...report,
        value1: organization.name,
        ...getNAICSWithIndustry(organization),
      });
    }
  }, [organization?.naics_code]);

  useEffect(() => {
    if (organization?.id) {
      getInsights();
      getReports();
    }
  }, [organization?.id]);

  useEffect(() => {
    if (report?.valueNaicsSic) {
      getInsights(report?.valueNaicsSic);
    }
  }, [report?.valueN, report?.valueNaicsSic]);

  useEffect(() => {
    if (selectedRpt?.key || hash?.includes('/id')) {
      getReportById(selectedRpt);
    }
  }, [selectedRpt?.key, selectedTenant?.key]);

  useEffect(() => {
    // look hash url if it contains new open a add modal.
    if (hash?.includes('/new')) {
      handleManualReport();
    }
  }, [hash]);

  useEffect(() => {
    if (currentTab === ReportTypes.Treasury) {
      setReport({});
      setPdf(null);
      setRptGenerated(false);
      setOpenGenerateReport(false);
      setFeeAllocationData({
        Wires: '',
        ACH: '',
        Checks: '',
        Other: '',
      });
      setAccordionBlock(TreasuryReportSections.Overview);
    }
  }, [currentTab]);

  const handleGenerateReport = (newReport, newWidgets, newReportPages) => {
    setOpenGenerateReport(false);
    overflowing();
    // here setting modal widgets back to original report widgets state to show updated
    setWidgets(newWidgets);
    setEditWidgets(newWidgets);
    setReportPages(newReportPages);
    if (newReport?.action === 'DELETED') {
      const newReports = [
        ...pastReports.filter((rpt) => rpt.key !== newReport.key),
      ];
      const reportsAvailable = newReports.length > 0;
      setPastReports(reportsAvailable ? newReports : []);
      setSelectedRpt(reportsAvailable ? newReports[0] : {});
      setRptGenerated(false);
    } else {
      setRptGenerated(true);
      if (newReport?.valueNaicsSic !== organization.naics_code) {
        getProfileInfo && getProfileInfo();
      }
      if (newReport) {
        try {
          if ('key' in selectedRpt) {
            const newReports = [
              ...pastReports.map((rpt) =>
                rpt.key === selectedRpt.key
                  ? {
                      ...rpt,
                      customElement: <ReportDropdownItem item={report} />,
                      name: getReportName(report),
                      widgets: newWidgets,
                      reportPages: newReportPages,
                      createdById: profileInfo.id,
                      updatedAt: new Date().toISOString(),
                      isAuto: false,
                      prettyDate: getReportPrettyDate(report),
                    }
                  : { ...rpt, widgets: newWidgets }
              ),
            ];
            setPastReports(newReports);
            setSelectedRpt(newReports.find((r) => r.key === selectedRpt.key));
          } else {
            const rptObject = newReport.manualInput;
            const pastReportObject = {
              key: newReport.reportId,
              customElement: <ReportDropdownItem item={rptObject} />,
              name: getReportName(rptObject),
              isManual: !!newReport.manualInput,
              createdById: profileInfo.id,
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString(),
              widgets: newWidgets,
              reportPages: newReportPages,
              isAuto: false,
              prettyDate: getReportPrettyDate(report),
            };
            const newReports = [pastReportObject].concat([...pastReports]);
            setPastReports(newReports);
            setSelectedRpt(pastReportObject);
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  function exportToCSV(data) {
    const wb = XLSX.utils.book_new();
    data.forEach((sheet) => {
      const headers = sheet.data.headers;
      let values = sheet.data.values.map((row) => {
        const newRow = [];
        for (let i = 0; i < headers.length; i++) {
          newRow[i] = row[i] || '';
        }
        return newRow;
      });
      values = values.map((row) => {
        return row.filter((cell) => cell !== '');
      });
      const ws = XLSX.utils.aoa_to_sheet([headers, ...values]);

      // Bold headers
      const headerRange = XLSX.utils.decode_range(ws['!ref']);
      for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
        const cell = XLSX.utils.encode_cell({ r: headerRange.s.r, c: col });
        const header = ws[cell].v;
        ws[cell].s = { font: { bold: true } };
        ws[cell].v = header;
      }

      // Column auto-width
      let colWidths = sheet.data.headers.map((header) => header.length);
      sheet.data.values.forEach((row) => {
        row.forEach((cell, index) => {
          const len = cell ? cell.toString().length : 0;
          if (colWidths[index] < len) {
            colWidths[index] = len;
          }
        });
      });
      colWidths = colWidths.filter((f) => f !== 1);
      for (let col = 0; col < colWidths.length; col++) {
        const width = colWidths[col] + 2;
        const cell = XLSX.utils.encode_col(col) + '1';
        ws[cell].s = { font: { bold: true } };
        ws[cell].v = sheet.data.headers[col];
        ws['!cols'] = ws['!cols'] || [];
        ws['!cols'][col] = { width };
      }

      XLSX.utils.book_append_sheet(wb, ws, sheet.name);
    });

    const fileName = `${report.value1} ${getReportPrettyDate(report)}.xlsx`;
    XLSX.writeFile(wb, fileName);
  }

  const onLoadPdf = async (event) => {
    // if excel bank mode skip all scraping and add random fake data
    const target = event.target.files[0];
    if (target?.type !== 'application/pdf') {
      return setErrorMessage('Only pdf files are allowed.');
    }

    if (target.size > MAX_WEIGHT) {
      return setErrorMessage('PDF file is too large to process.');
    }

    setLoader(true);
    setIsEdited(false);
    const fr = new FileReader();
    fr.onload = async () => {
      try {
        const buffer = fr.result;
        const doc = await pdfJS.getDocument(buffer).promise;
        const pageTexts = Array.from({ length: doc.numPages }, async (v, i) => {
          return (await (await doc.getPage(i + 1)).getTextContent()).items;
        });
        const allText = await Promise.all(pageTexts);
        const nameAndDate = getCompanyNameAndStatementDate(
          allText,
          organization,
          isCenturyBank,
          isSVB || selectedTenant?.key === DemoTenantsKeys.svb
        );
        let mapping = {};
        if (isSVB || selectedTenant?.key === DemoTenantsKeys.svb) {
          // new mapping for SVB and when selection SVB from company detail page
          mapping = processSVBBankStatement(allText, nameAndDate);
        } else {
          mapping = processComericaBankStatement(allText, nameAndDate);
        }
        // now we want to generate report as soon as user uploads a pdf, so porting all the logic from generate modal
        const defaultWidgets = getDefaultWidgets(mapping);
        const defaultReportPages = getDefaultReportPages();
        const newReport = {
          name: mapping.value1,
          date: mapping.value2,
          type: ReportTypes.Treasury,
          manualInput: {
            ...mapping,
            feeAllocation: feeAllocationData,
            widgets: defaultWidgets,
            reportPages: defaultReportPages,
            ...getNAICSWithIndustry(organization),
          },
        };
        const reportCreatedResponse =
          await OrganizationService.createManualReport(
            organization.id,
            newReport
          );
        setWidgets(defaultWidgets);
        setEditWidgets(defaultWidgets);
        setReportPages(defaultReportPages);
        setFeeAllocationData(mapping.feeAllocationData);
        setReport(mapping);
        setAccordionBlock(TreasuryReportSections.Overview);
        setLoader(false);
        setRptGenerated(true);
        setPdf(null);
        const rptObject = newReport.input || newReport.manualInput;
        const newlyCreatedReportObject = {
          key: reportCreatedResponse?.reportId,
          customElement: <ReportDropdownItem item={rptObject} />,
          name: getReportName(rptObject),
          isManual: !!newReport.manualInput,
          createdById: profileInfo.id,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          widgets: defaultWidgets,
          reportPages: defaultReportPages,
          prettyDate: getReportPrettyDate(mapping),
        };
        const newReports = [newlyCreatedReportObject].concat([...pastReports]);
        setPastReports(newReports);
        setSelectedRpt(newlyCreatedReportObject);
        setReportCreated({ report: reportCreatedResponse?.data });
        setSuccessMessage('Report processed successfully.');
      } catch (e) {
        setLoader(false);
        setPdf(null);
        setErrorMessage(
          'Unable to process the statement. Please re-export from the source and try again.'
        );
      }
    };
    fr.readAsDataURL(target);
  };

  const handleManualReport = () => {
    const estimatedTotalPayables = 0;
    const estimatedTotalReceivables = 0;
    const paymentMethodsUsed = {
      Checks: 0,
      ACH: 0,
      Wires: 0,
    };

    const typesOfReceivables = {
      'ACH/Checks': 0,
      Wires: 0,
      'Cash Vault': 0,
    };

    const opportunity = calculateOpportunity(
      paymentMethodsUsed.Checks,
      selectedTenant
    );

    const paymentRisksValueD = 0;
    const paymentRisksValueE = 0;
    const paymentRisks = {
      balance: {
        total: paymentRisksValueD + paymentRisksValueE,
        isChecked: paymentRisksValueD > 0 && paymentRisksValueE > 0,
      },
      fraudPreventionProducts: updateJsonObject({
        'Positive Pay': 0,
        'ACH Positive Pay': 0,
      }),
    };

    const newReport = {
      value1: organization.name,
      ...getNAICSWithIndustry(organization),
      value2: new Date(),
      value4: '',
      value5: '',
      value6: '',
      value7: '',
      value8: '',
      value9: '',
      value11: '',
      value12: [''],
      value13: [''],
      value14: [''],
      value15: '',
      value16: [''],
      opportunity,
      estimatedTotalPayables: Math.round(estimatedTotalPayables),
      estimatedTotalReceivables: Math.round(estimatedTotalReceivables),
      payableData: {},
      receivableData: {},
      paymentMethodsUsed: updateJsonObject(paymentMethodsUsed),
      typesOfReceivables: updateJsonObject(typesOfReceivables),
      paymentRisks,
      glossary: defaultGlossary,
      totalFees: '',
    };
    setReport(newReport);
    setLoader(false);
    setFeeAllocationData({
      Wires: '',
      ACH: '',
      Checks: '',
      Other: '',
    });
    setPdf(null);
    setAccordionBlock(TreasuryReportSections.Overview);
    setSelectedRpt({});
    setIsEdited(false);
    setRptGenerated(false);
    const defaultReportPages = getDefaultReportPages();
    const newWidgets = getDefaultWidgets(newReport);
    setWidgets(newWidgets);
    setEditWidgets(newWidgets);
    setReportPages(defaultReportPages);
  };

  const handleGenerateManualReport = () => {
    let estimatedTotalPayables = 0;
    let estimatedTotalReceivables = 0;
    let paymentMethodsUsed = {
      Checks: 0,
      ACH: 0,
      Wires: 0,
    };

    let typesOfReceivables = {
      'ACH/Checks': 0,
      Wires: 0,
      'Cash Vault': 0,
    };

    let opportunity = calculateOpportunity(
      paymentMethodsUsed.Checks,
      selectedTenant
    );

    const paymentRisksValueD = 0;
    const paymentRisksValueE = 0;
    const paymentRisks = {
      balance: {
        total: paymentRisksValueD + paymentRisksValueE,
        isChecked: paymentRisksValueD > 0 && paymentRisksValueE > 0,
      },
      fraudPreventionProducts: updateJsonObject({
        'Positive Pay': 0,
        'ACH Positive Pay': 0,
      }),
    };

    let newReport = {};
    if (excelBankMode && selectedTenant?.key !== DemoTenantsKeys.svb) {
      estimatedTotalPayables = 3;
      estimatedTotalReceivables = 3;
      paymentMethodsUsed = {
        Checks: 1,
        ACH: 1,
        Wires: 1,
      };

      typesOfReceivables = {
        'ACH/Checks': 1,
        Wires: 1,
        'Cash Vault': 1,
      };

      opportunity = '10';
      newReport = {
        value1: organization.name,
        ...getNAICSWithIndustry(organization),
        value2: new Date(),
        value4: '$0',
        value5: '$26,763.81',
        value6: '200',
        value7: '$20,117,166.17',
        value8: '$16,215,632.30',
        value9: '$33,069.31',
        value92: '$538,836.57',
        value11: '835',
        value12: ['509'],
        value13: ['51'],
        value14: ['2868'],
        value15: '1598',
        value16: ['379'],
        payableCreditCheck: '0',
        receiveableCreditCard: '0',
        opportunity,
        estimatedTotalPayables: Math.round(estimatedTotalPayables),
        estimatedTotalReceivables: Math.round(estimatedTotalReceivables),
        payableData: {},
        receivableData: {},
        paymentMethodsUsed: updateJsonObject(paymentMethodsUsed),
        typesOfReceivables: updateJsonObject(typesOfReceivables),
        paymentRisks,
        glossary: defaultGlossary,
        totalFees: '$0',
      };
      setReport(newReport);
      setFeeAllocationData({
        Wires: 29,
        Lockbox: 28,
        ACH: 1,
        Other: 42,
      });
      setCsvData(fakeCSVData);
    } else if (isSVB || selectedTenant?.key === DemoTenantsKeys.svb) {
      paymentMethodsUsed = {
        ACH: 0,
        Checks: 0,
        'Domestic Wire': 0,
        'International Wire': 0,
      };
      typesOfReceivables = {
        ACH: 0,
        Checks: 0,
        Wires: 0,
      };
      newReport = {
        value1: organization.name,
        ...getNAICSWithIndustry(organization),
        value2: new Date(),
        value4: '',
        value5: '',
        value6: '',
        value7: '',
        value8: '',
        value9: '',
        value11: '',
        value12: [''],
        value13: [''],
        value14: [''],
        value15: '',
        value16: [''],
        opportunity,
        estimatedTotalPayables: Math.round(estimatedTotalPayables),
        estimatedTotalReceivables: Math.round(estimatedTotalReceivables),
        payableData: {},
        receivableData: {},
        paymentMethodsUsed: updateJsonObject(paymentMethodsUsed),
        typesOfReceivables: updateJsonObject(typesOfReceivables),
        paymentRisks,
        glossary: defaultGlossary,
        totalFees: '',
      };
      setReport(newReport);
      setFeeAllocationData({
        Wires: '',
        ACH: '',
        Checks: '',
        Other: '',
      });
    } else {
      newReport = {
        value1: organization.name,
        ...getNAICSWithIndustry(organization),
        value2: new Date(),
        value4: '',
        value5: '',
        value6: '',
        value7: '',
        value8: '',
        value9: '',
        value11: '',
        value12: [''],
        value13: [''],
        value14: [''],
        value15: '',
        value16: [''],
        opportunity,
        estimatedTotalPayables: Math.round(estimatedTotalPayables),
        estimatedTotalReceivables: Math.round(estimatedTotalReceivables),
        payableData: {},
        receivableData: {},
        paymentMethodsUsed: updateJsonObject(paymentMethodsUsed),
        typesOfReceivables: updateJsonObject(typesOfReceivables),
        paymentRisks,
        glossary: defaultGlossary,
        totalFees: '',
      };
      setReport(newReport);
      setFeeAllocationData({
        Wires: '',
        ACH: '',
        Checks: '',
        Other: '',
      });
    }
    setLoader(false);
    setPdf(null);
    const newWidgets = getDefaultWidgets(newReport);
    const defaultReportPages = getDefaultReportPages();
    setWidgets(newWidgets);
    setEditWidgets(newWidgets);
    setSelectedRpt({});
    setAccordionBlock(TreasuryReportSections.Overview);
    setOpenGenerateReport(true);
    setIsEdited(false);
    setRptGenerated(false);
    setReportPages(defaultReportPages);
  };

  const handleEditReport = () => {
    setAccordionBlock(TreasuryReportSections.Overview);
    setIsEdited(true);
    setOpenGenerateReport(true);
    updateHash('');
  };

  const onRemoveFile = () => {
    setPdf(null);
    setLoader(false);
    // abort api request here
    cancelTokenSources.forEach((src) => {
      src.cancel('Request cancelled.');
    });
  };

  const onAddWidget = async (e, newWidget, oldWidget) => {
    const sectionWidgets = widgets[oldWidget.section];
    let updatedWidgets = [];
    if (oldWidget.type === WidgetTypes.BLANK) {
      sectionWidgets[oldWidget.widgetConfig.index] = {
        ...newWidget,
        id: crypto.randomUUID(),
      };
      updatedWidgets = [...sectionWidgets];
    } else {
      updatedWidgets = [...sectionWidgets].map((wg) =>
        wg.widgetConfig.heading === oldWidget.widgetConfig.heading
          ? { ...newWidget, id: crypto.randomUUID() }
          : { ...wg }
      );
    }
    // since we are giving add/remove in direct report so we need to update report widgets right here
    const newWidgets = { ...widgets, [oldWidget.section]: updatedWidgets };
    setWidgets(newWidgets);
    setEditWidgets(newWidgets); // this needs to be added in order to open updated in modal
    await ReportService.updateReport(report.reportId, {
      name: report.value1,
      date: report.value2,
      manualInput: {
        ...report,
        feeAllocation: feeAllocationData,
        widgets: newWidgets,
      },
    });
    setShowWidgetsLibrary(false);
    overflowing();
    setSuccessMessage('Report Saved');
  };

  const onDeleteWidget = async (oldWidget) => {
    const sectionWidgets = widgets[oldWidget.section];
    const updatedWidgets = [...sectionWidgets].map((wg, index) =>
      wg.widgetConfig.heading === oldWidget.widgetConfig.heading
        ? {
            action: { onAdd: () => setShowWidgetsLibrary(true) },
            widgetConfig: {
              index,
            },
            type: WidgetTypes.BLANK,
          }
        : { ...wg }
    );
    // since we are giving add/remove in direct report so we need to update report widgets right here
    const newWidgets = { ...widgets, [oldWidget.section]: updatedWidgets };
    setWidgets(newWidgets);
    setEditWidgets(newWidgets); // this needs to be added in order to open updated in modal
    await ReportService.updateReport(report.reportId, {
      name: report.value1,
      date: report.value2,
      manualInput: {
        ...report,
        feeAllocation: feeAllocationData,
        widgets: newWidgets,
      },
    });
    setSuccessMessage('Report Saved');
  };
  const qrCodeExternalLink = getVideoUrlWithTenant(
    tenant,
    FraudMitigationData.videoId
  );

  const createManualReportFromCoreData = (coreDataObject) => {
    const coreDataMetric = JSON.parse(coreDataObject.metrics_json);
    const estimatedTotalPayables = coreDataMetric.tmr_total_payables_volume;
    const estimatedTotalReceivables =
      coreDataMetric.tmr_total_receivables_volume;
    const paymentMethodsUsed = {
      Checks: coreDataMetric.tmr_checks_out_volume,
      ACH: coreDataMetric.tmr_ach_payables_volume,
      Wires: coreDataMetric.tmr_wires_payables_volume,
    };

    const typesOfReceivables = {
      'ACH/Checks': coreDataMetric.tmr_ach_checks_in_volume,
      Wires: coreDataMetric.tmr_wires_in_volume,
      'Cash Vault': coreDataMetric.tmr_cash_in_volume,
    };

    const paymentRisksValueD = coreDataMetric.tmr_positive_pay;
    const paymentRisksValueE = coreDataMetric.tmr_ach_positive_pay;
    const paymentRisks = {
      balance: {
        total: coreDataMetric.tmr_balances,
        isChecked: paymentRisksValueD > 0 && paymentRisksValueE > 0,
      },
      fraudPreventionProducts: updateJsonObject({
        'Positive Pay': paymentRisksValueD,
        'ACH Positive Pay': paymentRisksValueE,
      }),
    };

    const reportMapped = {
      value1: coreDataObject.customer_client_name || organization.name,
      ...getNAICSWithIndustry(organization),
      value2: getCycleDate(coreDataObject.analysis_date) || new Date(),
      value5: coreDataMetric.tmr_total_bank_fees,
      value6: coreDataMetric.tmr_earnings_credit_rate + '',
      value7: coreDataMetric.tmr_balances,
      value8: coreDataMetric.tmr_balances_needed_to_offset_fees,
      value9: coreDataMetric.tmr_earnings_allowance,
      opportunity: coreDataMetric.tmr_bank_difference + '',
      estimatedTotalPayables: Math.round(estimatedTotalPayables),
      estimatedTotalReceivables: Math.round(estimatedTotalReceivables),
      paymentMethodsUsed: updateJsonObject(paymentMethodsUsed),
      typesOfReceivables: updateJsonObject(typesOfReceivables),
      paymentRisks,
      glossary: defaultGlossary,
      totalFees: coreDataMetric.tmr_total_fees_paid,
      coreData: {
        report_id: coreDataObject.report_id || coreDataObject.report_ID,
        tenant_id: coreDataObject.tenant_id,
        tenant_name: coreDataObject.tenant_name,
        lead_composite_account_token:
          coreDataObject?.lead_composite_account_token,
      },
    };

    const defaultWidgets = getDefaultWidgets(reportMapped);
    const defaultPages = getDefaultReportPages();

    return {
      name: reportMapped.value1,
      date: reportMapped.value2,
      type: ReportTypes.Treasury,
      manualInput: {
        ...reportMapped,
        widgets: cleanDataWidgets(defaultWidgets),
        reportPages: defaultPages,
        isAuto: true, // track whether this was generated by core
      },
    };
  };

  return (
    <>
      <WidgetsLibraryModal />
      <SyncTMReportModal />
      <AlertWrapper className="alert-position">
        <Alert
          color="info"
          message={successMessage}
          setMessage={setSuccessMessage}
          time={8000}
        />
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
          time={8000}
        />
      </AlertWrapper>
      <GenerateTreasuryReportModal
        report={report}
        organization={organization}
        setReport={setReport}
        selectedTenant={selectedTenant}
        openGenerateReport={openGenerateReport}
        setOpenGenerateReport={setOpenGenerateReport}
        handleGenerateReport={handleGenerateReport}
        feeAllocationData={feeAllocationData}
        setFeeAllocationData={setFeeAllocationData}
        loaderInsights={loaderInsights}
        insightsData={insightsData}
        accordionBlock={accordionBlock}
        isManual={pdf === null}
        scrapeMetaData={reportCreated}
        selectedReport={selectedRpt}
        isEdited={isEdited}
        excelBankMode={excelBankMode}
        widgets={editWidgets}
        setWidgets={setEditWidgets}
        reportPages={reportPages}
        setReportPages={setReportPages}
      />

      {pastReports.length === 0 &&
        readOnly &&
        !loadingReport &&
        !loadingPastReports && (
          <NoDataFound
            icon={NO_REPORTS_AVAILABLE_ICON}
            iconRounded={true}
            containerStyle="my-6 py-6"
            title={NO_REPORTS_AVAILABLE}
          />
        )}

      <ReportAction
        readOnly={readOnly}
        pastReports={pastReports}
        handleManualReport={handleManualReport}
        rptGenerated={rptGenerated}
        handleEditReport={handleEditReport}
        loadingReport={loadingReport}
        loadingPastReports={loadingPastReports}
        selectedRpt={selectedRpt}
        setSelectedRpt={(newReport) => {
          updateHash('');
          setSelectedRpt(newReport);
        }}
        profileInfo={profileInfo}
        reSync={true}
        handleResyncClick={() => {
          setResyncReport(report);
          setOrganization(organization);
          setShowModal(true);
        }}
        report={report}
        startDownload={startDownload}
        setStartDownload={setStartDownload}
        downloadOptions={downloadOptions}
        csvData={csvData}
        exportToCSV={exportToCSV}
        reportType={ReportTypes.Treasury}
        linkConfig={{
          area: {
            areaX: 520,
            areaY: 400,
            areaWidth: 110,
            areaHeight: 30,
          },
          page: 5,
          url: qrCodeExternalLink,
        }}
      />
      <div className="text-center">
        {!rptGenerated &&
          !loadingReport &&
          !readOnly &&
          !loadingPastReports && (
            <ReportDragDrop
              file={pdf}
              setFile={setPdf}
              loader={loader}
              onRemoveFile={onRemoveFile}
              onLoadFile={onLoadPdf}
              uploadIcon="upload_file"
              handleGenerate={handleGenerateManualReport}
              lineBreak={true}
              reportType={ReportTypes.Treasury}
            />
          )}

        {loadingReport || loadingPastReports ? (
          <ReportBlocksSkeleton />
        ) : (
          <>
            {rptGenerated && (
              <>
                {startDownload && (
                  <ReportPDFWrapper>
                    <ReportCover
                      name={report.value1}
                      date={report.value2}
                      excelBankMode={excelBankMode}
                      selectedTenant={selectedTenant}
                      report={report}
                      type={ReportTypes.Treasury}
                      organization={organization}
                    />
                    <ReportPages
                      pages={widgets}
                      report={report}
                      reportPages={reportPages}
                      sectionKeys={TreasuryReportSectionKeys}
                      pageConfig={PageConfig}
                      showIcon={false}
                      selectedTenant={selectedTenant}
                      reportType={ReportTypes.Treasury}
                    />
                  </ReportPDFWrapper>
                )}
                <div
                  className={`pb-3 tm-generated-rpt ${
                    readOnly ? 'client-dashboard-rpt' : ''
                  }`}
                >
                  <ListGroup className="list-group-no-gutters mt-0 list-group-flush">
                    {TreasuryReportSectionKeys.flatMap((key) => {
                      const widgetsForCurrentKey = widgets[key] || [];
                      const pageEnabled = reportPages[key]?.enabled;
                      const widgetsWithoutBlank = widgetsForCurrentKey
                        ?.filter((widget) => widget.type !== WidgetTypes.BLANK)
                        .map((wg) => ({
                          ...wg,
                          id: crypto.randomUUID(),
                          section: key,
                        }));
                      if (widgetsWithoutBlank.length && pageEnabled) {
                        return widgetsWithoutBlank?.map((widget) => (
                          <WidgetWithActionButtons
                            key={widget.id}
                            widget={widget}
                            section={key}
                            onAddWidget={onAddWidget}
                            onDeleteWidget={onDeleteWidget}
                            selectedTenant={selectedTenant}
                            setSelectedWidget={setSelectedWidget}
                            setShowWidgetsLibrary={setShowWidgetsLibrary}
                            actionButtons={{}}
                          />
                        ));
                      }
                      return null;
                    })}
                    <DisclaimerWidget />
                  </ListGroup>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default TreasuryManagementReport;
