import { useEffect, useState } from 'react';
import Avatar from '../../../components/Avatar';
import GenericTable from '../../../components/GenericTable';
import { ShortDescription } from '../../../components/ShortDescription';
import { cubeService } from '../../../services';
import TableSkeleton from '../../../components/commons/TableSkeleton';
const LessonLeaderboard = ({
  dateRange = {},
  reportLimit = {},
  setShowCSVBtn = () => {},
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const dateFilter = Object.keys(dateRange)?.length > 0 && dateRange;
  useEffect(() => {
    (async () => {
      setLoading(true);
      const results = await cubeService.loadCustomAnalytics({
        type: 'Leaderboard',
        ...(reportLimit?.key !== 'all'
          ? { limit: parseInt(reportLimit.key) }
          : {}),

        ...(Object.keys(dateRange)?.length > 0
          ? dateRange?.key === 'All Time'
            ? {}
            : { startDate: dateRange?.startDate, endDate: dateRange?.endDate }
          : {}),
      });
      setData(results);
      setShowCSVBtn(results?.length > 0);
      setLoading(false);
    })();
  }, [dateFilter, reportLimit]);
  const updatedRows = data?.map((item) => {
    return {
      name: `${item?.first_name} ${item?.last_name}`,
      completed: item?.completed,
      uniqueCompleted: item?.uniqueCompleted,
      selfDirected: item?.completed - item?.taken_assigned,
      team: item?.teams,
      assigned: item?.taken_assigned,
    };
  });

  const rows = data?.map((result, index) => {
    const user = {
      firstName: result?.first_name,
      lastName: result?.last_name,
      avatar: result?.avatar,
    };
    const name = `${result?.first_name} ${result?.last_name}`;
    const rank = index + 1;

    return {
      id: index,
      dataRow: [
        {
          key: 'rank',
          component: (
            <div className="rank-container">
              <span className={`rank-${rank} font-weight-semi-bold`}>
                {rank}
              </span>
            </div>
          ),
        },
        {
          key: 'name',
          component: (
            <div className={`d-flex align-items-center font-weight-semi-bold`}>
              <Avatar defaultSize="xs" classModifiers="mr-2" user={user} />
              {name}
            </div>
          ),
        },
        {
          key: 'completed',
          component: (
            <div>
              <span>{result?.completed}</span>
            </div>
          ),
        },
        {
          key: 'uniqueCompleted',
          component: (
            <div>
              <span>{result?.uniqueCompleted}</span>
            </div>
          ),
        },
        {
          key: 'selfDirected',
          component: result?.completed - result?.taken_assigned,
        },
        {
          key: 'assigned',
          component: result?.taken_assigned,
        },
        {
          key: 'team',
          component: (
            <span className="d-flex align-items-center">
              <ShortDescription limit={20} content={result?.teams} />
            </span>
          ),
        },
      ],
    };
  });

  const loader = () => {
    if (loading) return <TableSkeleton cols={6} rows={10} />;
  };

  return (
    <div>
      <div>
        {loading ? (
          loader()
        ) : (
          <GenericTable
            checkbox={false}
            data={rows}
            exportToCSV={true}
            tableData={updatedRows}
            fileName="LeaderboardReport"
            columns={[
              {
                key: 'rank',
                component: 'Rank',
                width: '5%',
              },
              {
                key: 'name',
                component: 'User',
                width: '15.8%',
              },
              {
                key: 'completed',
                component: 'Completed',
                tooltipTitle: 'Total lessons completed',
                width: '15.8%',
              },
              {
                key: 'uniqueCompleted',
                component: 'Unique Completed',
                tooltipTitle: 'Total unique lessons completed',
                width: '15.8%',
              },
              {
                key: 'selfDirected',
                component: 'Self Study',
                tooltipTitle:
                  '# of lessons completed that were not part of a required assignment',
                width: '15.8%',
              },
              {
                key: 'assigned',
                component: 'Assigned',
                tooltipTitle:
                  '# of lessons completed as part of a required assignment',
                width: '15.8%',
              },
              {
                key: 'team',
                component: 'Team Name',
                width: '15.8%',
              },
            ]}
            usePagination={false}
            noDataInDbValidation={true}
          />
        )}
      </div>
    </div>
  );
};

export default LessonLeaderboard;
