import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FormGroup,
  Label,
} from 'reactstrap';
import MaterialIcon from '../../../commons/MaterialIcon';
import RightSlidingDrawer from '../../../modal/RightSlidingDrawer';
import { FormControl } from 'react-bootstrap';
import FormSelect from '../../../profile/FormSelect';
import { overflowing, removeBodyScroll } from '../../../../utils/Utils';
import ButtonIcon from '../../../commons/ButtonIcon';
import {
  AccountTypes,
  AccountTypesList,
  FraudServicesList,
  getOperatingAccountType,
  ProductServicesList,
} from '../account.structure.constants';
import Alert from '../../../Alert/Alert';
import AlertWrapper from '../../../Alert/AlertWrapper';
import useConfirmationModal from './useConfirmationModal';
import ValidationErrorText from '../../../commons/ValidationErrorText';
import SearchOptionDropdown from '../../../commons/SearchOptionDropdown';
import ButtonFilterDropdown from '../../../commons/ButtonFilterDropdown';

const containerWidth = 500;
const DefaultAccountTypeSelection = { key: -1, name: 'Select Account Type' };

const Header = ({ children }) => {
  return (
    <div className="bg-soft-gray text-gray-900 py-2 px-3 mb-2 font-weight-medium font-size-sm2">
      {children}
    </div>
  );
};
const GeneralInformation = ({
  accountDetails,
  setAccountDetails,
  errorFields,
  setErrorFields,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'accountNumber') {
      const error = {};
      error[name] = {
        ...errorFields[name],
        isShow: value.length < 4,
      };
      setErrorFields(error);
    }
    setAccountDetails({ ...accountDetails, [name]: value });
  };

  const AccountTypeOptions = [
    DefaultAccountTypeSelection,
    ...Object.entries(AccountTypes).map((acnt) => ({
      ...acnt[1],
    })),
  ];
  return (
    <>
      <Header>General Information</Header>
      <div className="mb-3 px-3">
        <FormGroup>
          <Label>Account Name</Label>
          <FormControl
            name="accountName"
            value={accountDetails.accountName}
            className="border-left-4 border-left-danger"
            autoFocus
            autoComplete="off"
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup className="my-3">
          <Label>Operating Account Type</Label>
          <ButtonFilterDropdown
            buttonText="Account Type"
            btnToggleStyle="w-100"
            menuClass="w-100"
            ignoreChildHover="w-100"
            icon="account_balance"
            options={AccountTypeOptions}
            filterOptionSelected={
              accountDetails.operatingAccountType || AccountTypes.Operating
            }
            handleFilterSelect={(e, item) => {
              handleChange({
                target: {
                  name: 'operatingAccountType',
                  value: item,
                },
              });
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label>Last 4 digits of account</Label>
          <FormControl
            name="accountNumber"
            maxLength={4}
            className={`border-left-4 border-left-danger ${
              errorFields.accountNumber.isShow ? 'border-danger' : ''
            }`}
            value={accountDetails.accountNumber}
            onChange={handleChange}
          />
          {errorFields.accountNumber.isShow && (
            <ValidationErrorText text={errorFields.accountNumber.message} />
          )}
        </FormGroup>
        <FormGroup>
          <Label>Account type</Label>
          <FormSelect
            items={[
              { value: '', title: 'Select Account type' },
              ...AccountTypesList,
            ]}
            name="accountType"
            value={accountDetails.accountType}
            borderClasses="border-left-4 border-left-danger"
            onChange={handleChange}
          />
        </FormGroup>
      </div>
    </>
  );
};
const FraudServices = ({ accountDetails, setAccountDetails }) => {
  const [options] = useState(
    [...FraudServicesList].map((value) => ({ key: value, name: value }))
  );
  const [show, setShow] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const handleOptionSelect = (event) => {
    const { value } = event.target;
    setSelectedData((prevState) => {
      const isSelected = prevState.includes(value);
      const updatedSelectedData = isSelected
        ? prevState.filter((item) => item !== value && !!item)
        : [...prevState, value];

      setAccountDetails((prevAccountDetails) => ({
        ...prevAccountDetails,
        fraudServices: updatedSelectedData.join(', '),
      }));

      return updatedSelectedData;
    });
  };

  const handleAllSelect = (all, allOrNone) => {
    setAccountDetails((prevAccountDetails) => ({
      ...prevAccountDetails,
      fraudServices: all ? allOrNone.join(', ') : '',
    }));
  };

  useEffect(() => {
    const accountFraudServices =
      accountDetails?.fraudServices.length > 0
        ? accountDetails.fraudServices.split(', ')
        : [];
    setSelectedData(accountFraudServices);
  }, [accountDetails?.fraudServices]);

  return (
    <>
      <Header>Fraud Services</Header>
      <div className="mb-3 mt-3 px-3">
        <SearchOptionDropdown
          dontClose
          data={options}
          show={show}
          label="fraud services"
          scrollable="pr-3"
          setShow={setShow}
          handleOptionSelected={handleOptionSelect}
          handleAllSelect={handleAllSelect}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
        />
      </div>
    </>
  );
};
const ProductServices = ({ accountDetails, setAccountDetails }) => {
  const [options] = useState(
    [...ProductServicesList].map((value) => ({ key: value, name: value }))
  );
  const [show, setShow] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const handleOptionSelect = (event) => {
    const { value } = event.target;
    setSelectedData((prevState) => {
      const isSelected = prevState.includes(value);
      const updatedSelectedData = isSelected
        ? prevState.filter((item) => item !== value)
        : [...prevState, value];

      setAccountDetails((prevAccountDetails) => ({
        ...prevAccountDetails,
        productServices: updatedSelectedData.join(', '),
      }));

      return updatedSelectedData;
    });
  };

  useEffect(() => {
    const accountProductServices =
      accountDetails?.productServices.length > 0
        ? accountDetails.productServices.split(', ')
        : [];
    setSelectedData(accountProductServices);
  }, [accountDetails?.productServices]);

  const handleAllSelect = (all, allOrNone) => {
    setAccountDetails((prevAccountDetails) => ({
      ...prevAccountDetails,
      productServices: all ? allOrNone.join(', ') : '',
    }));
  };
  return (
    <>
      <Header>Product & Services</Header>
      <div className="my-3 px-3">
        <SearchOptionDropdown
          scrollable="pr-5_1"
          data={options}
          dontClose
          search={true}
          show={show}
          label="product services"
          setShow={setShow}
          handleOptionSelected={handleOptionSelect}
          handleAllSelect={handleAllSelect}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
        />
      </div>
    </>
  );
};

const AddNewAccountModal = ({
  show,
  setShow,
  accounts,
  setAccounts,
  currentAccount,
}) => {
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorFields, setErrorFields] = useState({
    accountNumber: {
      isShow: false,
      message: 'Please add last 4 digits of your account',
    },
  });
  const defaultAccountObject = {
    id: crypto.randomUUID(),
    accountName: '',
    accountNumber: '',
    operatingAccountType: DefaultAccountTypeSelection,
    accountType: '',
    fraudServices: '',
    productServices: '',
    status: 'complete',
  };
  const [accountDetails, setAccountDetails] = useState(defaultAccountObject);

  const [modalConfig, setModalConfig] = useState({
    title: 'Confirm Change Operating Account',
    description: ``,
    confirmCallback: () => {},
  });
  const { ConfirmationModal, setShowModal, setData } =
    useConfirmationModal(modalConfig);
  useEffect(() => {
    if (show) {
      removeBodyScroll();
      if (currentAccount?.id) {
        const operatingAccountType = getOperatingAccountType(currentAccount);
        setAccountDetails({ ...currentAccount, operatingAccountType });
      } else {
        setAccountDetails(defaultAccountObject);
      }
    }
  }, [show, currentAccount]);

  const handleClose = () => {
    setShow(false);
    overflowing();
  };

  const handleSave = () => {
    try {
      if (errorFields.accountNumber.isShow) {
        return;
      }
      const existedOperatingAccount = accounts.find(
        (act) =>
          act.operatingAccount ||
          act.operatingAccountType?.key === AccountTypes.Operating.key
      );
      // if a different account
      if (
        existedOperatingAccount &&
        (accountDetails.operatingAccount ||
          accountDetails.operatingAccountType?.key ===
            AccountTypes.Operating.key)
      ) {
        if (existedOperatingAccount.id !== accountDetails.id) {
          setData({
            existedOperatingAccount,
            currentAccount: accountDetails,
          });
          setModalConfig({
            ...modalConfig,
            confirmMsg: 'Operating account changed.',
            description: `You have Account <b>${existedOperatingAccount.accountNumber}</b> already selected as Operating Account. Would you like to change it?`,
            confirmCallback: (data) => {
              const newAccounts = [...accounts].map((act) =>
                act.id === data.currentAccount.id
                  ? {
                      ...act,
                      ...accountDetails,
                      operatingAccountType: AccountTypes.Operating,
                    }
                  : act.id === data.existedOperatingAccount.id
                  ? { ...act, operatingAccountType: AccountTypes.SubAccount }
                  : { ...act }
              );
              setAccounts(newAccounts);
              setAccountDetails({
                ...accountDetails,
                operatingAccountType: AccountTypes.Operating,
              });
              setSuccessMessage('Operating account changed.');
              handleClose();
            },
          });
          setShowModal(true);
          return;
        }
      }

      handleClose();
      const accountExists = accounts.some(
        (acnt) => acnt.id === accountDetails.id
      );
      let updatedAccounts = [];
      if (!accountExists) {
        updatedAccounts = [...accounts, accountDetails];
      } else {
        updatedAccounts = accounts.map((acnt) =>
          acnt.id === accountDetails.id ? accountDetails : acnt
        );
      }
      setAccounts(updatedAccounts);
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
    }
  };
  return (
    <RightSlidingDrawer
      open={show}
      containerWidth={containerWidth}
      modalClass="account-structure-add-new-account"
      toggleDrawer={() => {
        setShow(false);
      }}
    >
      <ConfirmationModal />
      <AlertWrapper className="alert-position z-lg">
        <Alert
          message={errorMessage}
          setMessage={setErrorMessage}
          color="danger"
        />
        <Alert
          message={successMessage}
          setMessage={setSuccessMessage}
          color="success"
        />
      </AlertWrapper>
      <Card
        className="p-0 h-100 shadow-none border-0 account-structure-add-new-account"
        style={{ width: containerWidth }}
      >
        <CardHeader className="justify-content-between gap-2 align-items-center">
          <h3 className="mb-0 text-capitalize">Account Details</h3>
          <a className="icon-hover-bg cursor-pointer">
            <MaterialIcon
              icon="close"
              clazz="fs-5 rounded-circle p-1"
              rounded
              onClick={handleClose}
            />
          </a>
        </CardHeader>
        <CardBody className="h-100 px-0 overflow-y-auto">
          <GeneralInformation
            accountDetails={accountDetails}
            setAccountDetails={setAccountDetails}
            errorFields={errorFields}
            setErrorFields={setErrorFields}
          />
          <FraudServices
            accountDetails={accountDetails}
            setAccountDetails={setAccountDetails}
          />
          <ProductServices
            accountDetails={accountDetails}
            setAccountDetails={setAccountDetails}
          />
        </CardBody>
        <CardFooter>
          <div className="d-flex align-items-center justify-content-end gap-2">
            <ButtonIcon color="white" onclick={handleClose} label="Cancel" />
            <ButtonIcon
              color="primary"
              disabled={
                !accountDetails?.accountName ||
                !accountDetails?.accountNumber ||
                accountDetails?.operatingAccountType?.key === -1 ||
                !accountDetails.accountType
              }
              loading={loader}
              onclick={handleSave}
              label="Save"
            />
          </div>
        </CardFooter>
      </Card>
    </RightSlidingDrawer>
  );
};

const useAddNewAccountModal = (currentAccount, accounts, setAccounts) => {
  const [showModal, setShowModal] = useState(false);
  const AddNewAccountModalCallback = useCallback(() => {
    return (
      <AddNewAccountModal
        show={showModal}
        currentAccount={currentAccount}
        setShow={setShowModal}
        accounts={accounts}
        setAccounts={setAccounts}
      />
    );
  }, [showModal, setShowModal, accounts, currentAccount, setAccounts]);

  return useMemo(
    () => ({
      setShowModal,
      AddNewAccountModal: AddNewAccountModalCallback,
    }),
    [setShowModal, AddNewAccountModalCallback]
  );
};

export default useAddNewAccountModal;
