import { Dropdown, Form, FormControl } from 'react-bootstrap';
import ButtonIcon from './ButtonIcon';
import React, { useEffect, useRef, useState } from 'react';
import MaterialCheckbox from './MaterialCheckbox';
import useOutsideClickDropDown from '../../hooks/useOutsideClickDropDown';
import NoDataFound from './NoDataFound';
import NoDataFoundTitle from '../fields/NoDataFoundTitle';
import TextOverflowTooltip from './TextOverflowTooltip';

const SearchOptionDropdown = ({
  data = [],
  show,
  label = 'options',
  setShow,
  selectedData,
  customClass,
  customKey = 'key',
  setSelectedData,
  handleOptionSelected,
  handleAllSelect = (a, b) => {},
  search,
  scrollable,
  dontClose,
  borderClasses,
  dataReadKey = 'name',
  overflow,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const renderDropdownTitle = () => {
    if (
      selectedData?.length === 0 ||
      (selectedData?.length === 1 && selectedData[0] === '')
    ) {
      return `Select ${label}`;
    } else if (selectedData?.length === 1) {
      return `1 ${label.slice(0, label.length - 1)} selected`;
    } else {
      return `${selectedData?.length} ${label} selected`;
    }
  };
  const menuRef = useRef(null);
  useOutsideClickDropDown(menuRef, show, setShow);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    setSelectAll(selectedData?.length === data.length);
    if (selectedData?.length === data?.length || selectedData?.length === 0) {
      !dontClose && setShow(false);
    }
  }, [selectedData, data]);

  const filteredData = () => {
    return data?.filter((item) =>
      item[dataReadKey]?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  return (
    <Dropdown show={show} onToggle={setShow} className={customClass}>
      <Dropdown.Toggle
        variant="white"
        as="div"
        className={`w-100 btn btn-white dropdown-toggle cursor-pointer custom-toggle-caret ${borderClasses}`}
        id="dropdown-basic"
      >
        {renderDropdownTitle()}
      </Dropdown.Toggle>

      <Dropdown.Menu ref={menuRef} className="w-100 py-0">
        <div className="d-flex h-100 flex-column">
          <div
            onClick={() => {
              const checked = !selectAll;
              setSelectAll(checked);
              const allSelected = data.map((rpt) => rpt[customKey]);
              if (checked) {
                setSelectedData(allSelected);
              } else {
                setSelectedData([]);
              }
              handleAllSelect(checked, checked ? allSelected : []);
            }}
            className={`pt-3 border-bottom pl-1 pb-2 bg-hover-gray cursor-pointer d-flex justify-content-between ${scrollable}`}
          >
            <div className="d-flex align-items-center gap-1">
              <span>&nbsp;&nbsp;</span>
              <span className="font-size-sm2 font-weight-medium">
                Select All
              </span>
            </div>
            <MaterialCheckbox checked={selectAll} />
          </div>
          <Form className="p-0 flex-grow-1 menu-labels">
            {search && (
              <div className="p-3">
                <FormControl
                  value={searchQuery}
                  placeholder={`Search ${label}`}
                  type="search"
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            )}
            {filteredData()?.length > 0 ? (
              filteredData().map((rpt) => (
                <div
                  key={rpt.key}
                  onClick={() => {
                    setSelectAll(false);
                    handleOptionSelected({
                      target: { value: rpt[customKey] },
                    });
                  }}
                  className="py-2 d-flex cursor-pointer bg-hover-gray align-items-center justify-content-between px-3"
                >
                  <span className="fs-7 font-weight-mediumsetSelectedData text-align-left">
                    <>
                      {overflow ? (
                        <TextOverflowTooltip text={rpt[dataReadKey]} />
                      ) : (
                        <>{rpt[dataReadKey]}</>
                      )}
                    </>
                  </span>
                  <MaterialCheckbox
                    checked={selectedData?.includes(rpt[customKey])}
                  />
                </div>
              ))
            ) : (
              <NoDataFound
                icon="search"
                iconRounded={true}
                iconStyle="font-size-2em"
                containerStyle="text-gray-search mt-2 pt-2 mb-1"
                title={
                  <NoDataFoundTitle
                    clazz="fs-7 mb-0"
                    str={`No ${label} found`}
                  />
                }
              />
            )}
          </Form>
          <div className="p-3 border-top">
            <ButtonIcon
              label="Done"
              color="primary"
              type="button"
              classnames="btn-block btn-sm"
              onclick={() => setShow(false)}
            />
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SearchOptionDropdown;
