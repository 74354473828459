import React, { Fragment, useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import ButtonIcon from '../../commons/ButtonIcon';
import { Form } from 'react-bootstrap';
import MaterialIcon from '../../commons/MaterialIcon';
import activityService from '../../../services/activity.service';
import Avatar from '../../Avatar';
import feedService from '../../../services/feed.service';
import { getClientPortalToken } from '../../../layouts/constants';
import { ShortDescription } from '../../ShortDescription';

const DAYS = [
  { id: 7, text: 'sunday', isSelected: false },
  { id: 1, text: 'monday', isSelected: false },
  { id: 2, text: 'tuesday', isSelected: false },
  { id: 3, text: 'wednesday', isSelected: false },
  { id: 4, text: 'thursday', isSelected: false },
  { id: 5, text: 'friday', isSelected: false },
  { id: 6, text: 'saturday', isSelected: false },
];
const scheduleCallExtraOptions = [
  {
    id: 'schedule-option-1',
    icon: 'location_on',
    description: 'Details provided upon confirmation',
  },
];

const ModalScheduleCallForm = (props) => {
  const [days, setDays] = useState(DAYS);
  const [value, setValue] = useState([]);
  const [notes, setNotes] = useState('');
  const [requesting, setRequesting] = useState(false);
  const [scheduleCallOptions, setScheduleCallOptions] = useState(
    scheduleCallExtraOptions
  );
  const clientPortalToken = getClientPortalToken();
  const organizationId = clientPortalToken.resource_access?.organization[0]?.id;
  const profileInfo = clientPortalToken.shared_by;

  useEffect(() => {
    if (!props.data?.phone) {
      const newScheduleOptions = [
        ...scheduleCallOptions,
        {
          id: 'schedule-option-3',
          icon: 'call',
          description: props.data?.phone,
        },
      ];

      setScheduleCallOptions(newScheduleOptions);
    }
  }, [props.data]);

  const initForm = () => {
    setDays(
      [...days].map((d) => ({ ...d, isSelected: d.id === new Date().getDay() }))
    );
    setValue(value);
    setNotes('');
  };

  useEffect(() => {
    // get current day from date and select in UI
    setDays(
      [...days].map((d) => ({ ...d, isSelected: d.id === new Date().getDay() }))
    );
  }, []);

  const handleRequestCall = async () => {
    setRequesting(true);
    try {
      const requestPayload = {
        availability: {
          days: ['monday'],
          timePeriods: ['morning'],
        },
        notes,
      };
      await activityService.createActivityRequest(requestPayload);
      await feedService.createNote(
        notes,
        {
          blocks: [
            {
              key: crypto.randomUUID(),
              text: notes,
              type: 'unstyled',
              depth: 0,
              inlineStyleRanges: [],
              entityRanges: [],
              data: {},
            },
          ],
          entityMap: {},
        },
        null,
        organizationId,
        null,
        null,
        null,
        profileInfo?.tenant_id,
        profileInfo?.id,
        profileInfo?.id
      );
      initForm();
      props.onModalSuccessMsg();
    } catch (e) {
      console.log(e);
    } finally {
      setRequesting(false);
    }
  };

  return (
    <Fragment>
      <Modal
        {...props}
        size="lg"
        backdrop="static"
        keyboard={false}
        aria-labelledby="request_call"
        centered
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton className="p-3">
          <Modal.Title id="request_call">
            Contact Your Representative
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3 pb-3 pt-0">
          <div className="modal-request-call">
            <div className="row gy-2">
              <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <div className="bg-gray-5 rounded border d-flex flex-column justify-content-center h-100">
                  <div className="d-flex flex-column mb-1 justify-content-center align-items-center">
                    <div className="mb-2">
                      <Avatar user={props.data} defaultSize="md" />
                    </div>
                    <div className="text-center">
                      {props.data?.name && (
                        <h2 className="mb-0">{props.data?.name}</h2>
                      )}
                      {props.data?.title && (
                        <p className="text-muted fs-7 mb-0">
                          {props.data?.title}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="px-4">
                    {props.data?.phone && (
                      <p className="d-flex align-items-start text-left gap-2 mb-1">
                        <MaterialIcon
                          clazz={'text-primary fs-5'}
                          icon="phone"
                          rounded
                          filled
                        />
                        <span className="font-size-sm2">
                          {props.data?.phone}
                        </span>
                      </p>
                    )}
                    {props.data?.email && (
                      <p className="d-flex align-items-start text-left gap-2 mb-0">
                        <MaterialIcon
                          clazz={'text-primary fs-5'}
                          icon="mail"
                          rounded
                          filled
                        />
                        <span className="font-size-sm2">
                          <ShortDescription
                            content={props.data?.email}
                            limit={25}
                          />
                        </span>
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-xxl-7 pl-0 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <div className="d-flex flex-column gap-2 w-100 h-100">
                  <Form.Control
                    as="textarea"
                    className="rounded flex-fill"
                    style={{ height: 300 }}
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    placeholder="Question or Feedback"
                  />
                  <ButtonIcon
                    icon=""
                    color="primary"
                    classnames="btn-md btn-block"
                    label="Contact"
                    loading={requesting}
                    disabled={!notes?.length}
                    onClick={handleRequestCall}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ModalScheduleCallForm;
