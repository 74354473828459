export const WidgetTypes = {
  QRCode: 'QRCode', // the one that will have text on left and qrcode on right
  Icon: 'Icon', // the one with icon on right side, single icon with or without shield as bg
  IconSet: 'IconSet', // will add more as the dev goes on, where we want to add bunch of icons
  EPG: 'EPG', // electronic payments for growth
  IVR: 'IVR', // Improve Vendor Relationships = Electronic Payments (now) :\
  HSW: 'HSW', // Hours spent weekly = Automating Accounts Receivable (now) :\
  BEC: 'BEC', // Business Email Compromise
  EPS: 'EPS', // Electronic Payments =  Savings
  PEO: 'PEO', // Paper to Electronic Opportunity
  ESTPR: 'ESTPR', // Estimated Payable Receivable
  DPO: 'DPO', // Days Payable Outstanding
  DSO: 'DSO', // Days Sales Outstanding
  BAR: 'BAR', // Balances at Risk
  BAEC: 'BAEC', // Balances and Earnings Credit,
  GLOSS: 'GLOSS', // Glossary
  BLANK: 'BLANK',
  AAR: 'AAR', // Automating Accounts Receivables
  CPMR: 'CPMR', // Check Payments = More Risk
  CBPR: 'CBPR', // International Banking: Cross-Boarder Payments
  PFS: 'PFS', // International Banking: Paying Foreign Suppliers
  ICF: 'ICF', // Improve Your Cash Flow: Working Capital Report
  TODD: 'TODD', // The One Day Difference: Working Capital Report
  UWC: 'UWC', // Understanding Working Capital: Working Capital Report
  APO: 'APO', // Accounts Payable/Receivable Overview: Working Capital Report
  OTP: 'OTP', // DPO/DSO Opportunity to Improve: Working Capital Report
  FTI: 'FTI', // Factors that impact DPO/DSO: Working Capital Report,
  LOC: 'LOC', // Line of Credit: Working Capital Report
  UIE: 'UIE', // Utilization and Interest Expense: Working Capital Report
  RTM: 'RTM', // Insights: The Right Treasury Management Solutions: Working Capital Report
  IP: 'IP', // Instant Payments
  IVREP: 'IVREP', // Improve Vendor Relationship Electronic Payments
  PC: 'PC', // Payment Comparison
  PF: 'PF', // Payment Fraud Continues To Be A Significant Risk
  HSWR: 'HSWR', // Hours Spent Weekly On Reconciling Payments
  FSE: 'FSE', // How Can We Help, Solutions to Empower - Fraud Report
  FBAR: 'FBAR', // Balances at Risk - Fraud Report,
  PFIB: 'PFIB', // Payment Fraud Impacts All Businesses - Fraud Report
  VCW: 'VCW', // Total Active Vendors & Total $ of Payments
  PSW: 'PSW', // Today and Future payments stats
  YBW: 'YBW', // Your Benefits Widget
  VAP: 'VAP', // Vendor Analysis Phases
  AC: 'AC', // Acceptance Conditions
  IVREPH: 'IVREPH',
  FP: 'FP', // Future Plans
  PT: 'PT', // Payment Trends and Payment Types Charts
};

const FactorsWidth = 70;
export const FraudWhatYouCanDoHeading = 'Fraud - What You Can Do';
export const PFIBData = {
  heading: 'Payment Fraud Impacts All Businesses',
  items: [
    {
      id: 1,
      icon: 'zone_person_urgent',
      style: 'rpt-red-box-heading',
      variant: 'rpt-red',
      value: 65,
      text: `<b>65% of organizations</b> surveyed were victims of payment fraud attacks or attempts in the last twelve months.`,
    },
    {
      id: 2,
      icon: 'currency_exchange',
      style: 'rpt-orange-box-heading',
      variant: 'rpt-orange',
      value: 10,
      text: `The majority of these organizations recoup <b>less than 10%</b> of funds stolen due to fraud.`,
    },
  ],
  source: 'The Association for Financial Professionals',
};

export const FraudSolutionToEmpowerData = {
  heading: 'How We Can Help',
  description: `Solutions that empower you to navigate and secure your financial journey.`,
};

export const SimplifyingFraudData = {
  heading: 'Help Your Business Fight Fraud',
  oldHeading: 'Simplifying Fraud Prevention For Your Business',
  videoId: 'rysf9558p1',
  videoDuration: '8 min',
  channel: 'https://fast.wistia.com/embed/channel/rysf9558p1',
  description: `<p>Watch and share our bite-sized playlist of 3 videos designed to educate you and your team on the impacts of fraud, the common tactics used by criminals, and actionable steps you can take to safeguard your business against financial threats.</p>`,
};

export const HSWRData = {
  heading: 'Hours Spent Weekly On Reconciling Payments',
  description: 'Automate accounts receivable to save time and money.',
  cutoutHeading: 'Time Breakdown of Survey Results',
  barData: [
    {
      key: '< 1 hr',
      value: 5,
      percentage: '34%',
      color: '65%',
      multiply: 17,
    },
    {
      key: '1 - 3 hrs',
      value: 5.2,
      percentage: '36%',
      color: '50%',
      multiply: 17,
    },
    {
      key: '4 - 6 hrs',
      value: 3,
      percentage: '14%',
      color: '85%',
      multiply: 17,
    },
    {
      key: '> 6 hrs',
      value: 3.3,
      percentage: '16%',
      color: '75%',
      multiply: 17,
    },
  ],
  source: 'The Association for Financial Professionals',
};

export const PFData = {
  heading: 'B2B Fraud by Payment Type',
  description:
    'Check payments are the most vulnerable to fraud, with 63% of survey respondents reporting check fraud compared to only 9% for virtual card payments.',
  source: 'The Association for Financial Professionals',
};

export const PCData = {
  heading: 'Payment Comparison',
  paymentComparison: [
    {
      header: null,
      items: ['Average Cost', 'Funding', 'Risk of Fraud', 'Reconciliation'],
    },
    {
      header: { icon: 'credit_card', title: 'Virtual Card' },
      items: ['$0.30', 'Next Day', 'Lowest', 'Fastest'],
      bg: 'bg-primary-soft',
    },
    {
      header: { icon: 'account_balance', title: 'ACH' },
      items: ['$0.25', 'Next Day', 'Low', 'Faster'],
    },
    {
      header: { icon: 'price_change', title: 'Instant Payments' },
      items: ['$2.00', 'Instant', 'Low', 'Fastest'],
      bg: 'bg-primary-soft',
    },
    {
      header: { icon: 'checkbook', title: 'Check' },
      items: ['$2.98', '4 Days', 'Highest', 'Manual'],
    },
  ],
  source: 'The Association for Financial Professionals',
};

export const IPData = {
  heading: 'Instant Payments',
  description:
    'Organizations are using faster payments to support a variety of transactions to meet customer demands.',
  cutoutHeading: 'The Industry is Moving',
  demands: [
    { icon: 'location_city', label: 'B2B Payments' },
    { icon: 'order_approve', label: 'Bill Pay' },
    { icon: 'currency_exchange', label: 'Disbursements' },
    { icon: 'universal_currency', label: 'Payroll' },
    { icon: 'move_up', label: 'Wire Transfers' },
  ],
  percentageLabels: [
    { percentage: '44%', labels: ['are implementing', 'FedNow'] },
    {
      percentage: '62%',
      labels: ['have implemented or are', 'implementing RTP'],
    },
    {
      percentage: '86%',
      labels: ['have implemented or are', 'implementing Same Day ACH'],
    },
  ],
  source: 'US Faster Payments Council',
};

export const IVREPData = {
  heading: 'Improve Vendor Relationships',
  description: "Vendor's increasingly favor electronic payments.",
  blockData: [
    'Faster Receipt of Payment',
    'Enhanced Remittance to Improve Reconciliation',
    'More Secure Than Checks',
  ],
};

export const LOCData = {
  heading: 'Line of Credit',
  description:
    'Let’s take a look at your average credit utilization over the past 12 months.',
};

export const UIEData = {
  heading: 'Utilization and Interest Expense',
  expensesInsights: [
    {
      id: 2,
      icon: 'arrow_circle_up',
      text: 'Peak Utilization',
      key: 'peakUtilization',
      value: 0,
      borderClasses: 'border-right border-bottom',
    },
    {
      id: 3,
      icon: 'arrow_circle_down',
      text: 'Low Utilization',
      key: 'lowUtilization',
      value: 0,
      borderClasses: 'border-bottom',
    },
    {
      id: 4,
      icon: 'event_note',
      text: 'Average Monthly Utilization',
      key: 'avgMonthlyUtilization',
      value: 0,
      borderClasses: 'border-right',
    },
  ],
  expenses: [
    {
      id: 1,
      icon: 'export_notes',
      text: 'Line Limit',
      key: 'lineLimit',
      value: 0,
      borderClasses: 'border-right border-bottom',
    },
    {
      id: 2,
      icon: 'arrow_circle_up',
      text: 'Peak Utilization',
      key: 'peakUtilization',
      value: 0,
      borderClasses: 'border-right border-bottom',
    },
    {
      id: 3,
      icon: 'arrow_circle_down',
      text: 'Low Utilization',
      key: 'lowUtilization',
      value: 0,
      borderClasses: 'border-bottom',
    },
    {
      id: 4,
      icon: 'event_note',
      text: 'Average Monthly Utilization',
      key: 'avgMonthlyUtilization',
      value: 0,
      borderClasses: 'border-right',
    },
    {
      id: 5,
      icon: 'heap_snapshot_large',
      text: 'Average Annual Rate',
      key: 'avgAnnualRate',
      percentage: true,
      value: 0,
      borderClasses: 'border-right',
    },
    {
      id: 6,
      icon: 'monetization_on',
      text: 'Total Interest Expense',
      key: 'totalInterestExpense',
      value: 0,
    },
  ],
};
export const RTMData = {
  heading: 'Insights: The Right Treasury Management Solutions',
  icon: 'lightbulb',
};

export const DPOFTIData = {
  heading: 'Factors That Impact DPO',
  extraType: 'DPO',
  description:
    'In your accounts payable process there are several solutions and strategies that you can use to impact DPO.',
  factors: [
    {
      icon: 'credit_card',
      heading: 'Commercial Card',
      width: FactorsWidth,
      isChecked: true,
    },
    {
      icon: 'event',
      heading: 'Early Pay Discounts',
      width: FactorsWidth,
      isChecked: true,
    },
    {
      icon: 'acute',
      heading: 'Faster Payments',
      width: FactorsWidth,
      isChecked: true,
    },
  ],
};

export const DSOFTIData = {
  heading: 'Factors That Impact DSO',
  extraType: 'DSO',
  description:
    'In your accounts receivable process there are several solutions and strategies that you can use to impact DSO.',
  factors: [
    {
      icon: 'credit_card',
      heading: 'Card Acceptance',
      width: FactorsWidth,
      isChecked: true,
    },
    {
      icon: 'event',
      heading: 'Offering Early Pay Discounts',
      width: 110,
      isChecked: true,
    },
    {
      icon: 'acute',
      heading: 'Accepting Faster Payments',
      width: 130,
      isChecked: true,
    },
  ],
};

export const DPODSOFactors = {
  DPO: DPOFTIData.factors,
  DSO: DSOFTIData.factors,
};

export const DPOTPData = {
  heading: 'DPO Opportunity to Improve',
  extraType: 'DPO',
  description:
    'Let’s see how your DPO compares to the industry average and the best-in-class.',
  powerAndPotential: [
    {
      icon: 'calendar_clock',
      heading: 'The Power <br /> of One Day',
      description: 'A single day of improvement will free-up cash flow of:',
      xValue: 'oneDayDPODiff',
    },
    {
      icon: 'event',
      heading: 'The Potential of <br /> Best-In-Class',
      description:
        'An improvement of (bestInClassDPO) days will free-up cash flow of:',
      xValue: 'bestInClassDPO',
    },
  ],
};

export const DSOTPData = {
  heading: 'DSO Opportunity to Improve',
  extraType: 'DSO',
  description:
    'Let’s see how your DSO compares to the industry average and the best-in-class.',
  powerAndPotential: [
    {
      icon: 'calendar_clock',
      heading: 'The Power <br /> of One Day',
      description: 'A single day of improvement will free-up cash flow of:',
      xValue: 'oneDayDSODiff',
    },
    {
      icon: 'event',
      heading: 'The Potential of <br /> Best-In-Class',
      description:
        'An improvement of bestInClassDSO days will free-up cash flow of:',
      xValue: 'bestInClassDSO',
    },
  ],
};

export const APOData = {
  heading: 'Days Payable Outstanding (DPO)',
  valueText: 'Your DPO',
  extraType: 'DPO',
  description: `<p>On average, it takes your organization $X days to settle bills with suppliers.</p><p class="mb-0">By extending DPO, you’re holding on to your cash longer, potentially reducing  the need to borrow and improving your working capital.</p>`,
};
export const AROData = {
  heading: 'Days Sales Outstanding (DSO)',
  valueText: 'Your DSO',
  extraType: 'DSO',
  description: `<p>On average, it takes your organization $X days to receive payment for a sale.</p><p class="mb-0">DSO can be decreased by collecting from your clients faster. This has a positive effect on working capital and cash available for debt repayment or investment.</p>`,
};
export const UWCData = {
  heading: 'Understanding Working Capital',
  videoId: '2qtb05b8vw',
  videoDuration: '2 min',
  description: `<p>When you unlock cash from accounts payable and accounts receivable, it positively impacts your working capital.</p> <p>This allows you to pay down loans, invest in growth initiatives or technology, or move money into higher earning investments.</p>`,
};

export const TODDData = {
  heading: 'The One Day Difference',
  description: `<p class="rpt-print">By improving your accounts payable and accounts receivable processes, your organization will have more cash available.</p>
        <p>This enables your organization to invest in short term assets, borrow less on a line of credit, and ultimately, grow your business.</p>`,
};

export const ICFData = {
  heading: 'Improve Your Cash Flow',
  description:
    'Days payable outstanding (DPO) and days sales outstanding (DSO) are two critical indicators of healthy working capital management.',
  moreDescription: [
    'Based on the data provided, you could unlock $X by improving to the industry average.',
    'Based on the data provided, you are performing better than your industry peer group. However, improving your DPO and DSO can still have a positive impact on your working capital, allowing you to become best-in-class.',
  ],
};

export const FraudPreventData = {
  heading: 'What You Can Do To Prevent Fraud',
  description:
    'A robust fraud prevention protocol combining education, tools, and technology is crucial for safeguarding your business.',
  dos: [
    {
      text: 'Verify using contact <br /> information on file',
      icon: 'contact_phone',
      rounded: false,
      filled: true,
    },
    {
      text: 'Validate urgent requests <br /> before acting',
      icon: 'paid',
      rounded: false,
      filled: true,
    },
    {
      text: 'Enable two-factor <br /> authentication',
      icon: 'deployed_code_account',
      rounded: true,
      filled: false,
    },
  ],
  donts: [
    {
      text: 'Verify source before <br /> moving data/funds',
      icon: 'person_check',
      rounded: true,
      filled: false,
    },
    {
      text: 'Avoid clicking unknown <br /> links/attachments',
      icon: 'attach_email',
      rounded: false,
      filled: true,
    },
    {
      text: 'Verify emails with <br /> contact on file',
      icon: 'contacts',
      rounded: false,
      filled: true,
    },
  ],
};

export const AARData = {
  heading: 'Automating Accounts Receivables',
  description:
    'Over 55% of companies have largely or fully automated their accounts receivable reconciliation. Enhancing automation accelerates receipt posting and reduces days sales outstanding, positively impacting your working capital.',
  source: `Accenture Commercial Banking Treasury Management Survey 2022.
        <a
          class="text-decoration-underline decoration-underline text-muted"
          target="_blank"
          rel="external noreferrer"
          href="https://www.accenture.com/content/dam/accenture/final/industry/banking/document/Accenture-Commercial-Banking-Treasury-Management.pdf"
        >
          https://www.accenture.com/content/dam/accenture/final/industry/banking/document/Accenture-Commercial-Banking-Treasury-Management.pdf
        </a>`,
};

export const FraudMitigationData = {
  heading: 'Fraud Mitigation',
  videoId: '7329bypgjt',
  extraType: 'Fraud',
  videoDuration: '3 min',
  description:
    "<p>65% of companies have experienced a fraud attempt in the last 12 months.</p> <p>Learn how to combat business fraud effectively by learning common check and ACH fraud tactics, utilizing tools like Positive Pay, and implement a robust fraud prevention strategy to safeguard your company's financial well-being.</p>",
  source: 'The Association for Financial Professionals',
};
export const DSOCashFlowData = {
  heading: 'Days Sales Outstanding (DSO)',
  icons: ['wallet', 'monetization_on', 'account_balance'],
  description:
    '<p class="mb-0">Accelerate Cash Flow with Expanded Payment Methods. To enhance your working capital and streamline receivables collections, consider expanding your payment methods. Offering customers a variety of payment options can significantly reduce your Days Sales Outstanding (DSO) and optimize your working capital cycle.</p>',
};
export const HoursSpentWeeklyData = {
  heading: 'Automating Accounts Receivable',
  description:
    'Over 55% of companies have automated their accounts receivable reconciliation.',
  description2:
    'Over 55% of companies have largely or fully automated their accounts receivable reconciliation. Enhancing automation accelerates receipt posting and reduces days sales outstanding, positively impacting your working capital.',
  cutoutHeading: 'Benefits Include',
  blockData: [
    'Better Visibility',
    'Happier Customers',
    'Cost Savings',
    'Improved Accuracy',
    'Decreased Fraud',
  ],
  source: 'The Association for Financial Professionals',
};
export const ImproveVendorRelationshipData = {
  heading: 'Electronic Payments',
  cutoutHeading: 'Benefits Include',
  description:
    'Faster payment types like Card, Automated Clearing House (ACH), and Instant Payments improve financial management.',
  blockData: [
    'Improved working capital',
    'Enhanced security',
    'Fast and accurate reconciliation',
    'Reduced cost',
    'Incentives for early payment',
    'More convenience',
  ],
};
export const ElectronicPaymentsToGrowthData = {
  heading: 'Companies are Prioritizing the Move to Electronic Payments',
  source: `The Association for Financial Professionals`,
  description:
    '<p>Migrating to electronic payments can help you maintain your competitive advantage.</p> <p class="mb-0">Seventy-three percent of organizations surveyed are currently in the process of transitioning their B2B payments from paper checks to electronic payments.</p>',
};

export const ElectronicPaymentsSavingsData = {
  heading: 'Check Payments = Higher Cost + Poor Vendor Experience + More Risk',
  cutoutHeading: 'Cost Comparison',
  description: [
    'Require manual processing, check stock management, and postage.',
    'Payees dislike manual processing and lack of remittance data.',
    'Experience the highest fraud rate of any payment type.',
  ],
  costComparison: [
    { big: '$2.98', small: 'Cost Per Check' },
    { big: '$0.30', small: 'Cost Per Card Transaction' },
    { big: '$0.25', small: 'Cost Per ACH Transaction' },
  ],
  source: 'The Association for Financial Professionals',
};
export const VendorsCountData = {
  data: {},
  items: [
    {
      title: 'Total Active Vendors',
      key: 'activeVendors',
      value: 0,
    },
    {
      title: 'Total $ of Payments',
      key: 'paymentAmount',
      value: 0,
    },
  ],
};

export const PaymentStatsTypes = {
  Checks: 'Checks',
  Wire: 'Wire Transfer',
  Ach: 'ACH Payment',
  Card: 'Card',
};
export const PaymentStatsData = {
  data: {},
  items: [
    {
      type: PaymentStatsTypes.Checks,
      icon: 'article',
      today: {
        payments: 0,
        amount: 0,
      },
      future: {
        payments: 0,
        amount: 0,
        trend: 'down',
      },
    },
    {
      type: PaymentStatsTypes.Wire,
      icon: 'swap_horiz',
      today: {
        payments: 0,
        amount: 0,
      },
      future: {
        payments: 0,
        amount: 0,
        trend: 'neutral',
      },
    },
    {
      type: PaymentStatsTypes.Ach,
      icon: 'account_balance_wallet',
      today: {
        payments: 0,
        amount: 0,
      },
      future: {
        payments: 0,
        amount: 0,
        trend: 'up',
      },
    },
    {
      type: PaymentStatsTypes.Card,
      icon: 'credit_card',
      today: {
        payments: 0,
        amount: 0,
      },
      future: {
        payments: 0,
        amount: 0,
        trend: 'up',
      },
    },
  ],
};

export const YourBenefitsData = {
  header: 'Your benefits',
  benefits: [
    [
      {
        icon: 'savings',
        benefit: 'Cost savings by moving to electronic payments from check.',
      },
      {
        icon: 'data_usage',
        benefit:
          'Increased access to remittance data to make reconciliation easier.',
      },
    ],
    [
      {
        icon: 'security',
        benefit: 'Much lower likelihood of attempted fraud.',
      },
      {
        icon: 'trending_up',
        benefit: 'Example Revenue Share - $10,536 in revenue share at 100 bps.',
      },
    ],
  ],
};

export const VendorAnalysisPhasesData = {
  data: {},
  items: [
    {
      title: 'Phase I - Virtual Card Matches',
      description:
        'Virtual card matches denote that these vendors have accepted a virtual card from other payors. They will require minimal work to onboard if they accept virtual card from you.',
      venderCountKey: 'venderCountKey0',
      totalSpentKey: 'totalSpentKey0',
      expectedConversationKey: 'expectedConversationKey0',
      accentColor: '#DFF0DD',
      bg: 'green',
      fg: 'text-green',
    },
    {
      title: 'Phase II - "Qualified" Matches',
      description:
        'Qualified matches denote that we\'ve on a name or more, but more work will need to be done to onboard these onto your program. This is where we will spend a majority of time on our "campaign" to start.',
      venderCountKey: 'venderCountKey1',
      totalSpentKey: 'totalSpentKey1',
      expectedConversationKey: 'expectedConversationKey1',
      accentColor: '#FCE9D4',
      bg: 'orange',
      fg: 'text-orange',
    },
    {
      title: 'Phase III - Strategic Outreach',
      description:
        'Strategic Outreach matches include ACH to Card opportunity, those vendors with incomplete, or specified categories that typically do not accept (individuals, education, insurance, etc.). We will review these after "qualified."',
      venderCountKey: 'venderCountKey2',
      totalSpentKey: 'totalSpentKey2',
      expectedConversationKey: 'expectedConversationKey2',
      accentColor: '#FFD6D6',
      bg: 'red',
      fg: 'text-red',
    },
  ],
};

export const AcceptanceConditionsData = {
  header: 'Best Practices for Supplier Acceptance',
  conditions: [
    'Align supplier outreach with payment cycles and system readiness to maximize the effectiveness of each call.',
    'Make the most of supplier interactions by discussing electronic payment options during contract renewal or when the supplier calls about a missing check.',
    'Reach out to suppliers regularly; those previously unable to accept card may be capable.',
    'Partner with non-accepting suppliers that are interested in the benefits of card acceptance.',
  ],
};

export const IVREPHData = {
  header: 'Improve Vendor Relationships',
  subHeader:
    'Vendors increasingly favor electronic payments due to their universal acceptance and benefits.',
  improvements: [
    {
      icon: 'security',
      text: 'Enhanced security over other payment type',
    },
    {
      icon: 'speed',
      text: 'Improved cash flow with faster receipt of payment',
    },
    {
      icon: 'compare_arrows',
      text: 'Enhanced remittance to improve reconciliation',
    },
  ],
};

export const FPData = [
  {
    icon: 'assignment',
    title: 'Define Scope',
    description:
      'Recommend solutions that fit with your business strategy and identify automation opportunities to drive efficiency.',
  },
  {
    icon: 'analytics',
    title: 'Analyze Data',
    description:
      'Create a goal and a plan to reach that goal leveraging our experience in accounts payable transformation.',
  },
  {
    icon: 'handshake',
    title: 'Partner',
    description:
      'Align our subject matter experts with your allocated resources to execute a successful onboarding campaign.',
  },
  {
    icon: 'trending_up',
    title: 'Maximize',
    description:
      "Provide ongoing support to identify new card opportunities optimizing your card program's effectiveness throughout its lifecycle.",
  },
];

export const PaymentTrendsData = {
  title: 'Payment Trends and Vendor Benefits',
  subtitle: '2022 AFP Digital Payments Survey',
  lineChartData: {
    labels: ['2004', '2007', '2010', '2013', '2016', '2019', '2020'],
    data: [75, 64, 54, 50, 44, 36, 30],
    description: "Percentage of Organizations' B2B Payments Received by Check",
  },
  barChartData: {
    title:
      'Payment types corporations report on moving more payments to in the next year.',
    labels: ['ACH', 'Virtual Card', 'Real-Time Payments (RTP)', 'Same-Day ACH'],
    labelsPdf: ['ACH', 'Virtual Card', 'RTP', 'Same-Day ACH'],
    data: [61, 28, 27, 23],
  },
  infoBox: {
    icon: 'credit_card',
    text: 'Companies continue to invest in electronic payment methods to automate AP processes and improve vendor relationships.',
  },
};

export const VerticalWidgets = [
  {
    id: 10,
    widgetConfig: {
      ...UWCData,
    },
    type: WidgetTypes.QRCode,
  },
  {
    id: 11,
    widgetConfig: {
      ...FraudMitigationData,
    },
    type: WidgetTypes.QRCode,
  },
];

export const HorizontalWidgets = [
  {
    id: 1,
    widgetConfig: ElectronicPaymentsSavingsData,
    type: WidgetTypes.EPS,
  },
  {
    id: 2,
    widgetConfig: ImproveVendorRelationshipData,
    type: WidgetTypes.IVR,
  },
  {
    id: 3,
    widgetConfig: FraudPreventData,
    type: WidgetTypes.BEC,
  },
  {
    id: 4,
    widgetConfig: HoursSpentWeeklyData,
    type: WidgetTypes.HSW,
  },
  {
    id: 5,
    widgetConfig: IPData,
    type: WidgetTypes.IP,
  },
  {
    id: 6,
    widgetConfig: IVREPData,
    type: WidgetTypes.IVREP,
  },
  {
    id: 7,
    widgetConfig: PCData,
    type: WidgetTypes.PC,
  },
  {
    id: 8,
    widgetConfig: HSWRData,
    type: WidgetTypes.HSWR,
  },
  {
    id: 9,
    widgetConfig: PFData,
    type: WidgetTypes.PF,
  },
  {
    id: 10,
    widgetConfig: {
      ...UWCData,
    },
    type: WidgetTypes.QRCode,
  },
  {
    id: 11,
    widgetConfig: {
      ...FraudMitigationData,
    },
    type: WidgetTypes.QRCode,
  },
  {
    id: 12,
    widgetConfig: {
      ...PFIBData,
    },
    type: WidgetTypes.PFIB,
  },
];
