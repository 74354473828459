import { CardBody } from 'reactstrap';
import { transformTo2D } from '../../../../utils/Utils';
import TenantThemeWrapperWidget from './TenantThemeWrapperWidget';

const AcceptanceConditionsWidget = ({ widgetConfig, whenPrinting }) => {
  const conditions = transformTo2D(widgetConfig.conditions);
  return (
    <TenantThemeWrapperWidget bodyStyles="d-flex flex-column position-relative border-0">
      <div>
        <div className="d-flex align-items-start">
          <h5>{widgetConfig.header}</h5>
        </div>
        {conditions.map((row, rowIndex) => (
          <div
            className={`d-flex py-0 gap-2 ${
              rowIndex < conditions.length - 1
                ? 'rpt-border-bottom-primary'
                : ''
            }`}
            key={rowIndex}
          >
            {row.map((item, itemIndex) => (
              <CardBody
                key={itemIndex}
                style={{ borderWidth: 'var(--rpt-widget-border-thickness)' }}
                className={`d-flex align-items-center flex-1 ${
                  itemIndex < row.length - 1 ? 'rpt-border-right-primary' : ''
                } ${whenPrinting ? 'p-0' : ''} `}
              >
                <span
                  className={`font-weight-semi-bold ${
                    whenPrinting ? 'fs-10 sub-text' : 'font-size-sm2'
                  } text-center`}
                >
                  {item}
                </span>
              </CardBody>
            ))}
          </div>
        ))}
      </div>
    </TenantThemeWrapperWidget>
  );
};

export default AcceptanceConditionsWidget;
