import moment from 'moment';
import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { Dropdown } from 'react-bootstrap';
import { endOfDay } from 'date-fns';

import { DATE_FORMAT, setDateFormat } from '../../utils/Utils';
import ButtonFilterDropdown from '../commons/ButtonFilterDropdown';
import { applyReportDateRange, defineds, staticRanges } from './utils';

const DatePickerTraining = ({
  range,
  setRange,
  disablePastDate,
  disableDefaultRange,
  extraClass = '',
}) => {
  const [dateRange, setDateRange] = useState({
    startDate:
      range && range.startDate
        ? defineds.startOfLast30Days
        : !disableDefaultRange
        ? defineds.startOfLast30Days
        : undefined,
    endDate:
      range && range.endDate
        ? moment(new Date()).utc().toDate()
        : !disableDefaultRange
        ? defineds.endOfLast30Days
        : defineds.endOfToday,
    key: 'selection',
  });

  const [selectDate, setSelectedDate] = useState({});

  const applyChanges = (item) => {
    applyReportDateRange(setRange, dateRange, item);
  };

  return (
    <div className="d-flex align-items-center gap-2">
      {selectDate?.customRange && (
        <Dropdown>
          <Dropdown.Toggle
            className={`btn btn-sm btn-ghost-secondary dropdown-toggle border-0 ${extraClass}`}
          >
            <span className="material-symbols-rounded fs-5">date_range</span>
            {range?.startDate && range?.endDate && (
              <span className="js-daterangepicker-predefined-preview ml-1">
                {setDateFormat(range?.startDate, DATE_FORMAT)} -{' '}
                {setDateFormat(range?.endDate, DATE_FORMAT)}
              </span>
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu className="pt-0">
            <div className="tests report-date">
              <DateRangePicker
                showDateDisplay={false}
                staticRanges={[]}
                inputRanges={[]}
                rangeColors={['var(--secondaryColor)']}
                ranges={[dateRange]}
                onChange={(item) => {
                  setDateRange({
                    ...item.selection,
                    endDate: endOfDay(item.selection.endDate),
                  });
                }}
                months={1}
                maxDate={disablePastDate ? new Date() : undefined}
                direction="vertical"
              />
              <hr style={{ marginTop: 0, paddingTop: 0 }} />
              <div className="drp-buttons">
                <Dropdown.Item
                  className="d-flex align-items-center gap-2"
                  bsPrefix="deal"
                >
                  <button
                    className="cancelBtn btn btn-sm btn-white"
                    type="button"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      applyChanges();
                    }}
                    className="applyBtn btn btn-sm btn-primary"
                    type="button"
                  >
                    Apply
                  </button>
                </Dropdown.Item>
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      )}
      <ButtonFilterDropdown
        buttonText="Date"
        customKeys={['key', 'label']}
        options={staticRanges}
        icon="date_range"
        filterOptionSelected={
          Object.keys(selectDate)?.length > 0 ? selectDate : staticRanges[0]
        }
        handleFilterSelect={(e, item) => {
          setSelectedDate(item);
          applyChanges(item);
        }}
        btnToggleStyle="mr-2"
      />
    </div>
  );
};

DatePickerTraining.defaultProps = {
  disablePastDate: false,
  disableDefaultRange: false,
};

export default DatePickerTraining;
